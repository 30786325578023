import { ChakraProvider } from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { LocaleProvider } from "@imaldev/imal-react-ui/i18n";
import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { ProviderUser } from "./api/user/context";
import { AuthenticationProvider } from "./auth/useAuthentication";
import { extendedTheme } from "./styles/theme";
import { SheetsProvider } from "./views/PostLogin/AppSheetsAbc/SheetCreator/contexts/ContextSheets";

/* TODO: set locale according to domain extension of website. */
/* Use window.location to determine . */
/* Write fn getLocaleFromURL() or sth. */
/* This should be done in locale provider... which is in factory... */

/* Have to add taskWordTrie here? */

export const ContextsWrapper = ({ children }: { children: ReactNode }) => {
  /* Not restting css, because it messes with some styling... */
  return (
    <ChakraProvider resetCSS={false} theme={extendedTheme}>
      <BrowserRouter>
        <LocaleProvider defaultLocale={ELocale.de_DE}>
          <AuthenticationProvider>
            <ProviderUser>
              <SheetsProvider>{children}</SheetsProvider>
            </ProviderUser>
          </AuthenticationProvider>
        </LocaleProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};
