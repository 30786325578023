import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack
} from "@chakra-ui/react";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import VimeoVideo from "@u-wave/react-vimeo";
import { dissoc } from "ramda";
import { useEffect, useRef, useState } from "react";
import { useBoundingRect } from "../../../../../../../../../utils/utilsReact/useBoundingRect";
import { HelpIcon } from "../../../../../../VideoQuestionMark/VideoQuesitonMark";
import { useSheets } from "../../../../../contexts/ContextSheets";
import { sheetConfigs, TaskSheet, TTaskSheet } from "../../../../TaskSheet";
import { A4_HW_RATIO, EmbeddedIMalFonts } from "../../sheets/BlankSheet/BlankSheet";

type Props = {
  sheet: TTaskSheet; // unnecessary? Get sheet with useSheets instead?
  settings: React.ReactElement;
};

/* PDF issues: */
/* 1. On some mobile browsers, not creating png the first time an image is supposed to be shown. The second time the image will load. */
/*      Because the image is not in cache yet? Just have to increase the timeout? */
/* 2.  */

export const EditorContainer = (props: Props) => {
  const [ref, br] = useBoundingRect();
  const refSvgStr = useRef<SVGSVGElement>(null!);
  const refPngCanvas = useRef<HTMLCanvasElement | null>(null!);
  const { updateImage } = useSheets();

  /* Can be null, if navigate away from editor too quickly? */
  const refHelperImg = useRef<HTMLImageElement>(null!);
  const { locale } = useLocale();

  /* Is this necessary? */
  useEffect(() => {
    refHelperImg.current = new Image();
    refHelperImg.current.onerror = () => console.log("img error");
  }, []);

  const refHandleCreateImage = useRef<NodeJS.Timeout>(null!);

  /* Ideally this would be a function. */
  useEffect(() => {
    if (refHandleCreateImage.current) window.clearTimeout(refHandleCreateImage.current);
    const execCreatePng = async () => {
      if (!refSvgStr.current || !refPngCanvas.current) return;

      refHelperImg.current.onload = async () => {
        /* .current can actually be null. */
        const pngCanvas = refPngCanvas.current;
        const context = pngCanvas?.getContext("2d");
        if (!context || !pngCanvas) return;
        context.clearRect(0, 0, ...SIZE_NODE_GEN_PNG_TUPLE);
        context.drawImage(refHelperImg.current, 0, 0, ...SIZE_NODE_GEN_PNG_TUPLE);
        updateImage(props.sheet.id, { forPdf: pngCanvas.toDataURL(undefined, 1) });
      };

      /* Straight svg would be better... but doesn't work in this case for some reason, even though it worked in the PoC CRA-app. */
      //image.src = `data:image/svg+xml;utf8,${stringifySvgNode(refSvgStr.current)}`;
      if (!refSvgStr.current) return;
      const src = convertUTF8CharsToASCIICodes(stringifySvgNode(refSvgStr.current));
      refHelperImg.current.src = `data:image/svg+xml;base64,${btoa(src)}`;
    };

    /* TODO: find better way to fix state-snap-back bug... */
    const handle = setTimeout(execCreatePng, 300);
    refHandleCreateImage.current = handle;

    /* Is there a more efficient way to diff objects? */
  }, [JSON.stringify(dissoc("image", props.sheet))]);

  /* Think about having settings area as tabbed stuff. If do this, maybe take setting components as a dict. */
  /* If key for a setting-kind is in the dict, render tab for this setting-kind. */
  return (
    <Flex flex={1} h="100%" w="100%" p="1em" boxSizing="border-box">
      <Flex flex={1} h="100%" maxW="90em" mx="auto" gridGap="1rem" ref={ref}>
        <Flex h="100%" w={br.height / 1.4} pos="relative">
          <Box pos="absolute" top="5px" left="10px" zIndex={42}>
            <EditorHelpIcon videoUrls={sheetConfigs[props.sheet.name].videos?.[locale] ?? {}} />
          </Box>
          <Box h="100%" w="100%" boxShadow="2xl" boxSizing="content-box" pos="absolute" flex={1}>
            <TaskSheet interactive sheetProps={props.sheet} />
          </Box>
        </Flex>
        <Box bg="#BBB" boxShadow="2xl" flex={1} overflowY="auto" pos="relative">
          <Box h="100%" w="100%" pos="absolute">
            {props.settings}
          </Box>
        </Box>
      </Flex>
      <Box top="-10000px" left="-10000px" pos="absolute">
        <canvas {...SIZE_NODE_GEN_PNG} ref={refPngCanvas} style={{ background: "white" }} />
        <svg {...SIZE_NODE_GEN_PNG} ref={refSvgStr}>
          <EmbeddedIMalFonts />
          <TaskSheet sheetProps={props.sheet} />
        </svg>
      </Box>
    </Flex>
  );
};

export const stringifySvgNode = (svgNode: SVGElement) =>
  new XMLSerializer().serializeToString(svgNode);

const SIZE_NODE_GEN_PNG = getSheetDimensions({ height: 1600 });
const SIZE_NODE_GEN_PNG_TUPLE = Object.values(SIZE_NODE_GEN_PNG) as [width: number, height: number];

function getSheetDimensions(arg: Partial<Dimensions2D>) {
  let result: Dimensions2D = { width: 0, height: 0 };
  if (arg.width) result = { width: arg.width, height: arg.width * A4_HW_RATIO };
  else if (arg.height) result = { width: arg.height / A4_HW_RATIO, height: arg.height };
  return result;
}

type Dimensions2D = { width: number; height: number };

/* Only handles codes relevant for sheet generation. Extend as needed. */
const convertUTF8CharsToASCIICodes = (str: UTF8Str) => {
  const nonAsciiAndUTF8 = [
    ["ö", "&#x00F6;"],
    ["Ö", "&#x00D6;"],
    ["ä", "&#x00E4;"],
    ["Ä", "&#x00C4;"],
    ["ü", "&#x00FC;"],
    ["Ü", "&#x00DC;"],
    ["ß", "&#x00DF;"]
  ] as [char: string, utf8: string][];

  return nonAsciiAndUTF8.reduce(
    (acc, [utf8Char, utf8Encoding]) => acc.replaceAll(utf8Char, utf8Encoding),
    str
  );
};

type UTF8Str = string;

/* TODO: Reusable tab component? with variants? */
const VideoTab = ({ isActive = false as boolean, onClick = () => {}, title = "" }) => {
  return (
    <Box
      _hover={{
        bg: "#eee"
      }}
      borderRadius=".3rem"
      cursor="pointer"
      fontWeight={isActive ? "bold" : ""}
      h="1.5rem"
      onClick={onClick}
      textAlign="center"
      p=".5rem 2.5rem"
      w="7rem"
    >
      {title}
    </Box>
  );
};

enum EVideo {
  EDITOR = "editor",
  METHOD = "method"
}

export const EditorHelpIcon = ({
  videoUrls
}: {
  videoUrls: Partial<{ editor: string; method: string }>;
}) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [activeVideo, setActiveVideo] = useState(videoUrls.editor ? EVideo.EDITOR : EVideo.METHOD);

  return (
    <>
      <Box pos="absolute">
        <Box left="-1rem" pos="absolute" top="-.8rem">
          <HelpIcon onClick={() => setIsVideoModalOpen(true)} />
        </Box>
      </Box>
      <Modal isOpen={isVideoModalOpen} onClose={() => setIsVideoModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody m="1rem">
            <Stack>
              {videoUrls.editor && videoUrls.method && (
                <Flex>
                  <VideoTab
                    isActive={activeVideo === EVideo.EDITOR}
                    onClick={() => setActiveVideo(EVideo.EDITOR)}
                    title="Programm"
                  />
                  <VideoTab
                    isActive={activeVideo === EVideo.METHOD}
                    onClick={() => setActiveVideo(EVideo.METHOD)}
                    title="Methode"
                  />
                </Flex>
              )}
              <VimeoVideo
                responsive
                style={{ marginBottom: "1rem" }}
                video={activeVideo === EVideo.EDITOR ? videoUrls.editor! : videoUrls.method!}
              />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
