import { ECap } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { TTuple, tupleOf } from "@imaldev/imal-factory/ts";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import { Property } from "csstype";
import { produce } from "immer";
import { assoc, pipe as p } from "ramda";
import { localesDisabledEverywhere } from "../../../../../../i18n/useGlobalTxt";
import { colorScheme } from "../../../../../../styles/colors";
import {
  ETaskFontVariant,
  getDefaultTypefaceName,
  TaskFont
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import { getLocaleTypefaces, getTaskFont } from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useSheets } from "../../contexts/ContextSheets";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { PopoverSection } from "../shared/components/settings/Popover/MaterialUI/PopoverMaterialUI";
import { Popover } from "../shared/components/settings/Popover/ReactToolTip/PopoverReactToolTip";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import { SelectFontVariant } from "../shared/components/settings/SelectFont/SelectFontVariant";
import { SelectFontSize } from "../shared/components/settings/SelectFontSize";
import {
  EGuidelines,
  SelectGuidelineSetting
} from "../shared/components/settings/SelectGuidelineSetting/SelectGuidelines";
import { SelectCap } from "../shared/components/settings/SelectSound/SelectCapitalization";
import { SelectSound } from "../shared/components/settings/SelectSound/SelectSound";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { SectionHeader } from "../shared/components/sheets/SectionHeader/SectionHeader";
import {
  deriveRowSounds,
  mkWriteSoundRow,
  TWithWriteSoundRows,
  TWriteSoundRow,
  WriteSoundRow
} from "../shared/components/sheets/WriteSoundRow";
import {
  AbstractSheet,
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TSheetConfig
} from "../shared/misc";
import { setGuidelineSetting } from "../shared/sheetTypes/guidelinesSetting";
import { setCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried } from "../shared/sheetTypes/withFocusedSound";
import { setFont } from "../shared/sheetTypes/withFont";
import { TTaskSheet } from "../TaskSheet";

export type TWriteSoundRows = AbstractSheet &
  TWithWriteSoundRows<6> &
  TWithFontSize & {
    name: ESheet.WRITE_SOUND_ROWS;
  };

export type TWithFontSize = {
  fontSize: EFontSize;
};

type TSetFontSize = <ST extends TTaskSheet & TWithFontSize>(s: ST) => (size: EFontSize) => ST;

/* TOOD?: Use casting for other fns where assoc would be nice too? */
const setFontSize: TSetFontSize = (s) => (size) => assoc("fontSize", size, s) as typeof s;

export const Editor = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TWriteSoundRows };
  const { updateSheet } = useSheets();
  const { locale } = useLocale();

  return (
    <EditorContainer
      sheet={sheet}
      settings={
        <>
          <PopoverSoundInRow />
          <SelectSound
            activeSound={sheet.focusedSound}
            setSound={p(setFocusedSoundCurried(sheet), updateSheet)}
            setCap={p(setCap(sheet), updateSheet)}
            cap={sheet.cap}
          />
          <SelectFont
            activeFontVariant={sheet.font.variant}
            activeTypeface={sheet.font.typeface}
            includeBlank
            setFont={p(setFont(sheet), updateSheet)}
            typefaces={getLocaleTypefaces(locale)}
          />
          <SelectFontSize
            activeSize={sheet.fontSize}
            switchFontSize={p(setFontSize(sheet), updateSheet)}
          />
          <SelectGuidelineSetting
            activeSetting={sheet.guidelinesSetting}
            setSetting={p(setGuidelineSetting(sheet), updateSheet)}
          />
        </>
      }
    />
  );
};

export const PopoverSoundInRow = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TWithWriteSoundRows<number> };
  const { updateSheet } = useSheets();

  return (
    <Popover
      id="rowSoundPopover"
      position={"bottom"}
      getContent={(meta: string | null) => {
        if (!meta) return;
        const [, iRow, iSound] = meta.split(",").map((n) => parseInt(n));

        return (
          <PopoverSection>
            <SelectFontVariant
              includeBlank
              selectedTypeface={sheet.font.typeface}
              selectedVariant={sheet.rows[iRow].rowSounds[iSound].fontVariant}
              toggle
              setFontVariant={(font: TaskFont) => {
                updateSheet(
                  produce(sheet, (s) => {
                    const currentVariant = s.rows[iRow].rowSounds[iSound].fontVariant;
                    s.rows[iRow].rowSounds[iSound].fontVariant =
                      currentVariant === font.variant ? null : font.variant;
                  }) as TTaskSheet
                );
              }}
            />
            <Line margin=".8vh 0" />
            <SelectCap
              activeCap={sheet.rows[iRow].rowSounds[iSound].cap}
              toggleable
              setCap={(cap) => {
                updateSheet(
                  produce(sheet, (s) => {
                    const currentCap = s.rows[iRow].rowSounds[iSound].cap;
                    s.rows[iRow].rowSounds[iSound].cap = currentCap === cap ? null : cap;
                  }) as TTaskSheet
                );
              }}
            />
          </PopoverSection>
        );
      }}
    />
  );
};

/* Find appropriate place to define this. */
/* Replace with Chakra <Divider />? */
export const Line = ({ margin }: { margin?: Property.Margin }) => (
  <div
    style={{
      backgroundColor: colorScheme.GRAY,
      height: ".13vh",
      margin: margin ?? ".3vh 1vh"
    }}
  />
);

/* Could fontSize be used in any other sheets? */
export enum EFontSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}

const translations = {
  [ELocale.de_DE]: {
    write_and_say_every_sound_on_each_line: "Schreibe und sprich jeden Laut"
  },
  [ELocale.en_US]: {
    write_and_say_every_sound_on_each_line: "Write and say every sound on each line"
  },
  [ELocale.es_ES]: {
    write_and_say_every_sound_on_each_line: "Escriba y diga cada sonido en cada linea"
  }
};

const countRowsByFontSize: Record<EFontSize, number> = {
  [EFontSize.LARGE]: 4,
  [EFontSize.MEDIUM]: 5,
  [EFontSize.SMALL]: 6
};

const rowYOffsetByFontSize: Record<EFontSize, number> = {
  [EFontSize.LARGE]: 22,
  [EFontSize.MEDIUM]: 17.4,
  [EFontSize.SMALL]: 14.63
};

export const Sheet: SheetPreview<ESheet.WRITE_SOUND_ROWS> = ({
  sheetProps: sheet,
  interactive = false
}) => {
  const { txt } = useTxt(translations);

  const rowYOffset = rowYOffsetByFontSize[sheet.fontSize];
  const countRows = countRowsByFontSize[sheet.fontSize];

  /* TODO: add per column font setting icon & popover. */
  return (
    <BlankSheet inEditor={interactive} logoSize="small">
      <SectionHeader text={txt.write_and_say_every_sound_on_each_line} y="-13.5%" />
      <svg y="8%">
        {sheet.rows.map((row, i) => (
          <WriteSoundRow
            cap={row.cap ?? sheet.cap!}
            editable={interactive}
            fontSize={sheet.fontSize}
            rowSounds={deriveRowSounds(sheet, i)}
            guidelinesSetting={row.guidelinesSetting ?? sheet.guidelinesSetting}
            key={i}
            iRow={i}
            sound={row.focusedSound || sheet.focusedSound}
            y={i < countRows ? `${rowYOffset * i}%` : "200%"}
          />
        ))}
      </svg>
    </BlankSheet>
  );
};

export const fnMkSheet: MkSheet<TWriteSoundRows> = (arg) => {
  const { locale, pSheet } = getDefaultArgs(arg);
  return {
    ...mkAbstractSheet(),
    border: true,
    cap: ECap.UPPER,
    focusedSound: "a",
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    fontSize: EFontSize.MEDIUM,
    guidelinesSetting: EGuidelines.ALL_WITH_XAREA_BLUE,
    name: ESheet.WRITE_SOUND_ROWS,
    rows: tupleOf(mkWriteSoundRow(), 6),
    ...pSheet
  };
};

export const menuSheets: TMenuSheetsFor<ESheet.WRITE_SOUND_ROWS> = {
  [ELocale.de_DE]: fnMkSheet({
    locale: ELocale.de_DE,
    pSheet: {
      fontSize: EFontSize.SMALL,
      rows: "oeimoch".split("").map((l) => mkWriteSoundRow({ focusedSound: l })) as TTuple<
        TWriteSoundRow,
        6
      >
    }
  }),
  [ELocale.en_US]: fnMkSheet({
    locale: ELocale.en_US,
    pSheet: {
      fontSize: EFontSize.SMALL
    }
  }),
  [ELocale.es_ES]: fnMkSheet({
    locale: ELocale.es_ES,
    pSheet: {
      fontSize: EFontSize.SMALL
    }
  })
};

export const configWriteSoundRows: TSheetConfig<ESheet.WRITE_SOUND_ROWS> = {
  disabledLocales: localesDisabledEverywhere,
  Editor,
  videos: {},
  fnMkSheet,
  menuSheets,
  Sheet
};
