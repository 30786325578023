import { TTaskSheet } from "../../TaskSheet";
import { EGuidelines } from "../components/settings/SelectGuidelineSetting/SelectGuidelines";

/* How would do that thing with union of all sheet types... without creating */
/* cyclical dependency? */
/* But don't we have cyclical deps already? with sheet sub types and TTaskSheet. */

/* Wonder if this should be refactored... */
/* Instead have two props on the sheets with guidelinesettings: array of lines and bg color. */
/* Buttons in gui would then call fn setting the needed values for what we want to show. or whatever */

export type TWithGuidelinesSetting = {
  guidelinesSetting: EGuidelines;
};

type TSetGuidelineSetting = <ST extends TTaskSheet & TWithGuidelinesSetting>(
  s: ST
) => (setting: EGuidelines) => ST;

/* Could even use ramda here. */
export const setGuidelineSetting: TSetGuidelineSetting = (s) => (setting) => ({
  ...s,
  guidelinesSetting: setting
});
