import { Timestamp } from "firebase/firestore";

/* How to work with firebase apps and time? */
/* Anything to be aware of if case of future migration away from firebase? */

/* Create a firebase timestamp with time set to time of function invocation. */
const now = () => new Timestamp(new Date().getTime() / 1000, 0);

/* TODO: actually use now (??) */
const epoch = () => new Timestamp(0, 0);

export const FirebaseTime = {
  epoch,
  now
};
