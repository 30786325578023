import { Center, Flex, Image, Text } from "@chakra-ui/react";
import { colorScheme } from "../../../../styles/colors";
import { SoundSequence } from "../../../../utils/utilsiMAL/soundSequences/sequences";

type Props = {
  active: boolean;
  onClick?: () => void;
  sequence: SoundSequence;
};

export const Book = ({ active, onClick = () => {}, sequence }: Props) => {
  return (
    <Flex
      _hover={{
        bg: colorScheme.HOVER_BACKGROUND,
        cursor: "pointer"
      }}
      as={Center}
      bg={active ? colorScheme.WHITE : ""}
      border={active ? `3px solid ${colorScheme.NAVY}` : `1px solid ${colorScheme.GRAY}`}
      boxSizing="border-box"
      direction="column"
      h="16rem"
      onClick={onClick}
      p="0.1rem 0.5rem"
      transition="background 0.14s ease"
      w="12rem"
    >
      <Image
        border="1px solid lightgray"
        filter={active ? "grayscale(0)" : "grayscale(80%)"}
        m="1rem"
        maxH="70%"
        maxW="70%"
        src={sequence.bookPicture}
      />
      <Text alignSelf="center" fontWeight="bold" mt="1rem" mb="1rem">
        {sequence.displayName}
      </Text>
    </Flex>
  );
};
