import {
  Box,
  Center,
  chakra,
  Icon as ChakraIcon,
  Image,
  Text,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { MouseEvent } from "react";
import { FaCopy, FaTrash } from "react-icons/fa";
import { cs } from "../../../../../styles/colors";
import { useHover } from "../../../../../utils/utilsReact/useHover";
import { MAX_SHEETS, useSheets } from "../contexts/ContextSheets";
import { TaskSheet, TTaskSheet } from "../taskSheets/TaskSheet";

/* TODO: try out the package Thomas used for dnd in no/sv to see if that will */
/* fix lag when have a lot of draggables with base64 images. */
/* Another potential solution is framer-motion's built-in components. */

type Props = {
  closeDrawer: () => void;
  isBeingDragged?: boolean;
  sheet: TTaskSheet;
};

/* Can refactor this to useLongPress? */

/* TODO?: wrap this component in react-beautiful-dnd Draggable stuff. */
/* Abstract away the dragging stuff for readability. */
/* IsActive could be a prop? -> less hooks in ere.. */
export const DrawerSheet = ({ closeDrawer = () => null, isBeingDragged = false, sheet }: Props) => {
  const { txt } = useTxt({
    [ELocale.en_US]: {
      warning_max_pages: (x: number) => `Max ${x} pages!`
    },
    [ELocale.de_DE]: {
      warning_max_pages: (x: number) => `Maximum ${x} Blätter!`
    }
  });
  const { hasHover, hoverHandlers } = useHover();
  const { activeSheet, deleteSheet, duplicateSheet, setIdActiveSheet, sheets } = useSheets();
  const toast = useToast();

  const handleDeleteSheet = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    deleteSheet(sheet.id);
    if (sheet.id === activeSheet?.id) {
      setIdActiveSheet(null);
    }
  };

  const handleDuplicateSheet = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation(); // Prevent click triggering handleSelectSheet.
    if (sheets.length >= MAX_SHEETS && !toast.isActive("max_sheets"))
      toast({
        id: "max_sheets",
        isClosable: false,
        position: "top",
        status: "error",
        title: txt.warning_max_pages(MAX_SHEETS)
      });
    else duplicateSheet(sheet.id);
  };

  const handleSelectSheet = () => {
    setIdActiveSheet(sheet.id);
    closeDrawer();
  };

  const isActive = sheet.id === activeSheet?.id;

  /* Need to play around with onTouchEnd and onTouchStart to fix issue where */
  /* we get same behaviour for clicks and long presses/drags. */
  return (
    <Box
      _hover={{
        borderColor: isActive ? BorderColor.ACTIVE : BorderColor.INACTIVE_HOVER,
        boxShadow: "10px 10px 20px 0px rgba(0,0,0,0.90)"
      }}
      backgroundcolor="none"
      borderColor={
        isActive
          ? BorderColor.ACTIVE
          : isBeingDragged
          ? BorderColor.INACTIVE_HOVER
          : BorderColor.INACTIVE
      }
      borderRadius="0.3rem"
      borderStyle="solid"
      borderWidth=".12rem"
      boxShadow={`10px 10px 20px 0px rgba(${isActive ? "0,0,0,0.65" : "0,0,0,0.35"})`}
      filter={`brightness(${isActive || !activeSheet ? "1.0" : "0.5"})`}
      h="15rem"
      w="fit-content"
      overflow="hidden"
      pos="relative"
      m="0 5px"
      transition="ease .13s"
      onClick={handleSelectSheet}
      {...hoverHandlers}
    >
      {sheet.image.forPdf ? (
        <Image h="100%" src={`${sheet.image.forPdf}`} />
      ) : (
        /* Remove after png rewrite. */
        <TaskSheet sheetProps={sheet} />
      )}
      <SheetNumber number={sheet.pageNumber} />
      {(hasHover || isBeingDragged) && (
        <>
          <Tooltip hasArrow={false} label="Löschen">
            <Center>
              <SheetIcon as={FaTrash} left=".5rem" onClick={handleDeleteSheet} />
            </Center>
          </Tooltip>
          <Tooltip hasArrow={false} label="Duplisieren">
            <Center>
              <SheetIcon as={FaCopy} right=".5rem" onClick={handleDuplicateSheet} />
            </Center>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

enum BorderColor {
  INACTIVE_HOVER = "rgba(241, 241, 241, 0.12)",
  INACTIVE = "rgba(241, 241, 241, 0.12)",
  ACTIVE = "black"
}

const SheetNumber = ({ number }: { number: number }) => {
  return (
    <Box
      alignItems="center"
      bg={cs.TURQUOISE_LIGHT}
      borderRadius="50%"
      boxShadow="0px 0px 4px #222"
      color="white"
      display="flex"
      h="2rem"
      justifyContent="center"
      left="0.18rem"
      position="absolute"
      top="0.2rem"
      w="2rem"
    >
      <Text
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          margin: "0px",
          marginBottom: "1px"
        }}
      >
        {number}
      </Text>
    </Box>
  );
};

const SheetIcon = chakra(ChakraIcon, {
  baseStyle: {
    _hover: { color: "black", cursor: "pointer" },
    bottom: "0.8rem",
    color: "gray",
    fontSize: "1.8rem",
    position: "absolute",
    transition: "ease .07s"
  }
});
