import { Grid } from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import { Property } from "csstype";
import * as R from "ramda";
import { CanBeCollapsible } from "../../../../../../../../../components/Collapsable/Collapsible";
import { nordicSerif } from "../../../../../../../../../utils/utilsiMAL/fonts/nordicSerif/nordicSerif";
import { Nullable } from "../../../../../../../../../utils/utilsTS/dictionary/dict";
import { EGuidelineName, Guidelines } from "../../sheets/Guidelines/Guidelines";
import { Sound } from "../../sheets/Sound/Sound";
import { Activatable, SettingButton } from "../SettingButton/SettingButton";
import { SettingBox } from "../SettingContainer/SettingBox";

const translations = {
  [ELocale.en_US]: {
    guidelines: "Guidelines"
  },
  [ELocale.de_DE]: {
    guidelines: "Lineatur"
  },
  [ELocale.es_ES]: {
    guidelines: "Lineatura"
  }
};

const getGuidelines: (setting: EGuidelines) => EGuidelineName[] = (setting) => {
  if (setting === EGuidelines.BASELINE) return [EGuidelineName.BASE];
  if (setting === EGuidelines.NONE) return [];
  return Object.values(EGuidelineName);
};

export const getXAreaBg: (setting: EGuidelines) => Property.Color = (setting) => {
  if (setting === EGuidelines.ALL_WITH_XAREA_BLUE) return "#a5caf0";
  if (setting === EGuidelines.ALL_WITH_XAREA_GRAY) return "#ddd";
  return "";
};

type PropsSetting = Activatable & {
  setSetting: (setting: EGuidelines) => void;
  setting: EGuidelines;
};

/* Ideally, <><>Guideslines /><Sound /></> should be one component. */
/* Best would be if <Guidelines /> is part of <Sound />. */
export const Setting = ({ active, setSetting, setting }: PropsSetting) => {
  return (
    <SettingButton onClick={() => setSetting(setting)} active={active}>
      <svg height="80%" width="100%" viewBox="0 0 10 10">
        <Guidelines lines={getGuidelines(setting)} xAreaColor={getXAreaBg(setting)} />
        <Sound color="gray" font={nordicSerif.fonts.regular} sound="a" />
      </svg>
    </SettingButton>
  );
};

/* TODO?: merge this with module WithGuidelinesSetting. */
/* TODO?: rename, too? */
export enum EGuidelines {
  ALL = "all",
  ALL_WITH_XAREA_BLUE = "allWithXAreaBlue",
  ALL_WITH_XAREA_GRAY = "allWithXAreaGray",
  BASELINE = "baseline",
  NONE = "none"
}

/* TODO?: should have Record<ELocale, EGuidelinesSetting[]>. */

const getLocaleSettings = (locale: ELocale) => {
  /* Custom order, because Object.values is not right. */
  const allSettings = [
    EGuidelines.NONE,
    EGuidelines.BASELINE,
    EGuidelines.ALL,
    EGuidelines.ALL_WITH_XAREA_GRAY,
    EGuidelines.ALL_WITH_XAREA_BLUE
  ];
  if (locale === ELocale.de_DE) return R.difference(allSettings, [EGuidelines.BASELINE]);
  return allSettings;
};

type Props = CanBeCollapsible & {
  activeSetting?: Nullable<EGuidelines>;
  border?: boolean;
  hideBorderOption?: boolean;
  setSetting?: (setting: EGuidelines) => void;
};

/* These kinds of setting components should not have optional args? */
/* Or what do I think about such optional args overall? */
/* Intention is to render them easily to be able to see how they fit in the ui... */
export const SelectGuidelineSetting = ({
  isCollapsible = true,
  openByDefault = false,
  activeSetting,
  setSetting = () => null
}: Props) => {
  const { locale } = useLocale();
  const { txt } = useTxt(translations);

  return (
    <SettingBox isCollapsible={isCollapsible} openByDefault={openByDefault} title={txt.guidelines}>
      <Grid gap="1vmin" p=".5rem" templateColumns="repeat(6, 1fr)">
        {getLocaleSettings(locale).map((setting, i) => (
          <Setting
            active={setting === activeSetting}
            key={i}
            setSetting={setSetting}
            setting={setting}
          />
        ))}
      </Grid>
    </SettingBox>
  );
};
