import { useEffect, useState } from "react";

export const useViewportDimensions = () => {
  useEffect(() => {
    const handleResize = () => setDimensions(getViewportDimensions());
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);
  const [dimensions, setDimensions] = useState(getViewportDimensions());
  return dimensions;
};

const getViewportDimensions = () => ({
  height: window.innerHeight,
  width: window.innerWidth
});
