import { Box, Flex, Text } from "@chakra-ui/react";
import {
  CanBeCollapsible,
  Chevron,
  Collapsible
} from "../../../../../../../../../components/Collapsable/Collapsible";
import { FlexColumn } from "../../../../../../../../../components/layout/Flex/Flex";
import { WithChildren } from "../../../../../../../../../utils/utilsReact/types";
import { useToggle } from "../../../../../../../../../utils/utilsReact/useToggle";

type Props = WithChildren &
  CanBeCollapsible & {
    title: string;
  };

/* TODO: fix styles before pushing remove-bootstrap changes to production. */
/* TODO: also... switch away from bootstrap collapsible. */
/* use chakra or framer motion instead. */

export const SettingBox = ({
  children,
  isCollapsible = true,
  openByDefault = false,
  title: titleText = ""
}: // TODO: add `titleSubtext` (for additional info)
Props) => {
  const [isOpen, toggleIsOpen] = useToggle(openByDefault);

  const title = (
    <Text color="#333" fontSize="1.25rem" fontWeight="bold">
      {titleText}
    </Text>
  );
  /* TODO: fix styling... should be same when collapsible and not. */
  /* Only thing which changes is if is collapsible or not. */
  /* Need a content container. */

  return isCollapsible ? (
    <Collapsible
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
      header={
        <Flex
          bg="#F7F7F7"
          borderTop="2px #cacaca solid"
          borderBottom="1px #cacaca solid"
          h="fit-content"
          p="1rem 1.5rem .5rem"
        >
          <Box userSelect="none">{title}</Box>
          <Box ml="auto" transform={`rotate(${isOpen ? 0 : 90}deg)`} transition="ease .1s">
            <Chevron />
          </Box>
        </Flex>
      }
    >
      <Box bg="#fff" w="100%">
        {children}
      </Box>
    </Collapsible>
  ) : (
    <FlexColumn>
      <Box bg="#eee" mb=".8rem" p=".8rem">
        {title}
      </Box>
      <Box mx=".8rem" w="inherit">
        {children}
      </Box>
    </FlexColumn>
  );
};
