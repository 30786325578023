/* Expose all fonts here? */
import { germanBayernDottedB64 } from "./germanBayernDottedB64";
import { germanBayernDottedStartpointsB64 } from "./germanBayernDottedStartpointsB64";
import { germanBayernOutlineB64 } from "./germanBayernOutlineB64";
import { germanBayernOutlineNumbersB64 } from "./germanBayernOutlineNumbersB64";
import { germanBayernOutlineStartpointsB64 } from "./germanBayernOutlineStartpointsB64";
import { germanBayernRegularB64 } from "./germanBayernRegularB64";

import { germanNorthDottedB64 } from "./germanNorthDottedB64";
import { germanNorthDottedStartpointsB64 } from "./germanNorthDottedStartpointsB64";
import { germanNorthOutlineB64 } from "./germanNorthOutlineB64";
import { germanNorthOutlineNumbersB64 } from "./germanNorthOutlineNumbersB64";
import { germanNorthOutlineStartpointsB64 } from "./germanNorthOutlineStartpointsB64";
import { germanNorthRegularB64 } from "./germanNorthRegularB64";

import { germanSouthDottedB64 } from "./germanSouthDottedB64";
import { germanSouthDottedStartpointsB64 } from "./germanSouthDottedStartpointsB64";
import { germanSouthOutlineB64 } from "./germanSouthOutlineB64";
import { germanSouthOutlineNumbersB64 } from "./germanSouthOutlineNumbersB64";
import { germanSouthOutlineStartpointsB64 } from "./germanSouthOutlineStartpointsB64";
import { germanSouthRegularB64 } from "./germanSouthRegularB64";

import { germanGrundschriftDottedB64 } from "./germanGrundschriftDottedB64";
import { germanGrundschriftDottedStartpointsB64 } from "./germanGrundschriftDottedStartpointsB64";
import { germanGrundschriftOutlineB64 } from "./germanGrundschriftOutlineB64";
import { germanGrundschriftOutlineNumbersB64 } from "./germanGrundschriftOutlineNumbersB64";
import { germanGrundschriftOutlineStartpointsB64 } from "./germanGrundschriftOutlineStartpointsB64";
import { germanGrundschriftRegularB64 } from "./germanGrundschriftRegularB64";

import { nordicSansSerifDottedB64 } from "./nordicSansSerifDottedB64";
import { nordicSansSerifDottedStartpointsB64 } from "./nordicSansSerifDottedStartpointsB64";
import { nordicSansSerifOutlineB64 } from "./nordicSansSerifOutlineB64";
import { nordicSansSerifOutlineStartpointsB64 } from "./nordicSansSerifOutlineStartpointsB64";
import { nordicSansSerifRegularB64 } from "./nordicSansSerifRegularB64";

import { nordicSerifDottedB64 } from "./nordicSerifDottedB64";
import { nordicSerifDottedStartpointsB64 } from "./nordicSerifDottedStartpointsB64";
import { nordicSerifOutlineB64 } from "./nordicSerifOutlineB64";
import { nordicSerifOutlineStartpointsB64 } from "./nordicSerifOutlineStartpointsB64";
import { nordicSerifRegularB64 } from "./nordicSerifRegularB64";
import { nordicStartpointsB64 } from "./nordicStartpointsB64";

type Base64 = string;

export const getEmbeddedFonts = () =>
  Object.entries(fontFamilyToB64Font).reduce(
    (result, [fontFamily, fontBase64]) =>
      `${result}
@font-face {
font-family: ${fontFamily};
src: url(
'data:application/font-woff;charset=utf-8;base64,${fontBase64}'
)}`,
    ""
  );

export const fontFamilyToB64Font: { [key in string]: Base64 } = {
  /* nordicSansSerif (previously imal B) */
  nordicSansSerifDotted: nordicSansSerifDottedB64,
  nordicSansSerifDottedStartpoint: nordicSansSerifDottedStartpointsB64,
  nordicSansSerifRegular: nordicSansSerifRegularB64,
  nordicSansSerifOutline: nordicSansSerifOutlineB64,
  nordicSansSerifOutlineStartpoint: nordicSansSerifOutlineStartpointsB64,

  /* nordicSerif (previously imal A ) */
  nordicSerifDotted: nordicSerifDottedB64,
  nordicSerifDottedStartpoint: nordicSerifDottedStartpointsB64,
  nordicSerifRegular: nordicSerifRegularB64,
  nordicSerifOutline: nordicSerifOutlineB64,
  nordicSerifOutlineStartpoint: nordicSerifOutlineStartpointsB64,

  /* Used for borth nordic fonts. */
  nordicStartpoints: nordicStartpointsB64,

  germanBayernDotted: germanBayernDottedB64,
  germanBayernDottedStartpoints: germanBayernDottedStartpointsB64,
  germanBayernOutline: germanBayernOutlineB64,
  germanBayernOutlineNumbers: germanBayernOutlineNumbersB64,
  germanBayernOutlineStartpoints: germanBayernOutlineStartpointsB64,
  germanBayernRegular: germanBayernRegularB64,

  germanGrundschriftDotted: germanGrundschriftDottedB64,
  germanGrundschriftDottedStartpoints: germanGrundschriftDottedStartpointsB64,
  germanGrundschriftOutline: germanGrundschriftOutlineB64,
  germanGrundschriftOutlineNumbers: germanGrundschriftOutlineNumbersB64,
  germanGrundschriftOutlineStartpoints: germanGrundschriftOutlineStartpointsB64,
  germanGrundschriftRegular: germanGrundschriftRegularB64,

  germanNorthDotted: germanNorthDottedB64,
  germanNorthDottedStartpoints: germanNorthDottedStartpointsB64,
  germanNorthOutline: germanNorthOutlineB64,
  germanNorthOutlineNumbers: germanNorthOutlineNumbersB64,
  germanNorthOutlineStartpoints: germanNorthOutlineStartpointsB64,
  germanNorthRegular: germanNorthRegularB64,

  germanSouthDotted: germanSouthDottedB64,
  germanSouthDottedStartpoints: germanSouthDottedStartpointsB64,
  germanSouthRegular: germanSouthRegularB64,
  germanSouthOutline: germanSouthOutlineB64,
  germanSouthOutlineNumbers: germanSouthOutlineNumbersB64,
  germanSouthOutlineStartpoints: germanSouthOutlineStartpointsB64
};
