import { ChakraProps, Icon as ChakraIcon } from "@chakra-ui/react";
import { Property } from "csstype";
import { ReactNode } from "react";
import { Card } from "react-bootstrap";
import { Collapse } from "react-collapse";
import { FaChevronDown } from "react-icons/fa";
import { HiChevronDoubleDown } from "react-icons/hi";
import { WithChildren } from "../../utils/utilsReact/types";
import "./Collapsible.css";

export type PositionChevron = "middle" | "right" | "none";

export const mapPositionToMargin = (position: PositionChevron) => {
  switch (position) {
    case "middle":
      return "0";
    case "right":
    default:
      return "auto";
  }
};

/* TODO: move to gernic type alias dir. */
export type Direction = "right" | "left" | "up" | "down";

/* TODO: use react icon. */

export type Size = "large" | "small";

/* TODO: chakra factory. */

type PropsChevron = ChakraProps & {
  double?: boolean;
};

export const Chevron = ({
  color = "#444",
  double = false,
  fontSize = "1.4rem",
  ...rest
}: PropsChevron) => {
  return (
    <ChakraIcon
      as={double ? HiChevronDoubleDown : FaChevronDown}
      color={color}
      fontSize={fontSize}
      text-shadow="3px 6px rgba(255,165,0,.75)"
      {...rest}
    />
  );
};

/* Helper type for containers which wrap Collapsible. */
export type CanBeCollapsible = {
  isCollapsible?: boolean;
  openByDefault?: boolean;
};

type Props = WithChildren & {
  bg?: Property.Background;
  color?: Property.Color;
  overrideHoverHandlers?: { onMouseOver: () => void; onMouseLeave: () => void };
  isOpen?: boolean;
  toggleIsOpen?: () => void;
  header?: ReactNode;
};

export const Collapsible = ({
  bg,
  color,
  children,
  overrideHoverHandlers,
  isOpen = false,
  toggleIsOpen,
  header
}: Props) => {
  /* What is corresponding component in chakra? There is none; need to create/add one manually. */
  /* Can find one from chok-ui? */
  /* TODO: why no take up 100% of width. SettingsContainer need to do this maybe? Possible temp hack */
  /* is to add some extra invisible boxes to the sound selector... */
  return (
    <Card
      style={{
        color,
        padding: 0,
        margin: 0,
        width: "100%"
      }}
    >
      <Card.Header
        style={{
          background: bg ?? "#fafafa",
          cursor: "pointer",
          padding: "0rem",
          margin: 0,
          width: "100%"
        }}
        onClick={toggleIsOpen}
        {...overrideHoverHandlers}
      >
        {header}
      </Card.Header>
      <Collapse isOpened={isOpen}>
        <Card.Body style={{ backgroundColor: "rgb(241 241 241 / 12%)", width: "100%" }}>
          {children}
        </Card.Body>
      </Collapse>
    </Card>
  );
};
