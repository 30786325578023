import {
  findTaskWords,
  getTaskWords,
  newTrie,
  Node as TrieNode,
  TFilter,
  TWord
} from "@imaldev/imal-factory/abc";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { mergeRight } from "ramda";
import { createContext, useCallback, useContext, useMemo } from "react";
import { WithChildren } from "../../../../../utils/utilsReact/types";

export const ContextTaskWords = createContext<{
  getWords: (filter: TFilter) => TWord[]; // TODO: get right thingy here...
  trie: TrieNode;
}>(null!);

/* TODO: handle creating new trie when locale changes. Will happen very rarely, so don't think about this yet. */

export const TaskWordProvider = ({ children }: WithChildren) => {
  const { locale } = useLocale();
  const trie = useMemo(() => newTrie(getTaskWords(locale), locale), [locale]);

  const getWords = useCallback(
    (filter: TFilter) => findTaskWords(trie, mergeRight({ locale }, filter)).map((tw) => tw.word),
    [locale, trie]
  );

  return (
    <ContextTaskWords.Provider value={{ getWords, trie }}>{children}</ContextTaskWords.Provider>
  );
};

/* Type of this filter doesn't need locale. */
/* Filter could be array of filters? Sometimes might want more than 1 backup filter. */
/* useWords or useTaskWords? */
export const useWords = (filter: TFilter = {}, fallback: TFilter = {}) => {
  const { getWords, trie } = useContext(ContextTaskWords);

  const words: TWord[] = useMemo(() => {
    let words = getWords(filter);
    if (words.length === 0) words = getWords(fallback);
    return words;
  }, [JSON.stringify(filter), JSON.stringify(fallback), JSON.stringify(trie)]);

  return { getWords, words };
};
