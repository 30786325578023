import { SoundSequence } from "../../../sequences";
import img_mimi from "./mimi_die_lesemaus.jpg";

export const mimi: SoundSequence = {
  bookPicture: img_mimi,
  displayName: "Mimi die Lesemaus",
  id: "d3926445-37c0-4e8e-a069-82e3549c818e",
  name: "Mimi die Lesemaus",
  sounds: [
    "m",
    "a",
    "i",
    "o",
    "l",
    "t",
    "r",
    "s",
    "e",
    "w",
    "u",
    "d",
    "n",
    "sch",
    "ei",
    "k",
    "nk",
    "b",
    "f",
    "au",
    "g",
    "ng",
    "ck",
    "p",
    "pf",
    "ie",
    "ß",
    "ch",
    "chs",
    "h",
    "ä",
    "ö",
    "ü",
    "st",
    "sp",
    "z",
    "tz",
    "eu",
    "v",
    "äu",
    "j",
    "qu",
    "y",
    "c",
    "x"
  ]
};
