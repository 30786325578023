import { applyCap, ECap, getSounds, TSound } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { arrayOf, ensureSize, shuffle, takeRandoms, TTuple } from "@imaldev/imal-factory/ts";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import { concat, head, pipe as p, prepend, tail, without } from "ramda";
import { useCallback, useEffect } from "react";
import { localesDisabledEverywhere } from "../../../../../../i18n/useGlobalTxt";
import {
  ETaskFontVariant,
  getDefaultTypefaceName
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import { getTaskFont } from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useSheets } from "../../contexts/ContextSheets";
import { useWords } from "../../contexts/WordsContext";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import { SelectSound } from "../shared/components/settings/SelectSound/SelectSound";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { SectionHeader } from "../shared/components/sheets/SectionHeader/SectionHeader";
import {
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import { setCap, TWithCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried, TWithFocusedSound } from "../shared/sheetTypes/withFocusedSound";
import { setFont, TWithFont } from "../shared/sheetTypes/withFont";
import { setWords, TWithWords, TWordObj, TWriteWord } from "../shared/sheetTypes/withWords";

const N_SHEET_WORDS = 18;

export type TEncircleWordsWithSound = TNewSheetType<ESheet.ENCIRCLE_WORDS_WITH_SOUND> &
  TWithCap &
  TWithFont &
  TWithFocusedSound &
  TWithWords<typeof N_SHEET_WORDS>;

export const fnMkSheet: MkSheet<TEncircleWordsWithSound> = (arg) => {
  const { locale, pSheet } = getDefaultArgs(arg);
  const sheet: TEncircleWordsWithSound = {
    ...mkAbstractSheet(),
    cap: ECap.NORMAL,
    focusedSound: "f",
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    name: ESheet.ENCIRCLE_WORDS_WITH_SOUND,
    words: arrayOf("", 18).map((w) => ({
      value: w
    })) as TTuple<TWriteWord, 18>,
    ...pSheet
  };

  /* Now that gettingTWs is async, need to change this. Two options? */
  /* 1. make taskWords an optional arg for fns of type TNewSheet<T>. */
  /* 2. don't think about images in here, and instead use composition with */
  /* the blank sheet. */
  /* if (sheet.words[0].value === "")
   *   sheet = setWords(getWords({ locale, taskWords }) as any)(sheet) as any;
   */

  return sheet;
};

export const menuSheets: TMenuSheetsFor<ESheet.ENCIRCLE_WORDS_WITH_SOUND> = {
  // don't understand why this works? why aren't words random?
  [ELocale.de_DE]: fnMkSheet({
    locale: ELocale.de_DE,
    pSheet: {
      words: [
        /* TODO: get new words with nouns when getValidWords works properly again... */
        "rot",
        "sauer",
        "üben",
        "suchen",
        "läuten",
        "jagen",
        "feiern",
        "heulen",
        "feuern",
        "danke",
        "weinen",
        "drei",
        "bequem",
        "fegen",
        "beten",
        "hören",
        "elf",
        "böse"
      ].map((w) => ({ value: w })) as TTuple<TWordObj, 18>
    }
  }),
  [ELocale.en_US]: fnMkSheet({
    locale: ELocale.en_US
  }),
  [ELocale.es_ES]: fnMkSheet({
    locale: ELocale.es_ES,
    pSheet: {
      words: [
        "rosa",
        "yoyo",
        "verde",
        "zapato",
        "mano",
        "trigo",
        "nuez",
        "bus",
        "puente",
        "tambor",
        "morder",
        "pedo",
        "tres",
        "carta",
        "novio",
        "cuervo",
        "arco",
        "oso"
      ].map((w) => ({ value: w })) as TTuple<TWordObj, 18>
    }
  })
};

const translations = {
  [ELocale.de_DE]: {
    encircle_words_with_sound: (s: string) =>
      /* This might be different based on locale. If german, show both small and large. */
      /* For other locales, show only letter in the active locale? German is */
      /* special because capitalization of nouns. */
      /* TODO: this doesn't work for multigraphs and sounds which are never at the start of a word. */
      `Kreise Wörter mit ${applyCap(s, ECap.UPPER)} oder ${s} ein.`
  },
  [ELocale.es_ES]: {
    encircle_words_with_sound: (s: string) => `Rodea las palabras con ${s}.`
  }
};

export const Sheet: SheetPreview<ESheet.ENCIRCLE_WORDS_WITH_SOUND> = ({ sheetProps: sheet }) => {
  const { txt } = useTxt(translations);

  return (
    <BlankSheet logoSize="normal">
      <SectionHeader text={`${txt.encircle_words_with_sound(sheet.focusedSound)}`} y={-20} />
      <svg x="-1%" y="4%">
        <ellipse
          fill="white"
          stroke="#333"
          strokeWidth=".05rem"
          cx="20"
          cy="11.5"
          rx="14.4"
          ry="7.8"
        />
      </svg>
      {sheet.words.map((w, i) => (
        <text
          fontFamily={sheet.font.fontFamily}
          fontSize=".5rem"
          key={i}
          textAnchor="middle"
          x={`${20 + 30 * (i % 3)}%`}
          y={`${13.9 + 15.5 * Math.floor(i / 3)}%`}
        >
          {applyCap(w.value, sheet.cap)}
        </text>
      ))}
    </BlankSheet>
  );
};

export const Editor = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TEncircleWordsWithSound };
  const { updateSheet } = useSheets();
  const { getWords } = useWords();
  const { locale } = useLocale();

  /* Logic used for getting words here also should be used when creating sheets in fnMkSheet. */
  /*  */
  const getWordsLocal = useCallback(
    (sound: TSound) => {
      const N_W_FOCUSED = 6;
      const withFocused = ensureSize(N_W_FOCUSED, getWords({ mainSound: sound }));

      const withoutFocused = takeRandoms(
        N_SHEET_WORDS - N_W_FOCUSED,
        getWords({ sounds: without([sound], getSounds(locale) ?? []) })
      );

      return prepend(
        head(withFocused) ?? "err",
        shuffle(concat(tail(withFocused), withoutFocused))
      );
    },
    [sheet.focusedSound]
  );

  useEffect(() => {
    if (sheet.words[0].value === "")
      updateSheet(setWords((s: TEncircleWordsWithSound) => getWordsLocal(s.focusedSound))(sheet));
  }, []);

  return (
    <EditorContainer
      sheet={sheet}
      settings={
        <>
          <SelectSound
            activeSound={sheet.focusedSound}
            cap={sheet.cap}
            setCap={p(setCap(sheet), updateSheet)}
            setSound={p(
              setFocusedSoundCurried(sheet),
              setWords((s: TEncircleWordsWithSound) => getWordsLocal(s.focusedSound)),
              updateSheet
            )}
          />
          <SelectFont
            activeFontVariant={sheet.font.variant}
            activeTypeface={sheet.font.typeface}
            setFont={p(setFont(sheet), updateSheet)}
            hideVariantSelect
          />
        </>
      }
    />
  );
};

export const configEncircleWordsWithSound: TSheetConfig<ESheet.ENCIRCLE_WORDS_WITH_SOUND> = {
  disabledLocales: localesDisabledEverywhere,
  Editor,
  videos: {},
  fnMkSheet,
  menuSheets,
  Sheet
};
