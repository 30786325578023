import { ECap, TTaskWord, TWord } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { arrayOf, ensureSize, TIndex, TTuple } from "@imaldev/imal-factory/ts";
import { produce } from "immer";
import * as R from "ramda";
import { times } from "ramda";
import { createContext, useContext } from "react";
import { ETaskFontVariant } from "../../../../../../../utils/utilsiMAL/fonts/shared";
import { WithChildren } from "../../../../../../../utils/utilsReact/types";
import { TTaskSheet } from "../../TaskSheet";

/* S should be a tasksheet... */
export type TWordGetter = (arg1: {
  locale: ELocale;
  taskWords: TTaskWord[];
}) => (s: any) => TWord[];

export type TWordObj = { value: string };

export const mkWordObj = (w: TWord) => ({ value: w });

/* ==== TWithWords ==== */

/* Possible to have default type and extends at the same time? */
export type TWithWords<N extends number, WT = TWordObj> = {
  words: TTuple<WT, N>;
};

type TSetWord = <S extends TWithWords<number>>(iw: number) => (w: TWord) => (s: S) => S;

export const setWord: TSetWord = (iw) => (w) => (s) =>
  produce(s, (si) => {
    si.words[iw].value = w;
  });

type TSetWords = <ST extends TWithWords<number>>(getWords: (s: ST) => TWord[]) => (s: ST) => ST;

export const setWords: TSetWords = (getWords) => (s) => {
  const newWords = ensureSize(s.words.length, getWords(s));
  return produce(s, (si) => {
    si.words.forEach((ww, i) => {
      ww.value = newWords[i];
    });
  });
};

/* ==== TWithWriteWords ==== */
export type TWriteWord = {
  fontVariant: ETaskFontVariant | null;
  fontVariantPerSound: (ETaskFontVariant | null)[];
  value: TWord;
};

export const mkWriteWord: (pWriteWord: Partial<TWriteWord>) => TWriteWord = (pWriteWord) => {
  return {
    fontVariant: null,
    fontVariantPerSound: arrayOf(null, 7),
    value: "",
    ...pWriteWord
  };
};

/* Otherwise, might conflict somehow with words which have { words: Word[] }. */
/* remove withCap and stuff? */
export type TWithWriteWords<N extends number> = TWithWords<N, TWriteWord>;

/* TODO: see how would work with lens. */
/*   Can try out lens on one of these fns first? Don't have to implement lenses everywhere */
/*   at the same time. */
/* Try a lens after sheet works. */
/* Wanna end up with a solution which is simple... even if a more complex one might be "nice". */

/* ======== */
type TSetWriteWord = <ST extends TWithWriteWords<number>>(
  iw: TIndex
  /* Check if type here gets resolved when clean up sheet sub types. */
) => (w: TWord) => (s: ST) => ST;

/* As discussed, experiment with typings, union types, and currying. */

export const setWriteWord: TSetWriteWord = (iw) => (w) => (s) => {
  return produce(s, (s) => {
    s.words[iw].value = w;
    s.words[iw].fontVariantPerSound = times(() => null, s.words[iw].fontVariantPerSound.length);
  });
}; /* ===== */

/* ======== */
type TSetWriteWords = <ST extends TWithWriteWords<number>>(
  getWords: (s: ST) => TWord[]
) => (s: ST) => ST;

/* This should actually take the words to set? */
/* As the editor will already know which words to set for the edited sheet. */
export const setWriteWords: TSetWriteWords = (getWords) => (s) => {
  // const newWords = ensureSize(s.words.length, getWords(s));
  const newWords = getWords(s);
  return produce(s, (s) => {
    s.words.forEach((w, i) => {
      w.fontVariantPerSound.fill(null);
      w.value = newWords[i];
    });
  });
}; /* ===== */

type SetWriteWords2 = <Sheet extends TWithWriteWords<number>>(
  words: TWord[]
) => (s: Sheet) => Sheet;

export const setWriteWords2: SetWriteWords2 = (words: TWord[]) => (sheet) => {
  return produce(sheet, (s) => {
    words.forEach((w, i) => {
      s.words[i].value = w;
    });
  });
};

/* ======== */
type TResetFontVariantsForWordSounds = <S extends TWithWriteWords<number>>(
  iWord: TIndex
) => (s: S) => S;

/* TODO?: setWord for writeWord should use this? */
/* When would use this? only when a new word is set? */
export const resetFontVariantsForWordSounds: TResetFontVariantsForWordSounds = (iw) => (s) => {
  return produce(s, (s: any) => {
    (s.words as TWriteWord[])?.[iw].fontVariantPerSound.fill(null);
  });
};

/* Does this work the way we want it to? */
export const setWriteWordSoundFontVariant = R.curry(
  <N extends number>(
    sheet: TTaskSheet & TWithWriteWords<N>,
    iWord: number,
    iSound: number,
    v: ETaskFontVariant
  ) =>
    produce(sheet, (s: any) => {
      s.words[iWord].fontVariantPerSound[iSound] =
        v === s.words[iWord].fontVariantPerSound[iSound] ? null : v;
    })
);

export type WithReadingWords = TWithWords<6> & {
  wordsCap: ECap | null;
  usesShortWords: boolean; // not being used right now?
};

export const ContextSelectableWords = createContext<{ words: TWord[] }>({ words: [] });

export const ProviderSelectableWords = ({ words, children }: WithChildren & { words: TWord[] }) => (
  <ContextSelectableWords.Provider value={{ words }}>{children}</ContextSelectableWords.Provider>
);

export const useSelectableWords = () => useContext(ContextSelectableWords).words;
