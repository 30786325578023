import { Center, Flex, Grid, Image, Input, Text } from "@chakra-ui/react";
import { TWord } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { useState } from "react";
import { useIsOnScreen } from "../../../../../../../../../utils/utilsReact/hooks/useIsOnScreen";
import { useImageByWord } from "../../../../../../context/WordImages";
import { SettingBox } from "../SettingContainer/SettingBox";

type Props = {
  activeWord?: TWord;
  isCollapsible?: boolean;
  setWord: (word: string) => void;
  words: TWord[];
};

export const SelectWordWithImage = ({
  activeWord = "Gans",
  isCollapsible = true,
  setWord,
  words
}: Props) => {
  const [search, setSearch] = useState("");
  const { txt } = useTxt({
    [ELocale.de_DE]: { word: "Wort" },
    [ELocale.en_US]: { word: "Word" },
    [ELocale.es_ES]: { word: "Palabra" }
  });

  return (
    <SettingBox isCollapsible={isCollapsible} title={txt.word}>
      <Flex alignContent="flex-start" bg="#bbbfbf" flexDir="column" h="25rem" w="inherit">
        <Input
          bg="#fff"
          m=".5rem"
          minH="2.5rem"
          onChange={(e) => setSearch(e.target.value) /* (de_DE) scharf-s? */}
          placeholder={"Suchen..."}
          value={search}
          w="parent"
        />
        <Flex
          flexWrap="wrap"
          justifyItems="flex-start"
          alignContent="flex-start"
          h="100%"
          overflowY="scroll"
          w="inherit"
        >
          {(words.length === 0 ? ["foo"] : words)
            .filter((w) => w.toLowerCase().includes(search))
            .map((word, i) => (
              <WordCard
                isActive={activeWord === word}
                key={i}
                onClick={() => setWord(word)}
                word={word}
              />
            ))}
        </Flex>
      </Flex>
    </SettingBox>
  );
};

type PropsWordCard = {
  isActive?: boolean; // should be a layer?
  onClick?: () => void;
  word: string;
};

const WordCard = ({ isActive = false, onClick: handleOnClick, word }: PropsWordCard) => {
  const { imageByWord } = useImageByWord();
  const { ref, isOnScreen } = useIsOnScreen();

  return (
    <Center
      _hover={{ cursor: "pointer" }}
      h="5rem"
      onClick={handleOnClick}
      ref={ref}
      w="11rem"
      sx={{
        boxShadow: isActive ? "0px 0px 0px 4px green" : "",
        bg: "#fff",
        borderRadius: "base",
        m: ".5rem"
      }}
    >
      {isOnScreen && (
        <Grid
          alignItems="center"
          boxSizing="border-box"
          justifyItems="center"
          h="auto"
          templateColumns="3fr 4fr"
        >
          <Image maxH="85%" maxW="100%" src={`data:image/jpeg;base64,${imageByWord[word]}`} />
          <Text color="black" fontSize="2.1vh" fontWeight="bold" h="fit-content" m="0">
            {word}
          </Text>
        </Grid>
      )}
    </Center>
  );
};
