import {
  Box,
  Center,
  Grid,
  Icon as ChakraIcon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text
} from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { format as dateFormat } from "date-fns";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { TUser } from "../../../../../api/user/user";
import { FlexColumn } from "../../../../../components/layout/Flex/Flex";
import { useGlobalTxt } from "../../../../../i18n/useGlobalTxt";
import { useHover } from "../../../../../utils/utilsReact/useHover";

const translations = {
  [ELocale.de_DE]: {
    last_used: "Letzter Gebrauch"
  },
  [ELocale.en_US]: {
    last_used: "Last used"
  }
};

/* Figma draft: https://www.figma.com/files/drafts?fuid=978440116339772372 */

const PlusIcon = () => {
  /* I guess ref looks better/simpler. */
  const { hasHover, hoverHandlers } = useHover();

  return (
    <Box
      {...hoverHandlers}
      alignItems="center"
      bg="lightgray"
      borderRadius="2vh"
      cursor={hasHover ? "pointer" : "default"}
      display="flex"
      h="5vh"
      justifyContent="center"
      mt="2vh"
      p="1vh"
      w="5vh"
    >
      <ChakraIcon as={FaPlus} color={hasHover ? "#888" : "#555"} h="3vh" size={50} w="3vh" />
    </Box>
  );
};

type PropsCardAppInfo = {
  access: {
    expiration: Date;
    isTrial?: boolean;
  };
  dateLastUsed: Date;
  name: string;
};

const CardAppInfo = ({ access, dateLastUsed, name }: PropsCardAppInfo) => {
  const { txt } = useTxt(translations);
  const { txt_g } = useGlobalTxt();

  return (
    <Grid
      gap="2vh"
      gridTemplateColumns="2fr 3fr"
      bg="#ddd"
      borderRadius="1vh"
      cursor="pointer"
      m="0.5vh"
      p="0.5vh"
    >
      <Text fontWeight="bold"> App:</Text> <Text>{name}</Text>
      <Text fontWeight="bold">{txt_g.access}:</Text>
      <Text>{`${dateFormat(access.expiration, "yyyy-MM-dd/HH:mm")}${
        access.isTrial ? " (trial)" : null
      }`}</Text>
      <Text fontWeight="bold">{txt.last_used}:</Text>
      <span>{dateFormat(dateLastUsed, "yyyy-MM-dd/HH:mm")}</span>
    </Grid>
  );
};

type Props = {
  close: () => void;
  isShowing: boolean;
  user: TUser;
};

export const ModalManageUser = ({ close, isShowing, user }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { txt_g } = useGlobalTxt();

  useEffect(() => {
    const timeoutHandle = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearInterval(timeoutHandle);
  }, []);

  return (
    <Modal isOpen={isShowing} onClose={close} size="4xl">
      <ModalOverlay />
      <ModalContent w="70rem">
        <ModalHeader>
          <ModalCloseButton ml="auto" close={close} onClose={close} />
        </ModalHeader>
        <ModalBody>
          <Box h="fit-content" p="2vh" pos="relative">
            <Grid gap="1vh" templateColumns="2fr 3fr" h="inherit">
              {/* TODO: on hover, display pencil to edit data field (or sth). */}
              <Grid
                autoRows="min-content"
                columns={2}
                gap={3}
                m="auto"
                p="1rem"
                spacing={5}
                templateColumns="2fr 3fr"
              >
                <Text>{txt_g.first_name}:</Text>
                <Text>{user.firstName === "" ? "n/a" : user.firstName}</Text>
                <Text>{txt_g.last_name}</Text>
                {user.lastName === "" ? "n/a" : user.lastName}
                <Text>{txt_g.email}: </Text>
                {user.email}
                <Text>{txt_g.school_name}: </Text>
                {user.schoolName === "" ? "n/a" : user.schoolName}
              </Grid>
              <FlexColumn align="center" h="40vh">
                {isLoading ? (
                  <Center h="inherit">
                    <Spinner />
                  </Center>
                ) : (
                  <>
                    <FlexColumn flex={1} overflow="auto">
                      {[
                        /* todo: put button at bottom always. scroll above. */
                        ...[""].map((e, i) => (
                          <CardAppInfo
                            access={{ expiration: new Date(), isTrial: true }}
                            dateLastUsed={new Date()}
                            key={i}
                            name="iMAL sheets ABC de_DE"
                          />
                        ))
                      ]}
                    </FlexColumn>
                    <PlusIcon key={9001} />
                  </>
                )}
              </FlexColumn>
            </Grid>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
