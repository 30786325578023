import { Box, Grid, GridItem, HStack, Text } from "@chakra-ui/react";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { format } from "date-fns";
import firestore, { orderBy } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ArchivedBooklet } from "../../../../../api/booklet";
import { wGetCollectionDocs } from "../../../../../api/shared";
import { useHover } from "../../../../../utils/utilsReact/useHover";
import { TaskSheet } from "../../SheetCreator/taskSheets/TaskSheet";

type PropsBookCard = {
  book: ArchivedBooklet;
  isOpen?: boolean;
  toggleIsOpen: () => void;
};

const Row = ({ book, isOpen, toggleIsOpen }: PropsBookCard) => {
  /* const [isOpen, toggleIsOpen] = useToggle(false); */
  const { hasHover, hoverHandlers } = useHover();

  /* Doesn't need to be GridItem; can just be container with `w="100%"` */
  return (
    <GridItem
      {...hoverHandlers}
      bg={isOpen ? "#a1b3cf" : hasHover ? "#b1bfd7" : "#b9c8df"}
      border="2px solid #8193af"
      borderRadius=".5rem"
      boxShadow="md"
      colSpan={3}
      onClick={toggleIsOpen}
      overflow="hidden"
      m=".3rem"
    >
      <Grid cursor="pointer" templateColumns="repeat(3, 1fr)">
        {[
          format(book.created.toDate(), "yyyy-MM-dd/HH:mm"),
          book.authorEmail,
          book.sheets.length.toString()
        ].map((label, i) => (
          <Box key={i}>
            <Text col="white" fontWeight={isOpen ? "bold" : "default"} m="1rem">
              {label}
            </Text>
          </Box>
        ))}
      </Grid>
      {!isOpen ? null : (
        <HStack
          bg="#b9c8df"
          boxShadow="md"
          h="8rem"
          onClick={(e) => e.stopPropagation()}
          p=".4rem"
          w="100%"
        >
          {book.sheets.map((s, i) => (
            <TaskSheet sheetProps={s} key={i} />
          ))}
        </HStack>
      )}
    </GridItem>
  );
};

export const ListBooks = () => {
  const { locale } = useLocale();
  const [orderByCol, setOrderByCol] = useState<keyof ArchivedBooklet>("created");
  const [sortOrder, setSortOrder] = useState<firestore.OrderByDirection>("desc");
  const [books, setBooks] = useState([] as ArchivedBooklet[]);

  /* Try to use this again, later(tm). */
  // const [books, isLoading, error] = useCollectionDataOnce(
  //   query(
  //     getCollection(`/app_sheets_abc/${locale}/printed_books/`),
  //     orderBy(orderByCol)
  //   ) as firestore.Query<DbArchivedBook>
  // );
  const [iOpened, setIOpened] = useState<number | null>(null);

  useEffect(() => {
    const execGetDocs = async () => {
      const docs = await wGetCollectionDocs(
        `/app_sheets_abc/${locale}/printed_books`,
        orderBy(orderByCol, sortOrder)
      );

      docs.forEach(async (doc) => {
        const book = doc.data() as ArchivedBooklet;
        console.log(book);
        setBooks((prev) => [...prev, book]);
      });
    };
    execGetDocs();
  }, []);

  return (
    <>
      <Text color="red" fontWeight="bold" m="1rem">
        NB: saving printed books is currently disabled.
      </Text>
      <Grid templateColumns="repeat(3, 1fr)">
        {/* TODO: i18n-ify labels. */}
        {["Date", "Email", "Pages"].map((label, i) => (
          <GridItem colSpan={1} key={i}>
            <Text fontSize="1.5rem" fontWeight="bold" m="1rem">
              {label}
            </Text>
          </GridItem>
        ))}
        {(books ?? []).map((b, i) => (
          <Row
            book={b}
            isOpen={i === iOpened}
            key={i}
            toggleIsOpen={() => {
              iOpened === i ? setIOpened(null) : setIOpened(i);
            }}
          />
        ))}
      </Grid>
    </>
  );
};
