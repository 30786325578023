import { ETaskFontVariant, ETypeface, TaskFont, TTaskTypeface } from "../shared";

/* Could potentially generate font related files with a script? */
/* Idk... yagni? */

const germanBayernDotted: TaskFont = {
  fontFamily: "germanBayernDotted",
  typeface: ETypeface.GERMAN_BAYERN,
  variant: ETaskFontVariant.DOTTED
};

const germanBayernDottedStartpoint: TaskFont = {
  fontFamily: "germanBayernDottedStartpoints",
  typeface: ETypeface.GERMAN_BAYERN,
  variant: ETaskFontVariant.DOTTED_STARTPOINTS
};

const germanBayernOutline: TaskFont = {
  fontFamily: "germanBayernOutline",
  typeface: ETypeface.GERMAN_BAYERN,
  variant: ETaskFontVariant.OUTLINE
};

const germanBayernOutlineNumbers: TaskFont = {
  fontFamily: "germanBayernOutlineNumbers",
  typeface: ETypeface.GERMAN_BAYERN,
  variant: ETaskFontVariant.OUTLINE_NUMBERS
};

const germanBayernOutlineStartpoints: TaskFont = {
  fontFamily: "germanBayernOutlineStartpoints",
  typeface: ETypeface.GERMAN_BAYERN,
  variant: ETaskFontVariant.OUTLINE_STARTPOINTS
};

const germanBayernRegular: TaskFont = {
  fontFamily: "germanBayernRegular",
  typeface: ETypeface.GERMAN_BAYERN,
  variant: ETaskFontVariant.REGULAR
};

export const germanBayern: TTaskTypeface = {
  id: "d970912d-16bb-4f62-9997-5322006e4097",
  name: ETypeface.GERMAN_BAYERN,
  nameForDisplay: "iMAL C",
  fonts: {
    dotted: germanBayernDotted,
    dottedStartpoints: germanBayernDottedStartpoint,
    outline: germanBayernOutline,
    outlineNumbers: germanBayernOutlineNumbers,
    outlineStartpoints: germanBayernOutlineStartpoints,
    regular: germanBayernRegular
  }
};
