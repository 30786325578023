import { TSound } from "@imaldev/imal-factory/abc";
import { produce } from "immer";
import * as R from "ramda";
import { SoundSequence } from "../../../../../../../utils/utilsiMAL/soundSequences/sequences";
import { TWithFocusedSound } from "./withFocusedSound";

export type TWithSounds = {
  sounds: TSound[];
};

/* Want to compose sometimes? */
/* If compose then where want sheet arg? sheet as first arg. */
/* Also some fns where sheet will be only arg. */
/* Therefore, always have sheet be first arg. */

/* ===== */

/* All these update-sheet fns ought to be curried. */
/* Need this one, as well as enableSoundsToFocused. */
type TGetSounds = <S extends TWithSounds>(
  sequence: SoundSequence,
  sheet: S,
  last: TSound // what's a good name?
) => S;

/* might as well flip last two args? */
const getActiveSounds: TGetSounds = (sequence, sheet, lastInSeq) => {
  const wNewSounds = {
    ...sheet,
    sounds: R.append(
      lastInSeq,
      R.takeWhile((s) => s !== lastInSeq, sequence.sounds)
    )
  };
  return wNewSounds;
};

/* Does it work with R.pipe like this? */
const getActiveSoundsCurried = R.curry(getActiveSounds);
export { getActiveSoundsCurried as getActiveSounds };

/* ===== */

/* = = = = */
type TToggleSound = <S extends TWithSounds>(sheet: S) => (s: TSound) => S;

/* TODO: should not be able to toggle sound if it is the ony active sound. */
export const toggleSound: TToggleSound = (sheet) => (s) => ({
  ...sheet,
  sounds: sheet.sounds.includes(s)
    ? sheet.sounds.filter((selS) => selS !== s)
    : R.append(s, sheet.sounds)
});
/* = = = = */

/* = = = = */
/* Should be another place where can use this. */
type TEnableSoundsToFocused = <S extends TWithFocusedSound & TWithSounds>(
  sequence: SoundSequence
) => (sheet: S) => S;

export const enableSoundsToFocused: TEnableSoundsToFocused = (sequence) => (sheet) =>
  produce(sheet, (sheet) => {
    sheet.sounds = R.append(
      sheet.focusedSound,
      R.takeWhile((seqS) => seqS !== sheet.focusedSound, sequence.sounds)
    );
  });
/* = = = = */
