import { sendEmailVerification, signInWithEmailAndPassword, AuthError } from "firebase/auth";
import LogRocket from "logrocket";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { ENVIRONMENT } from "../constants";
import { WithChildren } from "../utils/utilsReact/types";
import { auth } from "./auth";

export const ContextAuthentication = createContext<{
  authStatus: EAuthStatus;
  sendEmailVerificationEmail: () => void;
  signIn: (email: string, password: string) => void;
  signOut: () => void;
}>(null!);

/* Missing some errors? */
export enum EAuthStatus {
  AUTHENTICATED = "authenticated",
  ERROR_INVALID_USERNAME_OR_PASSWORD = "error:invalid_username_or_password",
  ERROR_NOT_CONNECTED_TO_INTERNET = "error:not_connected_to_internet",
  LOADING = "loading",
  SIGNED_OUT = "signedOut"
}

/* TODO?: make sign in error a union string? */

/* TODO?: Move this hook to same dir as LogIn. */
/* do this for most (if not all) of the auth related hooks. */
export const AuthenticationProvider = ({ children }: WithChildren) => {
  const [authStatus, setAuthStatus] = useState(EAuthStatus.SIGNED_OUT);

  /* TODO(?): Can be replaced/improved with react-fire/react-firebase-hooks package? */
  /* Had issues when trying it out with doc subscriptions. Try again later. */
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setAuthStatus(!!user ? EAuthStatus.AUTHENTICATED : authStatus);
    });

    return unsub;
  }, []);

  const signIn = (email: string, password: string) => {
    setAuthStatus(EAuthStatus.LOADING);
    /* async/await doesn't work here. Why? */
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setAuthStatus(EAuthStatus.AUTHENTICATED); // wonder if can do this elsewhere?

        /* Possibly move this else where later. */
        /* Would be nice to tag sessions with... */
        /* If user is free, subscribed, new user, etc. */
      })
      .catch((e) => {
        console.log("Error signing in:");
        console.log(e.message);
        if (e.message.includes("network-request-failed")) {
          console.log("hi");
          setAuthStatus(EAuthStatus.ERROR_NOT_CONNECTED_TO_INTERNET);
        } else setAuthStatus(EAuthStatus.ERROR_INVALID_USERNAME_OR_PASSWORD);
      });
  };

  const signOut = () => {
    auth.signOut();
    /* Replace with useEffect? */
    /* Or does react-firebase-hooks contain a fn for this? */
    setAuthStatus(EAuthStatus.SIGNED_OUT);
  };

  /* Own hook for this? */
  /* todo: handle error "auth/too-many-requests". */
  /* Perhaps send this email on backend. Nice article: */
  /* https://medium.com/firebase-developers/generating-email-action-links-with-the-firebase-admin-sdk-4b9d5e2cf914 */
  const sendEmailVerificationEmail = () => {
    auth.currentUser &&
      sendEmailVerification(auth.currentUser, {
        handleCodeInApp: false,
        url: "https://www.dev.imalabc.de"
      });
  };

  return (
    <ContextAuthentication.Provider
      value={{
        authStatus,
        sendEmailVerificationEmail,
        signIn,
        signOut
      }}
    >
      {children}
    </ContextAuthentication.Provider>
  );
};

export const useAuthentication = () => useContext(ContextAuthentication);
