import { ReactNode } from "react";
import ReactTooltip from "react-tooltip";
import styles from "./Popover.module.css"; // TODO: Use css-in-js instead.

type Position = "right" | "left" | "top" | "bottom";

type Props = {
  getContent: (metaData: string) => ReactNode;
  id: string;
  position?: Position;
};

const PopoverReactTooltip = ({ getContent, id, position }: Props) => {
  /* Why has id? */
  return (
    <ReactTooltip
      className={styles.tooltip}
      clickable
      delayHide={100}
      effect="solid"
      getContent={getContent}
      globalEventOff="click"
      id={id}
      place={position}
    />
  );
};

export { PopoverReactTooltip as Popover };
