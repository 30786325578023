import { Routes } from "react-router";
import { Route } from "react-router-dom";
import { AboutUs } from "./routes/AboutUs";
import { Faq } from "./routes/Faq";
import { Home } from "./routes/Home/Home";
import { InfoParents } from "./routes/InfoParents";
import { InfoSchool } from "./routes/InfoSchool";
import { LiveCourses } from "./routes/LiveCourses";

/* Invalid paths lead to `/home`. */
export const HomeRoutes = () => (
  <Routes>
    <Route path="about_us" element={<AboutUs />} />
    <Route path="live_courses" element={<LiveCourses />} />
    <Route path="faq" element={<Faq />} />
    <Route path="info_parents" element={<InfoParents />} />
    <Route path="info_school" element={<InfoSchool />} />
    <Route element={<Home />} />
  </Routes>
);
