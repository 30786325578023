import { applyCap, ECap, getSounds, getSoundsInWord, TWord } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { arrayOf, shuffle, takeRandoms, TTuple } from "@imaldev/imal-factory/ts";
import { EFontLevel } from "@imaldev/imal-react-ui/abc";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import { append, assoc, concat, flatten, map, not, pipe as p, uniq, values, without } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { localesDisabledEverywhere } from "../../../../../../i18n/useGlobalTxt";
import {
  ETaskFontVariant,
  getDefaultTypefaceName
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import { getTaskFont } from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useDefaultSequence } from "../../../context/AppSheetsAbcUserData/contextDefaultSequence";
import { useSheets } from "../../contexts/ContextSheets";
import { useWords } from "../../contexts/WordsContext";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { getSoundFontLevels } from "../OneLargeSound";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import { SelectSounds } from "../shared/components/settings/SelectSounds/SelectSounds";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { SectionHeader } from "../shared/components/sheets/SectionHeader/SectionHeader";
import { Sound } from "../shared/components/sheets/Sound/Sound";
import { WriteWordInImageSmall } from "../shared/components/sheets/WriteWordInImageSmall/WriteWordInImageSmall";
import {
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import { setCap, TWithCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried, TWithFocusedSound } from "../shared/sheetTypes/withFocusedSound";
import { setFont, TWithFont } from "../shared/sheetTypes/withFont";
import { enableSoundsToFocused, toggleSound, TWithSounds } from "../shared/sheetTypes/withSounds";
import {
  mkWriteWord,
  ProviderSelectableWords,
  setWord,
  setWords,
  setWriteWords,
  setWriteWordSoundFontVariant,
  TWithWriteWords,
  TWriteWord,
  useSelectableWords
} from "../shared/sheetTypes/withWords";

const N_WORDS = 4;

export type TTrackAndWWII4x = TNewSheetType<ESheet.TRACK_AND_WWII_4X> &
  TWithCap &
  TWithFocusedSound &
  TWithFont &
  TWithSounds &
  TWithWriteWords<typeof N_WORDS>;

const Editor = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TTrackAndWWII4x };
  const { updateSheet } = useSheets();
  const { defaultSequence } = useDefaultSequence();
  const { getWords } = useWords();

  const availableWords = useMemo(
    () => getWords({ sounds: append(sheet.focusedSound, sheet.sounds), withImage: true }),
    append(sheet.focusedSound, sheet.sounds)
  );

  /* TODO: clean this up... */
  const getWords2 = useCallback(
    (s: TTrackAndWWII4x) => {
      const ws = takeRandoms(N_WORDS, getWords({ sounds: s.sounds, withImage: true }));

      let extraWs: TWord[] = [];
      if (ws.length < N_WORDS) {
        extraWs = p(
          () => s.sounds,
          map((s) =>
            getWords({
              withImage: true,
              mainSound: s,
              mainSoundPos: "first"
            })
          ),
          flatten,
          uniq,
          without(ws),
          (ws) => takeRandoms(N_WORDS - ws.length, ws)
        )();
      }

      const result = concat(ws, extraWs);

      /* TODO: add an addional check to re-use words if not enough words. */
      /* Should only happen veeeery rarely... */

      return shuffle(result);
    },
    [JSON.stringify(sheet)]
  );

  useEffect(() => {
    updateSheet(setWords(getWords2)(sheet));
  }, []);

  return (
    <ProviderSelectableWords words={availableWords}>
      <EditorContainer
        sheet={sheet}
        settings={
          <>
            <SelectSounds
              cap={sheet.cap}
              focusedSound={sheet.focusedSound}
              selectedSounds={sheet.sounds}
              setCap={p(setCap(sheet), updateSheet)}
              onSelectSound={p(
                setFocusedSoundCurried(sheet),
                enableSoundsToFocused(defaultSequence),
                setWriteWords(getWords2) as any,
                updateSheet
              )}
              toggleSound={p(
                toggleSound(sheet) as any,
                setWriteWords(getWords2) as any,
                updateSheet
              )}
            />
            <SelectFont
              activeFontVariant={sheet.font.variant}
              activeTypeface={sheet.font.typeface}
              includeBlank
              setFont={p(setFont(sheet), updateSheet)}
            />
          </>
        }
      />
    </ProviderSelectableWords>
  );
};

export const fnMkSheet: MkSheet<TTrackAndWWII4x> = (arg) => {
  const { locale, pSheet } = getDefaultArgs(arg);
  const sheet: TTrackAndWWII4x = {
    ...mkAbstractSheet(),
    cap: ECap.NORMAL,
    focusedSound: "f",
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    name: ESheet.TRACK_AND_WWII_4X,
    sounds: getSounds(locale),
    words: arrayOf("", 4).map((w) => mkWriteWord({ value: w })) as TTuple<TWriteWord, 4>,
    ...pSheet
  };
  return sheet;
};

const menuSheets: TMenuSheetsFor<ESheet.TRACK_AND_WWII_4X> = {
  [ELocale.de_DE]: fnMkSheet({
    pSheet: {
      font: getTaskFont(getDefaultTypefaceName(ELocale.de_DE), ETaskFontVariant.REGULAR),
      sounds: getSounds(ELocale.de_DE),
      words: ["Schaf", "Erde", "Auto", "Biber"].map((w) =>
        mkWriteWord({ value: w })
      ) as TTrackAndWWII4x["words"]
    }
  }),
  [ELocale.es_ES]: fnMkSheet({
    pSheet: {
      font: getTaskFont(getDefaultTypefaceName(ELocale.es_ES), ETaskFontVariant.REGULAR),
      focusedSound: "a",
      words: ["foca", "faro", "avena", "nota"].map((w) => mkWriteWord({ value: w })) as TTuple<
        TWriteWord,
        4
      >
    }
  })
};

const translations = {
  [ELocale.de_DE]: {
    write_and_say: "Schreibe und sprich",
    write_words_in_images: "Schreibe die Wörter",
    write_or_draw_words_with_sound_in_the_box: (sound: string) =>
      `Schreibe oder male Wörter mit ${sound}`
  },
  [ELocale.en_US]: {
    write_and_say: "Write and say",
    write_or_draw_words_with_sound_in_the_box: (sound: string) =>
      `Write or draw words with ${sound} in the box`
  }
};

export const Sheet: SheetPreview<ESheet.TRACK_AND_WWII_4X> = ({
  interactive,
  sheetProps: sheet
}) => {
  const { txt } = useTxt(translations);
  const { locale } = useLocale();
  /* interesting... in other sheets, just passing setWord-fn down from editor... */
  /* Makes things easier, or leads to extra code... */
  const { updateSheet } = useSheets();

  const W_CONTENT = 96;
  const W_MARGIN = (100 - W_CONTENT) / 2;

  const selectableWords = useSelectableWords();

  /* TODO: Fix how font works with tracking sound: should never be blank... */
  /* TODO: fix vertical positioning of sound. */
  return (
    <BlankSheet>
      <SectionHeader
        text={`${txt.write_and_say} ${sheet.focusedSound.toLocaleUpperCase()}`}
        y={-20}
      />
      <svg
        height="50%"
        width={`${W_CONTENT + 1}%`}
        x={W_MARGIN + 2} // ??
        y={
          getSoundFontLevels(applyCap(sheet.focusedSound, sheet.cap), sheet.font.typeface).includes(
            EFontLevel.DESCENDER
          )
            ? "-4%"
            : "0%"
        }
      >
        <Sound
          cap={sheet.cap}
          color="gray"
          font={getTaskFont(sheet.font.typeface, ETaskFontVariant.REGULAR)}
          sound={sheet.focusedSound}
          width={`${W_CONTENT}%`}
        />
      </svg>

      <SectionHeader text={txt.write_words_in_images} y="26.5%" />
      {/* TODO: bg and content inside same container/svg node. */}
      <rect fill="black" height="50.6%" width="96%" x="2.5%" y="48%" />
      <svg height="100%" x="3%" y="48.5%" width="95%">
        {sheet.words.map((word, i) => (
          <WriteWordInImageSmall
            cap={sheet.cap}
            column={i % 2}
            font={sheet.font}
            interactive={interactive}
            key={i}
            row={Math.floor(i / 2)}
            setSoundFontVariant={p(setWriteWordSoundFontVariant(sheet, i), updateSheet)}
            setWord={(w) => updateSheet(setWord(i)(w)(sheet) as TTrackAndWWII4x)}
            popoverWords={selectableWords}
            word={word.value}
            fontPerSound={word.fontVariantPerSound.map((variant) =>
              variant ? getTaskFont(sheet.font.typeface, variant ?? ETaskFontVariant.DOTTED) : null
            )}
            onlyFirstLetter={not(
              getSoundsInWord(locale, word.value).every((s) =>
                append(sheet.focusedSound, sheet.sounds).includes(s)
              )
            )}
          />
        ))}
      </svg>
    </BlankSheet>
  );
};

export const configTrackAndWWII4x: TSheetConfig<ESheet.TRACK_AND_WWII_4X> = {
  disabledLocales: localesDisabledEverywhere,
  Editor,
  videos: {},
  fnMkSheet,
  menuSheets,
  Sheet
};
