import { Box, Button, chakra } from "@chakra-ui/react";
import { applyCap, ECap } from "@imaldev/imal-factory/abc";
import { TTuple, tupleOf } from "@imaldev/imal-factory/ts";
import { produce } from "immer";
import { useState } from "react";
import { FaCog, FaTimes } from "react-icons/fa";
import {
  ETaskFontVariant,
  newBlankFont,
  TaskFont
} from "../../../../../../../../../utils/utilsiMAL/fonts/shared";
import {
  getTaskFont,
  taskTypefaceByName
} from "../../../../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useHover } from "../../../../../../../../../utils/utilsReact/useHover";
import { NullableFields } from "../../../../../../../../../utils/utilsTS/dictionary/dict";
import { MkFn } from "../../../../../../../../../utils/utilsTS/miscTypes/types";
import { useSheets } from "../../../../../contexts/ContextSheets";
import { TTaskSheet } from "../../../../TaskSheet";
import { TWithGuidelinesSetting } from "../../../sheetTypes/guidelinesSetting";
import { TWithCap } from "../../../sheetTypes/withCap";
import { TWithFocusedSound } from "../../../sheetTypes/withFocusedSound";
import { EFontSize, TWithFont } from "../../../sheetTypes/withFont";
import { Popover } from "../../settings/Popover/MaterialUI/PopoverMaterialUI";
import { SelectFont } from "../../settings/SelectFont/SelectFont";
import {
  EGuidelines,
  getXAreaBg,
  SelectGuidelineSetting
} from "../../settings/SelectGuidelineSetting/SelectGuidelines";
import { SelectSound } from "../../settings/SelectSound/SelectSound";
import { EGuidelineName, Guidelines } from "../Guidelines/Guidelines";

/* TODO?: move this module to shared/sheetTypes? */
/*        ie. sheetType takes presedence over component. */

/* Move to same module as TWithFont? */
export type TWithFontVariant = { fontVariant: ETaskFontVariant };

export type TRowSound = TWithCap & TWithFontVariant;

export type TWithWriteSoundRows<N extends number> = TWithFocusedSound &
  TWithFont &
  TWithCap &
  TWithGuidelinesSetting & {
    rows: TTuple<TWriteSoundRow, N>;
  };

export type TWriteSoundRow = NullableFields<
  TWithGuidelinesSetting & TWithFontVariant & TWithFocusedSound & TWithCap
> & {
  rowSounds: TTuple<NullableFields<TRowSound>, 7>;
};

export const mkWriteSoundRow: MkFn<TWriteSoundRow> = (pRow) => {
  const row: TWriteSoundRow = {
    cap: null,
    focusedSound: null,
    fontVariant: null,
    rowSounds: tupleOf({ cap: null, fontVariant: null }, 7),
    guidelinesSetting: null,
    ...pRow
  };
  return row;
};

/* ==== Helpers for parents of <WriteSoundRow />; not used locally. ==== */
/* Mapping sheet state to props for <WriteSoundRow />. */

export const getFontForRow = (forSheet: TaskFont, forRow: ETaskFontVariant | null) => {
  if (!forRow) return forSheet;
  if (forRow === "blank") return newBlankFont(forSheet.typeface);
  return taskTypefaceByName[forSheet.typeface].fonts[forRow] ?? forSheet;
};

export const getFontsForRowSounds = (
  fallback: TaskFont,
  fontPerSound: TTuple<ETaskFontVariant | null, 7>
) => {
  return fontPerSound.map((variant, i) => {
    if (
      !variant &&
      i === 0 &&
      [ETaskFontVariant.BLANK, ETaskFontVariant.STARTPOINTS].includes(fallback.variant)
    )
      return taskTypefaceByName[fallback.typeface].fonts.dottedStartpoints!;
    if (!variant && fallback.variant === ETaskFontVariant.BLANK)
      return newBlankFont(fallback.typeface);
    if (!variant) return fallback;
    return getTaskFont(fallback.typeface, variant);
  }) as TTuple<TaskFont, 7>;
};

type TDeriveRowSoundsProps = (
  sheet: TWithWriteSoundRows<number>,
  iRow: number
) => TTuple<RowSoundWFont, 7>;

export const deriveRowSounds: TDeriveRowSoundsProps = (sheet, iRow) => {
  const row = sheet.rows[iRow] ?? mkWriteSoundRow();

  const getFont = (rowSound: NullableFields<NullableFields<TRowSound>>, iRowSound: number) => {
    const typeface = taskTypefaceByName[sheet.font.typeface];

    const variant = rowSound.fontVariant ?? row.fontVariant ?? sheet.font.variant;

    if (iRowSound === 0 && variant === ETaskFontVariant.BLANK)
      return typeface.fonts.outlineStartpoints;

    /* wtf is happening here... */

    return variant === ETaskFontVariant.BLANK
      ? newBlankFont(sheet.font.typeface)
      : taskTypefaceByName[sheet.font.typeface].fonts[
          rowSound.fontVariant || row.fontVariant || sheet.font.variant
        ]!;
  };

  return row.rowSounds?.map((rs, i) => ({
    capitalization: rs.cap ?? row.cap ?? sheet.cap,
    font: getFont(rs, i)
  })) as TTuple<RowSoundWFont, 7>;
};

/* ===== ===== */
/* TODO: German teacher asked for a font size/line height akin normal paper/notebooks. */

type SoundRenderConfig = {
  countSounds: number;
  rowOffset: number;
  soundSpacing: number;
};

type SoundLength = 1 | 2 | 3;

/* Current typing doesn't look correct... But seems to work regardless. */
/* TODO: refactor to use Record<>. */
/* TODO: refactor to not be ugly. */
const renderSoundConfigs: {
  [size in EFontSize]:
    | { [sound in string]?: Partial<SoundRenderConfig> }
    | ({ fontSize: number } & {
        [length in SoundLength]: SoundRenderConfig;
      });
} = {
  large: {
    fontSize: 20,
    sch: {
      // TODO: use sound specific settings if they exist
      /* Implement this first for sounds that are problematic ('NG' with */
      /* font size large). */
      rowOffset: 15
    },
    NG: {
      countSounds: 2,
      rowOffset: 15,
      soundSpacing: 30
    },
    1: {
      countSounds: 4,
      rowOffset: 12.5,
      soundSpacing: 24.5
    },
    2: {
      countSounds: 5,
      rowOffset: 16.5,
      soundSpacing: 33.5
    },
    3: {
      countSounds: 2,
      rowOffset: 27,
      soundSpacing: 45
    }
  },
  medium: {
    fontSize: 16,
    "1": {
      countSounds: 5,
      rowOffset: 11.2,
      soundSpacing: 19.3
    },
    "2": {
      countSounds: 3,
      rowOffset: 18,
      soundSpacing: 31
    },
    "3": {
      countSounds: 3,
      rowOffset: 16,
      soundSpacing: 34
    }
  },
  small: {
    fontSize: 12,
    "1": {
      countSounds: 6,
      rowOffset: 9.5,
      soundSpacing: 16.1
    },
    "2": {
      countSounds: 4,
      rowOffset: 13,
      soundSpacing: 24.4
    },
    "3": {
      countSounds: 4,
      rowOffset: 18,
      soundSpacing: 31
    }
  }
};

type RowRenderConfig = {
  rowCap: ECap;
  rowSounds: TTuple<RowSoundWFont, 7>;
  fontSize: EFontSize;
};

/* TODO: refactor to named paramters. */
const renderSounds = (
  sound: string,
  { rowCap, rowSounds, fontSize: rowFontSize }: RowRenderConfig,
  iRow: number,
  editable = false
) => {
  const renderValues = renderSoundConfigs[rowFontSize];
  const fontSize = renderValues.fontSize as number;

  /* todo: Make typing nicer to read... */
  const { countSounds, rowOffset, soundSpacing } = renderValues[sound.length as SoundLength] as {
    countSounds: number;
    rowOffset: number;
    soundSpacing: number;
  };

  /* Always render all sounds, but only 'countSounds' sounds */
  /* are inside viewport. */
  return rowSounds.map((rowSound, i) => {
    return (
      <RowSound
        cap={rowSound.capitalization ?? rowCap}
        editable={editable}
        font={rowSound.font}
        fontSize={fontSize}
        iCol={i}
        iRow={iRow}
        key={i}
        sound={sound}
        x={i < countSounds ? i * soundSpacing + rowOffset : 250}
        y="70.5%"
      />
    );
  });
};

/* WTF, why is x and y not of same type?? */
type PropsRowSound = {
  cap: ECap;
  editable?: boolean;
  font: TaskFont;
  fontSize?: number;
  iCol: number;
  iRow: number;
  sound?: string;
  x?: number;
  y?: string;
};

const RowSound = ({
  cap = ECap.UPPER,
  editable = false,
  font,
  fontSize = 10,
  iCol,
  iRow,
  sound = "sch",
  x = 10,
  y = "3"
}: PropsRowSound) => {
  const { hasHover, hoverHandlers } = useHover();

  /* Fml this is so dirty. */
  /* I guess this is why id was required in popover... */
  const commonProps = {
    "data-event": "click",
    "data-for": editable ? "rowSoundPopover" : null, // Pass data to element with this id.
    "data-tip": `rowSoundPopover,${iRow},${iCol}`, // Value used to  in popover.
    cursor: editable && hasHover ? "pointer" : undefined,
    ...hoverHandlers
  };

  const hoverStyles = {
    fill: "#ccc",
    opacity: 0.35,
    rx: 3.5
  };

  const showRectForBlankSound = hasHover && font.variant === ETaskFontVariant.BLANK;

  return (
    <>
      <rect
        height="60%"
        opacity={0}
        width="15.5%"
        x={`${x - 7.5}%`}
        y={"25%"}
        {...(showRectForBlankSound ? hoverStyles : null)}
        {...commonProps}
      />
      <text
        fill={editable && hasHover ? "#555" : font.variant === "regular" ? "#AAAAAA" : "default"}
        fontFamily={font.fontFamily}
        fontSize={editable && hasHover ? fontSize + 1 : fontSize}
        x={`${x}%`}
        y={y}
        textAnchor="middle"
        {...commonProps}
      >
        {font.variant === ETaskFontVariant.BLANK ? "" : applyCap(sound, cap)}
      </text>
    </>
  );
};

/* ***** */

/* TODO: put somewhere else... */
/* hmmm... some components can be put together with types? */
export const getGuidelineNames = (setting: EGuidelines) => {
  if (setting === EGuidelines.BASELINE) return [EGuidelineName.BASE];
  if (setting === EGuidelines.NONE) return [];
  return Object.values(EGuidelineName);
};

const rowHeightByFontSize: Record<EFontSize, number> = {
  [EFontSize.LARGE]: 20,
  [EFontSize.MEDIUM]: 16,
  [EFontSize.SMALL]: 12
};

/* NB: different from the type RowSound defined in ... . */
/* TODO?: should pass fonyFamily instead of TaskFont. */
export type RowSoundWFont = {
  font: TaskFont;
  capitalization: ECap;
};

/* Union type with WriteSoundRow? */
type Props = {
  cap?: ECap;
  editable?: boolean;
  fontSize?: EFontSize;
  guidelinesSetting?: EGuidelines;
  iRow?: number;
  rowSounds: TTuple<RowSoundWFont, 7>;
  sound?: string;
  y?: string;
};

export type { Props as WriteSoundRowProps };

export const WriteSoundRow = ({
  cap = ECap.UPPER,
  editable = false,
  fontSize: fontSize = EFontSize.LARGE,
  guidelinesSetting = EGuidelines.BASELINE,
  iRow = 0,
  rowSounds,
  sound = "i",
  y = "5%"
}: Props) => {
  const rowHeight = rowHeightByFontSize[fontSize];
  const guidelines = getGuidelineNames(guidelinesSetting);

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const soundRenderConfig: RowRenderConfig = {
    rowCap: cap,
    rowSounds,
    fontSize
  };

  /* yeah... try HOC for SettingsIcon. */
  return (
    <svg height={`${rowHeight}%`} width="100%" y={y}>
      <svg width="90%" x="4%">
        <Guidelines verticalBorders xAreaColor={getXAreaBg(guidelinesSetting)} lines={guidelines} />
        {renderSounds(sound, soundRenderConfig, iRow, editable)}
      </svg>
      {editable && (
        <>
          <PopoverWriteSoundRow anchorEl={anchorEl} iRow={iRow} setAnchorEl={setAnchorEl} />
          {/* WTF is this? did we have multiple SettingsIcon's before? */}
          {/* Can delete this? */}
          <SettingsIcon x="95%" y="3%" anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </>
      )}
    </svg>
  );
};

/* Wtf does this even do. */
const CogIcon = chakra(FaCog);

type PropsSettingsIcon = {
  anchorEl: SVGSVGElement | null;
  x?: string;
  setAnchorEl: React.Dispatch<React.SetStateAction<SVGSVGElement | null>>;
  y?: string;
};

// Uses Material UI popover!! (╯°□°）╯︵ ┻━┻
/* Will it be possible to only use one kind of popover? */
export const SettingsIcon = ({ anchorEl, setAnchorEl, x = "0%", y = "0%" }: PropsSettingsIcon) => {
  const { hasHover, hoverHandlers } = useHover();
  return (
    <svg
      cursor={hasHover ? "pointer" : "default"}
      // don't need data-event prop?
      data-event="click"
      onClick={(e) => setAnchorEl(e.currentTarget)}
      viewBox="0 0 16 16"
      width="5%"
      x={x}
      y={y}
    >
      {/* Bg-rect to ease mouse hovering. */}
      <rect {...hoverHandlers} width="100%" height="100%" opacity={0} />
      <CogIcon
        {...hoverHandlers}
        color={anchorEl ?? hasHover ? "#555" : "#999"}
        transition="ease .065s"
      />
    </svg>
  );
};

type PropsPopoverRow = {
  anchorEl: SVGSVGElement | null;
  iRow: number;
  /* This prop name sucks. Rename to onClose */
  setAnchorEl: React.Dispatch<React.SetStateAction<SVGSVGElement | null>>;
};

/* Material UI popover. */
/* TODO: Migrate to chakra. */

/* Best would be to conditionally render this inside component? */
export const PopoverWriteSoundRow = ({ anchorEl, iRow, setAnchorEl }: PropsPopoverRow) => {
  /* Take set-fns as props? */
  const { activeSheet: sheet } = useSheets() as { activeSheet: TWithWriteSoundRows<number> };
  const { updateSheet } = useSheets();

  /* Why the hell need this? Try without? */
  const id = Boolean(anchorEl) ? "writeSoundRowPopover" : undefined;

  const handleClosePopover = () => setAnchorEl(null);

  /*
   * const handleSwitchSound = (sound: string) => switchRowSound(iRow, sound);
   * const handleSetRowFont = (font: TaskFont) => setRowFont(iRow, font.variant);
   * const handleSetGuidelinesSetting = (setting: EGuidelines) =>
   *   switchRowGuidelinesSetting(iRow, setting);
   */

  const row = sheet.rows[iRow];

  /* Material UI popover. */
  /* TODO: Migrate to chakra. */
  return (
    <Popover id={id} anchorEl={anchorEl} close={() => setAnchorEl(null)}>
      <Box w="65vmin">
        <Popover.Body>
          <Popover.Section display="flex" justifyContent="flex-start">
            <CloseButton onClick={handleClosePopover} />
          </Popover.Section>
          <Popover.Section>
            <SelectSound
              activeSound={row?.focusedSound ?? null}
              cap={row?.cap}
              isCollapsible={false}
              toggleable
              setCap={(cap) => {
                updateSheet(
                  produce(sheet, (s) => {
                    const currentCap = s.rows[iRow].cap;
                    s.rows[iRow].cap = currentCap === cap ? null : cap;
                  }) as TTaskSheet
                );
              }}
              setSound={(sound) => {
                updateSheet(
                  produce(sheet, (sheet) => {
                    const currentFocused = sheet.rows[iRow].focusedSound;
                    sheet.rows[iRow].focusedSound = currentFocused === sound ? null : sound;
                  }) as TTaskSheet
                );
              }}
            />
          </Popover.Section>
          <Popover.Section>
            <SelectFont
              activeFontVariant={row.fontVariant}
              activeTypeface={sheet.font.typeface}
              hideTypefaceSelect
              includeBlank
              isCollapsible={false}
              openByDefault
              setFont={(font) => {
                /* This one is also a bit weird? Name doesn't match what is happening? Setting font variant, not font? */
                updateSheet(
                  produce(sheet, (s) => {
                    const currentFontVariant = s.rows[iRow].fontVariant;
                    s.rows[iRow].fontVariant =
                      currentFontVariant === font.variant ? null : font.variant;
                  }) as TTaskSheet
                );
              }}
            />
          </Popover.Section>
          <Popover.Section>
            <SelectGuidelineSetting
              isCollapsible={false}
              hideBorderOption
              activeSetting={row?.guidelinesSetting}
              setSetting={(setting) => {
                updateSheet(
                  produce(sheet, (s) => {
                    const currentSetting = s.rows[iRow].guidelinesSetting;
                    s.rows[iRow].guidelinesSetting = currentSetting === setting ? null : setting;
                  }) as TTaskSheet
                );
              }}
            />
          </Popover.Section>
        </Popover.Body>
      </Box>
    </Popover>
  );
};

/* This has become really small now?? */
const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      style={{
        background: "black",
        color: "white",
        float: "left",
        marginLeft: "0.1rem",
        marginTop: "0.1rem",
        width: "0.3rem"
      }}
      onClick={onClick}
    >
      <FaTimes />
    </Button>
  );
};
