/* Rename module to matrix? */
/* Check out some matrix libraries? */

export type Grid<T> = T[][];

export type TPoint = { x: number; y: number };

export type TDimensions = { height: number; width: number };

/* ie. ROW and COLUMN. */
export type TGridPos = { row: number; col: number };

export type GridSize = {
  columns: number;
  rows: number;
};

/* perhaps create generic function for creating grids? (which does what??) */
export const createGrid = <T>({ columns, rows }: GridSize) => {
  const grid: T[][] = [...new Array(rows)].map((_) => [...new Array(columns).fill(null)]);

  return grid;
};
