import { SoundSequence } from "../../sequences";
import bookAbcDerTiere from "./bookPictures/abc_der_tiere.jpg";
import bookBucherwurm from "./bookPictures/bücherwurm.jpg";
import bookIMAL from "./bookPictures/imal.jpg";
import bookJojo from "./bookPictures/jojo.jpg";
import bookKaribu from "./bookPictures/karibu.jpg";
import bookNiko from "./bookPictures/niko.jpg";
import bookZebra from "./bookPictures/zebra.jpg";
import { deutschrad } from "./deutschrad";
import { einsternsSchwester } from "./einsterns_schwester";
import { mimi } from "./mimi";

export const abcDerTiere: SoundSequence = {
  displayName: "ABC der Tiere",
  bookPicture: bookAbcDerTiere,
  id: "d0f6e670-04ef-4269-8421-7a29f774a82d",
  name: "abcDerTiere",
  sounds: [
    "m",
    "u",
    "i",
    "o",
    "a",
    "e",
    "l",
    "t",
    "r",
    "s",
    "w",
    "d",
    "n",
    "sch",
    "ü",
    "ei",
    "k",
    "ck",
    "b",
    "ä",
    "f",
    "au",
    "g",
    "h",
    "ie",
    "ö",
    "p",
    "pf",
    "ß",
    "ch",
    "st",
    "sp",
    "z",
    "ai",
    "ng",
    "j",
    "eu",
    "v",
    "äu",
    "qu",
    "y",
    "c",
    "x"
  ]
};

export const buecherwurm: SoundSequence = {
  displayName: "Bücherwurm",
  bookPicture: bookBucherwurm,
  id: "c054b889-4ea2-402e-9d25-a15ae5c48004",
  name: "bucherwurm",
  sounds: [
    "m",
    "a",
    "o",
    "i",
    "n",
    "l",
    "e",
    "t",
    "s",
    "u",
    "g",
    "h",
    "f",
    "r",
    "d",
    "ei",
    "w",
    "p",
    "ch",
    "au",
    "k",
    "b",
    "nk",
    "v",
    "ng",
    "ß",
    "sch",
    "j",
    "st",
    "z",
    "ä",
    "ö",
    "ü",
    "ie",
    "pf",
    "eu",
    "sp",
    "tz",
    "ck",
    "äu",
    "chs",
    "y",
    "qu",
    "x",
    "c"
  ]
};

export const imalGerman: SoundSequence = {
  displayName: "iMAL",
  name: "imalGerman",
  id: "814bd72b-43ff-4ca4-82e7-ea8540e8928b",
  bookPicture: bookIMAL,
  sounds: [
    "i",
    "m",
    "a",
    "l",
    "o",
    "n",
    "u",
    "e",
    "s",
    "t",
    "g",
    "h",
    "f",
    "r",
    "d",
    "w",
    "p",
    "k",
    "b",
    "v",
    "j",
    "z",
    "ä",
    "ö",
    "ü",
    "y",
    "x",
    "c",
    "ei",
    "ch",
    "au",
    "nk",
    "ng",
    "ß",
    "sch",
    "st",
    "ie",
    "pf",
    "eu",
    "sp",
    "tz",
    "ck",
    "äu",
    "chs",
    "qu",
    "ai"
  ]
};

export const jojo: SoundSequence = {
  displayName: "Jo-Jo",
  bookPicture: bookJojo,
  id: "49a71ac7-19d7-4c8b-a9cd-8d8fcc9dd05d",
  name: "jojo",
  sounds: [
    "n",
    "i",
    "a",
    "o",
    "t",
    "m",
    "l",
    "s",
    "e",
    "p",
    "w",
    "r",
    "ei",
    "d",
    "u",
    "f",
    "h",
    "ie",
    "z",
    "b",
    "ch",
    "au",
    "k",
    "ö",
    "ü",
    "sch",
    "g",
    "ä",
    "j",
    "sp",
    "st",
    "ck",
    "pf",
    "tz",
    "chs",
    "v",
    "eu",
    "nk",
    "ng",
    "ß",
    "y",
    "äu",
    "c",
    "qu",
    "x"
  ]
};

export const karibu: SoundSequence = {
  displayName: "Karibu",
  bookPicture: bookKaribu,
  id: "17c3fcdb-ba4a-4cb5-96c2-56dc27ebeaa5",
  name: "karibu",
  sounds: [
    "o",
    "i",
    "u",
    "a",
    "e",
    "m",
    "l",
    "s",
    "w",
    "r",
    "n",
    "f",
    "t",
    "au",
    "ei",
    "h",
    "d",
    "sch",
    "k",
    "z",
    "p",
    "g",
    "j",
    "eu",
    "ch",
    "ü",
    "ö",
    // "ä", /* is missing... where does it come in the sequence? */
    "b",
    "st",
    "sp",
    "qu",
    "v",
    "c",
    "pf",
    "ß",
    "x",
    "y"
  ]
};

export const niko: SoundSequence = {
  displayName: "Niko",
  bookPicture: bookNiko,
  id: "de71aeab-244c-4be3-844b-7d35200ff3b4",
  name: "niko",
  sounds: [
    "a",
    "m",
    "l",
    "i",
    "n",
    "e",
    "s",
    "t",
    "o",
    "r",
    "u",
    "b",
    "w",
    "d",
    "h",
    "g",
    "f",
    "au",
    "ei",
    "k",
    "ch",
    "z",
    "ie",
    "p",
    "eu",
    "sch",
    "ä",
    "ö",
    "ü",
    "tz",
    "st",
    "j",
    "ck",
    "sp",
    "v",
    "ng",
    "x",
    "pf",
    "äu",
    "qu",
    "c",
    "ß",
    "y",
    "nk"
  ]
};

export const zebra: SoundSequence = {
  displayName: "Zebra",
  bookPicture: bookZebra,
  id: "cec8a1c2-da62-4264-b396-bbd5c48dfaae",
  name: "zebra",
  sounds: [
    "i",
    "m",
    "a",
    "l",
    "o",
    "t",
    "s",
    "e",
    "p",
    "n",
    "u",
    "f",
    "g",
    "r",
    "z",
    "b",
    "sch",
    "k",
    "d",
    "ei",
    "au",
    "eu",
    "h",
    "ie",
    "w",
    "j",
    "ö",
    "ü",
    "ch",
    "sp",
    "st",
    "ng",
    "tz",
    "ck",
    "pf",
    "ß",
    "ä",
    "äu",
    "qu",
    "v",
    "c",
    "x",
    "y"
  ]
};

/* Could be a map, and have getSequences()? */
export const sequences_de_DE: SoundSequence[] = [
  abcDerTiere,
  buecherwurm,
  deutschrad,
  einsternsSchwester,
  imalGerman,
  jojo,
  karibu,
  mimi,
  niko,
  zebra
];
