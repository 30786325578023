import { execGetImages, TWord } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";

// TODO: This stuff is only used in german app... Therefore move this back to german app.

type ImageByWord = {
  imageByWord: Record<TWord, Base64>;
};

type Base64 = string;

const ImageByWordContext = createContext<ImageByWord>({
  imageByWord: {} as Record<TWord, Base64>
});

/* TODO: what if name Context state providers Provider[state-name]. */

type Props = {
  children: ReactNode;
  locale: ELocale;
};

/* TODO?: images should be by their filename instead of word? */
/* Think yes, but meh... */
/* Current solution works okay, and locale likely won't change often anyway. */
export const ImageByWordProvider = ({ children, locale }: Props) => {
  const [imageByWord, setImageByWord] = useState({} as Record<TWord, Base64>);

  useEffect(() => {
    execGetImages(locale, {
      state: imageByWord,
      setState: setImageByWord
    });
  }, [locale]);

  return (
    <ImageByWordContext.Provider value={{ imageByWord }}>{children}</ImageByWordContext.Provider>
  );
};

export const useImageByWord = () => useContext(ImageByWordContext);
