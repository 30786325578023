import { MutableRefObject, useEffect, useRef, useState } from "react";

type AppElement = HTMLDivElement | SVGSVGElement;

export const useParentBoundingRect = <E extends AppElement = HTMLDivElement>() => {
  const [br, setBr] = useState(new DOMRect());
  const ref = useRef<E>(null!);

  /* need ev. listeners. */
  useEffect(() => {
    const updateDimensions = () => {
      ref.current &&
        ref.current.parentElement &&
        setBr(ref.current.parentElement?.getBoundingClientRect());
    };
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    window.addEventListener("orientationchange", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener("orientationchange", updateDimensions);
    };
  }, []);

  const result: [MutableRefObject<E>, DOMRect] = [ref, br];

  return result;
};
