import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { curry, tap } from "ramda";
import { produce } from "immer";
import { TWithSounds } from "../withSounds";
import { TWithFocusedSound } from "../withFocusedSound";
import {
  ECap,
  extractVowels,
  getConsonants,
  hasOnlyVowels,
  TSound,
  VowelPosition
} from "@imaldev/imal-factory/abc";
import _ from "lodash";
import { tupleOf } from "@imaldev/imal-factory/ts";
import { createEqFreqCollection } from "../soundGrid/generateSoundGrid/generateSoundGrid";

/* TODO: don't need */

/* TODO: rename dir names as last refactor action. */

export type Syllable = {
  vowel: string;
  consonant: string;
};

/* Would nesting all syllable things give any advantages? */
export type WithSyllables = TWithSounds &
  TWithFocusedSound & {
    syllables: [TSound, TSound][];
    syllablesCap: ECap | null;
    syllablesVowelPosition: VowelPosition;
  };

type TRegenSyllables = <ST extends WithSyllables>(locale: ELocale, s: ST) => ST;

export const regenSyllables = curry(((locale, s) => ({
  ...s,
  syllables: createSyllableSoundPairs(s.focusedSound, s.sounds, locale)
})) as TRegenSyllables);

type TRegenSyllablesM = <ST extends WithSyllables>(locale: ELocale) => (s: ST) => ST;

export const regenSyllablesM: TRegenSyllablesM = (locale) => (s) =>
  tap(
    console.log,
    produce(s, (s) => {
      s.syllables = createSyllableSoundPairs(s.focusedSound, s.sounds, locale);
    })
  );

/* ==== deps ==== */

/* TODO: take a new look at functions generating syllables. */
/* Ideally, write a fn which works for all locales, to which new locales can be */
/* added without much effort. */
/* In fact, think old German implementation may work fine; just need to tidy it up a bit. */
/* Create api first? */
// export const generateSyllables = (locale: ELocale, sounds: TSound[]) => {};
/* Do this after runtime hacks have been fixed/factory has gotten next update. */

/* Some sound combinations cannot occur. Either due to (irl) language structure, */
/* the two sounds form a whole word. */

const getIllegalSyllables = (l: ELocale) => {
  const illegalSyllablesByLocale: LocaleRecord<Record<TSound, TSound[]>> = {
    [ELocale.de_DE]: {
      ai: ["h"],
      au: ["f"],
      eu: ["ß"],
      i: ["chs", "ch"],
      qu: ["u", "ä", "ö", "ü"]
    },
    [ELocale.en_US]: {}
  };

  return illegalSyllablesByLocale[l] ?? {};
};

/* TODO: use this instead of doing the lookup below... */
/* Need change logic a little. Want to just list illegal syllables once; */
/* not twice, eg. i-ch and ch-i. */
// const getLegalSounds = (focused: Sound, sounds: Sound[]) => { };

const getPairables = (sounds: TSound[], focused: TSound, locale: ELocale) => {
  return _.difference(sounds, [...(getIllegalSyllables(locale)[focused] ?? []), focused]);
};

/* Potential problem here... */
/* we are creating 2-tuples here, and deciding ordering later... */
/* for some sounds A and B, we can have AB, but not BA. */
/* WTF do we do about that? Currently excluding both AB and BA. */

/* NB: Only pairing focused with single character sounds. */

type SyllableSoundPair = [TSound, TSound];

type TCreateSyllableSoundPairs = (
  focused: TSound,
  sounds: TSound[], // should not include focused;
  locale: ELocale
) => SyllableSoundPair[];

export const createSyllableSoundPairs: TCreateSyllableSoundPairs = (
  focused,
  sounds, // should not include focused;
  locale
) => {
  const COUNT_PAIRS = 12;
  const isFocusedVowel = hasOnlyVowels(focused, locale);
  const legalSounds = getPairables(sounds, focused, locale);

  if (legalSounds.length === 0) {
    console.error("No legal sounds provided to pair with focused sound.");
    return tupleOf(["", ""], 12);
  }

  return createEqFreqCollection(
    isFocusedVowel
      ? getConsonants(locale, legalSounds).filter((sound) => sound.length === 1)
      : extractVowels(locale, legalSounds).filter((sound) => sound.length === 1),
    COUNT_PAIRS
  )
    .map((sound) => (sound ? [focused, sound] : null))
    .filter((element) => element !== null) as [TSound, TSound][];
};
