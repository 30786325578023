import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  SimpleGrid,
  Stack,
  Text
} from "@chakra-ui/react";
import { assoc, last, range } from "ramda";
import { ReactElement, useLayoutEffect, useMemo, useState } from "react";
import Div100vh from "react-div-100vh";
import { AiOutlineHome, AiOutlineMenu } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { FaSave } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { ImCircleLeft } from "react-icons/im";
import { MdOutlineCancel, MdPayment } from "react-icons/md";
import { TiCancel, TiDocumentAdd } from "react-icons/ti";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../../auth/useAuthentication";
import { HScrollList } from "../../../../components/ScrollList";
import { WithChildren } from "../../../../utils/utilsReact/types";
import { useBoundingRect } from "../../../../utils/utilsReact/useBoundingRect";
import { configOneLargeSound } from "../SheetCreator/taskSheets/OneLargeSound";
import { sheetConfigs, TaskSheet } from "../SheetCreator/taskSheets/TaskSheet";
import { AbcBlock, Font } from "./icons";
import { Paper } from "./icons/Paper";

/* TODO: horizontal mobile mode, too? Probably just disable... landscape mode for now. */

/* TODO: put components in logical order/split mobile version into modules. */

/* Think about (at some point in the future) animated transitions between pages. */

/* Think about path translations. */

const MobileRoot = () => {
  return (
    <Routes>
      <Route path="home" element={<Home />} />
      <Route path="profile/*">
        <Route index element={<Profile />} />
        <Route path="purchase-subscription" element={<PurchaseSubscription />} />
      </Route>
      {/* <Route path="setup-new-booklet" element={<SetupNewBooklet />} /> */}
      <Route path="booklet-editor" element={<BookletEditor />} />
      <Route path="booklet-settings" element={<BookletSettings />} />
      <Route path="editor" element={<SheetEditor />} />
      <Route path="*" element={<Navigate to="home" />} />
    </Routes>
  );
};

/* Figure out how booklets and possible immutability should work. */

/* Feels like a booklet should be able to be part of multiple booklet collections, and also appear as */
/* a stand-alone booklet in saved booklets? */

/* Will a booklet potentially have a main collection it belongs to? */

/* Should it be possible to... */
/* Create stand alone booklets? */
const Home = () => {
  /* This page needs to include the popovers/modals for creating new sheet. */

  return (
    <Div100vh>
      <Flex flexDir="column" h="100%">
        {/* Possibly add some padding around all content? (but not around bottom bar). */}
        <Stack>
          <Stack>
            <Heading mb=".2em" ml=".5em" size="h4">
              Saved booklets
            </Heading>
            <HScrollList>
              {range(0, 8).map((_, i) => (
                <Box bg="purple.200" key={i} w="4em" h="5.7em" />
              ))}
            </HScrollList>
          </Stack>

          <Stack>
            <Heading mb=".2em" ml=".5em" size="h4">
              Unfinished booklets
            </Heading>
            <HScrollList>
              {range(0, 8).map((_, i) => (
                <Box bg="purple.200" key={i} w="4em" h="5.7em" />
              ))}
            </HScrollList>
          </Stack>

          <Stack>
            <Heading mb=".2em" ml=".5em" size="h4">
              Print history
            </Heading>
            <HScrollList>
              {range(0, 8).map((_, i) => (
                <Box bg="purple.200" key={i} w="4em" h="5.7em" />
              ))}
            </HScrollList>
          </Stack>
        </Stack>
        <MainBottomNav />
      </Flex>
    </Div100vh>
  );
};

/* Create a reusable BottomNav ? */
const MainBottomNav = () => {
  /* Use some hook to get current route? */
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const route = useMemo(() => last(pathname.split("/")) ?? "", [pathname]);

  const [mkNewBooklet, setMkNewBooklet] = useState(false);

  /* TODO: re-enable quick-create booklet, and add a question mark in bottom nav. */
  /* Questionmark will open a drawer from the bottom with a explainer video. */
  return (
    <>
      {/* Should probably be a subroute. */}
      <SetupNewBooklet isOpen={mkNewBooklet} onClose={() => setMkNewBooklet(false)} />
      <SimpleGrid
        bg="gray.300"
        columnGap=".3em"
        columns={3}
        w="100%"
        h="3.2em"
        mt="auto"
        py=".2em"
        px=".3em"
        boxSizing="border-box"
      >
        <NavButton
          icon={<Icon fontSize="1.3em" as={AiOutlineHome} />}
          isActive={route === "home"}
          label="Home"
          onClick={() => navigate("/app/home")}
        />
        <NavButton
          icon={<Icon fontSize="1.4em" as={TiDocumentAdd} />}
          label="New booklet"
          onClick={() => setMkNewBooklet(true)}
          //onClick={() => navigate("/app/setup-new-booklet")}
        />
        <NavButton
          isActive={route === "profile"}
          icon={<Icon fontSize="1.4em" as={CgProfile} />}
          label="Profile"
          onClick={() => navigate("/app/profile")}
        />
        {/* Should open a drawer where user picks sheet to add. */}
        {/* <NavButton
          icon={<Icon fontSize="1.3em" as={BsLightningCharge} />}
          label="Quick create"
          onClick={() => navigate("booklet-editor")}
          /> */}
      </SimpleGrid>
    </>
  );
};

/* TODO: a page for previewing an entire booklet. Not the same as previewing the booklet which is being edited. */

export { MobileRoot as AppSheetsAbcMobile };

const Profile = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthentication();

  return (
    <Div100vh>
      <Flex flexDir="column" h="100%">
        <Flex flexDir="column" p="1em" overflowY="auto">
          <Heading size="md">Personal information</Heading>
          <Grid templateColumns="4fr 6fr" rowGap=".8em">
            <Text>Name</Text>
            <Text>foobar</Text>
            <Text>Email</Text>
            <Text>foobar@imalabc.de</Text>
          </Grid>

          <Divider my="1.5em" />

          <Flex flexDir="column" h="100%">
            {/* TODO: add a info icon? idk, figure out how to do info stuff in general for mobile app. */}
            <Heading size="md">App settings</Heading>
            <Flex>
              <Text>Letter sequence</Text>
            </Flex>
            <Flex>
              <Text>Typeface</Text>
            </Flex>
            {/* Theme is waaay down the line, I think. */}
            {/* <Flex>Theme</Flex> */}
          </Flex>

          <Divider my="1.5em" />

          <Heading mt=".4em" size="md">
            Subscription
          </Heading>
          {/* TOOD: if has active subscription, let user manage it; */}
          {/* change, cancel, etc. */}
          {/* TODO: add subscription history/payment history. */}
          <Grid templateColumns="4fr 6fr" alignItems="center" rowGap=".8em">
            <Text>No active subscription</Text>
            {/* TODO: create flow for buying a subscription. */}
            <Button
              onClick={() => navigate("purchase-subscription")}
              leftIcon={<Icon as={MdPayment} />}
              mx="auto"
              w="fit-content"
            >
              Purchase
            </Button>
            {/* TODO: add purchase history? */}
            {/*       if no history, show this. If history, show first entry, */}
            {/*       and let user see more entries by expanding a collapsible (expand in steps?). */}
          </Grid>

          <Divider my="2em" />
          {/* Should only be shown if user is subscribed, or if user has super invites remaining. */}
          <Stack spacing="1em">
            <Heading m="0" mb=".2em" size="md">
              Invite a friend
            </Heading>
            <Grid templateColumns="4fr 6fr" gap="1.5em" alignItems="center" rowGap=".8em">
              <Button>Super-invite</Button>
              <Text>3 remaining</Text>
              <Button>Normal invite</Button>
            </Grid>
          </Stack>
          <Divider my="2em" />
          {/* TODO: fix logout not working: sent to screen with spinner which never ends. */}
          <Button
            colorScheme="red"
            h="3em"
            onClick={() => {
              signOut();
              navigate("/login");
            }}
            py=".7em"
            variant="outline"
          >
            Log out
          </Button>
        </Flex>
        <MainBottomNav />
      </Flex>
    </Div100vh>
  );
};

/* The subscription stuff in mobile app will probably lay the foundation for */
/* subscription management in desktop version, too. */

type SubscriptionPeriod = "monthly" | "yearly";

/* TODO: Revise subscription modal when start getting okay number of users. */
/* This is a nice place to try out page transitions. */
const PurchaseSubscription = () => {
  /* This page can have back arrow in top left. */
  const navigate = useNavigate();

  const [product, setProduct] = useState<SubscriptionPeriod | null>(null);
  const [step, setStep] = useState(0);

  /* Two choices to start with: */
  /* - monthly */
  /* - yearly */

  return (
    <Div100vh>
      <Flex flexDir="column" h="100%">
        <Flex alignItems="center" bg="gray.400" gridGap="2em" p="2em 1em" boxSizing="content-box">
          <Icon as={ImCircleLeft} fontSize="2em" onClick={() => navigate("..")} />
          <Heading m="0" size="lg">
            Purchase subscription
          </Heading>
        </Flex>
        {/* TODO: make progress thingy for buy a subscription. */}
        {/* <Flex></Flex> */}
        {step === 0 && (
          <>
            <Stack p="1em">
              <SimpleGrid columns={2} gap="1em" h="4em">
                <Product
                  active={product === "monthly"}
                  label="Monthly"
                  onSelect={() => setProduct("monthly")}
                  price={10}
                />
                <Product
                  active={product === "yearly"}
                  label="Yearly"
                  onSelect={() => setProduct("yearly")}
                  price={100}
                />
              </SimpleGrid>
            </Stack>
            <Button disabled={product === null} m="1.5em" mt="auto" onClick={() => setStep(1)}>
              Next
            </Button>
          </>
        )}
        {step === 1 && (
          <>
            <Stack p="1em">
              <Text>next step</Text>
              <Box>feref</Box>
            </Stack>
            <Flex gridGap="1.5em">
              <Button onClick={() => setStep(0)}>Back</Button>
              <Button>Next</Button>
            </Flex>
          </>
        )}
      </Flex>
    </Div100vh>
  );
};

const Product = (props: {
  active: boolean;
  label: string;
  onSelect: () => void;
  price: number;
}) => {
  return (
    <Stack
      alignItems="center"
      bg="white"
      border="3px solid"
      borderColor={props.active ? "green.400" : "gray.400"}
      borderRadius=".35em"
      onClick={props.onSelect}
      p=".85em"
    >
      <Text fontWeight="bold">{props.label}</Text>
      <Text>€{props.price},-</Text>
    </Stack>
  );
};

/* How to do invite stuff? */
/* When subscribe, user gets 3 super invites. */
/* - yearly sub, 3 super invites */
/* - first monthly sub, 1 super invite. Every 6 months, get another super invite. */

/* Have some cap of number of unused super invites a user can have. max = 8? */

/* Maybe a way to do this, would be to let users print x amount of sheets per month with "limited access". */
/* How many sheets? 50? */

type BookletSetup = {
  name: string;
  typeface: string; // TODO: proper type, users default typeface by default
  sequence: string; // TODO: proper type, users default sequence by default
  focusedSound: string;
  sounds: string[];
};

/* For selecting some settings, have a drawer come up where user can pick their choice. */
/* Can a reusable drawer be used for this? */
/* Where else would it be used? */
/* This should aso be a popup/drawer thingy. */
const SetupNewBooklet = (props: { isOpen: boolean; onClose: () => void }) => {
  const [config, setConfig] = useState<BookletSetup>({
    focusedSound: "f",
    name: `unnamed-${new Date(Date.now()).toLocaleString()}`, // Could set name to be the date?
    sequence: "",
    sounds: ["f", "o", "b", "a", "r"],
    typeface: ""
  });
  const navigate = useNavigate();

  return (
    <GenericDrawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={<Heading size="lg">New booklet</Heading>}
    >
      <Flex flexDir="column" h="100%">
        <Grid alignItems="center" gap="1.2em" templateColumns="2fr 8fr" p=".4em">
          <Text as="b">Name</Text>
          <Input
            bg="white"
            onChange={(e) => setConfig(assoc("name", e.currentTarget.value))}
            value={config.name}
            w="auto"
          />
          <Text as="b">Letter sequence</Text>
          <Input bg="white" value={""} w="auto" />
          <Text as="b">Main letter</Text>
          <Input bg="white" value={config.focusedSound} w="auto" />
          <Text as="b">Letters</Text>
          <Input bg="white" value={config.sounds} w="auto" />
        </Grid>
        <Button onClick={() => navigate("/app/booklet-editor")} mt="auto" mx="auto" w="8em">
          Start
        </Button>
      </Flex>
    </GenericDrawer>
  );
};

/* Custom inputs in popovers/drawer for focused sound and sounds. */

/* How would collections of booklets work? */
/*   What kind of use cases would this solve? */
/*   - homework for a student for x amount of days while they won't be at school. */
/*   - booklets which a user wants to reuse (beginning of school year, letter/sound introduction booklets). */

const SheetEditor = () => {
  const [ref, br] = useBoundingRect();
  const navigate = useNavigate();

  /* Epic hack to render sheet preview without resizing the window... */
  const [foo, setFoo] = useState(1);
  useLayoutEffect(() => {
    setFoo(0);
  }, []);

  return (
    <Div100vh>
      <Flex flexDir="column" h="100%">
        <Center bg="green.100" w="100%" h="100%" ref={ref}>
          <Box h={br.width * 1.35 + foo} w={br.width} pos="relative">
            <svg width="100%" height="100%">
              {foo === 0 && <TaskSheet sheetProps={configOneLargeSound.fnMkSheet()} />}
            </svg>
          </Box>
        </Center>
        {/* What is the maximum amount of settings a sheet can have? */}
        {/* Currently, 5 (sound poster). */}
        {/* This "bottom bar" will be a bit special? justifyContent: flex-end? */}
        <SimpleGrid bg="blue.200" columns={5} h="3.8em" mt="auto" py=".2em" w="100%">
          {/* Probably truncate "cancel" and "save" buttons into one button labeled "exit". */}
          {/* Clicking this button will open a small popover above the DOM-node. */}
          <NavButton
            icon={<Icon fontSize="1.4em" as={FaSave} />}
            label="Save"
            onClick={() => navigate("../booklet-editor")}
          />
          <GridItem />
          {/* https://www.flaticon.com/free-icon/abc-block_794562?term=abc&page=1&position=2&page=1&position=2&related_id=794562&origin=style */}
          <NavButton icon={<Icon fontSize="1.5em" as={AbcBlock} />} label="Sound" />
          {/* https://www.flaticon.com/free-icon/abc-block_794562?term=abc&page=1&position=2&page=1&position=2&related_id=794562&origin=style */}
          <NavButton icon={<Icon fontSize="1.8em" as={Font} />} label="Font" />
          <NavButton icon={<Icon fontSize="1.4em" as={AiOutlineMenu} />} label="Guidelines" />
        </SimpleGrid>
      </Flex>
      {/* These .... */}
      {/* Bottom icons should not be part of the editor? */}
      {/* Yeah... feels like best would be to not show this inside sheet editor. */}
    </Div100vh>
  );
};

/* If no onClick, use disabled-style? */
/* Will this link-like button ever be used for anything other than navigating between routes?. */
const NavButton = (props: {
  isActive?: boolean;
  icon?: ReactElement;
  label?: string;
  onClick?: () => void;
}) => {
  const isInteractive = !props.isActive && Boolean(props.onClick);
  return (
    <Grid
      _hover={{
        bg: isInteractive ? "gray.100" : undefined
      }}
      bg={isInteractive ? "gray.300" : "yellow.300"}
      borderRadius=".2em"
      role="group"
      templateRows="7fr 4fr"
      justifyContent="center"
      alignItems="center"
      h="100%"
      onClick={props.onClick}
    >
      <GridItem as={Center}>{props.icon}</GridItem>
      <GridItem>
        <Text fontSize="11">{props.label}</Text>
      </GridItem>
    </Grid>
  );
};

const BookletEditor = () => {
  const navigate = useNavigate();

  /* Maybe need to do math for dimensions of things... */
  return (
    <Div100vh>
      <Flex flexDir="column" bg="green.200" h="100%">
        <SimpleGrid columns={3} gap=".5em" autoRows="10em" w="100%" mx=".5em">
          <GridItem onClick={() => navigate("../editor")}>
            <TaskSheet sheetProps={configOneLargeSound.fnMkSheet()} />
          </GridItem>
          <GridItem>
            <TaskSheet
              sheetProps={sheetConfigs.soundPoster.fnMkSheet({
                pSheet: { focusedSound: "r", words: [{ value: "Rose" }] }
              })}
            />
          </GridItem>
          <GridItem>
            <TaskSheet sheetProps={sheetConfigs.writeSoundRows.fnMkSheet()} />
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          bg="blue.200"
          columns={4}
          columnGap=".1em"
          w="100%"
          h="3.8em"
          mt="auto"
          py=".2em"
        >
          <NavButton
            icon={<Icon fontSize="1.7em" as={TiCancel} />}
            label="Cancel"
            onClick={() => navigate("..")}
          />
          <NavButton icon={<Icon fontSize="1.4em" as={FaSave} />} label="Save" />
          <NavButton
            // Change icon to this one?
            // https://www.flaticon.com/free-icon/add_2312400?related_id=2312400&origin=search
            icon={<Icon fontSize="1.6em" as={TiDocumentAdd} />}
            label="New sheet"
            onClick={() => navigate("../editor")}
          />
          {/* TODO: book with settings icon. */}
          {/* https://www.flaticon.com/free-icon/ebook_865507?related_id=865507&origin=search */}
          <NavButton
            icon={
              <Box bg="inherit" pos="relative">
                <Icon fontSize="1.6em" as={Paper} />
                <Icon
                  bg="gray.200"
                  borderRadius="50%"
                  fontSize="1em"
                  as={FiSettings}
                  pos="absolute"
                  top="14px"
                  right="-3px"
                />
              </Box>
            }
            label="Settings"
            onClick={() => {
              navigate("../booklet-settings");
            }}
          />
        </SimpleGrid>
      </Flex>
    </Div100vh>
  );
};

/* Might be preferable to not have this be a it's own page (like booklet-setup), and instead */
/* have it be a popup drawer like other things. */
/* The content in this one, and BookletSetup is basically the same. */
/* Reuse component? */
const BookletSettings = () => {
  const [config, setConfig] = useState<BookletSetup>({
    focusedSound: "", // optional
    name: "", // required
    sequence: "", // required (default value: users prefered sequence)
    sounds: [],
    typeface: "" // // required (default value: users prefered typeface)
  });
  const navigate = useNavigate();

  return (
    <Stack pos="relative">
      <Box pos="absolute" top="0" right="0">
        <Icon as={MdOutlineCancel} fontSize="1.8em" m=".5em" onClick={() => navigate("..")} />
      </Box>
      <Heading mt="1em">Booklet settings</Heading>
      <Grid alignItems="center" templateColumns="2fr 8fr" p=".4em" pos="relative">
        <Text>Name</Text>
        <Input
          bg="white"
          value={config.name}
          onChange={(e) => setConfig(assoc("name", e.currentTarget.value))}
        />
        <Text>Letter sequence</Text>
        <Input bg="white" value={""} />
        <Text>Main letter</Text>
        <Input bg="white" value={""} />
        <Text>Letters</Text>
        <Input bg="white" value={""} />
      </Grid>
      <Flex>
        <Button onClick={() => navigate("../booklet-editor")}>Save</Button>
        <Button onClick={() => navigate("../booklet-editor")}>Cancel</Button>
      </Flex>
    </Stack>
  );
};

/* Would be cool to have the kind of drawer which can be closed by dragging downwards on the component. */
const GenericDrawer = (
  props: WithChildren & { isOpen: boolean; onClose?: () => void; title?: string | ReactElement }
) => {
  return (
    <Drawer isOpen={props.isOpen} onClose={props.onClose ?? (() => {})} placement="bottom">
      <DrawerOverlay />
      <DrawerContent borderTopRadius="20px">
        <DrawerCloseButton />
        <DrawerHeader bg="blue.100" borderTopRadius="20px">
          {typeof props.title === "string" ? <Heading>{props.title}</Heading> : props.title}
        </DrawerHeader>
        <DrawerBody bg="gray.100">
          <Flex h="65vh" w="100%" pb="1em" boxSizing="border-box">
            {props.children}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const DrawerSelectSheet = (props: { isOpen: boolean; title: string }) => {
  return (
    <GenericDrawer isOpen={props.isOpen}>
      <Heading>{props.title}</Heading>
      <SimpleGrid columns={3} gap=".45em">
        <Box boxSize="4em" />
        <Box boxSize="4em" />
        <Box boxSize="4em" />
        <Box boxSize="4em" />
        <Box boxSize="4em" />
      </SimpleGrid>
    </GenericDrawer>
  );
};

/* Drawer for settings... */
/* Some setting components will need to be rewritten? */
const DrawerSelectSound = (props: { isOpen: boolean; sounds: string[] }) => {
  return (
    <GenericDrawer isOpen={props.isOpen}>
      <SimpleGrid columns={8}>
        {props.sounds.map((s, i) => (
          <Box key={i}>
            <Text>{s}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </GenericDrawer>
  );
};

/* Clicking settings for inside a booklet... will... */
/* bring up a component/view which is almost the same as the setup-new-booklet window? */

/* What to do about sequence creator? */
/* This is actually not that big a deal? */
/* Most teachers will be using a sequence from one of the books already in the app. */

/* HScrollContainer? */
