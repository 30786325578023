import _ from "lodash";
import { EPermission } from "../../permissions/permissions";
import { Time } from "../../utils/utilsTS/time/time";
import { mkCommonUser, TCommonUser } from "./context";

export type TUser = TCommonUser & {
  permissions: EPermission[];
  metadata: {
    dateLastSignIn: Date;
    dateCreated: Date;
  };
};

type TNewUser = (args?: Partial<TUser>) => TUser;

export const mkUser: TNewUser = (args) => {
  return {
    ...mkCommonUser(),
    isEmailVerified: false,
    metadata: {
      dateLastSignIn: Time.epoch,
      dateCreated: Time.epoch
    },
    permissions: [],
    ...args
  };
};

/* Move to a permissions module? */
export const isAdmin = (user: TUser) =>
  _.intersection(user.roles, ["admin", "userAdmin"]).length > 0;

/* Exporting like this makes it hard to find usages with IDE... */
