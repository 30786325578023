import { extendTheme, Tooltip, withDefaultProps } from "@chakra-ui/react";

/* TODO?: use to-be-created iMAL chakra theme. */

/* Theme for Chakra-UI (Styled System Theme Specification). */

/* Chakra ui includes css reset? */

/* How to not have hover styles when button is disabled? */

/* TODO: read about chakra themes... */
/* https://chakra-ui.com/docs/theming/customize-theme */

/* TODO: add typings to style extensions. */
const stylesButton = {
  baseStyle: {
    _hover: { cursor: "pointer" },
    background: "#007BFF",
    color: "white",
    cursor: "pointer"
  },
  variants: {
    primary: {
      _hover: {
        filter: "brightness(90%)"
      }
    }
  },
  defaultProps: {
    variant: "primary"
  }
};

/* All theme extensions are done here(?). */
export const extendedTheme = extendTheme(
  withDefaultProps({
    defaultProps: {
      display: "flex",
      margin: 0,
      padding: 0
    }
  }),
  {
    colors: {
      black: "#000",
      link: "#3290d9",
      muted: "#798188",
      primary: "#abcdef",
      secondary: "#222bbb",
      transparent: "transparent",
      white: "#fff",
      gray: {
        50: "#f7fafc",
        900: "#171923"
      },
      text: "#bb44cc",
      margin: "0"
    },
    components: {
      Button: stylesButton,
      Tooltip: {
        defaultProps: {
          openDelay: 500
        }
      },
      Heading: {
        m: 0,
        h: "fit-content",
        w: "fit-content"
      },
      Text: {
        baseStyle: {
          color: "color",
          m: 0,
          h: "fit-content",
          w: "fit-content"
        }
      }
    },
    /* Does this work? */
    config: {
      initialColorMode: "light",
      useSystemColorMode: false
    }
  }
);

/* "Workaround" to set default props. Apparently Chakra-ui has a an api */
/* in their backlog. */
/* https://github.com/chakra-ui/chakra-ui/issues/1424 */
Tooltip.defaultProps = { ...Tooltip.defaultProps, openDelay: 300 };
Tooltip.defaultProps = { ...Tooltip.defaultProps, hasArrow: true };
