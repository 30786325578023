import {
  Box,
  Button,
  Center,
  ChakraProps,
  Flex,
  Grid,
  GridItem,
  Image,
  Text
} from "@chakra-ui/react";
import PillPity from "pill-pity";
import * as R from "ramda";
import { Link } from "react-router-dom";
import { FlexColumn } from "../../../../components/layout/Flex/Flex";
import { WithChildren } from "../../../../utils/utilsReact/types";
import { Carousel } from "../../components/Carousel";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import ols from "./carouselSheets/ols_b.png";
import sound_poster from "./carouselSheets/sound_poster_maus.png";
import wsr_rts from "./carouselSheets/wrs_rts_eu.png";
import wsrs from "./carouselSheets/wsrs_UuaSd.png";
import wsr_wiiwg from "./carouselSheets/wsr_wiiwg_o.png";
import workbooks_de_DE from "./german_books.png";
import icon_parents from "./icon_parents.png";
import icon_school from "./icon_school.png";
import icon_webinar from "./icon_webinar.png";
import kidAndSheets from "./kid_and_sheets_poc_1.png";

const carouselImages = [ols, sound_poster, wsr_rts, wsr_wiiwg, wsrs];

const getCardStyles = () => ({
  bg: "#f4f7f6",
  color: "#383838",
  transition: "ease .07s"
});

const getCardHoverStyles = () => ({
  bg: "#fff",
  brightness: "80%",
  color: "#444444",
  cursor: "pointer",
  textDecoration: "none"
});

type Hoverable = {
  isHoverable?: boolean;
};

type PropsMyCards = Hoverable &
  WithChildren &
  ChakraProps & {
    disabled?: boolean;
  };

/* This feels dirty af currently... needless splitting of code. */
const MyCard = ({ children, disabled, isHoverable = false, ...rest }: PropsMyCards) => {
  return (
    <Flex
      filter={`brightness(${disabled ? "55%" : "100%"})`}
      border="#dfdfdf"
      borderRadius=".4rem"
      borderStyle="solid"
      borderWidth="2.5px"
      boxShadow="xl"
      justifyContent="center"
      {...getCardStyles()}
      _hover={
        // this is fked.
        disabled
          ? {}
          : isHoverable
          ? R.merge(getCardHoverStyles(), {
              boxShadow: "lg"
            })
          : {}
      }
      {...rest}
    >
      {children}
    </Flex>
  );
};

type PropsProductCard = {
  disabled?: boolean;
  path: string;
  price: number;
  text: string;
};

/* TODO: fix link clicking not working on MyCard. */
const ProductCard = ({ disabled = false, path, price, text }: PropsProductCard) => {
  const iconByPath: Record<string, string> = {
    info_school: icon_school,
    info_parents: icon_parents,
    live_courses: icon_webinar
  };

  /* TODO: pass as and to props to MyCard as well? */

  return (
    <MyCard disabled={disabled} isHoverable h={["12rem"]} p="1.8rem" pos="relative">
      <Flex
        as={Link}
        to={disabled ? "/home" : `/home/${path}`}
        _hover={{
          brightness: disabled ? "20%" : "125%",
          color: "#444",
          textDecoration: "none"
        }}
        brightness={disabled ? "20%" : "100%"}
        alignItems="center"
        color="#444"
        cursor={disabled ? "default" : "pointer"}
        flexDirection="column"
      >
        {path === "live_courses" ? null : (
          <Text
            fontSize=".8rem"
            textDecoration="line-through"
            fontWeight="bold"
            left="1rem"
            top=".6rem"
            color="#888"
            pos="absolute"
          >
            €{price},- {path === "live_courses" ? "" : "/ Monat"}
          </Text>
        )}
        <Text
          color="#2F932F"
          fontSize={{
            base: "1.5rem",
            md: "1.3rem"
          }}
          fontWeight="bold"
          left={{
            base: "1.8rem",
            md: "1.2rem",
            lg: "2rem"
          }}
          top="2rem"
          pos="absolute"
        >
          € 0,-
        </Text>
        <Box h="5rem" mb={{ base: "1rem", md: ".7rem" }} w="5rem">
          <Image maxH="100%" objectFit="cover" src={iconByPath[path]} />
        </Box>
        <Text
          fontSize={{ base: "1.3rem", md: "1.6rem" }}
          fontWeight="bold"
          textAlign="center"
          textDecoration="none"
        >
          {text}
        </Text>
      </Flex>
    </MyCard>
  );
};

/* Original boy_writing image: */
/* https://www.shutterstock.com/image-photo/kid-self-isolation-using-computor-his-1695423166 */

export const Home = () => {
  return (
    <FlexColumn bg="#f5f7fa">
      <Header />
      {/* TODO: dont use as on PillPity... losing semantic meaning. */}
      <PillPity
        alignItems="center"
        as={Grid}
        bgColor="#261633"
        h={["auto", "auto", "fit-content"]}
        pattern="wiggle"
        patternFill={"#3d2d4d"}
        templateAreas={{
          base: "'img' 'cta'",
          md: " 'cta img' "
        }}
        templateColumns={{
          sm: "1fr",
          md: "1fr 5fr",
          lg: "2fr 3fr"
        }}
      >
        <FlexColumn
          boxSizing="content-box"
          gridArea="cta"
          justifyContent={{ base: "flex-start", md: "center" }}
          textAlign={["center", "left"]}
          px={{
            /* Change this to margin? */
            /* TODO: make things better sized for tablets; larger dark area etc. */
            /* Looong into future, almost nobody on tablets lol */
            base: ".5rem",
            md: "4rem",
            lg: "6vw"
          }}
          pb={{ base: "4rem", lg: "0" }}
          pt={{ sm: "0rem", md: "2rem", lg: "0" }}
          flex={1}
        >
          <Text
            color="#dddddd"
            fontSize={{
              sm: "1.6rem",
              md: "1.4rem",
              lg: "2vw"
            }}
            fontWeight="bold"
            wordBreak="break-word"
          >
            {/* Might need to name some grid cells so can position elements on mobile displays. */}
            Individuell anpassbare Lese- und Schreibaufgaben zur Buchstabeneinführung
          </Text>
          <Text
            color="#999"
            fontSize={{
              md: "1.2rem",
              lg: "1.3rem"
            }}
            mt="1.6rem"
            wordBreak="break-word"
          >
            Eine methodische, multisensorische Ergänzung für den ersten Schriftspracherwerb
          </Text>
          <Flex
            alignItems="flex-end"
            justifyContent="space-around"
            mx="auto"
            mt={{ base: "1.5rem", lg: "3rem" }}
          >
            <FlexColumn justifyContent="center" flex={1} mx="auto">
              <Button
                as={Link}
                to="/login"
                _hover={{
                  color: "#C894DF",
                  filter: "brightness(110%)",
                  textDecoration: "none"
                }}
                bg="#261633"
                color="#C894DF"
                border="2px solid #B38EC5"
                mr="1.7rem"
                pt=".2rem"
                transition="ease .2s"
                h="3rem"
              >
                <Text fontWeight="bold" fontSize="1.05rem" mb=".2rem">
                  Anmelden
                </Text>
              </Button>
            </FlexColumn>
            <Button
              as={Link}
              to="/signup"
              _hover={{
                color: "#261633",
                filter: "brightness(110%)",
                textDecoration: "none"
              }}
              bg="#C894DF"
              color="#261633"
              border="none"
              mr={{ base: "0", md: "10%" }}
              transition="ease .13s"
              px="2rem"
              h="3.5rem"
            >
              <Text fontWeight="bold" fontSize="1.15rem">
                Registrieren
              </Text>
            </Button>
          </Flex>
        </FlexColumn>
        {/* Image should not be 100% when display is realy large. */}
        <Center gridArea="img">
          <Image h="100%" w="100%" src={kidAndSheets} />
        </Center>
      </PillPity>
      <FlexColumn m="auto" mt="1.7rem" maxW="70rem">
        <Grid
          alignItems="center"
          justifyContent="center"
          gap={{ base: "2rem", md: "1.5rem" }}
          m="1.5rem"
          templateColumns={{
            sm: "18rem",
            md: "repeat(3, 1fr)"
          }}
        >
          {[
            { path: "info_school", price: 20, text: "iMAL Schule" },
            {
              disabled: true,
              path: "info_parents",
              price: 10,
              text: "Information für Eltern"
            },
            { path: "live_courses", price: 0, text: "Webinar" }
          ].map((cardProps, i) => (
            <ProductCard key={i} {...cardProps} />
          ))}
        </Grid>
        {/* possibly size columns a bit differently; */}
        {/* repeat(8, 1fr) */}
        <Grid
          m="auto"
          mt={{ base: "4rem", md: "3.5rem" }}
          mb="3rem"
          maxW="80vw"
          templateAreas={{
            base: "'txt_1' 'carousel_sheets' 'txt_2' 'img_books'",
            md: "'txt_1 txt_1 carousel_sheets' 'img_books txt_2 txt_2'"
          }}
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        >
          <GridItem colSpan={{ base: 1, md: 2 }} as={Center}>
            <Text
              color="#444"
              textAlign="center"
              fontSize={{
                base: "1.7rem",
                md: "2.8rem"
              }}
              fontWeight="bold"
              gridArea="txt_1"
              mx="auto"
              mt={{ base: "1.5rem", sm: ".5rem" }}
            >
              Lese- und Schreibaufgaben von erfahrenen PädagogInnen entwickelt
            </Text>
          </GridItem>
          <GridItem
            as={Center}
            colSpan={1}
            gridArea="carousel_sheets"
            m="auto"
            mb={{ base: "2rem", md: "5rem" }}
            mt="2rem"
            maxW="80vw"
          >
            <Carousel
              borderRadius="1rem"
              boxShadow="xl"
              slides={carouselImages.map((imgSrc, i) => (
                <Image
                  borderRadius=".5rem"
                  boxSize="full"
                  key={i}
                  maxH="30rem"
                  objectFit="contain"
                  src={imgSrc}
                />
              ))}
            />
          </GridItem>
          <GridItem as={Center} colSpan={1} gridArea="img_books">
            <Image
              bg="#E7DEEA"
              borderRadius=".45rem"
              boxShadow="xl"
              objectFit="cover"
              p=".75rem"
              w="100%"
              src={workbooks_de_DE}
            />
          </GridItem>
          <GridItem as={Center} colSpan={{ sm: 1, md: 2 }} mt="3rem">
            <Text
              color="#444"
              fontSize={{
                base: "1.7rem",
                md: "2.8rem"
              }}
              fontWeight="bold"
              gridArea="txt_2"
              m="2rem"
              textAlign="center"
            >
              Mit jedem deutschsprachigen Lehrwerk kompatibel
            </Text>
          </GridItem>
        </Grid>
      </FlexColumn>
      <FlexColumn flex="1" justifyContent="flex-end" mt="3rem">
        <Footer />
      </FlexColumn>
    </FlexColumn>
  );
};
