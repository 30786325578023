import { Center, SimpleGrid, Text } from "@chakra-ui/react";
import * as R from "ramda";
import { colorScheme } from "../../../../../../../../../styles/colors";
import {
  blankFont,
  ETaskFontVariant,
  ETypeface,
  TaskFont,
  TTaskTypeface
} from "../../../../../../../../../utils/utilsiMAL/fonts/shared";
import { getTaskFont } from "../../../../../../../../../utils/utilsiMAL/fonts/typefaces";

/* Could just pass down Font? Don't need both active variant and typeface? */
type Props = {
  activeFontVariant: ETaskFontVariant | null;
  activeTypeface: ETypeface;
  setFont: (font: TaskFont) => void;
  typefaces: TTaskTypeface[];
};

/* TODO: SelectTf and SelectFontVariant should take setTf and setVariant, respectively; */
/* handle logic in cb. */

export const SelectTypeface = ({
  activeFontVariant,
  activeTypeface,
  setFont,
  typefaces
}: Props) => {
  const activeFont = activeFontVariant ? getTaskFont(activeTypeface, activeFontVariant) : null;

  const handleSetTypeface = (typeface: TTaskTypeface) => {
    /* TODO: Later, perhaps just include blank as "normal" taskFont in taskTypefaces. */
    /* Will make things easier, and avoid handling a bunch of edge cases in many places. */
    /* Can make this easy with a function for creating typefaces. */
    const prevVariant = activeFont?.variant ?? null;
    if (!prevVariant) return;
    const font =
      activeFont?.variant === "blank"
        ? {
            ...blankFont,
            typeface: typeface.name
          }
        : typeface.fonts[prevVariant] ?? R.head(Object.values(typeface.fonts))!;
    if (prevVariant) setFont(font);
  };

  return (
    <SimpleGrid columns={4} pb="0.5vmin">
      {typefaces.map((typeface, i) => {
        const active = typeface.name === activeTypeface;
        return (
          <Center
            bg={active ? "white" : ""}
            border={active ? `2px solid ${colorScheme.NAVY}` : "defaul"}
            borderBottom={active ? "1.5px solid #efefef" : "1.5px solid " + colorScheme.NAVY}
            borderTopRadius="5px"
            boxSizing="border-box"
            cursor={active ? "default" : "pointer"}
            h="4vh"
            key={i}
            onClick={() => {
              handleSetTypeface(typeface);
            }}
            _hover={{
              bg: active ? "" : "#efefef"
            }}
          >
            <Text
              cursor={active ? "default" : "pointer"}
              fontSize="2vmin"
              fontWeight="bold"
              padding="1vmin"
            >
              {typeface.nameForDisplay}
            </Text>
          </Center>
        );
      })}
    </SimpleGrid>
  );
};
