import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { useSheets } from "./contexts/ContextSheets";
import { PrintSheets } from "./PrintSheets/PrintSheets";
import { DrawerH, SheetDrawer } from "./SheetDrawer/SheetDrawer";
import { SheetMenu } from "./SheetMenu/SheetMenu";
import { ESheet } from "./taskSheets/shared/misc";
import { sheetConfigs } from "./taskSheets/TaskSheet";

export const SheetCreator = () => {
  const { activeSheet } = useSheets();

  /* Does useMemo make sense here? Read about react rendering. */
  const ActiveSheetEditor = sheetConfigs[activeSheet?.name ?? ESheet.ONE_LARGE_SOUND].Editor as any;

  return (
    <Routes>
      <Route path="print" element={<PrintSheets />} />
      <Route
        path="/"
        element={
          <Grid templateRows={`auto ${DrawerH.ACTIVE}`} flex={1} h="100%">
            <GridItem>
              {!activeSheet ? (
                <Flex h="100%" justifyContent="center">
                  <SheetMenu />
                </Flex>
              ) : (
                <ActiveSheetEditor />
              )}
            </GridItem>
            <GridItem minH={DrawerH.ACTIVE} w="100%">
              <SheetDrawer />
            </GridItem>
          </Grid>
        }
      />
    </Routes>
  );
};
