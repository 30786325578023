import { Center, Spinner, useColorMode } from "@chakra-ui/react";
import LogRocket from "logrocket";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { callCloudFn } from "../../api/cloudFunctions";
import { useUser } from "../../api/user/context";
import { EAuthStatus, useAuthentication } from "../../auth/useAuthentication";
import { ENVIRONMENT } from "../../constants";
import { AppSheetsAbc } from "./AppSheetsAbc/AppSheetsAbc";

const procUserSignedIn = () => {
  callCloudFn("updateUserDateLastSignIn");
};

/* Could just merge all this to AppSheetsAbc? */
/* TODO: yes, merge, and put this in `WrappedAppSheetsAbc`. */
/* Putting as todo, as this change will require us to change shit loads of import paths... */
/* Reason we didn't is if want to expand app in the future. */
export const PostLogin = () => {
  const { authStatus } = useAuthentication();
  const { colorMode, setColorMode } = useColorMode();
  const { user, haveUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    /* Fix bug where colorscheme is automatically set to dark. */
    /* Can fix this by defining colorscheme in chakra's theme.ts? */
    /* Also, should put this high upp in component tree? */
    if (colorMode === "dark") setColorMode("light");
    if (authStatus !== "authenticated") navigate("/login");
    if (authStatus === EAuthStatus.AUTHENTICATED) procUserSignedIn();

    if (ENVIRONMENT === "production")
      LogRocket.identify(user.id, {
        email: user.email
      });
  }, []);

  /* TODO: let user exit/logout incase enter infinite loading. */
  /* Or fix it somehow. */
  if (!haveUser) {
    return (
      <Center h="100%" w="100%">
        <Spinner mt="40vh" />
      </Center>
    );
  }

  return <AppSheetsAbc />;
};
