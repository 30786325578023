import { Box, Flex } from "@chakra-ui/react";
import { WithChildren } from "../../utils/utilsReact/types";

export const ScrollList = (props: WithChildren & { bg?: string; p?: string }) => {
  return (
    <Box bg={props.bg} flex={1} overflowY="auto" pos="relative">
      <Flex flexDir="column" gap="5px" p={props.p} pos="absolute" w="100%">
        {props.children}
      </Flex>
    </Box>
  );
};

/* TODO: do some logic to determine if should add padding for h scroll bar. */
export const HScrollList = (props: WithChildren) => {
  return (
    <Flex gridGap=".5em" w="100%" bg="blue.300" overflowX="scroll">
      <Flex gridGap=".8em" bg="green.100" py=".7em" px=".4em">
        {props.children}
      </Flex>
    </Flex>
  );
};
