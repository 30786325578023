import * as R from "ramda";
import { ECap } from "@imaldev/imal-factory/abc";

export type TWithCap = {
  cap: ECap;
};

type TSetCap = <S extends TWithCap>(s: S, cap: ECap) => S;

/* Curry manually? */

export const setCap = R.curry(((s, cap) => ({
  ...s,
  cap
})) as TSetCap);

/* This should be together with component for selecting cap setting..? */
