import { ECap } from "@imaldev/imal-factory/abc";
import { Grid } from "../../../../../../../../utils/utilsTS/grid/grid";
import { TWithFocusedSound } from "../withFocusedSound";
import { TWithSounds } from "../withSounds";
import { generateSoundGrid } from "./generateSoundGrid/generateSoundGrid";

export type TWithSoundGrid = TWithSounds &
  TWithFocusedSound & {
    soundGrid: Grid<string>;
    soundGridCap: ECap | null;
  };

/* Should regen fn, potentially take array of words to embed within itself? */
/* Or create new type for this: TWithFindWordGrid. */

/* TODO: Probably create a post on stack overflow, saying need some help with generics. */
/* Maybe something like this would work? Try it after done with current solution. */
/* type SheetWith<T extends /* sheettypes-union \*\/> = TTaskSheet & T & any; */

type TRegenSoundGrid = <S extends TWithSoundGrid>(s: S) => S;

/* Why R.assoc no work. */
/* Could use dimensions of existing sound grid to create sound grid of same size? */
export const regenSoundGrid: TRegenSoundGrid = (s) => ({
  ...s,
  soundGrid: generateSoundGrid(s.sounds, s.focusedSound)
});
