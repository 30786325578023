export const IMalLogoNoTextSvg = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 250 250"
      preserveAspectRatio="xMidYMid meet"
    >
      <g fill="#d1d2d4">
        <path d="M155 233.1 c0 -1.1 -0.4 -2.1 -1 -2.1 -0.5 0 -1 -0.7 -1 -1.5 0 -0.8 0.6 -1.5 1.3 -1.5 1.9 0 5.7 -4.3 5.7 -6.3 0 -1 0.8 -1.7 2 -1.7 1.3 0 2 0.7 2 2 0 1.3 -0.7 2 -1.8 2 -2.6 0 -6.6 4.5 -5.8 6.6 0.6 1.4 1.7 1.6 8.9 0.9 4.5 -0.3 8.4 -1.1 8.5 -1.6 0.8 -2.1 2.2 -0.7 2.2 2.2 0 2.7 -0.2 3 -1.5 1.9 -1.9 -1.6 -16.3 -1.4 -18.2 0.2 -1 0.8 -1.3 0.5 -1.3 -1.1z" />
        <path d="M159.5 215.8 c0.6 -1.6 -9.2 -8.1 -11.1 -7.4 -1.5 0.6 -1.8 -1.3 -0.8 -4.9 0.5 -1.8 1.2 -2 8.4 -1.7 6.8 0.3 8 0.1 8 -1.2 0 -0.9 0.7 -1.6 1.5 -1.6 1 0 1.5 1 1.5 3.2 0 2.5 -0.3 2.9 -1.2 2 -0.7 -0.7 -4 -1.2 -7.9 -1.2 -6.2 0 -7.9 0.7 -7.9 3.4 0 0.2 2.4 1.9 5.4 3.9 4 2.6 5.6 3.3 6.5 2.4 0.8 -0.8 1.1 -0.4 1.1 1.6 0 2 -0.5 2.7 -2 2.7 -1.1 0 -1.7 -0.5 -1.5 -1.2z" />
        <path d="M176 194.5 c0 -0.8 0.6 -1.5 1.4 -1.5 1.9 0 2.9 -3 1.5 -4.6 -0.9 -1.1 -0.6 -1.4 2 -1.4 2.5 0 3.1 0.4 3.1 2.2 0 1.2 -0.3 1.8 -0.7 1.5 -0.9 -0.9 -4.3 2.2 -4.3 4 0 0.7 -0.7 1.3 -1.5 1.3 -0.8 0 -1.5 -0.7 -1.5 -1.5z" />
        <path d="M134 186.5 c-2 -2.4 -10.1 -6.7 -11.6 -6.1 -1.5 0.6 -1.7 -0.5 -0.8 -5.3 0.5 -2.2 1 -2.8 1.8 -2.2 0.7 0.6 5.2 -0.2 12.5 -2.2 9.7 -2.6 11.4 -3.4 12.2 -5.5 0.5 -1.4 0.8 -2.6 0.7 -2.7 -0.2 -0.2 -6.2 -1.9 -13.5 -3.8 -9.5 -2.5 -13.4 -3.2 -14.2 -2.4 -0.8 0.8 -1.1 0.4 -1.1 -1.6 0 -1.8 0.5 -2.7 1.6 -2.7 0.8 0 1.3 0.4 0.9 0.9 -0.6 1.1 -1 1 14.8 5.1 9.3 2.5 12.1 2.9 13.3 1.9 1.2 -0.9 1.4 -0.6 1.4 2 0 1.7 -0.4 3.1 -1 3.1 -0.5 0 -1 1.4 -1 3 0 2.9 -1.4 4.2 -2.2 1.9 -0.2 -0.7 -4.4 0 -12.1 2.1 -13.4 3.7 -14.4 4.9 -7.2 9.3 4.9 2.9 6.5 3.3 6.5 1.7 0 -0.5 0.5 -1 1 -1 0.6 0 1 1.4 1 3 0 3.3 -1.1 3.8 -3 1.5z" />
        <path d="M141 182.9 c0 -1.2 0.4 -1.7 1.1 -1.3 1.2 0.7 7.9 -5.4 7.9 -7.3 0 -0.7 0.7 -1.3 1.5 -1.3 0.8 0 1.5 0.7 1.5 1.5 0 0.8 -0.6 1.5 -1.3 1.5 -1.6 0 -7.7 6.2 -7.7 7.8 0 0.7 -0.7 1.2 -1.5 1.2 -0.8 0 -1.5 -0.9 -1.5 -2.1z" />
        <path d="M197 171.5 c0 -1.8 0.4 -2.3 1.5 -1.8 2.3 0.8 5.8 -2.6 4.4 -4.4 -0.9 -1 -0.5 -1.3 2 -1.3 4.1 0 4 2.1 -0.2 4.1 -1.7 0.8 -3.6 2.5 -4.2 3.7 -1.3 3 -3.5 2.8 -3.5 -0.3z" />
        <path d="M165 161.5 c0 -1.7 0.4 -2.3 1.4 -1.9 1.9 0.7 10.8 -5.7 10.2 -7.3 -0.3 -0.8 0.2 -1.3 1.4 -1.3 1.1 0 2 0.7 2 1.5 0 0.8 -0.5 1.5 -1 1.5 -2.1 0 -10.8 7.3 -10.3 8.6 0.3 0.9 -0.2 1.4 -1.6 1.4 -1.6 0 -2.1 -0.6 -2.1 -2.5z" />
        <path d="M202 161.1 c0 -1.4 -14.8 -9.3 -16 -8.6 -0.5 0.3 -1 -0.3 -1 -1.4 0 -1.4 0.6 -2.1 2 -2.1 1.1 0 1.9 0.5 1.8 1.1 -0.4 1.5 12.2 9 14 8.4 0.7 -0.3 1.2 0.4 1.2 1.5 0 1.1 -0.4 2 -1 2 -0.5 0 -1 -0.4 -1 -0.9z" />
        <path d="M173 145 c0 -1.7 -8.3 -4.9 -9.7 -3.8 -1 0.8 -1.3 0.4 -1.3 -1.6 0 -1.9 0.5 -2.6 2 -2.6 1.1 0 2 0.6 2 1.3 0 2 6.5 4.8 7.9 3.4 0.8 -0.8 1.1 -0.4 1.1 1.6 0 1.5 -0.4 2.7 -1 2.7 -0.5 0 -1 -0.5 -1 -1z" />
        <path d="M44 136.4 c0 -2 0.3 -2.4 1.3 -1.6 1.6 1.4 18.1 -12.3 17.3 -14.4 -0.3 -0.9 0.2 -1.4 1.4 -1.4 1.4 0 2 0.7 2 2.4 0 1.4 -0.6 2.3 -1.4 2.2 -1.8 -0.1 -17.5 12.5 -17.6 14.2 -0.1 2.5 -3 1.2 -3 -1.4z" />
        <path d="M180 138.1 c0 -0.5 0.4 -1.1 0.9 -1.3 1.4 -0.5 2.9 -16.7 1.7 -17.7 -0.6 -0.4 -8.2 -4.7 -16.9 -9.5 -12.9 -7.3 -16 -8.6 -17.2 -7.6 -1.3 1.1 -1.5 0.6 -1.5 -3.4 0 -4.1 0.2 -4.5 1.8 -3.5 1.4 0.9 3.6 0 11.5 -4.7 6.5 -3.8 9.6 -6.1 9.3 -7 -0.3 -0.9 0.2 -1.4 1.4 -1.4 1.6 0 2 0.7 2 3.3 0 2.3 -0.3 2.8 -1 1.7 -0.7 -1.2 -1.5 -1.1 -5.2 1 -8.8 4.9 -16.2 9.6 -16.5 10.5 -0.3 1 32.6 20 33.3 19.2 0.2 -0.2 0.9 -5.3 1.6 -11.2 0.9 -8.4 0.9 -11 0 -11.3 -2.3 -0.7 -1.2 -2.2 1.8 -2.2 2.9 0 4.1 1.5 1.8 2.2 -1.3 0.5 -4.1 22.9 -3 24 0.4 0.4 5.9 3.6 12.2 7.2 8.4 4.7 11.8 6.2 12.7 5.4 1 -0.8 1.3 -0.4 1.3 1.6 0 1.9 -0.5 2.6 -2 2.6 -1.1 0 -1.9 -0.5 -1.7 -1.1 0.2 -1.3 -22.1 -14.4 -23 -13.6 -1.1 1.1 -2.3 15.2 -1.3 15.5 2 0.7 0.9 2.2 -1.5 2.2 -1.4 0 -2.5 -0.4 -2.5 -0.9z" />
        <path d="M32 136.8 c0 -1.8 -14.7 -12.1 -16 -11.3 -0.5 0.3 -1 -0.3 -1 -1.4 0 -1.5 0.6 -2.1 2.1 -2.1 1.1 0 1.7 0.4 1.4 1 -0.7 1.2 14.2 12.3 15.5 11.5 0.6 -0.3 1 0.3 1 1.4 0 1.2 -0.7 2.1 -1.5 2.1 -0.8 0 -1.5 -0.6 -1.5 -1.2z" />
        <path d="M103 133.1 c0 -0.5 0.6 -1.1 1.3 -1.3 1.4 -0.5 3.9 -30.1 2.5 -30.6 -1.8 -0.8 -0.7 -2.2 1.7 -2.2 2.5 0 3.5 1.5 1.5 2.2 -1.6 0.5 -3.6 28.7 -2.1 30.9 1 1.7 0.8 1.9 -1.9 1.9 -1.6 0 -3 -0.4 -3 -0.9z" />
        <path d="M223 132 c0 -1.1 0.5 -1.9 1 -1.7 0.6 0.1 2.6 -1.8 4.4 -4.3 2.5 -3.3 3.2 -4.8 2.4 -5.7 -0.8 -1 -0.4 -1.3 1.6 -1.3 1.6 0 2.6 0.5 2.6 1.5 0 0.8 -0.6 1.5 -1.2 1.5 -1.8 0 -8.2 9.6 -7.3 10.9 0.3 0.7 -0.2 1.1 -1.4 1.1 -1.4 0 -2.1 -0.6 -2.1 -2z" />
        <path d="M138.8 119.8 c1 -1 0.2 -2.3 -3.5 -6 -2.6 -2.6 -5.3 -4.8 -6 -4.8 -0.7 0 -1.3 -0.7 -1.3 -1.5 0 -1.8 3 -2 3 -0.2 0.1 1.8 9.8 10 11 9.2 0.6 -0.4 1 0.5 1 1.9 0 2.2 -0.5 2.6 -2.7 2.6 -2.1 0 -2.4 -0.3 -1.5 -1.2z" />
        <path d="M225 109.5 c0 -1.9 -9 -2.1 -10.8 -0.3 -0.9 0.9 -1.2 0.6 -1.2 -1.5 0 -1.5 0.5 -2.7 1 -2.7 0.6 0 1 0.5 1 1 0 1.7 9.2 1.2 11.2 -0.7 1.7 -1.5 1.8 -1.4 1.8 2 0 2.6 -0.4 3.7 -1.5 3.7 -0.8 0 -1.5 -0.7 -1.5 -1.5z" />
        <path d="M60.4 108.1 c0.8 -1.2 -9 -4.5 -10.9 -3.7 -1.1 0.4 -1.5 -0.2 -1.5 -1.9 0 -1.6 0.6 -2.5 1.5 -2.5 0.8 0 1.5 0.6 1.5 1.3 0 1.9 9.5 4.8 10.9 3.4 0.8 -0.8 1.1 -0.3 1.1 1.6 0 1.8 -0.5 2.7 -1.6 2.7 -0.8 0 -1.3 -0.4 -1 -0.9z" />
        <path d="M121.5 103.1 c0.8 -1.3 -4.3 -5.1 -6.1 -4.4 -1 0.4 -1.4 -0.2 -1.4 -2.1 0 -2 0.5 -2.6 2.1 -2.6 1.4 0 1.9 0.5 1.6 1.5 -0.7 1.8 3.9 5.6 5.9 4.8 0.9 -0.3 1.4 0.2 1.4 1.6 0 1.5 -0.6 2.1 -2.1 2.1 -1.1 0 -1.8 -0.4 -1.4 -0.9z" />
        <path d="M200.8 96.8 c1.5 -1.5 -0.4 -5.2 -2.4 -4.4 -0.9 0.3 -1.4 -0.1 -1.4 -1.4 0 -1.5 0.7 -2 2.6 -2 1.4 0 2.3 0.4 2 0.9 -0.9 1.4 1.2 6.3 2.4 5.6 0.5 -0.3 1 0.1 1 0.9 0 1.1 -0.9 1.6 -2.7 1.6 -2 0 -2.4 -0.3 -1.5 -1.2z" />
        <path d="M100 93.1 c0 -1.5 -17.1 -19.1 -18.6 -19.1 -0.8 0 -1.4 -0.7 -1.4 -1.5 0 -1 1 -1.5 2.6 -1.5 2.1 0 2.4 0.3 1.6 1.3 -1.6 1.9 14.8 18.3 16.6 16.5 0.9 -0.9 1.2 -0.5 1.2 2 0 1.8 -0.4 3.2 -1 3.2 -0.5 0 -1 -0.4 -1 -0.9z" />
        <path d="M35 85 c0 -0.5 0.6 -1.3 1.3 -1.7 1.9 -1.1 4.2 -10.6 2.8 -11.1 -2.3 -0.8 -1 -2.2 1.9 -2.2 2 0 3 0.5 3 1.5 0 0.8 -0.6 1.5 -1.4 1.5 -0.8 0 -1.9 1.9 -2.5 4.7 -1.1 4.1 -1 4.9 0.3 5.9 2.4 1.7 1.9 2.4 -1.9 2.4 -1.9 0 -3.5 -0.4 -3.5 -1z" />
        <path d="M103.8 84.7 c1.8 -2.1 2.7 -17.4 1.2 -19.2 -1.1 -1.3 -0.8 -1.5 2.4 -1.5 2.5 0 3.6 0.4 3.6 1.5 0 0.8 -0.7 1.5 -1.5 1.5 -1.6 0 -2.1 1.8 -2.9 10.3 -0.4 4.5 -0.2 5.7 1 5.7 0.8 0 1.4 0.7 1.4 1.5 0 1 -1 1.5 -3.1 1.5 -2.5 0 -2.9 -0.3 -2.1 -1.3z" />
        <path d="M153 74.5 c0 -1.4 0.5 -2.5 1 -2.5 0.6 0 1 0.5 1 1.1 0 1.3 13.3 0.1 13.8 -1.2 0.8 -2 2.2 -0.7 2.2 2.1 0 2 -0.5 3 -1.5 3 -0.8 0 -1.5 -0.7 -1.5 -1.5 0 -1.2 -1.1 -1.4 -6.2 -0.8 -3.5 0.3 -6.4 1 -6.6 1.5 -0.8 1.8 -2.2 0.7 -2.2 -1.7z" />
        <path d="M68.3 66.6 c-1.5 -1.8 -12.6 -3.2 -13.1 -1.7 -0.8 2.3 -2.2 1 -2.2 -1.9 0 -3.2 1.6 -3.9 3.4 -1.6 1.4 1.8 12.6 3.3 12.6 1.7 0 -0.6 0.5 -1.1 1 -1.1 0.6 0 1 1.4 1 3 0 3.2 -0.8 3.7 -2.7 1.6z" />
        <path d="M147 65.5 c0 -0.8 0.6 -1.5 1.3 -1.5 1.3 -0.1 11.7 -16.8 11.7 -18.8 0 -0.7 0.9 -1.2 2 -1.2 1.5 0 2 0.7 2 2.6 0 2.2 -0.2 2.4 -1.4 1.4 -1.2 -0.9 -2 -0.4 -4.2 3.2 -1.4 2.4 -4 6.5 -5.7 9.1 -2.5 4 -2.8 5 -1.6 5.8 1 0.6 0.7 0.9 -1.3 0.9 -1.8 0 -2.8 -0.5 -2.8 -1.5z" />
        <path d="M102.7 39.9 c1.2 -1.2 -0.3 -5.9 -1.9 -5.9 -0.4 0 -0.8 -0.4 -0.8 -1 0 -0.5 1.2 -1 2.6 -1 2.3 0 2.5 0.2 1.3 1.6 -1.1 1.4 -1 1.9 0.9 3.4 3 2.4 2.8 4 -0.5 4 -2 0 -2.4 -0.3 -1.6 -1.1z" />
        <path d="M158.4 38.2 c0.3 -0.5 -7.9 -2.4 -18.2 -4.2 -10.3 -1.8 -21.7 -3.8 -25.4 -4.5 -3.8 -0.7 -6.8 -0.9 -6.8 -0.4 0 0.5 -0.4 0.9 -1 0.9 -0.5 0 -1 -1.1 -1 -2.5 0 -2.5 1.5 -3.5 2.2 -1.4 0.6 1.7 49.8 10.1 51.5 8.8 1 -0.9 1.3 -0.6 1.3 1.5 0 1.7 -0.5 2.6 -1.6 2.6 -0.8 0 -1.3 -0.4 -1 -0.8z" />
      </g>
      <g fill="#f6a7bf">
        <path d="M102 133.6 c0 -1.9 3.8 -2.7 6.1 -1.3 2.9 1.8 2.3 2.7 -2.1 2.7 -2.7 0 -4 -0.4 -4 -1.4z" />
        <path d="M15 123.4 c0 -1.9 0.5 -2.4 2.6 -2.4 2.1 0 2.4 0.3 1.5 1.3 -0.6 0.8 -0.8 1.6 -0.6 1.9 0.3 0.3 -0.3 0.8 -1.5 1.1 -1.6 0.4 -2 0 -2 -1.9z" />
        <path d="M64.4 123.2 c-0.6 -0.4 -1.3 -1.5 -1.7 -2.4 -0.5 -1.4 -0.1 -1.8 1.8 -1.8 2 0 2.5 0.5 2.5 2.5 0 2.6 -0.7 3 -2.6 1.7z" />
        <path d="M47 103 c0 -2.3 0.4 -3 2 -3 1.2 0 2 0.7 2 1.8 0 0.9 -0.9 2.3 -2 3 -1.8 1.1 -2 1 -2 -1.8z" />
        <path d="M121.2 103.3 c0.6 -3.7 3.8 -4.4 3.8 -0.8 0 1.9 -0.5 2.5 -2 2.5 -1.4 0 -2 -0.6 -1.8 -1.7z" />
        <path d="M53 62.5 c0 -2.3 0.5 -3.5 1.4 -3.5 1.9 0 2.6 3.3 1.1 5.3 -1.8 2.5 -2.5 2.1 -2.5 -1.8z" />
        <path d="M103.1 39.2 c1.5 -2.3 4.9 -2.3 4.9 0 0 1.3 -0.8 1.8 -3 1.8 -2.6 0 -2.9 -0.2 -1.9 -1.8z" />
        <path d="M100 34.3 c-7 -1.1 -10.4 -6.6 -7.5 -12.2 3.6 -7.1 14.6 -4.9 15.8 3.2 0.6 4.1 -4.5 9.6 -8.3 9z" />
      </g>
      <g fill="#a6abae">
        <path d="M154 232.5 c0 -3.9 0.9 -4.5 2.6 -1.7 0.9 1.4 0.9 2.2 -0.1 3.5 -1.8 2.5 -2.5 2.1 -2.5 -1.8z" />
        <path d="M162.3 224 c-2.6 -1.1 -3.9 -6.1 -2.3 -9 1.6 -3.1 5.6 -4.3 8.8 -2.8 8.3 4 2.1 15.4 -6.5 11.8z" />
        <path d="M147 203.1 c0 -2.9 0.2 -3 2 -1.9 2.4 1.5 2.2 4.1 -0.3 4.6 -1.3 0.3 -1.7 -0.3 -1.7 -2.7z" />
        <path d="M175 194.6 c0 -1.9 0.4 -2.3 2 -1.9 1.1 0.3 2 1.4 2 2.4 0 1.2 -0.7 1.9 -2 1.9 -1.4 0 -2 -0.7 -2 -2.4z" />
        <path d="M134.8 187.4 c-1.4 -1.7 -1.5 -2.1 -0.2 -3.8 1.8 -2.5 2.6 -1.6 2.2 2.4 -0.3 3.1 -0.4 3.2 -2 1.4z" />
        <path d="M151.9 176.3 c-1 -0.2 -1.9 -1.3 -2.2 -2.4 -0.4 -1.5 0 -1.9 1.9 -1.9 1.9 0 2.4 0.5 2.4 2.5 0 1.4 -0.1 2.4 -0.2 2.4 -0.2 -0.1 -1 -0.3 -1.9 -0.6z" />
        <path d="M201.8 161.2 c-0.3 -1.2 0.2 -2.4 1.3 -3.1 1.7 -1 1.9 -0.8 1.9 1.9 0 3.3 -2.4 4.2 -3.2 1.2z" />
        <path d="M208.3 135.1 c0 -1 0.9 -2.4 1.9 -3 1.6 -1 1.8 -0.8 1.8 1.9 0 2.3 -0.5 3 -1.9 3 -1.3 0 -1.9 -0.6 -1.8 -1.9z" />
        <path d="M222 132.1 c0 -2.5 3.7 -2.4 4.3 0.1 0.4 1.4 -0.1 1.8 -1.9 1.8 -1.7 0 -2.4 -0.6 -2.4 -1.9z" />
      </g>
      <g fill="#f06791">
        <path d="M162 139 c0 -2.5 0.4 -3 2.4 -3 1.8 0 2.3 0.4 1.9 1.8 -0.2 1 -1.3 2.3 -2.4 3 -1.7 1.1 -1.9 0.9 -1.9 -1.8z" />
        <path d="M99.6 92.5 c-0.6 -1.6 1.3 -5.5 2.6 -5.5 0.5 0 0.8 1.6 0.8 3.5 0 3.7 -2.2 4.9 -3.4 2z" />
        <path d="M37.9 71.4 c-10.8 -7 -5.3 -23.8 7.3 -22.1 10 1.3 14.1 13.2 6.9 20.1 -3.7 3.6 -10.3 4.5 -14.2 2z" />
        <path d="M104.8 65.6 c-2.6 -2 -2.2 -2.6 1.7 -2.6 2.8 0 3.5 0.4 3.5 2 0 2.3 -2.6 2.6 -5.2 0.6z" />
      </g>
      <g fill="#9b9da1">
        <path d="M143.6 207.4 c-0.9 -0.8 -1.6 -2.2 -1.6 -2.9 0 -1.7 2.7 -4.5 4.4 -4.5 2.2 0 4.7 3.5 3.9 5.6 -1.3 3.6 -4.2 4.4 -6.7 1.8z" />
        <path d="M185 150.4 c0 -1.9 0.5 -2.4 2.6 -2.4 2.1 0 2.4 0.3 1.5 1.3 -0.6 0.8 -0.8 1.6 -0.6 1.9 0.3 0.3 -0.3 0.8 -1.5 1.1 -1.6 0.4 -2 0 -2 -1.9z" />
        <path d="M172.7 145.1 c-0.3 -1.2 0.3 -2.7 1.4 -3.7 1.8 -1.6 1.9 -1.6 1.9 1.9 0 4 -2.4 5.2 -3.3 1.8z" />
      </g>
      <g fill="#fcb913">
        <path d="M197 90.4 c0 -2 0.5 -2.4 3 -2.4 2.7 0 2.9 0.2 1.8 1.9 -0.7 1.1 -2 2.2 -3 2.4 -1.4 0.4 -1.8 -0.1 -1.8 -1.9z" />
        <path d="M137.1 86.3 c-4.2 -2.1 -7.5 -8.8 -6.5 -13 1.7 -7 7.4 -11 14.5 -10 6.5 0.9 11.3 7.9 9.8 14.5 -1.8 8 -10.6 12.2 -17.8 8.5z" />
        <path d="M163.4 48.4 c-3.1 -1.5 -5.3 -6.2 -5.1 -10.7 0.1 -3 6.3 -7.7 10.2 -7.7 7.1 0 12.2 7.8 9.1 13.9 -3.1 5.9 -8.1 7.5 -14.2 4.5z" />
      </g>
      <g fill="#f7961d">
        <path d="M184 95 c-7.6 -2.1 -13.2 -7.4 -15 -14.1 -2.4 -8.8 1.9 -18.2 10.2 -22.3 2.8 -1.4 6.7 -2.6 8.5 -2.6 6.8 0 14.7 5 18 11.5 3.3 6.2 1.1 17.9 -4.3 22.6 -4.6 4 -12.6 6.3 -17.4 4.9z" />
      </g>
      <g fill="#818589">
        <path d="M174.1 233.6 c-1.6 -2 -0.3 -4.6 2.4 -4.6 2.7 0 3.6 1.7 2.3 4.3 -1 2 -3.1 2.2 -4.7 0.3z" />
        <path d="M152 229.6 c0 -1.9 0.4 -2.3 1.9 -1.9 1.1 0.3 2.1 1.3 2.4 2.4 0.4 1.5 0 1.9 -1.9 1.9 -1.9 0 -2.4 -0.5 -2.4 -2.4z" />
      </g>
      <g fill="#0db5c6">
        <path d="M233.4 122 c-3.3 -1.3 -7.2 -6.6 -7.9 -10.8 -0.5 -3 -0.2 -4.7 1.3 -7.2 7.3 -11.9 24.5 -7.6 24.5 6.1 0 8.8 -9.7 15.3 -17.9 11.9z" />
      </g>
      <g fill="#95b43c">
        <path d="M178 187.2 c-3.4 -4.1 -4.4 -7.9 -3.5 -12.2 1.6 -7.3 10 -12.3 16.8 -10 10.9 3.7 13.1 16.8 4 24 -2.1 1.7 -3.9 2 -8.6 1.9 -5.3 -0.2 -6.1 -0.5 -8.7 -3.7z" />
      </g>
      <g fill="#ee2f63">
        <path d="M47 102.7 c0 -3 0.4 -3.7 2 -3.7 1.3 0 2 0.7 2 1.8 0 1.1 -0.9 2.7 -2 3.7 -2 1.8 -2 1.7 -2 -1.8z" />
        <path d="M74.3 74 c-3 -1.2 -5.6 -5.5 -5.6 -9.2 0 -4.1 5.1 -8.8 9.4 -8.8 4.4 0 8.9 4.7 8.9 9.4 0 6.4 -6.9 11.1 -12.7 8.6z" />
      </g>
      <g fill="#ea2454">
        <path d="M32.4 109.6 c-9.2 -4 -11 -17.1 -3.1 -23 6.6 -4.9 13.6 -4 19 2.3 2.1 2.5 2.7 4.3 2.7 7.8 0 5.8 -2.5 9.9 -7.5 12.3 -4.8 2.3 -6.8 2.4 -11.1 0.6z" />
      </g>
      <g fill="#d82c59">
        <path d="M32 137.2 c0 -0.9 0.9 -2.3 2 -3 1.8 -1.1 2 -1 2 1.8 0 2.3 -0.4 3 -2 3 -1.2 0 -2 -0.7 -2 -1.8z" />
        <path d="M60.4 108.3 c1.1 -5.9 3.6 -7.1 3.6 -1.8 0 2.8 -0.4 3.5 -2 3.5 -1.4 0 -1.9 -0.6 -1.6 -1.7z" />
        <path d="M106.8 101.2 c-3.4 -0.7 -2 -3.2 1.7 -3.2 2.3 0 3.5 0.5 3.5 1.4 0 1.5 -2.5 2.4 -5.2 1.8z" />
        <path d="M99.7 93.1 c-0.5 -2 0.9 -5.1 2.3 -5.1 0.6 0 1 1.6 1 3.5 0 3.8 -2.4 5 -3.3 1.6z" />
        <path d="M104 64.8 c-7.2 -4.9 -9.7 -12.4 -6.5 -19.2 2.4 -5.1 6.8 -7.9 12.9 -8.3 15.6 -1.2 21.7 19.6 8 27.4 -5 2.9 -10.2 2.9 -14.4 0.1z" />
      </g>
      <g fill="#843f98">
        <path d="M120 179.8 c0 -0.7 0.3 -3 0.6 -5.1 0.6 -3.5 0.8 -3.6 2.4 -2.2 2.6 2.4 1 8.4 -2.2 8.5 -0.5 0 -0.8 -0.6 -0.8 -1.2z" />
        <path d="M97.5 166.7 c-3.7 -1.7 -9.3 -7.9 -10.1 -11.3 -0.4 -1.4 -0.4 -4.8 -0.2 -7.5 1 -9.8 10.4 -16.9 20.4 -15.4 8.4 1.2 16.3 11.6 15.1 19.7 -0.6 4.6 -4.1 10 -7.9 12.6 -4.7 3 -12.8 3.9 -17.3 1.9z" />
        <path d="M176.2 152 c-1.1 -1.8 -1 -2 1.8 -2 2.3 0 3 0.4 3 2 0 1.2 -0.7 2 -1.8 2 -0.9 0 -2.3 -0.9 -3 -2z" />
        <path d="M179 138.6 c0 -1.8 3.2 -2.6 5.4 -1.4 2.7 1.5 1.8 2.8 -1.9 2.8 -2.3 0 -3.5 -0.5 -3.5 -1.4z" />
        <path d="M139.1 119.1 c0.7 -1 2 -2.3 3 -3 1.7 -1 1.9 -0.8 1.9 1.9 0 2.7 -0.3 3 -3 3 -2.7 0 -2.9 -0.2 -1.9 -1.9z" />
      </g>
      <g fill="#749645">
        <path d="M166.6 204.9 c-4.8 -3.8 -2.8 -12 3.3 -13.4 8 -1.7 13.3 7.4 7.6 13 -3 3.1 -7.3 3.2 -10.9 0.4z" />
      </g>
      <g fill="#d32a49">
        <path d="M6.9 125.5 c-6 -3.3 -5.4 -12.8 1 -15.4 6 -2.6 12.1 1.4 12.1 7.8 -0.1 7.3 -6.7 11.1 -13.1 7.6z" />
      </g>
      <g fill="#d24628">
        <path d="M198.9 112.1 c-4.1 -4.2 -4.1 -10.2 0 -14.1 2.8 -2.5 4.5 -2.9 9.7 -2.2 2.4 0.4 6.2 5.4 6.6 8.9 0.4 2.9 -0.1 4.1 -2.8 6.9 -4.2 4.5 -9.2 4.7 -13.5 0.5z" />
        <path d="M123.1 108.1 c-2.6 -2.6 -2.7 -4.5 -0.1 -7.1 2.6 -2.6 4.5 -2.5 7.1 0.1 1.5 1.5 1.9 2.8 1.4 4.7 -0.8 3.3 -0.4 2.9 -3.7 3.7 -1.9 0.5 -3.2 0.1 -4.7 -1.4z" />
      </g>
      <g fill="#7c2a8e">
        <path d="M148.5 145.1 c-4.6 -1.1 -7.9 -3.7 -10.3 -8 -8.2 -14.6 7.9 -30.1 22.3 -21.6 5 2.9 7.8 8.1 7.7 14.1 -0.1 7.2 -3.9 12.5 -10.9 15 -4.2 1.5 -4.6 1.5 -8.8 0.5z" />
      </g>
      <g fill="#438e56">
        <path d="M148.1 170.1 c-0.7 -1.3 -0.7 -2.9 0.1 -5.3 2.1 -6.2 4.8 -7.8 4.8 -2.8 0 1.7 -0.4 3 -1 3 -0.5 0 -1 1.6 -1 3.5 0 3.8 -1.4 4.6 -2.9 1.6z" />
        <path d="M203.2 165.8 c-1.7 -1.7 -1.5 -6.3 0.2 -7.7 4.6 -3.8 11.1 2.5 7.1 6.9 -2 2.2 -5.5 2.6 -7.3 0.8z" />
      </g>
      <g fill="#5b5a5f">
        <path d="M147.5 236.5 c-1.4 -1.3 -2.5 -3.1 -2.5 -4 0 -2.1 3.6 -5.5 6 -5.5 2.4 0 6 3.4 6 5.5 0 1.8 -4.4 6.5 -6 6.5 -0.6 0 -2.2 -1.1 -3.5 -2.5z" />
        <path d="M140 183 c0 -2.8 0.2 -2.9 2 -1.8 1.1 0.7 2 2.1 2 3 0 1.1 -0.8 1.8 -2 1.8 -1.6 0 -2 -0.7 -2 -3z" />
      </g>
      <g fill="#632c81">
        <path d="M115.6 179.9 c-3.3 -2.6 -3.3 -5.2 0 -7.8 2.5 -1.9 4.5 -1.9 7.2 0.2 2 1.6 1.4 6.4 -1 8.1 -2.9 2 -3 2 -6.2 -0.5z" />
      </g>
      <g fill="#a8342a">
        <path d="M141.1 103.7 c-4.7 -2.4 -4.5 -8.7 0.4 -10.9 2.2 -1 3 -0.8 5.3 0.9 3.8 2.8 4 6.5 0.6 9.2 -3.1 2.4 -3.2 2.5 -6.3 0.8z" />
      </g>
      <g fill="#3a7b4a">
        <path d="M153.1 176.6 c-6.6 -3.7 -6.6 -15.1 0.1 -18.3 4.7 -2.3 6.2 -2.3 10.4 -0.3 7.3 3.6 8.4 12.3 2.2 17.5 -3.3 2.8 -8.7 3.3 -12.7 1.1z" />
      </g>
      <g fill="#971e3b">
        <path d="M34.5 144.5 c-3.2 -3.1 -3.3 -7.4 -0.2 -10.3 7.2 -6.7 17.2 3.4 10.2 10.3 -1.3 1.4 -3.6 2.5 -5 2.5 -1.4 0 -3.7 -1.1 -5 -2.5z" />
        <path d="M103.5 85 c1 -1.1 2.6 -2 3.7 -2 1.1 0 1.8 0.7 1.8 2 0 1.6 -0.7 2 -3.7 2 -3.5 0 -3.6 0 -1.8 -2z" />
      </g>
      <g fill="#9e1b36">
        <path d="M68.9 125.7 c-4.9 -2.6 -8.9 -8.9 -8.9 -14.3 0 -5.1 4 -11.5 9 -14.1 7.3 -3.9 14 -2.5 19.7 4 5.5 6.3 5.4 14.9 -0.3 21.1 -5.5 5.8 -12.4 7 -19.5 3.3z" />
        <path d="M103.9 99.7 c-2.3 -1.7 -3.9 -5.2 -3.9 -8.2 0 -3.8 5 -8.5 9 -8.5 4.5 0 9 4.7 9 9.5 0 4.8 -3.9 8.5 -8.9 8.5 -2 0 -4.3 -0.6 -5.2 -1.3z" />
      </g>
      <g fill="#522669">
        <path d="M175.6 150.9 c-3.4 -4 -3.3 -7.9 0 -11.3 5.4 -5.3 14.4 -1.8 14.4 5.6 -0.1 8.1 -9.3 11.8 -14.4 5.7z" />
      </g>
      <g fill="#434344">
        <path d="M135.6 188.1 c-2.2 -2.5 -1.3 -5.8 2 -7.3 1.9 -0.9 2.8 -0.8 4.4 0.7 2.5 2.3 2.6 4.7 0.2 6.8 -2.4 2.2 -4.5 2.1 -6.6 -0.2z" />
      </g>
      <g fill="#115c5c">
        <path d="M214.4 148.6 c-4.2 -1.9 -6.4 -5.2 -6.4 -9.9 0 -7.5 8 -12 15.4 -8.6 3.3 1.5 5.9 7.1 5.1 10.9 -0.7 3.1 -5 8 -7.1 8 -0.7 0 -1.8 0.2 -2.6 0.5 -0.7 0.2 -2.7 -0.2 -4.4 -0.9z" />
      </g>
    </svg>
  );
};
