import { Box, Button, Text } from "@chakra-ui/react";
import { LoremIpsum } from "react-lorem-ipsum";
import { Link } from "react-router-dom";
import { FlexColumn } from "../../../components/layout/Flex/Flex";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

/* How to design this so makes sense? Rember we get here by clicking a card */
/* with price and icon from main menu. */

/* Will eventually have different types of users? */
/* Teacher, parents? */

/* "Problem" right now is only have 1 type of user; no way to differentiate */
/* between teachers and parents. Ideal would be to have have some nice */
/* features for teacher accounts, so they don't just register for a parents */
/* account. */
/* One such feature can be being able to save sheets/workbooks per student. */
/* This also makes sense when thinking about adding future features such as */
/* the proficiency planner. Then will also have the option to track individual */
/* progress for students, should they be struggling. */

/* When we get to this point, can have different sign up forms for teachers */
/* and privte accounts. */

export const InfoSchool = () => {
  return (
    <FlexColumn bg="#f5f7fa" minH="100vh">
      <Header />
      <FlexColumn m="auto" mb="1.5rem" mt="1.7rem" w="95%" maxW="55rem">
        <Text fontSize="2.8rem" fontWeight="bold" m=".5rem" w="fit-content">
          iMAL in der Schule
        </Text>
        <Button as={Link} to="/home" mx="auto" px="2rem" w="fit-content">
          Back
        </Button>
        <Box width="100%">
          <LoremIpsum p={8} />
        </Box>
      </FlexColumn>
      <Footer />
    </FlexColumn>
  );
};
