type Props = {
  hideLine?: boolean;
  text?: string;
  x?: number;
  y?: number | string;
};

/* TODO: Try to fix, so that default position of the header is at 0,0 */
/* and not half way down the sheet. */
/* TODO: add prop `hideLine`? */
export const SectionHeader = ({ hideLine = false, text, x = 0, y = 0 }: Props) => {
  return (
    <svg viewBox="0 0 100 100" x={x} y={y}>
      <linearGradient id="Gradient1">
        <stop offset="0%" stopColor="#144861" stopOpacity="1" />
        <stop offset="100%" stopColor="#144861" stopOpacity="0" />
      </linearGradient>
      <text
        fontFamily="arial"
        fontSize="3.3"
        fontWeight="bold"
        width="100%"
        height="100%"
        x={"3%"}
        y={"2.4%"}
      >
        {text}
      </text>
      {!hideLine && <rect fill="url(#Gradient1)" width="65%" height="2%" y="4%" />}
    </svg>
  );
};
