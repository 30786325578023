import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text
} from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { useState } from "react";
import { FaHome } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthentication } from "../../auth/useAuthentication";
import { ESendMail, useSendPasswordResetEmail } from "../../auth/usePasswordResetEmail";
import { FlexColumn } from "../../components/layout/Flex/Flex";
import { globalTranslations, useGlobalTxt } from "../../i18n/useGlobalTxt";
import iMalLogoGerman from "./../../utils/utilsiMAL/images/logo/Logo_German.png";

/* Use flaticon login page for inspiration. */

export const LogIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signIn, authStatus } = useAuthentication();
  const { txt } = useTxt(translations);
  const { txt_g } = useGlobalTxt();
  const [showingResetPwModal, setShowingResetPwModal] = useState(false);
  const navigate = useNavigate();

  /* TODO: fix this not going to same route on refresh. */
  if (authStatus === "authenticated") return <Navigate to="/app" />;

  /* Just use `flex: 1` here? */
  return (
    <>
      <ModalResetPassword
        isShowing={showingResetPwModal}
        close={() => {
          setShowingResetPwModal(false);
        }}
      />
      <FlexColumn alignItems="center" height="100vh" justifyContent="center" minWidth="100vw">
        <Flex
          as="a"
          href="https://home.imalabc.de"
          textDecoration="none"
          alignItems="center"
          bg="#bbb"
          borderRadius=".4rem"
          p="1rem"
          pos="fixed"
          top="4rem"
          left="4rem"
        >
          <Icon as={FaHome} mr=".5rem" fontSize="1.5rem" />
          <Text fontWeight="bold">Homepage</Text>
        </Flex>
        <FlexColumn m="5vh" maxWidth="17rem" textAlign="center">
          <Image
            alt=""
            h="auto"
            alignSelf="center"
            mb="4vh"
            maxH="120px"
            maxW="200px"
            src={iMalLogoGerman}
            w="auto"
          />
          <Input
            autoFocus={true}
            bg="#fff"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={txt_g.email}
            type="email"
            value={email}
            w="inherit"
          />
          <Input
            bg="#fff"
            mt=".2rem"
            onKeyDown={(e) => {
              if (e.key === "Enter" && email && password) signIn(email, password);
            }}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={txt_g.password}
            type="password"
            value={password}
            w="inherit"
          />
          <Center minHeight="2.5rem">
            <Text color="red">
              {authStatus === "error:invalid_username_or_password"
                ? txt.sign_in_error_invalid_username_or_password
                : authStatus === "error:not_connected_to_internet"
                ? txt.sign_in_error_not_connected_to_internet
                : ""}
            </Text>
          </Center>
          <Button
            fontWeight="bold"
            onClick={() => {
              if (email && password) signIn(email, password);
            }}
            size="lg"
          >
            {authStatus === "loading" ? <Spinner /> : txt_g.sign_in}
          </Button>
          <Text
            _hover={{ cursor: "pointer", textDecoration: "underline" }}
            color="link"
            mb="5vh"
            mt="3.5vh"
            onClick={() => {
              setShowingResetPwModal(true);
            }}
            alignSelf="center"
          >
            {txt.forgot_your_password}
          </Text>
          <p>{txt.dont_have_an_account}</p>
          <Button size="lg" variant="secondary" onClick={() => navigate("/signup")}>
            {txt.sign_up}
          </Button>
          <Flex flexDirection="column" mt="5vh">
            <Text color="muted" fontSize=".8rem" mt="2vh" mb="2vh">
              {txt.copyright_imal}
            </Text>
          </Flex>
          <Box m="4vh" />
        </FlexColumn>
      </FlexColumn>
    </>
  );
};

export const translations = {
  [ELocale.de_DE]: {
    app_not_supported_in_your_browser:
      "iMAL funktioniert leider nicht auf Ihrem Browser:( Versuchen Sie es erneut mit Chrome oder Edge.",
    check_your_inbox:
      "Checken Sie ihren Mail! Einen E-Mail mit Zurücksetzungslink wurde versandt an ",
    click_here: "Hier clicken",
    copyright_imal: "© iMAL Deutsch 2020-2021",
    dont_have_an_account: "Sie haben kein Konto?",
    enter_your_email_address_and_click_link_in_email:
      "Ihre Email-addresse eintippen, und auf den Link in der an Ihnen geschickten Mail clicken, um Ihre Passwort zurückzusetzen.",
    forgot_your_password: "Passwort vergessen?",
    reset_password: "Passwort zurücksetzen",
    sign_in_error_invalid_username_or_password: "Email oder Passwort ist ungültig.",
    sign_in_error_not_connected_to_internet: "Device not connected to the internet.",
    sign_up: "Neu anmelden"
  },
  [ELocale.en_US]: {
    app_not_supported_in_your_browser:
      "iMAL does not work with your browser:( Visit this website on ______ instead!",
    check_your_inbox: "Check your inbox! An email with a password reset link has been sent to ",
    click_here: "Click here",
    copyright_imal: "© iMAL English 2020-2021",
    dont_have_an_account: "Don't have an account?",
    enter_your_email_address_and_click_link_in_email:
      "Enter your email address and click the link in the email to reset your password.",
    forgot_your_password: "Forgot your password? ",
    reset_password: "Reset password",
    sign_in_error_invalid_username_or_password: "Invalid username or password.",
    sign_up: "Sign up"
  },
  [ELocale.es_ES]: {
    app_not_supported_in_your_browser:
      "IMAL no funciona en su navegador :( Visita la página web en Chrome o Edge.",
    check_your_inbox: "Check your inbox! An email with a password reset link has been sent to ",
    click_here: "Haga clic aquí",
    copyright_imal: "© iMAL España 2020-2021",
    dont_have_an_account: "¿No tienes cuenta?",
    enter_your_email_address_and_click_link_in_email:
      "Enter your email address and click the link in the email to reset your password.",
    forgot_your_password: "¿Olvidaste tu contraseña?",
    reset_password: "¿Restablecer contraseña?",
    sign_in_error_invalid_username_or_password: "Usario o Contraseña invalido.",
    sign_up: "Inscríbite"
  }
};

export const ModalResetPassword = ({
  isShowing,
  close
}: {
  isShowing: boolean;
  close: () => void;
}) => {
  const { txt } = useTxt(translations);
  const { txt_g } = useGlobalTxt();
  const [email, setEmail] = useState("");
  const { clearSendEmailStatus, sendPasswordResetEmail, status } = useSendPasswordResetEmail();

  const getErrorMessage = () => {
    if (status === ESendMail.SUCCESS)
      return (
        <Text color="green">
          {txt_g.email_has_been_sent_to} <b>{email}</b>.
        </Text>
      );
    if ([ESendMail.AWAITING_INPUT, ESendMail.LOADING].includes(status)) return "";
    return (
      <Text color="red">{txt_g[status as keyof typeof globalTranslations[ELocale.de_DE]]}</Text>
    );
  };

  return (
    <Modal
      isCentered
      isOpen={isShowing}
      onClose={() => {
        close();
        setEmail("");
        clearSendEmailStatus();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{txt.reset_password}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FlexColumn>
            <FormLabel>{txt_g.email}</FormLabel>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && email && sendPasswordResetEmail(email)}
              placeholder={txt_g.email}
              w="inherit"
            />
            {status && (
              <Flex alignItems="center" h="4vh">
                {getErrorMessage()}
              </Flex>
            )}
            <Button my="2vh" onClick={() => sendPasswordResetEmail(email)}>
              {status === "loading" ? <Spinner /> : txt_g.email_send}
            </Button>
          </FlexColumn>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
