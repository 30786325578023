import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon as ChakraIcon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Wrap
} from "@chakra-ui/react";
import { getSounds, TSound } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { TIndex } from "@imaldev/imal-factory/ts";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import * as R from "ramda";
import { useMemo, useState } from "react";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import { RiCloseCircleFill } from "react-icons/ri";
import { wSetDoc } from "../../api/shared";
import {
  newSequence as fnNewSequence,
  SoundSequence
} from "../../utils/utilsiMAL/soundSequences/sequences";
import { useHover } from "../../utils/utilsReact/useHover";
import { TUserSequence } from "../../views/PostLogin/AppSheetsAbc/AppSheetsAbc";
import { TClickable } from "../../views/PostLogin/AppSheetsAbc/VideoQuestionMark/VideoQuesitonMark";

/* Should generally have updateSequence which both updates and creates doc */
/* in a coll. if doc doesn't already exsist? Rather than having both update */
/* and create. */

/* Should seq be "normal" or "userSeq"?  */
/* DRY-ify */
export const dbSaveSequence = (locale: ELocale, idUser: string, seq: TUserSequence) => {
  wSetDoc(`/app_sheets_abc/${locale}/users/${idUser}/sequences/${seq.id}`, seq);
};

type PropsSound = TClickable & { xOnClick?: () => void; sound: TSound };

const Sound = chakra(({ sound, xOnClick, ...rest }: PropsSound) => {
  const { hasHover, hoverHandlers } = useHover();

  return (
    <Box boxSizing="border-box" h="100%" p=".2rem" w="fit-content">
      <Center
        _hover={{ filter: "brightness(90%)" }}
        {...hoverHandlers}
        bg="beige"
        borderRadius=".4rem"
        boxShadow="sm"
        cursor="pointer"
        h="100%"
        pos="relative"
        transition="ease .045s"
        w="100%"
        {...rest}
      >
        {xOnClick && hasHover && (
          <ChakraIcon
            _hover={{
              opacity: 1
            }}
            opacity={0.4}
            as={RiCloseCircleFill}
            color="#888"
            cursor="pointer"
            onClick={xOnClick}
            pos="absolute"
            w="1.2rem"
            h="1.2rem"
            left={0}
            top={0}
            transition="ease .07s"
          />
        )}
        <Text
          color="#333"
          fontSize="1.4rem"
          fontWeight="bold"
          minW="3.5rem"
          textAlign="center"
          userSelect="none"
        >
          {sound}
        </Text>
      </Center>
    </Box>
  );
});

/* TODO: create custom hook for useShortClick: */
/* which will include a timer and only fire supplied callback when click */
/* was shorter than some length. */

/* Should also incude close? */
export type Openable = { isOpen: boolean };

type PropsSequenceEditor = Openable & {
  onClose: () => void;
  onSave: (seq: TUserSequence) => void; // TODO: should be called saveSequence.
  sequence?: SoundSequence;
  title?: string;
};

/* TODO: make this thing a little bit smaller? At least make sure don't have to scroll */
/* on 1080p. */
export const SequenceEditor = ({
  onClose,
  isOpen,
  onSave,
  sequence,
  title = "Reihenfolge erstellen"
}: PropsSequenceEditor) => {
  /* useReducer for states? Then logic will be in reducer instead. */
  const [newName, setNewName] = useState(sequence?.name ?? "");
  const [newSounds, setNewSounds] = useState<TSound[]>(sequence?.sounds ?? []);
  /* const { sounds: localeSounds } = useleSounds(); */
  const { locale } = useLocale();

  const localeSounds = useMemo(() => getSounds(locale), [locale]);

  const handleChange = (foo: string, source: TIndex, target: TIndex) => {
    const nextState = swap(newSounds, source, target);
    setNewSounds(nextState);
  };

  const appendToSequence = (s: TSound) => setNewSounds((prev) => [...prev, s]);

  const removeFromSequence = (s: TSound) =>
    setNewSounds((prev) => prev.filter((fromNew) => fromNew !== s));

  const resetState = () => {
    setNewName("");
    setNewSounds([]);
  };

  const handleClose = () => {
    if (!sequence) resetState();
    onClose();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose} size="5xl">
      <ModalOverlay />
      <ModalContent height="fit-content" m="0" maxH="90vh" overflow="auto">
        <ModalHeader>
          <Heading as="b">{title}</Heading>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody boxShadow="lg">
          <Center>
            <GridContextProvider onChange={handleChange}>
              <Box w="90%" m="auto">
                <Wrap
                  bg="lightblue"
                  borderRadius=".3rem"
                  boxShadow="md"
                  h="15rem"
                  mx="1rem"
                  mb="1rem"
                  p=".4rem"
                >
                  {localeSounds
                    .filter((s) => !newSounds.some((seqSound) => seqSound === s))
                    .map((s, i) => (
                      <Sound sound={s} onClick={() => appendToSequence(s)} key={i} />
                    ))}
                </Wrap>
                <FormControl isRequired>
                  <FormLabel>
                    <Text as="b">Name Reihenfolge</Text>
                  </FormLabel>
                  {/* TODO: do save action on enter press. Check on e if pressed enter. */}
                  {/* Extract an enter fn? */}
                  <Input
                    ml="1rem"
                    onChange={(e) => setNewName(e.target.value)}
                    placeholder="..."
                    value={newName}
                    w="20rem"
                  />
                </FormControl>
                <GridDropZone
                  boxesPerRow={12}
                  id="mandatory"
                  rowHeight={50}
                  style={{
                    background: "#9FE0B1",
                    borderRadius: ".3rem",
                    height: "15rem",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    marginTop: ".7rem"
                  }}
                >
                  {newSounds.map((s) => (
                    <GridItem key={s}>
                      {/* Render an x icon here. Maybe outside GridItem actually... */}
                      {/* How is it done in no-se app? */}
                      <Sound
                        boxShadow="lg"
                        cursor="grab"
                        sound={s}
                        xOnClick={() => removeFromSequence(s)}
                      />
                    </GridItem>
                  ))}
                </GridDropZone>
              </Box>
            </GridContextProvider>
          </Center>
          <Flex m="1rem">
            <Button
              _hover={{}}
              disabled={
                newName === "" ||
                R.isEmpty(newSounds) ||
                /* Ie. has not changed. */
                (sequence?.name === newName && R.equals(sequence.sounds, newSounds))
              }
              onClick={() => {
                /* Debate whether should always call onClose along onSave. */
                /* Maybe parent can decide this? */
                /* Or have prop close onSave. */
                /* Need flag "dirty"? */
                /* TODO: should also set display name. Or rather, display_name is the field we are */
                /* most interested in setting. */
                onSave(
                  R.merge(sequence ?? fnNewSequence(), {
                    name: newName,
                    sounds: newSounds
                  })
                );
                handleClose();
              }}
            >
              Speichern
            </Button>
            <Button
              _hover={{}}
              disabled={R.equals(newSounds, sequence?.sounds)}
              ml="1rem"
              onClick={() => setNewSounds(sequence?.sounds ?? [])}
            >
              Zurrücksetzen
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
