import * as R from "ramda";
import { useEffect, useRef, useState } from "react";

export const useIsOnScreen = () => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const ref = useRef<any>(null);

  const observer = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return { ref, isOnScreen };
};
