import { Box, Center, Icon as ChakraIcon } from "@chakra-ui/react";
import Color from "color";
import * as R from "ramda";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FlexColumn, FlexRow } from "../../../../../../../../../components/layout/Flex/Flex";
import { useGlobalTxt } from "../../../../../../../../../i18n/useGlobalTxt";
import { cs } from "../../../../../../../../../styles/colors";
import { applyCap, ECap, TSound } from "@imaldev/imal-factory/abc";
import { Nullable } from "../../../../../../../../../utils/utilsTS/dictionary/dict";
import { useDefaultSequence } from "../../../../../../context/AppSheetsAbcUserData/contextDefaultSequence";
import { SelectCap } from "../SelectSound/SelectCapitalization";
import { SettingBox } from "../SettingContainer/SettingBox";

type ButtonStatus = "focused" | "inactive" | "selected";

type PropsSoundButton = {
  cap: Nullable<ECap>;
  mainAction: () => void;
  sound: string;
  status: ButtonStatus;
  toggleSound: () => void;
};

/* Should be Soundbuttons? */
/* setFocused -> mainAction? */
const SoundButton = ({
  cap,
  mainAction,
  sound,
  status,
  toggleSound = () => {}
}: PropsSoundButton) => {
  /* Think chakra docs have examples for better way of doing this. */
  /* Component variants and layers. */
  const bgByStatus: Record<ButtonStatus, string> = {
    focused: "#FFAE00",
    inactive: cs.RED_WEAK,
    selected: cs.GREEN
  };

  return (
    <FlexColumn boxShadow="base" m=".5vh" w="5vh" userSelect="none">
      <Center
        bg={bgByStatus[status]}
        borderTopRadius="0.4vh"
        color={cs.WHITE}
        fontSize="2vh"
        fontWeight={status === "focused" ? "bold" : "initial"}
        onClick={status === "focused" ? undefined : mainAction}
        status={status}
        _hover={{
          bg:
            status !== "focused"
              ? new Color(bgByStatus[status]).darken(0.2).hex()
              : bgByStatus.focused,
          cursor: status === "focused" ? "default" : "pointer"
        }}
      >
        {applyCap(sound, cap || ECap.UPPER)}
      </Center>
      <Center
        bg={status === "focused" ? cs.GRAY_DARK : "default"}
        {...["Left", "Right", "Bottom"].reduce(
          (acc, pos) => R.assoc(`border${pos}`, `0.1vh solid ${cs.GRAY}`, acc),
          {} as any
        )}
        h="2.5vh"
        justifyContent="center"
        onClick={toggleSound}
        p="0.5vh 0"
        _hover={{
          bg: status === "focused" ? cs.GRAY_DARK : "#ddd",
          cursor: status === "focused" ? "default" : "pointer"
        }}
      >
        <ChakraIcon
          as={status === "inactive" ? FaPlus : FaMinus}
          fontSize="1.31vh"
          onClick={toggleSound}
          _hover={{
            color: "#777",
            cursor: "pointer"
          }}
        />
      </Center>
    </FlexColumn>
  );
};

type Props = {
  cap?: Nullable<ECap>;
  disableCapFirstUpper?: boolean;
  focusedSound?: TSound;
  isCollapsible?: boolean;
  isOpenByDefault?: boolean;
  selectedSounds: TSound[]; // Rename to selected?
  setCap: (v: ECap) => void;
  onSelectSound: (_: TSound) => void;
  toggleSound: (sound: TSound) => void;
};

const SelectSounds = ({
  cap,
  disableCapFirstUpper = false,
  focusedSound,
  isOpenByDefault = true,
  isCollapsible = true,
  selectedSounds,
  onSelectSound = (s: TSound) => {},
  setCap,
  toggleSound
}: Props) => {
  const { txt_g } = useGlobalTxt();
  const { defaultSequence } = useDefaultSequence();

  return (
    <SettingBox isCollapsible={isCollapsible} openByDefault={isOpenByDefault} title={txt_g.sounds}>
      <FlexRow p=".7rem" wrap="wrap">
        {defaultSequence.sounds.map((s, i) => (
          <SoundButton
            cap={cap}
            key={i}
            mainAction={() => onSelectSound(s)}
            sound={s}
            status={
              s === focusedSound ? "focused" : selectedSounds.includes(s) ? "selected" : "inactive"
            }
            toggleSound={() => toggleSound(s)}
          />
        ))}
      </FlexRow>
      <Box boxSizing="border-box" w="90%" m="auto" pb=".7rem">
        <SelectCap activeCap={cap} disableFirstUpper={disableCapFirstUpper} setCap={setCap} />
      </Box>
    </SettingBox>
  );
};

export { SelectSounds };
