import { TFilter, TWord } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { Base64, PRecord } from "@imaldev/imal-factory/ts";
import * as R from "ramda";
import { ETypeface } from "../../../../../../utils/utilsiMAL/fonts/shared";
import { mkUUID, UUID } from "../../../../../../utils/utilsTS/uuid/uuid";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { TTaskSheet } from "../TaskSheet";

/* ===== Things used for sheet components and their respective assets. ===== */
/* TODO: reorder things in this module. */
/* Very long names are abbreviated. */

export enum ESheet {
  ENCIRCLE_WORDS_WITH_SOUND = "encircleWordsWithSound",
  LETTER_LARGE_AND_SMALL = "letterLargeAndSmall",
  READ_AND_WRITE_MAPPING = "readAndWriteMapping",
  ONE_LARGE_SOUND = "oneLargeSound",
  ONE_LARGE_WWII = "oneLargeWWII",
  SOUND_POSTER = "soundPoster",
  TRACK_AND_BOX = "trackAndBox",
  TRACK_AND_WWII_4X = "trackAndWWII4x",
  WRITE_SOUND_ROWS = "writeSoundRows",
  WSR_2x_AND_READ_3X = "2xWriteAnd3xRead",
  WSR_2X_AND_WWII_4X = "WSR2xAndWWII4x",
  WWII_8X = "writeWordInImageGrid"
}

/* Do we actually need this? Or unnecessary complexity? */
export type TSheetNamed<T extends ESheet> = TTaskSheet & { name: T };

export type AbstractSheet = {
  id: UUID;
  image: { forPdf: Base64; thumbnail: Base64 };
  name: ESheet;
  pageNumber: number; // hmmm... good for performance, but bad for complexity.
};

export const mkAbstractSheet: () => AbstractSheet = () => ({
  id: mkUUID(),
  name: null!,
  pageNumber: -1,
  image: { forPdf: "", thumbnail: "" }
});

/* TODO?: rename to MkSheetT<SN>? */
/* Idk... this thing feels is kinda iffy overall... */
export type TNewSheetType<SN extends ESheet> = AbstractSheet & { name: SN };

/* nice article about typing children: */
/* https://www.carlrippon.com/react-children-with-typescript/ */
/* Ie. "childless component". */
// type CLC<T> = Omit<React.FC<T>, "children">;

/* All sheets follow this pattern? */
/* What about "sheets" which consist of multiple sheets? */
/* MappingSheet, ReadingSheet (, future multi page task sheets).  */
export type SheetPreview<T extends ESheet> = (props: {
  interactive?: boolean;
  sheetProps: TSheetNamed<T>;
}) => JSX.Element;

export type MkSheet<ST extends TTaskSheet> = (config?: {
  locale?: ELocale;
  pSheet?: Partial<ST>;
  // Filters will always be passed, right?
  getWords?: (nWords: number, filters: TFilter[]) => TWord[];
  typefaceName?: ETypeface;
}) => ST;

/* Helper for fns of type TFnNewSheet. */
/* Probably rewrite this... */
/* WTF does it even do? Or work? Try not to use this fn? bloat? unnecessary and complex? */
export const getDefaultArgs = <
  SN extends ESheet,
  T extends TSheetNamed<SN>,
  U extends { locale?: ELocale; partSheet?: Partial<T> }
>(
  arg?: U,
  defaultSheetProps?: Partial<T>
) => {
  return R.mergeRight(
    { locale: ELocale.de_DE, partSheet: defaultSheetProps ?? {} },
    arg ?? ({} as Partial<T>)
  ) as Required<U>;
};

/* TODO?: inside sheet modules, the name off the sheet as a postfix. */
/* Not 100% sure, but think this would make sense. */
/* Try it for 1 or 2 modules and see how it works. */
export type TSheetConfig<T extends ESheet = ESheet> = {
  disabledLocales?: ELocale[];
  Editor: React.ReactNode;
  videos?: InstructionalVideos;
  fnMkSheet: MkSheet<TSheetNamed<T>>;
  menuSheets: TMenuSheetsFor<T>;
  Sheet: SheetPreview<T>; // rename to preview?
};

export type InstructionalVideos = PRecord<
  ELocale,
  Partial<{
    editor?: VimeoVideoUrl;
    method?: VimeoVideoUrl;
  }>
>;

type VimeoVideoUrl = string;
