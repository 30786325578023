import { useState } from "react";
import { sendPasswordResetEmail as fbSendPasswordResetEmail } from "firebase/auth";
import { auth } from "./auth";

export enum ESendMail {
  AWAITING_INPUT = "",
  ERROR_EMAIL_INVALID = "error_email_invalid",
  ERROR_TOO_MANY_REQUESTS = "error_too_many_requests",
  ERROR_USER_NOT_FOUND = "error_user_not_found",
  LOADING = "loading",
  SUCCESS = "success"
}

export const useSendPasswordResetEmail = () => {
  const [status, setStatus] = useState(ESendMail.AWAITING_INPUT);

  const sendPasswordResetEmail = async (emailAddress: string) => {
    setStatus(ESendMail.LOADING);
    fbSendPasswordResetEmail(auth, emailAddress)
      .then(() => {
        setStatus(ESendMail.SUCCESS);
      })
      .catch((error) => {
        console.log(error);
        const errorToStatus: Record<string, ESendMail> = {
          ["auth/user-not-found"]: ESendMail.ERROR_USER_NOT_FOUND,
          ["auth/invalid-email"]: ESendMail.ERROR_EMAIL_INVALID,
          ["auth/too-many-requests"]: ESendMail.ERROR_TOO_MANY_REQUESTS
        };
        console.log(error.code);
        setStatus(errorToStatus[error.code]);
      });
  };

  return {
    clearSendEmailStatus: () => {
      setStatus(ESendMail.AWAITING_INPUT);
    },
    sendPasswordResetEmail,
    status
  };
};
