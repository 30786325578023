import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Grid,
  Icon,
  Input,
  Select,
  Spinner,
  Text
} from "@chakra-ui/react";
import { Property } from "csstype";
import { format as dateFormat } from "date-fns";
import firestore, { limit, orderBy, startAfter } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { IoMdArrowDropdown } from "react-icons/io";
import { wGetCollectionDocs } from "../../../../../api/shared";
import { mapDbUserToUser, TUserDb } from "../../../../../api/user/db";
import { TUser } from "../../../../../api/user/user";
import { FlexColumn, FlexRow } from "../../../../../components/layout/Flex/Flex";
import { useGlobalTxt } from "../../../../../i18n/useGlobalTxt";
import { cs } from "../../../../../styles/colors";
import { useToggle } from "../../../../../utils/utilsReact/useToggle";
import { PopupCreateUser } from "./ModalCreateUser";
import { ModalManageUser } from "./ModalManageUser";

type PropsField = {
  color?: Property.Color;
  text: string;
};

const Field = ({ color, text }: PropsField) => (
  <Text color={color} cursor="pointer" fontSize="1.3rem">
    {text}
  </Text>
);

// Can remove hasHover? Chara supports highlighting children of container which
// is being hovered.
const UserRow = chakra(({ user, ...rest }: { user: TUser }) => {
  const [showingModal, setShowingModal] = useState(false);

  return (
    <>
      <ModalManageUser close={() => setShowingModal(false)} isShowing={showingModal} user={user} />
      <Grid
        alignItems="center"
        columns={5}
        cursor="pointer"
        gap="1rem"
        onClick={() => setShowingModal(true)}
        p="0.3rem 0.7rem"
        templateColumns="3fr 3fr 5fr 3fr 3fr"
        _hover={{
          filter: "brightness(92%)"
        }}
        {...rest}
      >
        <Field text={user.firstName} />
        <Field text={user.lastName} />
        {/* TODO: show popover on red text if user has not verified email. */}
        <Field color={user.isEmailVerified ? "default" : "#A54343"} text={user.email} />
        <Field
          text={
            user.metadata.dateLastSignIn.getTime() > 0
              ? dateFormat(user.metadata.dateLastSignIn, "yyyy-MM-dd/HH:mm")
              : "n/a"
          }
        />
        <Field
          text={
            user.metadata.dateCreated.getTime() > 0
              ? dateFormat(user.metadata.dateCreated, "yyyy-MM-dd/HH:mm")
              : "n/a"
          }
        />
      </Grid>
    </>
  );
});

type PropsColumnLabel = {
  isFocused: boolean;
  onClick: () => void;
  sortOrder: firestore.OrderByDirection;
  text: string;
  toggleSortOrder: () => void;
};

/* TODO: instead of children, take a prop indicating if column is the active */
/* column or not. */
const ColumnLabel = ({
  isFocused,
  onClick,
  sortOrder,
  text,
  toggleSortOrder
}: PropsColumnLabel) => (
  <FlexRow alignItems="center">
    <Text
      cursor="pointer"
      fontWeight={isFocused ? "bold" : "default"}
      fontSize="1.5rem"
      onClick={onClick}
      textAlign="center"
    >
      {text}
    </Text>
    {isFocused && (
      <Center w="2rem">
        {/* TODO: use transform: scale() instead of fontSize. */}
        <Icon
          as={IoMdArrowDropdown}
          cursor="pointer"
          fontSize="2.2rem"
          onClick={toggleSortOrder}
          transform={`rotate(${sortOrder === "asc" ? 180 : 0}deg)`}
          transition="0.15s ease"
          origin=""
          _hover={{
            fontSize: "1.9rem"
          }}
        />
      </Center>
    )}
  </FlexRow>
);

enum EColumn {
  NAME_FIRST = "first_name",
  NAME_LAST = "last_name",
  EMAIL = "email",
  LAST_LOGIN = "last_login",
  CREATED = "created"
}

/* Names/paths of fields in firestore. */
type SortableUserField =
  | "email"
  | "firstName"
  | "lastName"
  | "metadata.dateCreated"
  | "metadata.dateLastSignIn";

/* TODO: Feel like this isnt neccessary... */
export const getUserPropPath = (col: EColumn) => {
  const UserPropByColLabel: Record<EColumn, SortableUserField> = {
    [EColumn.NAME_FIRST]: "firstName",
    [EColumn.NAME_LAST]: "lastName",
    [EColumn.EMAIL]: "email",
    [EColumn.LAST_LOGIN]: "metadata.dateLastSignIn",
    [EColumn.CREATED]: "metadata.dateCreated"
  };

  return UserPropByColLabel[col];
};

type TGetUsers = (props?: {
  limit?: number;
  orderBy?: SortableUserField; // could be keyof TUser?
  sortOrder?: firestore.OrderByDirection;
  startAfter?: firestore.DocumentSnapshot<any>; // fix any?
}) => Promise<[users: TUser[], lastDoc: firestore.DocumentSnapshot | undefined]>;

/* Just subscribe on the docs? */
/* Wont create extra reads, right? */
/* how...? */

const DEFAULT_LIMIT = 100;
const DEFAULT_SORT_ORDER = "desc";
const DEFAULT_ORDER_BY = "metadata.dateLastSignIn";

export const getUsers: TGetUsers = async (
  arg = {
    orderBy: DEFAULT_ORDER_BY,
    sortOrder: DEFAULT_SORT_ORDER,
    limit: DEFAULT_LIMIT
  }
) => {
  const docs: firestore.QuerySnapshot<TUserDb> = await wGetCollectionDocs(
    "/users",
    ...[
      arg.startAfter ? startAfter(arg.startAfter) : [],
      limit(arg.limit ?? DEFAULT_LIMIT),
      orderBy(arg.orderBy ?? DEFAULT_ORDER_BY, arg.sortOrder ?? DEFAULT_SORT_ORDER)
    ].flat()
  );
  let users: TUser[] = [];
  docs.forEach((doc) => {
    users = [...users, mapDbUserToUser(doc.data() as TUserDb)];
  });
  return [users, undefined];
};

/* TODO: create new fn for getting users. (rather than refactoring current fn). */
/* const useUsers2 = () => {}; */

/* TODO: create user list into an infinite scroll. */
/* TODO?: use a lib for virtualized list. */

const useGetUsers = (
  orderBy: SortableUserField,
  sortOrder: firestore.OrderByDirection,
  usersPerBatch = 300
) => {
  const [isLoading, setIsLoading] = useState(false);
  /* Why ref and not state? (???) */
  const lastDocRef = useRef<firestore.DocumentSnapshot<any> | undefined>();
  const [users, setUsers] = useState([] as TUser[]);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);

  useEffect(() => {
    setUsers([]);
    lastDocRef.current = undefined;
    getNextBatch();
  }, [orderBy, sortOrder]);

  const getNextBatch = async () => {
    setIsLoading(true);
    const [users, newLast] = await getUsers({
      limit: usersPerBatch,
      orderBy,
      startAfter: lastDocRef.current,
      sortOrder
    });
    setIsLoading(false);
    if (users.length < usersPerBatch) setHasMoreUsers(false);

    if (newLast) lastDocRef.current = newLast;
    setUsers((oldUsers) => [..._.cloneDeep(oldUsers), ...users]);
  };

  return {
    getNextBatch,
    hasMoreUsers,
    isLoading,
    users
  };
};

/* TODO: this component actually needs to take a cb to set value of `searchText` */
/* in parent; otherwise, cannot use the value of this text to filter/find users. */
const SearchUsers = () => {
  const [isSearching, toggleIsSearching] = useToggle(false);
  /* Might have to add a column which is firstName+lastName. */
  type TSearchField = "email" | "name";
  const [searchField, setSearchField] = useState<TSearchField>("email");
  const [searchText, setSearchText] = useState("");

  const { txt_g } = useGlobalTxt();

  /* Show search glass both when closed and open? */
  return (
    <Flex alignItems="center" ml="3rem">
      <Icon
        aria-label="foobar"
        as={GoSearch}
        bg="#bbb"
        boxShadow="0px 0px 2px #aaa"
        borderRadius=".9rem"
        cursor="pointer"
        onClick={toggleIsSearching}
        mr=".5rem"
        p=".5rem"
        transform={`scale(${isSearching ? 0.85 : 1})`}
        transition=".08s ease"
        _hover={{
          filter: "brightness(90%)"
        }}
      />
      {isSearching ? (
        <>
          <Select
            w="10rem"
            bg="#FFF"
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value as TSearchField);
              setSearchText("");
            }}
          >
            {(["name", "email"] as TSearchField[]).map((field, i) => (
              <option key={i} value={field}>
                {txt_g[field]}
              </option>
            ))}
          </Select>
          <Input
            bg="white"
            border="2.5px solid"
            ml=".3rem"
            placeholder="UNDER DEVELOPMENT"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </>
      ) : (
        <></>
      )}
    </Flex>
  );
};

/* TODO: users with long names should have text "cut off". */
/* TODO: different widths for the columns (email should be longest). */
export const ListUsers = () => {
  const [isShowingPopup, setIsShowingPopup] = useState(false);
  const { txt_g } = useGlobalTxt();

  const [focusedCol, setFocusedCol] = useState<EColumn>(EColumn.LAST_LOGIN);
  const [sortOrder, setSortOrder] = useState<firestore.OrderByDirection>("desc");

  const toggleSortOrder = () => setSortOrder(sortOrder === "asc" ? "desc" : "asc");

  /* Replacable by useCollectionData? useCollectionDataOnce? */
  const { users, isLoading: isLoadingUsers } = useGetUsers(getUserPropPath(focusedCol), sortOrder);

  return (
    <Box
      boxShadow="0px 0px 2px #aaa"
      h="fit-content"
      maxW="100rem"
      mb="2rem"
      mx="auto"
      pb="1rem"
      px="1rem"
      w="90vw"
    >
      <PopupCreateUser close={() => setIsShowingPopup(false)} isShowing={isShowingPopup} />
      <Flex alignItems="center" h="4.5rem">
        <Button shadow="md" onClick={() => setIsShowingPopup(true)}>
          {txt_g.create_user}
        </Button>
        {/* Should be able to search for users... */}
        {/* Search for names or email? be able to pick? */}
        <SearchUsers />
        <FlexColumn justify="center" ml="auto">
          <FaFilter color={cs.GRAY} />
        </FlexColumn>
      </Flex>
      <Grid
        bg="#dddddd"
        borderTopRadius=".4rem"
        boxShadow="0px 0px 1.5px #444"
        boxSizing="border-box"
        gap="1rem"
        p=".5rem .4rem .35rem"
        pos="sticky"
        zIndex={
          1337 // fix ui-bug: prev hovered UserRows appear above cols when scroll quickly.
        }
        top="0"
        templateColumns="3fr 3fr 5fr 3fr 3fr"
        w="100%"
      >
        {(Object.values(EColumn) as (keyof typeof txt_g)[]).map((colName, i) => (
          <ColumnLabel
            isFocused={focusedCol === colName}
            key={i}
            onClick={() => setFocusedCol(colName as EColumn)}
            sortOrder={sortOrder}
            text={txt_g[colName] as string}
            toggleSortOrder={toggleSortOrder}
          />
        ))}
      </Grid>
      <Box boxShadow="0px 0.3px 1.5px #444">
        {users.map((user, i) => (
          <UserRow bg={i % 2 === 0 ? "#e7e7e7" : "#eeeeee"} key={i} user={user} />
        ))}
        {isLoadingUsers && (
          <Center h="8rem">
            <Spinner />
          </Center>
        )}
      </Box>
    </Box>
  );
};
