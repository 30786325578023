import { onAuthStateChanged, User as FbUser } from "firebase/auth";
import firestore from "firebase/firestore";
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { auth } from "../../auth/auth";
import { IS_FAST_DEV } from "../../constants";
import { TRole } from "../../permissions/permissions";
import { callCloudFn } from "../cloudFunctions";
import { wGetDocData } from "../shared";
import { mapDbUserToUser, TUserDb } from "./db";
import { mkUser, TUser } from "./user";

export type TCommonUser = {
  email: string;
  firstName: string;
  id: string;
  isEmailVerified: boolean; // issue with field; read below
  lastName: string;
  roles: TRole[];
  schoolName: string;
};

export const mkCommonUser = () => {
  const user: TCommonUser = {
    email: "",
    firstName: "",
    id: "",
    isEmailVerified: false,
    lastName: "",
    roles: [],
    schoolName: ""
  };
  return user;
};

const procUserVerifiedEmail = () => {
  IS_FAST_DEV
    ? (() => {})()
    : /* TODO: actually check whether email is verified in cloud fn. */
      callCloudFn("updateUserIsEmailVerified");
};

type TDbGetUser = (id: string) => Promise<TUser>;

const dbGetUser: TDbGetUser = async (id) => {
  const userFromDb = (await wGetDocData(`users/${id}`)) as TUserDb;
  return mapDbUserToUser(userFromDb);
};

export type TContextUser = {
  haveUser: boolean; // TODO: rename to `loading`?
  user: TUser;
};

export const ContextUser = createContext<TContextUser>(null!);

/* Have pretty strong feeling this belong in separate module (loggedInUser). */
/* TODO: move to loggedInUser. */

/* TODO: There is a problem with reads currently. Doing waaay more reads */
/* than should be neccessary. Also creating a lot more listeners than needed. */
/* Unsure whats causing this. Only happens on production. */

/* A) someone is purposely doing unneccessary reads to the db. */

/* B) memory leaks; useEffect setting up listener on userData. */
/* But then why is only happening in production? */

/* TODO: just rename to ProviderUser? Implicit that it's about the logged in user... */
export const ProviderUser = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<TUser>(mkUser());
  /* TODO?: rename to isLoading. Seems to be the convention used a lot of */
  /* places when loading data. If want another name, rename field */
  /* when destructuring. */
  const [haveUser, setHasRetrievedUser] = useState(false);

  /* TODO: save in callback? */
  const execGetUserData = async (id: string) => {
    /* This could(/should?) actually be a sub? */
    const userData = await dbGetUser(id);
    setUser(userData);
  };

  /* Instead of doing all this shit... like in a hacky way, */
  /* try just using useWDocumentData? */
  // const [d, il] = useWDocumentData();
  /* Yeah, but not urgent here. ContextAppData first. */

  useEffect(() => {
    if (user.id !== "") setHasRetrievedUser(true);
  }, [user]);

  /* Is there better way for unsubscriptions, other than refs? */
  const unsubRef1 = useRef<firestore.Unsubscribe>();

  useEffect(() => {
    if (unsubRef1.current) unsubRef1.current();
    /* Wondering if can only have 1 auth.onAuthStateChanged()? */
    unsubRef1.current = auth.onAuthStateChanged((authUser) => {
      if (!authUser) {
        setUser(mkUser());
        setHasRetrievedUser(false);
      } else execGetUserData(authUser.uid);
    });
    return () => {
      if (unsubRef1.current) unsubRef1.current();
      if (unsubRef3.current) unsubRef3.current();
    };
  }, [auth.currentUser]);

  /* = = = = = = = = = = = = = = = = = = = = = */
  /*** For email verification/sign up flow. ***/
  /* Firebase doesn't offer listening to updating of authUser.emailVerified... */
  /* Best solution I could come up with. */
  const pollEmailVerifiedIntervalRef = useRef<NodeJS.Timeout>();

  /* jayzuz. Is this causing the massive amount of read/writes? */
  const pollEmailVerification = (authUser: FbUser) => {
    pollEmailVerifiedIntervalRef.current = setInterval(() => {
      authUser.reload();
      if (authUser.emailVerified) {
        clearInterval(pollEmailVerifiedIntervalRef.current!);
        setUser((prevUser) => ({ ...prevUser, isEmailVerified: true }));
      }
    }, 1000);
  };

  const unsubRef3 = useRef<firestore.Unsubscribe>();

  useEffect(() => {
    /* Verify tha polling for updated isEmailVerified works. */
    if (auth.currentUser?.emailVerified === true)
      pollEmailVerifiedIntervalRef.current && clearInterval(pollEmailVerifiedIntervalRef.current);

    unsubRef3.current = onAuthStateChanged(auth, (authUser) => {
      if (authUser?.emailVerified === false) {
        /* This might be causing a lot of read? */
        /* Maybe only poll for x number of minutes or something. */
        pollEmailVerification(authUser);
      } else {
        procUserVerifiedEmail();
      }
    });

    return () => {
      if (pollEmailVerifiedIntervalRef.current) clearInterval(pollEmailVerifiedIntervalRef.current);
      if (unsubRef3.current) unsubRef3.current();
    };
  }, [auth.currentUser?.emailVerified]);

  return (
    <ContextUser.Provider
      value={{
        haveUser,
        user
      }}
    >
      {children}
    </ContextUser.Provider>
  );
};

export const useUser = () => {
  return useContext(ContextUser);
};
