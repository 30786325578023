import { useParentBoundingRect } from "../../../../../../../../../utils/utilsReact/hooks/useParentBoundingRect";
import { WithChildren } from "../../../../../../../../../utils/utilsReact/types";
import { getEmbeddedFonts } from "./fontsBase64";
import { IMalLogoNoTextSvg } from "./IMalLogoNoText.svg";

type Props = WithChildren & {
  inEditor?: boolean;
  logoSize?: "small" | "normal";
  noMargin?: boolean;
  pageNumber?: {
    nPages?: number;
    number?: number;
    format?: PageNumberFormat;
  };
};

export const BlankSheet = ({ children, logoSize, noMargin }: Props) => {
  const [refBrParent, brParent] = useParentBoundingRect<SVGSVGElement>();

  return (
    <svg
      height={brParent.height}
      ref={refBrParent}
      style={{ userSelect: "none" }}
      viewBox={`0 0 ${PAGE_A4_WIDTH} ${PAGE_A4_HEIGHT}`}
    >
      <rect fill="white" height="100%" width="100%" />
      {noMargin ? (
        children
      ) : (
        <>
          <svg
            width={`${100 - BLUE_LINE_COLUMN_WIDTH}%`}
            height="100%"
            x={BLUE_LINE_COLUMN_WIDTH}
            id="content"
          >
            {children}
          </svg>
          <VerticalBlueLine />
        </>
      )}
      {logoSize && <IMalLogo size={logoSize} />}
    </svg>
  );
};

export const EmbeddedIMalFonts = () => {
  return (
    <defs>
      <style type="text/css">{getEmbeddedFonts()}</style>
    </defs>
  );
};

type LogoSize = "small" | "normal" | "large";

const IMalLogo = ({ size }: { size: LogoSize }) => {
  return (
    <svg
      height={size === "normal" ? "10%" : "6%"}
      style={{ background: "yellow" }}
      viewBox="0 0 100 100"
      width={size === "normal" ? "10%" : "6%"}
      x={size === "normal" ? "88%" : "92%"}
    >
      <IMalLogoNoTextSvg />
    </svg>
  );
};

const BLUE_LINE_COLUMN_WIDTH = 4;

const VerticalBlueLine = () => {
  const MARGIN_LEFT = 1.3;
  /* Width gets extra pixel to overlap content. This fixes spacing bug */
  /* between blue margin line and section header lines. */
  /* This make content justification off by one pixel, but thats OK: */
  /* that is only noticable if one knows it's there. */
  /* Could possibly remedy this with a <PageSection /> component... */
  return (
    <svg width={BLUE_LINE_COLUMN_WIDTH + 1}>
      <rect x={MARGIN_LEFT} height="100%" width="100%" fill="#144861" />
    </svg>
  );
};

/* This is a bug: ratio is not quite accurate enough... */
/* Also have enum for this in <PdfGenerator />? */
export const A4_HW_RATIO = 1.4;

// 100 is just nice round number.
export const PAGE_A4_WIDTH = 100,
  PAGE_A4_HEIGHT = PAGE_A4_WIDTH * A4_HW_RATIO;

enum PageNumberFormat {
  BOTH = "both",
  CURRENT_ONLY = "currentOnly",
  hide = "hide"
}
