import { Box } from "@chakra-ui/react";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import _ from "lodash";
import * as R from "ramda";
import { CanBeCollapsible } from "../../../../../../../../../components/Collapsable/Collapsible";
import { useGlobalTxt } from "../../../../../../../../../i18n/useGlobalTxt";
import {
  ETaskFontVariant,
  ETypeface,
  TaskFont,
  TTaskTypeface
} from "../../../../../../../../../utils/utilsiMAL/fonts/shared";
import { getLocaleTypefaces } from "../../../../../../../../../utils/utilsiMAL/fonts/typefaces";
import { SettingBox } from "../SettingContainer/SettingBox";
import { SelectFontVariant } from "./SelectFontVariant";
import { SelectTypeface } from "./SelectTypeface";

/* TODO? :SelectFont always has a sel font. no... this is not 100% true actuly. */
/* Therefore dont need both activeFontVariant and activeTypeface. */
/* Can just pass the active TaskFont object? */

/* Could have some reusable code for collapsiveness? */

type Props = CanBeCollapsible & {
  activeFontVariant?: ETaskFontVariant | null;
  activeTypeface: ETypeface;
  disabledFontVariants?: ETaskFontVariant[];
  enabledFontVariants?: ETaskFontVariant[];
  hideTypefaceSelect?: boolean;
  hideVariantSelect?: boolean;
  includeBlank?: boolean;
  setFont?: (font: TaskFont) => void;
  typefaces?: TTaskTypeface[];
};

/* TODO: Think about how diabledFontVariants should work. */
/* Default should be to show all variants. -> Then pass disabled variants where */
/* want to hide some variants. */
export const SelectFont = ({
  activeFontVariant,
  activeTypeface,
  hideTypefaceSelect = false,
  hideVariantSelect = false,
  setFont = () => null,
  typefaces,
  openByDefault = false,
  isCollapsible = true,
  ...rest
}: Props) => {
  const { txt_g } = useGlobalTxt();
  const { locale } = useLocale();

  return (
    <SettingBox
      isCollapsible={isCollapsible}
      title={_.capitalize(txt_g.font)}
      openByDefault={openByDefault}
    >
      <Box px=".3rem" pt=".5rem">
        {R.not(hideTypefaceSelect) && (
          <SelectTypeface
            activeFontVariant={activeFontVariant!}
            activeTypeface={activeTypeface}
            setFont={setFont}
            typefaces={typefaces?.[0] ? typefaces : getLocaleTypefaces(locale)}
          />
        )}
        {R.not(hideVariantSelect) && (
          <SelectFontVariant
            setFontVariant={setFont}
            selectedTypeface={activeTypeface}
            selectedVariant={activeFontVariant!}
            {...rest}
          />
        )}
      </Box>
    </SettingBox>
  );
};
