import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import iMalLogoGerman from "../../../utils/utilsiMAL/images/logo/logo_german_white_text.png";

/* TODO: make this prettier... */
export const Header = () => {
  return (
    <Flex
      alignItems="center"
      bg="#23142A"
      boxShadow="dark-lg"
      h="4rem"
      justifyContent="center"
      width="100%"
    >
      <Flex alignItems="center" justifyContent="space-around" maxWidth="65rem" w="100%">
        <Box as={Link} to="/home">
          <Image maxH="3rem" ml="1.5rem" src={iMalLogoGerman} />
        </Box>

        <Flex ml="25%">
          {[
            { path: "about_us", text: "Über uns" },
            { path: "faq", text: "FAQ" }
          ].map(({ path, text }, i) => (
            <Text
              _hover={{
                brightness: "120%",

                color: "#BC9056",
                textDecoration: "none"
              }}
              as={Link}
              to={`/home/${path}`}
              fontSize=".9rem"
              fontWeight="bold"
              color="#ECD096"
              transition="ease .15s"
              key={i}
              minWidth="5rem"
              mt="1rem"
            >
              {text}
            </Text>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
