import { initializeApp } from "firebase/app";

const configDev = {
  apiKey: "AIzaSyB1J_amCGJJ-2Gff7ahR93vCxVQ6qOdsVs",
  authDomain: "imal-german-dev.firebaseapp.com",
  projectId: "imal-german-dev",
  storageBucket: "imal-german-dev.appspot.com",
  messagingSenderId: "1027379401849",
  appId: "1:1027379401849:web:6c8be717903a372640f0a8",
  measurementId: "G-PYZ6ZC6K4Q"
};

const configProd = {
  apiKey: "AIzaSyAuzua6rlL6LSE1_GmTXJABdGZnKAbnpcM",
  authDomain: "imal-german-prod.firebaseapp.com",
  databaseURL: "https://imal-german-prod-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "imal-german-prod",
  storageBucket: "imal-german-prod.appspot.com",
  messagingSenderId: "444618169563",
  appId: "1:444618169563:web:ceb10ef92a1b7d7bbb80fe",
  measurementId: "G-MMWFTM1JYX"
};

const getFirebaseConfig = () => {
  const env = process.env.REACT_APP_IS_PRODUCTION;
  console.log(`Using ${env ? "prod" : "dev"} fb config.`);
  return env ? configProd : configDev;
};

/* Getting firebase config values: */
/* Always try to read env variables first. If undefined or */
/* incomplete -> use devConfig.  */
/* Important thing is `.env` is .gitignore'd. */
/* Then users can use their own firebase project if they wish. */
/* This might require a few additional things, like a testing stripe account? */

/* if REACT_APP_ENV === PROD -> always prod config */
/* if REACT_APP_ENV === development -> do the above. */

export const imalFirebase = initializeApp(getFirebaseConfig());

/* TODO: move auth and cloudFn initializations here? */

/* "w"-prefix is short for "wrapper". Avoid importing and passing the db object around everywhere. */
