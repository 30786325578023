import { Icon as ChakraIcon } from "@chakra-ui/icons";
import { Box, Center, chakra } from "@chakra-ui/react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { colorScheme } from "../../../../styles/colors";

/* TODO: put this somewhere smart. */
/* ts utils somewhere? web? react? or can import from some built in module? */
/* for example `utils/reacts/componentProps.ts` */
export type TClickable = {
  onClick?: () => void;
};

/* TODO?: add props for bg and color? */
/* Consider if should pass a video-url prop, and then render modal along with the icon.  */

export const HelpIcon = chakra(({ onClick = () => {}, ...rest }: TClickable) => (
  <Center
    {...rest}
    _hover={{
      cursor: "pointer",
      transform: "scale(1.6)"
    }}
    bg="orange"
    borderRadius="50%"
    boxShadow="lg"
    boxSize="1.9em"
    pos="relative"
    transform="scale(1.4)"
    transition="ease .08s"
  >
    <Box bg={colorScheme.TURQUOISE} boxSize="1.4em" borderRadius="50%" m="auto" pos="absolute" />
    <ChakraIcon
      as={HiQuestionMarkCircle}
      color="yellow"
      borderRadius="100%"
      filter="brightness(100%)"
      fontSize="2.5rem"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    />
  </Center>
));
