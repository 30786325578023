import { TIndex } from "@imaldev/imal-factory/ts";
import { produce } from "immer";
import _ from "lodash";
import * as R from "ramda";
import { assoc, move, propEq } from "ramda";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { mkUUID, UUID } from "../../../../../utils/utilsTS/uuid/uuid";
import { TTaskSheet } from "../taskSheets/TaskSheet";

/* TODO: extract out logic to a reducer. */

/* ***** This type is Single Source of Truth. ***** */
/* The other type SheetState is a superset of this type, with some */
/* extra fields which are derived from this 'source state'. */

export const useSheets = () => useContext(ContextSheets);

export const ContextSheets = createContext<SheetsAPI>(null!);

type SheetsAPI = {
  /* data */
  activeSheet: TTaskSheet | null;
  sheets: TTaskSheet[];
  /* actions */
  addSheet: (sheet: TTaskSheet) => void;
  deleteAllSheets: () => void;
  deleteSheet: (id: UUID) => void;
  duplicateSheet: (idDuplicate: UUID) => void;
  moveSheet: (oldSheetNumber: number, newSheetNumber: number) => void;
  updateImage: (sheetId: TTaskSheet["id"], image: Partial<TTaskSheet["image"]>) => void;
  updateSheet: (updatedSheet: TTaskSheet) => void;
  setIdActiveSheet: (id: UUID | null) => void;
};

export const SheetsProvider = ({ children }: { children: ReactNode }) => {
  const [sheets, setSheets] = useState<Omit<TTaskSheet, "pageNumber">[]>([]);
  const [idActiveSheet, setIdActiveSheet] = useState<UUID | null>(null);

  const addSheet = (sheet: TTaskSheet) => {
    if (sheets.length >= MAX_SHEETS) return;
    setSheets((prevSheets) => {
      return [...prevSheets, sheet];
    });
    setIdActiveSheet(sheet.id);
  };

  const deleteSheet = (id: UUID) => {
    setSheets((sheets) => {
      return sheets.filter((sheet) => sheet.id !== id);
    });
  };

  const deleteAllSheets = () => {
    setIdActiveSheet(null);
    setSheets([]);
  };

  const duplicateSheet = (id: UUID) => {
    if (sheets.length >= MAX_SHEETS) return;
    const dup = _.cloneDeep(sheets.find((sheet) => sheet.id === id))!;
    dup.id = mkUUID();
    const iDup = sheets.findIndex((sheet) => sheet.id === id)! + 1;
    setSheets((sheets) => {
      return produce(sheets, (sheets) => {
        sheets.splice(iDup, 0, dup);
      });
    });
    setIdActiveSheet(dup.id);
  };

  const moveSheet = (from: TIndex, to: TIndex) => {
    setSheets((sheets) => move(from, to, sheets));
  };

  const updateSheet: SheetsAPI["updateSheet"] = (updatedSheet) => {
    const iSheet = sheets.findIndex((sheet) => sheet.id === updatedSheet.id);
    if (iSheet < 0) return;
    setSheets((sheets) => R.update(iSheet, updatedSheet, sheets));
  };

  const updateImage: SheetsAPI["updateImage"] = (sheetId, image) => {
    const sheet = sheets.find(propEq("id", sheetId));
    if (!sheet) return;
    updateSheet(assoc("image", image, sheet) as TTaskSheet);
  };

  /* Get rid of sheet numbers? */
  const numberedSheets = sheets.map((sheet, i) => assoc("pageNumber", i + 1, sheet) as TTaskSheet);
  const activeSheet = useMemo(
    () => numberedSheets.find((sheet) => sheet.id === idActiveSheet) ?? null,
    [sheets, idActiveSheet]
  );

  return (
    <ContextSheets.Provider
      value={{
        /* Values */
        activeSheet,
        sheets: numberedSheets,
        /* Actions */
        addSheet,
        deleteAllSheets,
        deleteSheet,
        duplicateSheet,
        moveSheet,
        setIdActiveSheet,
        updateImage,
        updateSheet
      }}
    >
      {children}
    </ContextSheets.Provider>
  );
};

export const MAX_SHEETS = 50;
