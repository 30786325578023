import { SoundSequence } from "../../../sequences";
import img_deutschrad from "./deutschrad.jpg";

export const deutschrad: SoundSequence = {
  bookPicture: img_deutschrad,
  displayName: "Deutschrad",
  id: "0d164593-5abd-4c84-aa2f-23e267b556a1",
  name: "deutschrad",
  sounds: [
    "a",
    "o",
    "u",
    "e",
    "i",
    "ä",
    "ö",
    "ü",
    "ei",
    "eu",
    "au",
    "d",
    "g",
    "b",
    "f",
    "s",
    "m",
    "h",
    "l",
    "j",
    "t",
    "k",
    "p",
    "w",
    "z",
    "n",
    "r",
    "sch",
    "st",
    "sp",
    "pf",
    "qu",
    "ch",
    "c",
    "v",
    "x",
    "y",
    "ß"
  ]
};
