/* TODO: Move this to factory/i18n, too? */
/* Should reside in a context? Otherwise will be recomputed every time */

import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";

/* we use a translation object. */
export const globalTranslations = {
  [ELocale.de_DE]: {
    access: "Zugang",
    cancel: "Abbrechen",
    create: "Erstellen",
    created: "Erstellt",
    create_user: "Nutzer erstellen",
    close: "Schließen",
    date: "Datum",
    error_email_already_exsists: "Es exsistiert bereits ein Konto mit dieser Emailaddresse.",
    error_email_invalid: "Email ist üngultig.",
    error_too_many_requests:
      "Sie haben das zu viele Male gemacht! Warten Sie um erneut zu versuchen.",
    error_user_not_found: "Nutzer nicht gefunden.",
    email: "Email",
    email_has_been_sent_to: "Email wurde geschickt an ",
    email_send: "Email schicken",
    email_resend: "Email erneut schicken",
    first: "Zuerst",
    first_name: "Vorname",
    font: "Schriftart",
    invalid_email: "Üngultige E-mailaddresse",
    invalid_password_too_short: "Passwort zu kurz: mindestens 6 Buchstaben.",
    last: "Zuletzt",
    last_name: "Nachname",
    last_login: "Letztes Einl.",
    loading: "loading",
    name: "Name",
    no: "Nein",
    password: "Passwort",
    password_confirm: "Passwort bästatigen", // camel (edge) case
    school: "Schule",
    school_name: "Schulname", // camel (edge) case
    school_name_confirm: "Schulname bästatigen", // camel (edge) case
    sign_in: "Einloggen",
    sound: "Laut",
    sounds: "Laute",
    user_created_successfully: (email: string) => `Das Konto ${email} wurde erstellt!`,
    vowel: "Vokal",
    yes: "Ja"
  },
  [ELocale.en_US]: {
    access: "Access",
    Admin: "Admin",
    cancel: "Cancel",
    close: "Close",
    create: "Create",
    created: "Created",
    create_user: "Create user",
    date: "Date",
    email: "Email",
    email_has_been_sent_to: "Email has been sent to ",
    email_send: "Send email",
    email_resend: "Email erneut schicken",
    error_email_already_exsists: "Email is already in use by another user.",
    error_email_invalid: "Email is invalid.",
    error_too_many_requests: "You have done that too many times!",
    error_user_not_found: "User not found.",
    first: "First",
    first_name: "First name" /* camel (edge) case */,
    font: "Font",
    invalid_email: "Invalid email",
    invalid_password_too_short: "Password must be at least 6 characters",
    last: "Last",
    last_name: "Last name" /* camel (edge) case */,
    last_login: "Last login" /* camel (edge) case */,
    loading: "Loading",
    sound: "Sound",
    sounds: "Sounds",
    name: "Name",
    no: "No",
    password: "Password",
    password_confirm: "Confirm password",
    refresh: "Refresh",
    school: "School",
    school_name: "School name",
    school_name_confirm: "Confirm school name" /* camel (edge) case */,
    sign_in: "Sign in",
    subscription: "subscription",
    status: "Status",
    user_created: (email: string) => `Created user ${email}!`,
    vowel: "Vowel",
    yes: "Yes"
  },
  [ELocale.es_ES]: {
    access: "Access",
    Admin: "Admin",
    cancel: "Cancel",
    close: "Close",
    create: "Create",
    created: "Created",
    create_user: "Crear usuario",
    date: "Fecha",
    email: "Correo electrónico",
    email_has_been_sent_to: "El correo ha sido enviado a ",
    email_send: "Enviar correo electrónico",
    email_resend: "Email erneut schicken",
    error_email_already_exsists: "Email is already in use by another user.",
    error_email_invalid: "Correo electrónico invalido.",
    error_too_many_requests: "You have done that too many times!",
    error_user_not_found: "User not found.",
    first: "Primera",
    first_name: "First name" /* camel (edge) case */,
    font: "Fuente",
    invalid_email: "Invalid email",
    invalid_password_too_short: "Password must be at least 6 characters",
    last: "Last",
    last_name: "Last name" /* camel (edge) case */,
    last_login: "Last login" /* camel (edge) case */,
    loading: "Cargando",
    sound: "Sonido",
    sounds: "Sonidos",
    name: "Nombre",
    no: "No",
    password: "Contraseña",
    password_confirm: "Confirmar contraseña",
    refresh: "Refresh",
    school: "School",
    school_name: "School name",
    school_name_confirm: "Confirm school name" /* camel (edge) case */,
    sign_in: "Iniciar sesión",
    subscription: "subscription",
    status: "Status",
    user_created: (email: string) => `Created user ${email}!`,
    vowel: "Vowel",
    yes: "Sí"
  }
};

/* TODO: use this helper for some lookups that require casting. */
export type TGlobalText = keyof typeof globalTranslations[ELocale.de_DE];

export const useGlobalTxt = () => {
  const { txt: txt_g } = useTxt(globalTranslations);
  return { txt_g };
};

/* Locales for which everything is currently disabled.  */
/* Disable all but German for now. */
export const localesDisabledEverywhere = [
  ELocale.en_US,
  ELocale.es_ES,
  ELocale.nb_NO,
  ELocale.nn_NO,
  ELocale.sv_SE
];
