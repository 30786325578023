import { ECap, getSounds, TWord } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { arrayOf, ensureSize, takeRandoms, TTuple } from "@imaldev/imal-factory/ts";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { produce } from "immer";
import { shuffle } from "lodash";
import { assoc, concat, flatten, map, pipe as p, propEq, uniq, values, without } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { localesDisabledEverywhere } from "../../../../../../i18n/useGlobalTxt";
import {
  ETaskFontVariant,
  getDefaultTypefaceName
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import { getLocaleTypefaces, getTaskFont } from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useDefaultSequence } from "../../../context/AppSheetsAbcUserData/contextDefaultSequence";
import { useSheets } from "../../contexts/ContextSheets";
import { useWords } from "../../contexts/WordsContext";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import { SelectSounds } from "../shared/components/settings/SelectSounds/SelectSounds";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { WriteWordInImageSmall } from "../shared/components/sheets/WriteWordInImageSmall/WriteWordInImageSmall";
import {
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import { setCap, TWithCap } from "../shared/sheetTypes/withCap";
import { setFont, TWithFont } from "../shared/sheetTypes/withFont";
import { getActiveSounds, toggleSound, TWithSounds } from "../shared/sheetTypes/withSounds";
import {
  mkWriteWord,
  ProviderSelectableWords,
  setWriteWord,
  setWriteWords,
  TWithWriteWords,
  TWriteWord,
  useSelectableWords
} from "../shared/sheetTypes/withWords";

export const N_WORDS = 8;

export type TWWII8x = TNewSheetType<ESheet.WWII_8X> &
  TWithCap &
  TWithFont &
  TWithSounds &
  TWithWriteWords<8>;

export const Editor = () => {
  const { activeSheet: sheet } = useSheets() as {
    activeSheet: TWWII8x;
  };
  const { updateSheet } = useSheets();
  const { locale } = useLocale();
  const { defaultSequence: activeSequence } = useDefaultSequence();

  /* Would be nice to be able to get a result like this by traversing the trie. */
  /* Ie. have a way to search using multiple filters at the same time. */
  const [selectableWords, setSelectableWords] = useState({
    allKnown: [] as string[],
    firstKnown: [] as string[]
  });

  const { getWords } = useWords();

  /* Can we reuse this outside this component? */
  /* Is kinda slow, but might be because of how takeRandoms is implemented... */
  const getWords2 = useCallback(
    (s: TWWII8x) => {
      const ws = takeRandoms(
        N_WORDS,
        getWords({ maxLength: 6, sounds: s.sounds, withImage: true })
      );
      setSelectableWords((p) => assoc("allKnown", ws, p));

      let extraWs: TWord[] = [];
      if (ws.length < N_WORDS) {
        extraWs = p(
          () => s.sounds,
          map((s) =>
            getWords({
              mainSound: s,
              mainSoundPos: "first",
              maxLength: 6,
              withImage: true
            })
          ),
          flatten,
          uniq,
          without(ws),
          (ws) => takeRandoms(N_WORDS - ws.length, ws)
        )();
      }
      setSelectableWords((p) => assoc("firstKnown", extraWs, p));

      /* ensureSize not working how we expect it to... */
      const result = ensureSize(N_WORDS, concat(ws, extraWs));

      /* TODO: add an addional check to re-use words if not enough words. */
      /* Should only happen veeeery rarely... */

      return shuffle(result);
    },
    [JSON.stringify(sheet)]
  );

  useEffect(() => {
    if (sheet.words.every(propEq("value", ""))) updateSheet(setWriteWords(getWords2)(sheet));
  }, []);

  return (
    <ProviderSelectableWords words={values(selectableWords).flat()}>
      <EditorContainer
        sheet={sheet}
        settings={
          <>
            <SelectSounds
              cap={sheet.cap}
              disableCapFirstUpper
              setCap={p(setCap(sheet), updateSheet)}
              selectedSounds={sheet.sounds}
              onSelectSound={p(
                getActiveSounds(activeSequence, sheet),
                setWriteWords(getWords2),
                updateSheet
              )}
              toggleSound={p(toggleSound(sheet), setWriteWords(getWords2), updateSheet)}
            />
            <SelectFont
              activeFontVariant={sheet.font.variant}
              activeTypeface={sheet.font.typeface}
              disabledFontVariants={[ETaskFontVariant.STARTPOINTS]}
              includeBlank
              setFont={p(setFont(sheet), updateSheet)}
              typefaces={getLocaleTypefaces(locale)}
            />
          </>
        }
      />
    </ProviderSelectableWords>
  );
};

export const Sheet: SheetPreview<ESheet.WWII_8X> = ({ sheetProps: sheet, interactive = false }) => {
  const { updateSheet } = useSheets();
  const words = useSelectableWords();

  return (
    <BlankSheet>
      <svg height="97%" width="94%" x="3.5%" y="1.5%">
        <rect fill="black" height="99.6%" width="100%" />
        <svg height="99%" width="99%" x=".6%" y=".5%">
          {sheet.words.map((wWord, i) => (
            <WriteWordInImageSmall
              cap={sheet.cap}
              column={i % 2}
              interactive={interactive}
              font={sheet.font}
              key={i}
              row={Math.floor(i / 2)}
              setWord={(w) => updateSheet(setWriteWord(i)(w)(sheet) as any)}
              word={wWord.value}
              popoverWords={words}
              fontPerSound={sheet.words[i].fontVariantPerSound.map((variant) =>
                variant ? getTaskFont(sheet.font.typeface, variant) : null
              )}
              setSoundFontVariant={(iSound, fv) =>
                updateSheet(
                  produce(sheet, (s) => {
                    const currentValue = s.words[i].fontVariantPerSound[iSound];
                    s.words[i].fontVariantPerSound[iSound] = currentValue === fv ? null : fv;
                  })
                )
              }
            />
          ))}
        </svg>
      </svg>
    </BlankSheet>
  );
};

export const fnMkSheet: MkSheet<TWWII8x> = (arg) => {
  const {
    locale,
    pSheet: { sounds = getSounds(locale), ...pSheet }
  } = getDefaultArgs(arg);

  const sheet: TWWII8x = {
    ...mkAbstractSheet(),
    cap: ECap.NORMAL,
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    name: ESheet.WWII_8X,
    sounds: sounds ?? ["foo", "bar"],
    words: arrayOf("", 8).map((word) => mkWriteWord({ value: word })) as TTuple<TWriteWord, 8>,
    ...pSheet
  };

  return sheet;
};

export const menuSheets: TMenuSheetsFor<ESheet.WWII_8X> = {
  [ELocale.de_DE]: fnMkSheet({
    locale: ELocale.de_DE,
    pSheet: {
      words: ["Schaf", "Kiste", "Biber", "Rose", "Zug", "Löwe", "Erde", "Auto"].map((w) =>
        // Word-wide variant currently not being used... So using sheet wide font for now
        mkWriteWord({ value: w, fontVariant: ETaskFontVariant.OUTLINE })
      ) as TTuple<TWriteWord, 8>
    }
  }),
  [ELocale.es_ES]: fnMkSheet({
    locale: ELocale.es_ES,
    pSheet: {
      words: ["tren", "asno", "nido", "cresta", "radio", "taxi", "iglú", "golf"].map((w) =>
        mkWriteWord({ value: w })
      ) as TTuple<TWriteWord, 8>
    }
  })
};

export const configWWII8x: TSheetConfig<ESheet.WWII_8X> = {
  disabledLocales: localesDisabledEverywhere,
  Editor,
  fnMkSheet,
  menuSheets,
  Sheet,
  videos: {
    [ELocale.de_DE]: {
      editor: "https://vimeo.com/656572209"
    }
  }
};
