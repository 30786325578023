import { TSound } from "@imaldev/imal-factory/abc";

export type TWithFocusedSound = {
  focusedSound: string;
};

/* param order should be reversed? */
type TSetFocusedSound = <S extends TWithFocusedSound>(sheet: S, sound: TSound) => S;

export const setFocusedSound: TSetFocusedSound = (s, sound) => ({
  ...s,
  focusedSound: sound
});

/* Maybe remove non curried version? only use curried fn's for updating sheets? */

type TSetFocusedSoundCurried = <S extends TWithFocusedSound>(sheet: S) => (sound: TSound) => S;
export const setFocusedSoundCurried: TSetFocusedSoundCurried = (s) => (sound) =>
  setFocusedSound(s, sound);
