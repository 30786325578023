import * as R from "ramda";
import { TaskFont } from "../../../../../../../utils/utilsiMAL/fonts/shared";
import { TTaskSheet } from "../../TaskSheet";

/* Now have tried both manually currying, and currying with ramda and that other lib. */
/* Lib curry seems to work when curried fn only has 2 parameters? */

/* Result of R.pipe is not curried? */
/* So how would use with currying then? Have to use R.__ ? */

/* Check in repl how would work? */
/* cf is curried with arity 2. */
/* f = R.pipe(cf, someFn) */

export type TWithFont = { font: TaskFont };

type TSetFont = <ST extends TTaskSheet & TWithFont>(s: ST, f: TaskFont) => ST;

export const setFont = R.curry(((s, font) => ({
  ...s,
  font
})) as TSetFont);

/* Could fontSize be used in any other sheets? */
/* Getting use-before-define error now... */
export enum EFontSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}
