import { Box, Grid, Text } from "@chakra-ui/react";
import { FlexRow } from "../../../../../../../../../components/layout/Flex/Flex";
import { SettingButton } from "../SettingButton/SettingButton";

type Props = {
  choices?: number[];
  selectedCount: number | null;
  setCount: (count: number) => void;
};

export const CheckboxCountSelector = ({ choices = [1, 2, 3], selectedCount, setCount }: Props) => {
  return (
    <Grid gap="1vmin" templateColumns="repeat(3, 1fr)">
      {choices.map((count, i) => (
        <SettingButton key={i} onClick={() => setCount(count)} active={count === selectedCount}>
          <FlexRow margin="1vmin" alignItems="center" justify="center">
            <Box boxSize="8px" mr="7px" border="2px solid black" />
            <Text>x {count}</Text>
          </FlexRow>
        </SettingButton>
      ))}
    </Grid>
  );
};
