import { Grid } from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { CanBeCollapsible } from "../../../../../../../../../components/Collapsable/Collapsible";
import { EFontSize } from "../../../sheetTypes/withFont";
import { SettingButton } from "../SettingButton/SettingButton";
import { SettingBox } from "../SettingContainer/SettingBox";

const translations = {
  [ELocale.en_US]: {
    font_size: "Font size",
    small: "Small",
    medium: "Medium",
    large: "Large"
  },
  [ELocale.de_DE]: {
    font_size: "Schriftgröße",
    small: "Klein",
    medium: "Medium",
    large: "Groß"
  },
  [ELocale.es_ES]: {
    font_size: "Tamaño de letra",
    small: "Pequeño",
    medium: "Mediano",
    large: "Grande"
  }
};

type Props = CanBeCollapsible & {
  activeSize?: EFontSize;
  switchFontSize?: (size: EFontSize) => void;
};

export const SelectFontSize = ({
  openByDefault = false,
  activeSize,
  switchFontSize = (_) => null
}: Props) => {
  const { txt } = useTxt(translations);

  return (
    <SettingBox title={txt.font_size} openByDefault={openByDefault}>
      <Grid gap="1vmin" templateColumns="repeat(3, 1fr)" p="1vh">
        {Object.values(EFontSize).map((size, i) => (
          <SettingButton
            onClick={() => {
              switchFontSize(size);
            }}
            key={i}
            label={txt[size]}
            active={activeSize === size}
          />
        ))}
      </Grid>
    </SettingBox>
  );
};
