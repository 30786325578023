import { nordicStartpoints } from "../nordicSerif/nordicSerif";
import { ETaskFontVariant, ETypeface, TaskFont, TTaskTypeface } from "../shared";

const nordicSansSerifDotted: TaskFont = {
  fontFamily: "nordicSansSerifDotted",
  typeface: ETypeface.NORDIC_SANS_SERIF,
  variant: ETaskFontVariant.DOTTED
};

const nordicSansSerifDottedStartpoints: TaskFont = {
  fontFamily: "nordicSansSerifDottedStartpoints",
  typeface: ETypeface.NORDIC_SANS_SERIF,
  variant: ETaskFontVariant.DOTTED_STARTPOINTS
};

const nordicSansSerifOutline: TaskFont = {
  fontFamily: "nordicSansSerifOutline",
  typeface: ETypeface.NORDIC_SANS_SERIF,
  variant: ETaskFontVariant.OUTLINE
};

const nordicSansSerifOutlineStartpoints: TaskFont = {
  fontFamily: "nordicSansSerifOutlineStartpoints",
  typeface: ETypeface.NORDIC_SANS_SERIF,
  variant: ETaskFontVariant.OUTLINE_STARTPOINTS
};

const nordicSansSerifRegular: TaskFont = {
  fontFamily: "nordicSansSerifRegular",
  typeface: ETypeface.NORDIC_SANS_SERIF,
  variant: ETaskFontVariant.REGULAR
};

export const nordicSansSerif: TTaskTypeface = {
  id: "6bd05f21-a530-43ca-9576-57b59e695931",
  name: ETypeface.NORDIC_SANS_SERIF,
  nameForDisplay: "iMal B",
  fonts: {
    dotted: nordicSansSerifDotted,
    dottedStartpoints: nordicSansSerifDottedStartpoints,
    outline: nordicSansSerifOutline,
    outlineStartpoints: nordicSansSerifOutlineStartpoints,
    regular: nordicSansSerifRegular,
    startpoints: {
      ...nordicStartpoints,
      typeface: ETypeface.NORDIC_SANS_SERIF
    } as TaskFont
  }
} as const;
