import ReactDOM from "react-dom";
import { App } from "./App";
import "./styles/global.css";
import LogRocket from "logrocket";
import { ENVIRONMENT } from "./constants";

if (ENVIRONMENT === "production") LogRocket.init("ue0bqi/imal-german-prod");

const renderApp = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

renderApp();

if (module.hot) {
  console.log("Development: using hot reloading.");
  module.hot.accept("./App", () => renderApp());
} else {
  renderApp();
}
