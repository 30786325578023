import { Box, Divider, Flex, Text, Wrap } from "@chakra-ui/react";
import { applyCap, ECap, TSound } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import * as R from "ramda";
import { useGlobalTxt } from "../../../../../../../../../i18n/useGlobalTxt";
import { Nullable } from "../../../../../../../../../utils/utilsTS/dictionary/dict";
import { useDefaultSequence } from "../../../../../../context/AppSheetsAbcUserData/contextDefaultSequence";
import { SettingBox } from "../SettingContainer/SettingBox";
import { SelectCap } from "./SelectCapitalization";

const isEdgeCase = (s: TSound) => {
  return ["ß"].includes(s);
};

/* TODO: use colorscheme instead. */
enum BgColor {
  DEFAULT = "#eee",
  SELECTED = "#007bff",
  HOVER = "lightgray"
}

type PropsSoundButton = {
  isSelected: boolean;
  onClick: () => void;
  text: string;
  toggleable?: boolean;
};

/* TODO: Center sound vertically text inside button. */
const SoundButton = ({ isSelected, onClick, text, toggleable = false }: PropsSoundButton) => (
  <Text
    bg={isSelected ? BgColor.SELECTED : BgColor.DEFAULT}
    borderRadius="3px"
    borderWidth="2px"
    boxShadow="base"
    color={isSelected ? "white" : "black"}
    h="28px"
    m="3px"
    mt="4px"
    onClick={onClick}
    p="2px"
    textAlign="center"
    transition="ease .04s"
    userSelect="none"
    w="50px"
    _hover={{
      cursor: isSelected && !toggleable ? "default" : "pointer",
      bg: isSelected ? BgColor.SELECTED : BgColor.HOVER
    }}
  >
    {text}
  </Text>
);

type Props = {
  activeSound?: Nullable<string>;
  cap?: Nullable<ECap>;
  hideCapSetting?: boolean;
  hideMultiGraph?: boolean; // multi graph = multi grapheme = multi letters sound
  isCollapsible?: boolean;
  isOpenByDefault?: boolean;
  setCap?: (v: ECap) => void;
  setSound?: (sound: string) => void;
  toggleable?: boolean;
};

/* TODO: fix bg color when not collapsible. */
export const SelectSound = ({
  activeSound = "a",
  cap,
  hideCapSetting = false,
  hideMultiGraph = false, // `onlyLetters` is clearer?
  isCollapsible = true,
  isOpenByDefault = true,
  setCap = (_) => {},
  setSound = (_) => null,
  toggleable = false
}: Props) => {
  const {
    defaultSequence: { sounds: dss }
  } = useDefaultSequence();
  const { txt_g } = useGlobalTxt();
  const { locale } = useLocale();

  /* Sorting sounds doesn't work properly... sharf-s should be last... */
  /* TODO: write sort fn which is actually works for all locales? */
  /* Alphabet array-constant for each locale? */
  /* Perhaps something like `imalSort`? `sortSounds`?  */
  /* Current solution works, but is kinda ugly, verbose. Currently, this is only done here, */
  /* so is only a small pasta. */
  return (
    <SettingBox isCollapsible={isCollapsible} openByDefault={isOpenByDefault} title={txt_g.sound}>
      <Flex flexDir="column" p=".8rem">
        <Wrap>
          {R.concat(
            R.concat(
              dss.filter((s) => s.length === 1 && R.not(isEdgeCase(s))).sort(),
              locale === ELocale.de_DE && R.not(hideMultiGraph) ? ["ß"] : []
            ),

            hideMultiGraph ? [] : dss.filter((s) => s.length > 1).sort()
          ).map((sound, i) => (
            <SoundButton
              isSelected={sound === activeSound}
              key={i}
              onClick={() => setSound(sound)}
              text={applyCap(sound, cap || ECap.UPPER)}
              toggleable={toggleable}
            />
          ))}
        </Wrap>
        {R.not(hideCapSetting) && (
          <>
            <Divider w="98%" mt="1.7rem" />
            <Box boxSizing="border-box" w="90%" m="auto">
              <SelectCap activeCap={cap} setCap={setCap} toggleable={toggleable} />
            </Box>
          </>
        )}
      </Flex>
    </SettingBox>
  );
};
