/* Ideally, should use h1 tags for sections, and use css to make the text smaller. */
/* Better for SEO and screen readers. */

import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { ModalBody, ModalTitle } from "react-bootstrap";

/* Translations by language at some point... */
const TermsAndConditions = () => {
  return (
    <div>
      <h4>1. Datenverantwortung</h4>
      <p>
        IMAL Norwegen AS ist Datenmanager und verantwortlich für die Behandlung der
        personenbezogenen Daten der Nutzer und Kunden von iMALs digitalen Lehrwerken (Kommunen,
        Organisationen und Privatpersonen).
      </p>
      <p>
        Kontaktinformation iMAL:
        <ul
          style={{
            listStyleType: "none"
          }}
        >
          <li>Postadresse: </li>
          <li>iMAL Norwegen AS</li>
          <li>Busk Bruns Veg 1</li>
          <li>7760 Snåsa</li>
        </ul>
      </p>
      <p>
        E-Mail:
        <ul
          style={{
            listStyleType: "none"
          }}
        >
          <li>mail@imalabc</li>
        </ul>
        Telefon:
        <ul
          style={{
            listStyleType: "none"
          }}
        >
          +47 90 40 70 60
        </ul>
      </p>
      <h4>2. Geschäftsmodell iMALs</h4>
      <p>
        iMAL bietet digitale Lehrmittel zum Erlernen des Lesens und Schreibens an. Neue Nutzer
        registrieren sich mit gewünschten Nutzernamen und Passwort. Die iMAL-Aufgaben zum Lesen und
        Schreiben werden mit Hilfe der Web-Applikation erstellt. Anschließend können die Schüler die
        Aufgaben entweder ausgedruckt auf Papir oder als digitale Aufgaben auf dem Tablet lösen.
        iMAL bietet seinen Nutzern Kurse und Training für die Methodik und die Pädagogik iMALs an.
      </p>

      <h4>3. Verwendung und Behandlung personenbezogener Daten</h4>
      <p>
        Personenbezogene Daten sind jegliche Daten, die eine Person identifizieren können. iMAL
        speichert und behandelt Daten, die für die Funktionen des Programs und die Kundenübersicht
        notwendig sind. Um die existierenden Funktionen zu verbessern und neue Systemanforderungen
        zu erkunden, speichert iMAL Nutzerdaten über die Verwendung des Systems. Für Privatnutzer
        werden jegliche Daten gespeichert, die bei der Kontoerstellung oder bei sonstigen Zugriffen
        auf die Lehrwerke angegeben wurden. Um die Lehrwerke zu benutzen, muss der Nutzer zustimmen,
        dass iMAL AS personenbezogene Daten speichert und behandelt.
      </p>

      <h4>4. Von uns erhobene personenbezogene Daten</h4>
      <p>
        iMAL speichert und behandelt folgende personenbezogenen Daten:
        <ul>
          <li>Daten über die Schule: </li>
          <ul>
            <li>interne Nutzer-ID</li>
            <li>externe ID (Nutzername)</li>
            <li>Name der Schule</li>
            <li>Anzahl Schüler an der Schule</li>
            <li>Addresse</li>
            <li>Organisationsnummer</li>
          </ul>
          <li>Daten von Klassen/Gruppen:</li>
          <ul>
            <li>interne Nutzer-ID</li>
            <li>externe ID</li>
            <li>Name</li>
            <li>sonstige Zugehörigkeiten</li>
            <li>Nutzerberechtigungen</li>
          </ul>
          <li>Daten jeglicher Nutzer:</li>
          <ul>
            <li>interne Nutzer-ID</li>
            <li>externe ID</li>
            <li>Name</li>
            <li>Nutzername</li>
            <li>E-Mail-Addresse</li>
            <li>Gruppenzugehörigkeit und entsprechende Berechtigungen</li>
            <li>Jahrgang/Klasse</li>
            <li>persönliche Einstellungen (z.B. Sprache)</li>
            <li>
              Verwendungsinformationen zur Fehlerbeseitigung und sonstige Daten, die bei der
              Anwendung des Programs generiert werden
            </li>
          </ul>
          <li>Daten ausgeführter Handlungen im Program:</li>
          <ul>
            <li>Zuteilungen (Aufgaben, Markierungen, Aufgabeeinstellungen)</li>
            <li>Zeitpunkte und zugehörige Details</li>
          </ul>
          <li>Information jeglicher Kunden:</li>
          <ul>
            <li>Kontaktperson und deren Kontaktinformation</li>
            <li>Einkaufsstatistik</li>
            <li>Zahlungsdetails und sonstige notwendige Informationen über die Kundenbeziehung</li>
          </ul>
        </ul>
      </p>
      <h4>5. Verwaltung und Behandlung von personenbezogenen Daten</h4>
      <p>
        Die von iMAL gespeicherten personenbezogenen Daten werden in Datenbanken auf Servers
        gespeichert. Es werden regelmäßig Datensicherungen durchgeführt. Des Weiteren wird
        regelmäßig eine Sicherheitskopie von den Daten, die für die Operation der Lehrwerke iMALs
        notwendig sind, erstellt. Die Sicherheitskopien werden auf einem anderen Datenspeicher
        gesichert als die Server, um Datenverlust zu vermeiden. iMAL hat eine Zulieferfirma, die die
        von iMAL gespeicherten Daten behandelt: Microsoft Ireland Operations, Ltd. Attn: Data
        Protection, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18,
        D18P521 Dienst. Betreiben von Serverdiensten für iMAL: Microsoft Online Services Terms,
        aktualisiert September 2020. Die Zulieferfirmen iMAls sind an die Datenschutzgrundverordnung
        (GDPR), gebunden, und erfüllen jegliche dabei verlangten Anforderungen (darunter
        Aufenthaltsort innerhalb der EU/EEA und/oder über Privacy Shield Verpflichtungen). Kopien
        von Verträge zwischen iMAL und die Zulieferfirmen sind nach Anfrage erhältlich.
      </p>
      <p>
        In der Web-Applikation wird jegliche Kommunikation zwischen Klient und Server, die
        personenbezogenen Daten beinhaltet, mit TSL-Kryptografie gesichert.
      </p>
      <h4>6. Zugang zu personenbezogenen Daten</h4>
      <p>
        Personen und Nutzer, die Zugang zu den gespeicherten personenbezogenen Daten haben:
        <ul>
          <li>
            Nutzer: Lehrer/innen haben Zugang zu ihren eigenen personenbezogenen Daten und die aller
            Schüler, die den von dem/der Lehrer/in administrierten Gruppe zugehören.
          </li>
          <li>
            Angestellte Entwickler, die mit der Entwicklung von den Lehrmitteln arbeiten, haben
            Zugang zu den personenbezogenen Daten. Die Angestellten haben Zugang auf die
            Datenbanken, die die Daten für die Web-Applikation iMALs speichern und sind unter
            anderem für die Datensicherung verantwortlich. Dies ist für die tägliche Bedienung,
            Datensicherheit und Weiterentwicklung notwendig.
          </li>
          <li>
            Angestellte von dem Zulieferbetrieb: ausgewählte Angestellte können begrenzt Zugang zu
            personenbezogenen Daten erhalten. Die von der EU/EEA verfassten Gesetze bezüglich des
            Datenschutzes fordern dafür strenge Auflagen. Der Zulieferer ist an diese Auflagen, wie
            in Punkt 5 beschrieben, gebunden.
          </li>
        </ul>
      </p>
      <h4>7. Löschen von personenbezogenen Daten</h4>
      <p>
        Personenbezogenen Daten werden nur nach Einwilligung und/oder Vereinbarung mit dem
        Administrator (üblicherweise Kommune, Schulleiter oder sonstige Person, die Zugang zu den
        Lehrwerken iMALs angefragt hat) gespeichert. Normalerweise werden personenbezogene Daten
        gespeichert, wenn Privatpersonen um Zugang anfragen. Personenbezogenen Daten werden von iMAL
        anonomysiert und/oder gelöscht in den folgenden Situationen:
        <ul>
          <li>
            Wenn der Administrator oder der Einzelnutzer beschließt, dass deren Daten nicht mehr von
            iMAL behandelt werden sollen, und sich direkt an iMAL richtet.
          </li>
          <li>Spätestens 18 Monate nach Ende der Kundenbeziehung.</li>
          <li>Spätestens 24 Monate nach Inaktivität eines iMAL-Nutzers.</li>
        </ul>
      </p>
      <p>
        Wenn Sie wünschen, dass iMAL Ihre personenbezogenen Daten löscht, können Sie Kontakt mit
        iMAL per E-Mail aufnehmen. Beim Löschen der personenbezogenen Daten eines Nutzers, verliert
        der Inhaber des Nutzers den Zugang zu den Lehrmitteln iMALs.{" "}
      </p>
      <h4>8. Ihre Rechte</h4>
      <p>
        Sie haben folgende Rechte:
        <ul>
          <li>
            Ihre personenbezogenen Daten, die von iMAL gespeichert werden, einzusehen. Des Weiteren
            können Sie anfordern, dass unzutreffende Daten über Sie korrigiert werden.
          </li>
          <li>
            falls Sie Administrator sind oder über privaten Zugang zu den Lehrmittel verfügen, haben
            Sie das Recht ihre Zustimmung zum Speichern personenbezogener Daten, zurückzuziehen.
          </li>
          <li>
            bei Verletzung oder Verstößen gegen Datenschutz, eine Beschwerde bei einer zuständigen
            Datenschutzaufsichtsbehörde einzureichen.
          </li>
          <li>
            eine Übersicht über die von Ihnen generierten Nutzerdaten durch die Applikation zu
            erhalten.
          </li>
          <li>sich von dem Newsletter iMALs abzumelden.</li>
        </ul>
        <p>
          Um Gebrauch von Ihren Rechten zu machen, kontakten Sie iMAL über E-Mail oder Telefon:
          <ul>
            <li>mail@imalabc.de</li>
            <li>+47 90 40 70 60</li>
          </ul>
        </p>
      </p>
      {/* should be higher than h4? */}
      <h4>NUTZERBEDINGUNGEN</h4>
      <p>
        Beim Einloggen mit den zugewiesenem Nutzernamen und Passwort, stimmen Sie den
        Nutzerbedingungen zu und bestätigen, dass Sie diese gelesen, verstanden und akzeptiert
        haben.
      </p>
      <h4>Vertragsschließende Partei</h4>
      <p>Lieferant des Dienstes ist der Betrieb iMAL AS, mit Organisationsnummer NO920807453 MVA</p>
      <h4>Lieferung</h4>
      <p>
        Bei erfolgreicher Bestellung unserer Software, werden die Zugangsdaten (Nutzername und
        Passwort) direkt per E-Mail an den Nutzer gesendet, sobald die Nutzerdaten bestätigt worden
        sind.{" "}
      </p>
      <p>
        Fehler und technische Herausforderungen können vorkommen und werden korrigiert, sobald diese
        von iMAL wahrgenommen worden sind.
      </p>
      <p>
        Die Server werden hin und wieder abgeschaltet, um diese instand zuhalten. iMAL ist bestrebt,
        die Software möglichst ohne Stillstand zu betreiben. Beim Abschalten der Server wird die
        Software für eine kurze Zeit nicht verfügbar sein.{" "}
      </p>
      <h4>Abonnement</h4>
      <p>
        Jeglicher Verkauf von Software ist ein Abonnement. Abonnements können jederzeit gekündigt
        werden. Die Kündigung von Abonnements muss auf unserer Webseite unter Abonnement, per E-Mail
        oder Brief, durchgeführt werden.
      </p>
      <h4>Kundenregistrierung</h4>
      <p>
        Um Produkte kaufen zu können, müssen Sie bei iMAL als Kunde registriert sein. Neue Kunden
        registrieren sich im Laufe des Zahlunsprozesses. Jede Bestellung wird mit der entsprechenden
        Kundennummer registriert.
      </p>
      <h4>Vorbehalte</h4>
      <p>
        Wir behalten eventuelle Druckfehler bezüglich der Preise und der Informationen über Waren
        und Leistungen auf unserer Webseite vor. Wenn Sie dies bezüglich Fragen haben, kontakten Sie
        uns per Telefon oder per E-Mail. Es werden fortlaufend Aktualisierungen durchgeführt. Diese
        können neue Fehler oder sogenannte "Bugs" herbeiführen. Unser Ziel ist es, unseren Kunden
        Leistungen bereitzustellen, die ihren Bedürfnissen entsprechen. Bugs und technische Fehler,
        die als Folge von Aktualisierungen entstehen, kommen vor. Fehler kommen häufiger auf, wenn
        der Web-Applikation neue Funktionen hinzugefügt werden und können zu einer reduzierten
        Nutzererfahrung führen. Wir versuchen jedoch diese so schnell wie möglich zu korrigieren.
        iMAL ist von Rückmeldungen seiner Kunden abhängig, damit Fehler so schnell wie möglich
        behoben werden können. Ohne Rückmeldungen wird es länger dauern, bis iMAL die Fehler
        entdeckt und korrigieren kann.
      </p>
      <h4>Datenschutz</h4>
      <p>
        Datenschutz ist wichtig für iMAL und auch für Sie als Kunde. Pesonenbezogene Daten werden
        über sichere Verbindungen (TLS-Verbindung) übertragen. Der Datenaustausch über TLS erfolgt
        verschlüsselt.{" "}
      </p>
      <p>
        Um unsere Produkte zu abonnieren, müssen Sie bei uns als Kunde registriert sein. Wir
        registrieren Ihren Namen, den Namen Ihrer Schule (betrifft nur Nutzer, die die Software als
        Lehrer benutzen) und Ihre E-Mail-Adresse. Personenbezogene Daten wie Name und E-Mail-Adresse
        werden in den Datenbanken von iMAL gespeichert. Diese Informationen werden nicht mit
        externen Partnern geteilt, sondern nur in Verbindung mit der Software iMALs benutzt. Weitere
        Informationen Zum Datenschutz können Sie auf unserer Webseite finden.
      </p>
      <h4>Widerrufsrecht</h4>
      <p>
        Sie können jederzeit verlangen, dass wir Ihr Konto kostenlos löschen. Dazu kontakten Sie uns
        per E-Mail oder per Telefon.
      </p>
      <h4>Beschwerde/Anfragen</h4>
      <p>
        Beschwerden und Anfragen können an unsere Kundenbetreuung gerichtet werden:
        <ul>
          <li>mail@imalabc.de</li>
        </ul>
      </p>
      <h4>Rechtsstreit</h4>
      <p>Rechtsstreitigkeiten sind den Vorschriften der EU untergeordnet.</p>
    </div>
  );
};

export const PopupTermsAndConditions = (props: { isOpen: boolean; onClose: () => void }) => (
  <Modal size="2xl" {...props}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader closeButton>
        <ModalTitle>Terms and conditions</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Box w="100%" p="1em" boxSizing="border-box">
          <TermsAndConditions />
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);
