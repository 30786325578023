import { Box, Grid, SimpleGrid } from "@chakra-ui/react";
import { applyCap, ECap, isVowel, TSound, TWord } from "@imaldev/imal-factory/abc";
import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import _ from "lodash";
import { assoc, pipe as p } from "ramda";
import { ReactNode, useMemo } from "react";
import { localesDisabledEverywhere } from "../../../../../../i18n/useGlobalTxt";
import { colorScheme } from "../../../../../../styles/colors";
import {
  ETaskFontVariant,
  getDefaultTypefaceName
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import { getTaskFont, taskTypefaceByName } from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { rgb, RGB } from "../../../../../../utils/utilsTS/color/color";
import { Size } from "../../../../../../utils/utilsTS/miscTypes/types";
import { useImageByWord } from "../../../context/WordImages";
import { useSheets } from "../../contexts/ContextSheets";
import { useWords } from "../../contexts/WordsContext";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import { SelectSound } from "../shared/components/settings/SelectSound/SelectSound";
import { SelectWordWithImage } from "../shared/components/settings/SelectWordWithImage/SelectWordWithImage";
import {
  Activatable,
  SettingButton
} from "../shared/components/settings/SettingButton/SettingButton";
import { SettingBox } from "../shared/components/settings/SettingContainer/SettingBox";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { IMalLogoNoTextSvg } from "../shared/components/sheets/BlankSheet/IMalLogoNoText.svg";
import {
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import { setCap, TWithCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried, TWithFocusedSound } from "../shared/sheetTypes/withFocusedSound";
import { setFont, TWithFont } from "../shared/sheetTypes/withFont";
import { setWord, setWords, TWithWords } from "../shared/sheetTypes/withWords";

export type TSoundPoster = TNewSheetType<ESheet.SOUND_POSTER> &
  TWithCap &
  TWithFocusedSound &
  TWithFont &
  TWithWords<1> & {
    background: RGB;
    isHighlightingVowels: boolean;
  };

export const EditorSoundPoster = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TSoundPoster };
  const { locale } = useLocale();
  const { updateSheet } = useSheets();
  const { words } = useWords(
    { mainSound: sheet.focusedSound, mainSoundPos: "first", withImage: true },
    { mainSound: sheet.focusedSound, withImage: true }
  );

  return (
    <EditorContainer
      sheet={sheet}
      settings={
        <>
          <SelectSound
            activeSound={sheet.focusedSound}
            cap={sheet.cap}
            hideCapSetting
            setCap={p(setCap(sheet), updateSheet)}
            setSound={p(
              setFocusedSoundCurried(sheet),
              setWords((s) => [getDefaultPosterWord(s.focusedSound, locale)]),
              updateSheet
            )}
          />
          <SelectFont
            activeFontVariant={sheet.font.variant}
            activeTypeface={sheet.font.typeface}
            disabledFontVariants={[ETaskFontVariant.BLANK, ETaskFontVariant.STARTPOINTS]}
            hideVariantSelect
            setFont={p(setFont(sheet), updateSheet)}
          />
          <SelectWordWithImage
            activeWord={sheet.words[0].value}
            setWord={(w) => updateSheet(setWord(0)(w)(sheet) as any)}
            words={words}
          />
          <SelectBgColor
            setColor={(col) => updateSheet(assoc("background", col, sheet))}
            selectedColor={sheet.background}
          />
          <SelectVowelHighlight
            isHighlightingVowels={sheet.isHighlightingVowels}
            setIsHighlightingVowels={(val) =>
              updateSheet(assoc("isHighlightingVowels", val, sheet))
            }
          />
        </>
      }
    />
  );
};

type PropsColorButton = {
  color: RGB;
  isSelected: boolean;
  setColor: (color: RGB) => void;
};

/* Use min-max stuff here to make buttons not too thin horizontally? */
/* TODO: fix how selected color is displayed: use <SettingButton /> here too. */
const ColorButton = ({ color, isSelected, setColor }: PropsColorButton) => {
  return (
    <Box
      onClick={() => setColor(color)}
      bg={rgb(...color)}
      border={isSelected ? `0.5vh solid ${colorScheme.TURQUOISE}` : ""}
      borderRadius=".5rem"
      cursor={isSelected ? "default" : "pointer"}
    />
  );
};

export const bgColors: Record<string, RGB> = {
  BLUE: [30, 72, 94],
  TURQUOISE: [139, 207, 220],
  YELLOW: [252, 247, 188],
  PURPLE: [124, 101, 142],
  CREAM: [247, 245, 244]
};

export const SelectBgColor = ({
  selectedColor,
  ...rest
}: {
  selectedColor?: RGB;
  setColor: (color: RGB) => void;
}) => {
  const { txt } = useTxt({
    [ELocale.de_DE]: {
      background: "Hintergrund"
    },
    [ELocale.en_US]: {
      background: "Background"
    },
    [ELocale.es_ES]: {
      background: "Fondo"
    }
  });

  return (
    <SettingBox title={txt.background}>
      <Grid gap="1vmin" h="8vh" templateColumns="repeat(7, 1fr)" p=".7rem">
        {Object.values(bgColors).map((color, i) => (
          <ColorButton
            color={color}
            isSelected={_.isEqual(color, selectedColor)}
            key={i}
            {...rest}
          />
        ))}
      </Grid>
    </SettingBox>
  );
};
/* Letter formatting is a little clunky on wider screens... */

/* Could use same letter as in the poster here? Or perhaps Poster should import. */
const Letter = ({
  children = "",
  isHighlighted
}: {
  children: ReactNode;
  isHighlighted?: boolean;
}) => (
  <tspan y="7vh" fontSize="8vh" fill={isHighlighted ? "#d22" : "#222"}>
    {children}
  </tspan>
);

/* TODO: should probably just use normal html <p> here; instead of svg. */
const HighlightSetting = ({
  active = false,
  hlVowels = false,
  onClick
}: Activatable & {
  hlVowels?: boolean;
  onClick: (v: boolean) => void;
}) => {
  return (
    <SettingButton active={active}>
      <svg
        height="15vh"
        onClick={(e) => {
          e.preventDefault();
          onClick(hlVowels);
        }}
        width="100%"
      >
        <svg height="100%" width="100%" x="2%" y="20%">
          <text x="15%" y="20%">
            <Letter isHighlighted={hlVowels}>a</Letter>
            <Letter>b</Letter>
            <Letter>c</Letter>
            <Letter>d</Letter>
            <Letter isHighlighted={hlVowels}>e</Letter>
            <Letter>f</Letter>
          </text>
        </svg>
      </svg>
    </SettingButton>
  );
};

export const SelectVowelHighlight = ({
  isHighlightingVowels,
  setIsHighlightingVowels
}: {
  isHighlightingVowels: boolean;
  setIsHighlightingVowels: (v: boolean) => void;
}) => {
  const { txt } = useTxt({
    [ELocale.de_DE]: {
      color_vowels: "Vokale markieren"
    },
    [ELocale.en_US]: {
      color_vowels: "Color vowels"
    },
    [ELocale.es_ES]: {
      color_vowels: "Colorea las vocales"
    }
  });

  return (
    <SettingBox title={txt.color_vowels}>
      <SimpleGrid columns={2} gap="1vmin" height="fit-content">
        <HighlightSetting
          active={isHighlightingVowels}
          onClick={setIsHighlightingVowels}
          hlVowels
        />
        <HighlightSetting active={!isHighlightingVowels} onClick={setIsHighlightingVowels} />
      </SimpleGrid>
    </SettingBox>
  );
};

/* TODO: reuse things across both poster designs. */
/* - image + word */
/* - main sound presentation. */
/* Two "versions" or conditional inside single sheet design? */

type LetterSize = "small" | "medium" | "large";

type PropsLetter = {
  fontFamily: string;
  isHighlighted?: boolean;
  letter: string;
  size: LetterSize;
};

const PosterLetter = ({ fontFamily, isHighlighted, letter, size }: PropsLetter) => {
  return (
    <tspan
      fill={isHighlighted ? "red" : "black"}
      fontFamily={fontFamily}
      fontSize={size === "small" ? 15 : size === "medium" ? 30 : 40}
    >
      {letter}
    </tspan>
  );
};

/* Sounds which cannot appear as first sound in a word. */
/* TODO: move to sound utils. */
const nonFirstsByLocale: LocaleRecord<Array<TSound>> = {
  [ELocale.de_DE]: ["ai", "chs", "ck", "ie", "ng", "nk"],
  [ELocale.en_US]: ["ck", "ng"]
};

const canBeFirstInWord = (s: TSound, l: ELocale) => !(nonFirstsByLocale[l] ?? []).includes(s);

/* Only for "PosterNormal"? */
const getSoundSize: (s: TSound) => Size = (s) => {
  if (s.length === 2) return "medium";
  if (s.length > 2) return "small";
  return "large";
};

type Props = { inEditor?: boolean; sheet: TSoundPoster };

/* TODO: fix words overflowing content box when length is 5+. */
export const SheetSoundPoster: SheetPreview<ESheet.SOUND_POSTER> = ({ sheetProps: sheet }) => {
  const { locale } = useLocale();
  return canBeFirstInWord(sheet.focusedSound, locale) ? (
    <SheetSoundPosterNormal sheet={sheet} />
  ) : (
    <SheetSoundPosterNonFirst sheet={sheet} />
  );
};

export const SheetSoundPosterNormal = ({ sheet, inEditor }: Props) => {
  const { imageByWord } = useImageByWord();
  const { locale } = useLocale();

  const imageBytes = useMemo(
    () => imageByWord[sheet.words[0].value] ?? null,
    [sheet.words[0].value, imageByWord]
  );

  return (
    <BlankSheet inEditor={inEditor} noMargin>
      <rect
        height="100%"
        style={{
          fill: sheet.background ? rgb(...sheet.background) : "blueLight",
          stroke: "2vmin"
        }}
        width="100%"
      />
      <rect
        height="42%"
        rx="5"
        style={{
          fill: "white",
          stroke: rgb(...sheet.background!, 0.7),
          strokeWidth: ".05vmin"
        }}
        width="90%"
        x="5%"
        y="6%"
      />
      <svg width="15%" height="15%" x="77%" y="5%">
        <IMalLogoNoTextSvg />
      </svg>
      {/* TODO: modify y-value, depending on sound levels. */}
      <svg x="20%" y="-4%" width="60%" height="60%">
        <line stroke="#222" strokeWidth={0.6} x1="5%" x2="95%" y1="64.5%" y2="64.5%" />
        <text textAnchor="middle" x="50%" y="64%">
          {applyCap(sheet.focusedSound, ECap.FIRST_UPPER)
            .split("")
            .map((l, i) => (
              <PosterLetter
                fontFamily={sheet.font.fontFamily}
                isHighlighted={sheet.isHighlightingVowels && isVowel(l.toLocaleLowerCase(), locale)}
                key={i}
                letter={l}
                size="large"
              />
            ))}
        </text>
      </svg>
      {/* TODO: component-ize. */}
      <rect
        height="43%"
        rx="5"
        style={{
          fill: "white",
          stroke: rgb(...sheet.background!, 0.7),
          strokeWidth: ".05vmin"
        }}
        width="42%"
        x="5%"
        y="51%"
      />
      <svg width="40%" x="6%" y="18%">
        {imageBytes && (
          <image width="100%" height="100%" href={`data:image/png;base64,${imageBytes}`} />
        )}
      </svg>
      <text fontFamily={sheet.font.fontFamily} fontSize="12" textAnchor="middle" x="26%" y="90%">
        {applyCap(sheet.words[0].value, sheet.cap)
          .split("")
          .map((l, i) => (
            <PosterLetter
              key={i}
              letter={l}
              fontFamily={sheet.font.fontFamily}
              isHighlighted={sheet.isHighlightingVowels && isVowel(l, locale)}
              size="small"
            />
          ))}
      </text>
      <rect
        height="43%"
        rx="5"
        style={{
          fill: "white",
          stroke: rgb(...sheet.background!, 0.7),
          strokeWidth: ".05vmin"
        }}
        width="42%"
        x="52%"
        y="51%"
      />
      <svg x="43%" y="40%" width="60%" height="60%">
        <text textAnchor="middle" x="50%" y="64%">
          {applyCap(sheet.focusedSound, ECap.NORMAL)
            .split("")
            .map((l, i) => (
              <PosterLetter
                fontFamily={sheet.font.fontFamily}
                size={getSoundSize(sheet.focusedSound)}
                isHighlighted={sheet.isHighlightingVowels && isVowel(l, locale)}
                key={i}
                letter={l}
              />
            ))}
        </text>
        <line stroke="#222" strokeWidth={0.6} x1="23%" x2="77%" y1="64.5%" y2="64.5%" />
      </svg>
    </BlankSheet>
  );
};

const SheetSoundPosterNonFirst = ({ inEditor, sheet }: Props) => {
  const { locale } = useLocale();
  const { imageByWord } = useImageByWord();

  const imageBytes = useMemo(
    () => imageByWord[sheet.words[0].value ?? null],
    [sheet.words[0].value]
  );

  return (
    <BlankSheet inEditor={inEditor} noMargin>
      <rect
        height="100%"
        width="100%"
        style={{
          fill: sheet.background ? rgb(...sheet.background) : "blueLight",
          stroke: "2vmin"
        }}
      />
      <rect
        x="5%"
        y="6%"
        height="42%"
        width="90%"
        rx="5"
        style={{
          fill: "white",
          stroke: rgb(...sheet.background!, 0.7),
          strokeWidth: ".05vmin"
        }}
      />
      <svg width="15%" height="15%" x="77%" y="5%">
        <IMalLogoNoTextSvg />
      </svg>
      {/* TODO: modify y-value, depending on sound levels. */}
      <svg x="20%" y="-4%" width="60%" height="60%">
        <line x1="5%" x2="95%" y1="64.5%" y2="64.5%" stroke="#222" strokeWidth={0.6} />
        <text textAnchor="middle" x="50%" y="65%">
          {sheet.focusedSound.split("").map((letter, i) => (
            <PosterLetter
              fontFamily={sheet.font.fontFamily}
              isHighlighted={sheet.isHighlightingVowels && isVowel(letter, locale)}
              key={i}
              letter={letter}
              size={"large"}
            />
          ))}
        </text>
      </svg>
      {/* TODO: create/abstract component for sound or image+word... */}
      {/* Abstraction for this? */}
      <rect
        x="23%"
        y="51%"
        height="43%"
        width="52%"
        rx="5"
        style={{
          fill: "white",
          stroke: rgb(...sheet.background!, 0.7),
          strokeWidth: ".05vmin"
        }}
      />
      <svg width="40%" x="28%" y="18%">
        {imageBytes && (
          <image width="100%" height="100%" href={`data:image/png;base64,${imageBytes}`} />
        )}
      </svg>
      <text
        fontFamily={taskTypefaceByName.germanGrundschrift.fonts.regular!.fontFamily}
        fontSize="12"
        textAnchor="middle"
        x="50%"
        y="90%"
      >
        {applyCap(sheet.words[0].value, sheet.cap)
          .split("")
          .map((l, i) => (
            <PosterLetter
              key={i}
              letter={l}
              fontFamily={sheet.font.fontFamily}
              isHighlighted={sheet.isHighlightingVowels && isVowel(l, locale)}
              size="small"
            />
          ))}
      </text>
    </BlankSheet>
  );
};

type WordBySound = Record<TSound, TWord>;

/* Rename to getDefaultWord? */
export const getDefaultPosterWord = (sound: TSound, locale: ELocale) => {
  const defaultWords: LocaleRecord<WordBySound> = {
    [ELocale.de_DE]: {
      a: "Ara",
      ai: "Hai",
      au: "Auto",
      b: "Bär",
      /* No suitable words in list. Which words for "c" in books? */
      c: "Clown", // Look in books.
      ch: "Chef",
      chs: "Fuchs",
      ck: "Rock",
      d: "Degen",
      e: "Esel",
      ei: "Eis",
      eu: "Heu",
      f: "Fuß",
      g: "Gans",
      h: "Hase",
      i: "Iglu",
      ie: "Dieb",
      j: "Jäger",
      k: "Kamel",
      l: "Löwe",
      m: "Mond",
      n: "Note",
      ng: "Engel",
      nk: "danke",
      o: "Ochse", // Use grandma here (when finished).
      p: "Pirat",
      pf: "Pferd",
      /* Does "q" even appear in any de_DE sound sequence? */
      q: "", // Look in books.
      qu: "Quark",
      r: "Rose",
      s: "Sofa",
      sch: "Schaf",
      sp: "Spur",
      st: "Stift",
      ß: "Fuß",
      t: "Tisch",
      tz: "Netz",
      u: "Ufo",
      v: "Vase",
      w: "Wal",
      x: "Box", // Sound not first. Look in books? Saskia proposed Xylofon.
      y: "Yeti", // Image missing.
      z: "Zug",
      ä: "Käse",
      äu: "Mäuse",
      ö: "Öl",
      ü: "üben"
    },
    [ELocale.es_ES]: {
      a: "abeja",
      b: "beso",
      c: "café",
      d: "delfín",
      e: "esquí",
      f: "foto",
      g: "gato",
      h: "huevo",
      i: "isla",
      j: "jefe",
      k: "kayak",
      l: "león",
      m: "maleta",
      n: "nube",
      // ng: "", // does spanish have any multi-letter sounds? any are they used in the classroom?
      o: "oso",
      p: "pato",
      q: "queso", // should be "que"/"ue"?
      r: "regalo",
      s: "sapo",
      t: "tren",
      u: "uvas",
      v: "vaca",
      w: "", // ???
      x: "",
      y: "yoga",
      z: "zapato"
    }
  };
  return defaultWords[locale]?.[sound] ?? "foobar";
};

export const newSheetSoundPoster: MkSheet<TSoundPoster> = (arg = { locale: ELocale.de_DE }) => {
  const {
    locale,
    pSheet: { focusedSound = "b", ...partSheet }
  } = getDefaultArgs(arg);
  const newSheet: TSoundPoster = {
    ...mkAbstractSheet(),
    background: bgColors.BLUE,
    cap: ECap.NORMAL,
    focusedSound,
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    isHighlightingVowels: false,
    name: ESheet.SOUND_POSTER,
    words: [
      {
        value:
          partSheet?.words?.[0].value !== ""
            ? getDefaultPosterWord(focusedSound, locale)
            : partSheet.words[0].value
      }
    ],
    ...partSheet
  };
  return newSheet;
};

export const menuSheetsSoundPoster: TMenuSheetsFor<ESheet.SOUND_POSTER> = {
  [ELocale.de_DE]: newSheetSoundPoster({
    locale: ELocale.de_DE,
    pSheet: {
      focusedSound: "l",
      words: [{ value: "Löwe" }]
    }
  }),
  [ELocale.es_ES]: newSheetSoundPoster({
    locale: ELocale.es_ES,
    pSheet: {
      focusedSound: "l",
      words: [{ value: "león" }]
    }
  })
};

export const configSoundPoster: TSheetConfig<ESheet.SOUND_POSTER> = {
  disabledLocales: localesDisabledEverywhere,
  Editor: EditorSoundPoster,
  videos: {
    [ELocale.de_DE]: {
      editor: "https://vimeo.com/652924205"
    }
  },
  fnMkSheet: newSheetSoundPoster,
  menuSheets: menuSheetsSoundPoster,
  Sheet: SheetSoundPoster
};
