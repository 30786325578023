import { ETaskFontVariant, ETypeface, TaskFont, TTaskTypeface } from "../shared";

/* Could potentially generate font related files with a script? */
/* Idk... yagni? */

const germanGrundschriftDotted: TaskFont = {
  fontFamily: "germanGrundschriftDotted",
  typeface: ETypeface.GERMAN_GRUNDSCHRIFT,
  variant: ETaskFontVariant.DOTTED
};

const germanGrundschriftDottedStartpoint: TaskFont = {
  fontFamily: "germanGrundschriftDottedStartpoints",
  typeface: ETypeface.GERMAN_GRUNDSCHRIFT,
  variant: ETaskFontVariant.DOTTED_STARTPOINTS
};

const germanGrundschriftOutline: TaskFont = {
  fontFamily: "germanGrundschriftOutline",
  typeface: ETypeface.GERMAN_GRUNDSCHRIFT,
  variant: ETaskFontVariant.OUTLINE
};

const germanGrundschriftOutlineNumbers: TaskFont = {
  fontFamily: "germanGrundschriftOutlineNumbers",
  typeface: ETypeface.GERMAN_GRUNDSCHRIFT,
  variant: ETaskFontVariant.OUTLINE_NUMBERS
};

const germanGrundschriftOutlineStartpoints: TaskFont = {
  fontFamily: "germanGrundschriftOutlineStartpoints",
  typeface: ETypeface.GERMAN_GRUNDSCHRIFT,
  variant: ETaskFontVariant.OUTLINE_STARTPOINTS
};

const germanGrundschriftRegular: TaskFont = {
  fontFamily: "germanGrundschriftRegular",
  typeface: ETypeface.GERMAN_GRUNDSCHRIFT,
  variant: ETaskFontVariant.REGULAR
};

/* Should type as taskTypeface */
export const germanGrundschrift: TTaskTypeface = {
  id: "d6558431-70f3-4bb2-a4e9-d086330b3b16",
  name: ETypeface.GERMAN_GRUNDSCHRIFT,
  nameForDisplay: "Grundschrift",
  /* what about creating fontObjects with map? */
  fonts: {
    dotted: germanGrundschriftDotted,
    dottedStartpoints: germanGrundschriftDottedStartpoint,
    outline: germanGrundschriftOutline,
    outlineNumbers: germanGrundschriftOutlineNumbers,
    outlineStartpoints: germanGrundschriftOutlineStartpoints,
    regular: germanGrundschriftRegular
  }
} as const;
