import { ETaskFontVariant, ETypeface, TaskFont, TTaskTypeface } from "../shared";

/* Could potentially generate font related files with a script? */
/* Idk... yagni? */
/* Need 2 things to generate a TaskFont: 1. typeface name, 2. font variant */

const germanNorthDotted: TaskFont = {
  fontFamily: "germanNorthDotted",
  typeface: ETypeface.GERMAN_NORTH,
  variant: ETaskFontVariant.DOTTED
};

const germanNorthDottedStartpoints: TaskFont = {
  fontFamily: "germanNorthDottedStartpoints",
  typeface: ETypeface.GERMAN_NORTH,
  variant: ETaskFontVariant.DOTTED_STARTPOINTS
};

const germanNorthOutline: TaskFont = {
  fontFamily: "germanNorthOutline",
  typeface: ETypeface.GERMAN_NORTH,
  variant: ETaskFontVariant.OUTLINE
};

const germanNorthOutlineNumbers: TaskFont = {
  fontFamily: "germanNorthOutlineNumbers",
  typeface: ETypeface.GERMAN_NORTH,
  variant: ETaskFontVariant.OUTLINE_NUMBERS
};

const germanNorthOutlineStartpoints: TaskFont = {
  fontFamily: "germanNorthOutlineStartpoints",
  typeface: ETypeface.GERMAN_NORTH,
  variant: ETaskFontVariant.OUTLINE_STARTPOINTS
};

const germanNorthRegular: TaskFont = {
  fontFamily: "germanNorthRegular",
  typeface: ETypeface.GERMAN_NORTH,
  variant: ETaskFontVariant.REGULAR
};

export const germanNorth: TTaskTypeface = {
  id: "6bea619b-ac92-4b1c-bce9-a76f7541b89f",
  name: ETypeface.GERMAN_NORTH,
  // nameForDisplay: "Druck Nord",
  nameForDisplay: "iMAL B",
  fonts: {
    dotted: germanNorthDotted,
    dottedStartpoints: germanNorthDottedStartpoints,
    outline: germanNorthOutline,
    outlineNumbers: germanNorthOutlineNumbers,
    outlineStartpoints: germanNorthOutlineStartpoints,
    regular: germanNorthRegular
  }
} as const;
