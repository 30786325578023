import { Flex, Icon, Text } from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../../api/user/context";
import { FlexColumn } from "../../../../../components/layout/Flex/Flex";
import { useGlobalTxt } from "../../../../../i18n/useGlobalTxt";

export const Profile = () => {
  const { txt_g } = useGlobalTxt();
  const { user } = useUser();
  const navigate = useNavigate();

  return (
    <Flex mt="5rem" mx="auto" w="fit-content">
      <Icon
        as={FaArrowLeft}
        cursor="pointer"
        fontSize="2rem"
        onClick={() => navigate("..")}
        transition=".17s ease"
        _hover={{ color: "#666" }}
      />
      <FlexColumn ml="4rem">
        <Text fontSize="2rem">
          <Text as="b" mb="2rem" mr="4rem">
            {txt_g.name}
          </Text>
          {user.firstName} {user.lastName}
        </Text>
        <Text fontSize="2rem" mt="1rem">
          <Text as="b" mb="2rem" mr="4rem">
            {txt_g.email}
          </Text>
          {user.email}
        </Text>
      </FlexColumn>
    </Flex>
  );
};
