import { Box } from "@chakra-ui/react";
import { getSoundsInWord, TWord } from "@imaldev/imal-factory/abc";
import { Percent } from "@imaldev/imal-factory/ts";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import * as R from "ramda";
import { useMemo, useRef, useState } from "react";
import {
  ETaskFontVariant,
  FontFamily,
  TaskFont
} from "../../../../../../../../../utils/utilsiMAL/fonts/shared";
import { taskTypefaceByName } from "../../../../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useHover } from "../../../../../../../../../utils/utilsReact/useHover";
import { Popover } from "../../settings/Popover/MaterialUI/PopoverMaterialUI";
import { SelectFontVariant } from "../../settings/SelectFont/SelectFontVariant";
import { Activatable } from "../../settings/SettingButton/SettingButton";

type PropsLetterUnderlined = {
  editable?: boolean;
  font: FontFamily;
  fontSize: number;
  letter: string; // Both lower and uppercase possible.
  x: Percent;
};

const LetterUnderlined = ({ editable, font, fontSize, letter, x }: PropsLetterUnderlined) => {
  const LINE_Y: Percent = 88;
  const LINE_WIDTH: Percent = 23;

  return (
    <svg height="100%" width="100%" x={x}>
      {/* TODO: fix this when do major font system overhaul. */}
      {font === "" ? (
        <></>
      ) : (
        <text
          cursor={editable ? "pointer" : "default"}
          fill={font.includes("Regular") ? "#AAAAAA" : "#222222"}
          fontFamily={font}
          fontSize={fontSize}
          textAnchor="middle"
          x={x + 5.6}
          y={LINE_Y - 0.5}
        >
          {letter}
        </text>
      )}
      <line
        stroke="black"
        strokeLinecap="butt"
        strokeWidth="1%"
        x1={x - 5}
        x2={x - 7 + LINE_WIDTH}
        y1={LINE_Y}
        y2={LINE_Y}
      />
    </svg>
  );
};

type PropsSoundUnderlined = Activatable & {
  editable?: boolean;
  font: TaskFont;
  setFontVariant: (variant: ETaskFontVariant) => void;
  sound: string;
  x: number;
};

/* TODO: try create popover with Chakra-UI. */
/* Lettersep and sound sep has to be the same. */

/* Split this in two? */
/* letter  */
/* letter width sound sep. yeah but... ??? */
const LETTER_SEP = 11;

/* Probably should rewrite WriteWord from scratch if wanna improve it further. */

const SoundUnderlined = ({
  active = false,
  editable,
  font,
  setFontVariant = () => {},
  sound,
  x
}: PropsSoundUnderlined) => {
  const { hoverHandlers, hasHover } = useHover();
  /* This popover anchor shit feels kinda icky... */
  /* "isOpen" would be cleaner. */
  const [popoverAnchor, setPopoverAnchor] = useState<SVGElement | null>(null);

  const popoverRef = useRef<any>();
  const closePopover = () => setPopoverAnchor(null);

  let letterX: Percent = x;

  return (
    <g>
      {sound.split("").map((letter, i) => {
        if (i > 0) letterX += LETTER_SEP - 0.6;
        return (
          <LetterUnderlined
            editable={editable}
            font={font.fontFamily}
            fontSize={30 + (editable && hasHover ? 2 : 0)}
            key={i}
            letter={letter}
            x={letterX}
          />
        );
      })}
      <rect
        {...hoverHandlers}
        cursor={editable ? "pointer" : "default"}
        fill={"#bbb"}
        fillOpacity={editable && hasHover && font?.variant === "blank" ? 0.3 : 0}
        height={30 / 1.2}
        onClick={() => setPopoverAnchor(popoverRef.current)}
        ref={popoverRef}
        rx={3.5}
        width={`${12 * sound.length}%`}
        x={x * 1.95}
        y="65.5"
      />
      {editable && (
        <Popover anchorEl={popoverAnchor} close={closePopover}>
          <Box border=".5vh solid black" p="1.5vh">
            <SelectFontVariant
              includeBlank
              setFontVariant={(font) => {
                closePopover();
                setFontVariant(font.variant);
              }}
              selectedTypeface={taskTypefaceByName.germanBayern.name}
              selectedVariant={active ? font?.variant : null}
              toggle
            />
          </Box>
        </Popover>
      )}
    </g>
  );
};

type Props = {
  editable?: boolean;
  fallbackFont: TaskFont;
  fontPerSound: (TaskFont | null)[];
  lineKind?: "letter" | "sound";
  setFontVariantForSound?: (iSound: number, variant: ETaskFontVariant) => void;
  word: TWord;
};

export const WriteWord = ({
  editable,
  fallbackFont,
  fontPerSound,
  lineKind = "sound",
  setFontVariantForSound = () => {},
  word
}: Props) => {
  const { locale } = useLocale();

  const textItems = useMemo(
    () => (lineKind === "sound" ? getSoundsInWord(locale, word, true) : word.split("")),
    [locale, word]
  );
  const BASE_X = 6.3;
  const xValues = textItems.reduce(
    (acc, s) => R.append(R.last(acc)! + s.length * LETTER_SEP, acc),
    [BASE_X]
  );

  // TODO: adjust viewbox based on number of letters in sound.
  // Maybe want to adjust size depending on where component is used? Might
  // want to size differently if using in sheet with 1 word, and a grid.
  return (
    <svg width="100%" height="100%" viewBox="0 0 140 100">
      {textItems.map((sound, iSound) => (
        <SoundUnderlined
          active={!!fontPerSound[iSound]}
          editable={!!editable}
          font={fontPerSound[iSound] ?? fallbackFont}
          key={iSound}
          setFontVariant={(variant) => setFontVariantForSound(iSound, variant)}
          sound={sound}
          x={xValues[iSound]}
        />
      ))}
    </svg>
  );
};
