import { Icon, IconProps } from "@chakra-ui/react";

/* Copied from */
/* https://www.flaticon.com/free-icon/font_3515514?term=font&page=1&position=44&page=1&position=44&related_id=3515514&origin=style */

export const Font = (props: IconProps) => (
  <Icon viewBox="0 0 74 74" color="red.200" {...props}>
    <path d="M42.242,57.554h-8.59a1,1,0,0,1-.934-.641L29.55,48.671H15.305L12.316,56.9a1,1,0,0,1-.94.659H3a1,1,0,0,1-.932-1.363L17.3,17.083a1,1,0,0,1,.932-.637h8.35a1,1,0,0,1,.928.628L43.17,56.183a1,1,0,0,1-.928,1.372Zm-7.9-2h6.426L25.906,18.446h-6.99L4.463,55.554h6.213l2.989-8.225a1,1,0,0,1,.94-.659H30.237a1,1,0,0,1,.934.641ZM27.7,42.082H17.032a1,1,0,0,1-.939-1.342l5.282-14.512a1,1,0,0,1,.936-.658h0a1,1,0,0,1,.938.652l5.389,14.512a1,1,0,0,1-.937,1.348Zm-9.243-2h7.8L22.324,29.467Z" />
    <path d="M53.82,57.554a10.657,10.657,0,0,1-7.582-2.646,8.891,8.891,0,0,1-2.835-6.73,9.081,9.081,0,0,1,1.293-4.8,8.514,8.514,0,0,1,3.633-3.261,25.809,25.809,0,0,1,6.209-1.829,48.994,48.994,0,0,0,6.471-1.547V36.68a3,3,0,0,0-.72-2.324c-.293-.253-1.122-.678-3.375-.678a4.446,4.446,0,0,0-2.546.6,4.561,4.561,0,0,0-1.446,2.275,1,1,0,0,1-1.121.652L45,35.983a1,1,0,0,1-.786-1.253,11.608,11.608,0,0,1,4.335-6.63c2.03-1.431,5.025-2.157,8.9-2.157,3.529,0,6.115.421,7.9,1.287a8.449,8.449,0,0,1,3.9,3.435c.755,1.38,1.123,3.732,1.123,7.189l-.08,8.76a29.625,29.625,0,0,0,.34,5.3A15.159,15.159,0,0,0,71.9,55.476a1,1,0,0,1-.9,1.438H63.584a1,1,0,0,1-.931-.634c-.164-.417-.35-.97-.565-1.678a13.886,13.886,0,0,1-3.207,1.939A12.818,12.818,0,0,1,53.82,57.554Zm3.095-25.876c2.243,0,3.774.38,4.682,1.163A4.827,4.827,0,0,1,63.01,36.68v.747a1,1,0,0,1-.612.921,41.21,41.21,0,0,1-7.486,1.9A24.3,24.3,0,0,0,49.2,41.916a6.455,6.455,0,0,0-2.8,2.5,7.013,7.013,0,0,0-1,3.761,6.874,6.874,0,0,0,2.207,5.274,8.739,8.739,0,0,0,6.21,2.1A10.828,10.828,0,0,0,58.1,54.7a12.579,12.579,0,0,0,3.8-2.6,1,1,0,0,1,1.634.367c.058.155.157.475.292.96.169.582.323,1.079.46,1.484h5.178a15.455,15.455,0,0,1-.792-2.6,30.947,30.947,0,0,1-.38-5.708l.08-8.76c0-3.814-.477-5.488-.877-6.219a6.517,6.517,0,0,0-3.013-2.595,17.122,17.122,0,0,0-7.034-1.087c-3.455,0-6.061.6-7.748,1.792a9.122,9.122,0,0,0-3.225,4.483l4.876.88a5.8,5.8,0,0,1,1.867-2.45A6.341,6.341,0,0,1,56.915,31.678Zm-.854,20.594a5.085,5.085,0,0,1-3.687-1.432A4.659,4.659,0,0,1,50.9,47.4a4.139,4.139,0,0,1,1.889-3.457,13.992,13.992,0,0,1,4.451-1.469c2.036-.435,3.535-.812,4.454-1.118a1,1,0,0,1,1.316.949V43.8a15.11,15.11,0,0,1-.337,3.947,5.685,5.685,0,0,1-2.146,2.99A7.513,7.513,0,0,1,56.061,52.272Zm4.949-8.626c-.887.237-2,.5-3.353.788A12.357,12.357,0,0,0,53.92,45.6,2.121,2.121,0,0,0,52.9,47.4a2.69,2.69,0,0,0,.873,2.005,3.109,3.109,0,0,0,2.289.863,5.472,5.472,0,0,0,3.282-1.144,3.682,3.682,0,0,0,1.414-1.95A13.373,13.373,0,0,0,61.01,43.8Z" />
  </Icon>
);
