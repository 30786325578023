import { ECap, getSounds, getSoundsInWord, TWord } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { shuffle, takeRandoms, TTuple, tupleOf } from "@imaldev/imal-factory/ts";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import { produce } from "immer";
import { append, assoc, concat, flatten, map, not, pipe as p, uniq, values, without } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ETaskFontVariant,
  getDefaultTypefaceName
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import { getLocaleTypefaces, getTaskFont } from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { Editable } from "../../../../../../utils/utilsReact/types";
import { useDefaultSequence } from "../../../context/AppSheetsAbcUserData/contextDefaultSequence";
import { useSheets } from "../../contexts/ContextSheets";
import { useWords } from "../../contexts/WordsContext";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import {
  EGuidelines,
  SelectGuidelineSetting
} from "../shared/components/settings/SelectGuidelineSetting/SelectGuidelines";
import { SelectSounds } from "../shared/components/settings/SelectSounds/SelectSounds";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { SectionHeader } from "../shared/components/sheets/SectionHeader/SectionHeader";
import {
  deriveRowSounds,
  mkWriteSoundRow,
  TWithWriteSoundRows,
  WriteSoundRow
} from "../shared/components/sheets/WriteSoundRow";
import { WriteWordInImageSmall } from "../shared/components/sheets/WriteWordInImageSmall/WriteWordInImageSmall";
import {
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import {
  setGuidelineSetting,
  TWithGuidelinesSetting
} from "../shared/sheetTypes/guidelinesSetting";
import { setCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried, TWithFocusedSound } from "../shared/sheetTypes/withFocusedSound";
import { EFontSize, setFont } from "../shared/sheetTypes/withFont";
import { enableSoundsToFocused, toggleSound, TWithSounds } from "../shared/sheetTypes/withSounds";
import {
  mkWriteWord,
  ProviderSelectableWords,
  setWord,
  setWords,
  setWriteWords,
  TWithWriteWords,
  TWriteWord,
  useSelectableWords
} from "../shared/sheetTypes/withWords";
import { PopoverSoundInRow } from "../WriteSoundRows";

const N_WORDS = 4;

export type TWSR2xAndWWII4x = TNewSheetType<ESheet.WSR_2X_AND_WWII_4X> &
  Editable &
  TWithFocusedSound &
  TWithSounds &
  TWithGuidelinesSetting &
  TWithWriteSoundRows<2> &
  TWithWriteWords<typeof N_WORDS>;

export const Editor = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TWSR2xAndWWII4x };
  const { defaultSequence } = useDefaultSequence();
  const { updateSheet } = useSheets();
  const { locale } = useLocale();
  const { getWords } = useWords();

  const availableWords = useMemo(
    () => getWords({ sounds: append(sheet.focusedSound, sheet.sounds), withImage: true }),
    append(sheet.focusedSound, sheet.sounds)
  );

  /* const getNewWords = useCallback(p(getWords({ locale, taskWords }), shuffle), [locale]); */
  /* should pass words down through sheet to sheet images? */

  /* TODO: Should have different functions for getting new words for setting new focused sound */
  /* and toggling a sound. */
  /* Does this work (calling a setState twice from same fn)? */
  const getWords2 = useCallback(
    (s: TWSR2xAndWWII4x) => {
      const ws = takeRandoms(N_WORDS, getWords({ sounds: s.sounds, withImage: true }));

      let extraWs: TWord[] = [];
      if (ws.length < N_WORDS) {
        extraWs = p(
          () => s.sounds,
          map((s) =>
            getWords({
              withImage: true,
              mainSound: s,
              mainSoundPos: "first"
            })
          ),
          flatten,
          uniq,
          without(ws),
          (ws) => takeRandoms(N_WORDS - ws.length, ws)
        )();
      }

      const result = concat(ws, extraWs);

      /* TODO: add an addional check to re-use words if not enough words. */
      /* Should only happen veeeery rarely... */
      /* When this case happens, return this. */
      if (result.length < N_WORDS) return "foobarbz".split("");

      return shuffle(result);
    },
    [JSON.stringify(sheet)]
  );

  useEffect(() => {
    /* if fresh sheet. */
    updateSheet(setWords(getWords2)(sheet));
  }, []);

  return (
    <ProviderSelectableWords words={availableWords}>
      <EditorContainer
        sheet={sheet}
        settings={
          <>
            <PopoverSoundInRow />
            <SelectSounds
              cap={sheet.cap}
              focusedSound={sheet.focusedSound}
              selectedSounds={sheet.sounds}
              onSelectSound={p(
                setFocusedSoundCurried(sheet),
                enableSoundsToFocused(defaultSequence),
                setWriteWords(getWords2),
                updateSheet
              )}
              setCap={p(setCap(sheet), updateSheet)}
              toggleSound={p(toggleSound(sheet), setWriteWords(getWords2), updateSheet)}
            />
            <SelectFont
              activeFontVariant={sheet.font.variant}
              activeTypeface={sheet.font.typeface}
              includeBlank
              setFont={p(setFont(sheet), updateSheet)}
              typefaces={getLocaleTypefaces(locale)}
            />
            <SelectGuidelineSetting
              activeSetting={sheet.guidelinesSetting}
              setSetting={p(setGuidelineSetting(sheet), updateSheet)}
            />
          </>
        }
      />
    </ProviderSelectableWords>
  );
};

const translations = {
  [ELocale.de_DE]: {
    write_words_in_images: "Schreibe die Wörter",
    write_and_say_every_sound_on_each_line: "Schreibe und sprich jeden Laut"
  },
  [ELocale.en_US]: {
    write_words_in_images: "Write the words",
    write_and_say_every_sound_on_each_line: "Write and say every sound"
  }
};

/* Wanna delete this... */

/* Should not think about count words... */
/* Perhaps should return "proper words" and fillers in separate collections: */
/* [proper: Word[], fillers: Word[]] */
// export const getWords: TWordGetter = (arg1) => (sheet) => {
//   const N_WORDS = 4;
//   let words = getValidWords({
//     focused: sheet.focusedSound,
//     sounds: sheet.sounds,
//     withImage: true,
//     ...arg1
//   });
//
//   /* If words too small, find words which begin with known sound. */
//   /* TODO: this should go in a separate fn? */
//   if (words.length < N_WORDS) {
//     const validFillers = shuffle(
//       getValidWords({
//         focused: sheet.focusedSound,
//         focusedPosition: "first",
//         withImage: true,
//         ...arg1
//       })
//     );
//     words = uniq(concat(words, take(N_WORDS - words.length, validFillers)));
//   }
//
//   return words;
// };

export const fnMkSheet: MkSheet<TWSR2xAndWWII4x> = (arg = { locale: ELocale.de_DE }) => {
  const {
    locale,
    pSheet: { focusedSound = "f", sounds = getSounds(locale), ...partSheet }
  } = getDefaultArgs(arg);

  const sheet: TWSR2xAndWWII4x = {
    ...mkAbstractSheet(),
    cap: ECap.NORMAL,
    focusedSound,
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    guidelinesSetting: EGuidelines.ALL_WITH_XAREA_BLUE,
    name: ESheet.WSR_2X_AND_WWII_4X,
    sounds,
    rows: tupleOf(mkWriteSoundRow(), 2),
    words: tupleOf(mkWriteWord({}), 4),
    ...partSheet
  };

  return sheet;
};

export const menuSheets: TMenuSheetsFor<ESheet.WSR_2X_AND_WWII_4X> = {
  [ELocale.de_DE]: fnMkSheet({
    locale: ELocale.de_DE,
    pSheet: {
      cap: ECap.UPPER,
      focusedSound: "m",
      rows: [mkWriteSoundRow({ cap: ECap.UPPER }), mkWriteSoundRow({ cap: ECap.NORMAL })],
      sounds: getSounds(ELocale.de_DE),
      words: ["Schaf", "Mond", "rosa", "Rose"].map((value) => mkWriteWord({ value })) as TTuple<
        TWriteWord,
        4
      >
    }
  }),
  [ELocale.es_ES]: fnMkSheet({
    locale: ELocale.es_ES,
    pSheet: {
      cap: ECap.UPPER,
      focusedSound: "m",
      rows: [mkWriteSoundRow({ cap: ECap.UPPER }), mkWriteSoundRow({ cap: ECap.NORMAL })],
      words: ["", "", "", ""].map((value) => mkWriteWord({ value })) as TTuple<TWriteWord, 4>
    }
  })
};

export const Sheet: SheetPreview<ESheet.WSR_2X_AND_WWII_4X> = ({
  sheetProps: sheet,
  interactive
}) => {
  const { locale } = useLocale();
  const { txt } = useTxt(translations);
  const { updateSheet } = useSheets();

  const ROW_Y_MARGIN = 17;

  /* If fns/some props cannot be undefined, will be easier with regards to these (??) */

  const words = useSelectableWords();

  /* Rename and use new getWordX() for both syllables and words? */
  return (
    <BlankSheet inEditor={interactive} logoSize={"small"}>
      <SectionHeader y="-13.5%" text={txt.write_and_say_every_sound_on_each_line} />
      <svg y="4%">
        {sheet.rows.map((row, i) => (
          <WriteSoundRow
            cap={row.cap ?? sheet.cap}
            editable={interactive}
            fontSize={EFontSize.MEDIUM}
            guidelinesSetting={row.guidelinesSetting ?? sheet.guidelinesSetting}
            iRow={i}
            key={i}
            rowSounds={deriveRowSounds(sheet, i)}
            sound={row.focusedSound || sheet.focusedSound}
            y={`${2 + ROW_Y_MARGIN * i}%`}
          />
        ))}
      </svg>
      <SectionHeader y="25.2%" text={txt.write_words_in_images} />
      <rect fill="black" height="50.6%" width="96%" x="2.5%" y="46%" />
      {/* TODO: make size a little smaller. */}
      <svg x="3%" y="46.4%" width="95%" height="100%">
        {sheet.words.map((word, i) => (
          <WriteWordInImageSmall
            cap={sheet.cap}
            column={i % 2}
            interactive={interactive}
            font={sheet.font}
            key={i}
            row={Math.floor(i / 2)}
            setWord={(w: TWord) => updateSheet(setWord(i)(w)(sheet) as typeof sheet)}
            word={word.value}
            popoverWords={words}
            fontPerSound={word.fontVariantPerSound.map((variant) =>
              variant ? getTaskFont(sheet.font.typeface, variant)! : null
            )}
            onlyFirstLetter={not(
              getSoundsInWord(locale, word.value).every((s) =>
                append(sheet.focusedSound, sheet.sounds).includes(s)
              )
            )}
            setSoundFontVariant={(iSound, fv) =>
              updateSheet(
                produce(sheet, (s) => {
                  const currentValue = s.words[i].fontVariantPerSound[iSound];
                  s.words[i].fontVariantPerSound[iSound] = currentValue === fv ? null : fv;
                })
              )
            }
          />
        ))}
      </svg>
    </BlankSheet>
  );
};

export const configWSRx2AndWWIIx4: TSheetConfig<ESheet.WSR_2X_AND_WWII_4X> = {
  Editor,
  fnMkSheet,
  menuSheets,
  Sheet,
  videos: {
    [ELocale.de_DE]: {
      editor: "https://vimeo.com/652924335"
    }
  }
};
