import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FlexColumn } from "../../../components/layout/Flex/Flex";
import { Header } from "../components/Header";

export const AboutUs = () => {
  return (
    <FlexColumn bg="red" minH="100vh" w="100vw">
      <Header />
      <Button as={Link} to="/home" mx="auto" px="2rem" w="fit-content">
        Back
      </Button>
    </FlexColumn>
  );
};
