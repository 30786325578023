import { Center, SimpleGrid, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FlexColumn } from "../../../../components/layout/Flex/Flex";
import { useHover } from "../../../../utils/utilsReact/useHover";
import { ListBooks } from "./ListBooks/ListBooks";
import { ListSheets } from "./ListSheets/ListSheets";
import { ListUsers } from "./ListUsers/ListUsers";

/* TODO: make use of react table (and maybe react virtualized). */

/* TODO: refactor this view. Should be called AdminDashboard. */
/*  */

enum EView {
  LIST_USERS = "list_users",
  LIST_BOOKS = "list_books",
  LIST_SHEETS = "list_sheets",
  STATS = "stats"
}

type PropsTab = {
  isActive?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  title: string;
};

const Tab = ({ isActive = false, isDisabled = false, onClick, title }: PropsTab) => {
  const { hasHover, hoverHandlers, ...hover } = useHover();

  const BORDER_COLOR = "#24687F";

  return (
    <Center
      {...hoverHandlers}
      cursor={isActive || isDisabled ? "default" : "pointer"}
      bg={isDisabled ? "#123035" : isActive ? "#428095" : hasHover ? "#20647B" : "#14586F"}
      borderTop={`.1rem solid ${BORDER_COLOR}`}
      borderX={`.05rem solid ${BORDER_COLOR}`}
      p=".6rem"
      onClick={isDisabled ? undefined : onClick}
    >
      <Text fontWeight={isDisabled ? "default" : "bold"} color={isDisabled ? "gray" : "white"}>
        {title}
      </Text>
    </Center>
  );
};

export const AdminDashboard = () => {
  const [view, setView] = useState<EView>(EView.LIST_USERS);

  return (
    <FlexColumn mx="auto" overflowY="scroll" pos="relative" w="100%">
      <SimpleGrid boxShadow="0px 2px 5px #444" columns={3}>
        <Tab
          isActive={view === EView.LIST_USERS}
          onClick={() => setView(EView.LIST_USERS)}
          title={"Users"}
        />
        <Tab
          isActive={view === EView.LIST_BOOKS}
          onClick={() => setView(EView.LIST_BOOKS)}
          title={"Printed books"}
        />
        <Tab
          isActive={view === EView.LIST_SHEETS}
          isDisabled
          onClick={() => setView(EView.LIST_SHEETS)}
          title={"Printed sheets"}
        />
      </SimpleGrid>
      {
        {
          [EView.LIST_USERS]: <ListUsers />,
          [EView.LIST_BOOKS]: <ListBooks />,
          [EView.LIST_SHEETS]: <ListSheets />,
          [EView.STATS]: <p>hello world</p>
        }[view]
      }
    </FlexColumn>
  );
};

/* Also have column, showing locales this user has apps for? */
