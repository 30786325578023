import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { useEffect, useState } from "react";
import { ArchivedSheet } from "../../../../../api/booklet";
import { useToggle } from "../../../../../utils/utilsReact/useToggle";

/* TODO: */
/* instead of showing individual sheets, allow admins to query data about */
/* all sheets generated. */

type PropsSheetCard = ArchivedSheet;

const ArchivedSheetCard = (props: PropsSheetCard) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <GridItem colSpan={3} onClick={toggleIsOpen}>
      <Grid bg="#abc" borderRadius=".5rem" boxShadow="md" m="1rem" templateColumns="repeat(3, 1fr)">
        <Box>
          <Text m="1rem">{props.created.toDate().getUTCDate()}</Text>
        </Box>
        <Box>
          <Text m="1rem">{props.authorEmail}</Text>
        </Box>
      </Grid>
    </GridItem>
  );
};

export const ListSheets = () => {
  const { locale } = useLocale();

  type SheetId = string;
  const [sheets, setSheets] = useState({} as Record<SheetId, ArchivedSheet>);

  useEffect(() => {
    const execGetDocs = async () => {
      /* const docs = await db
       *   .collection("app_sheets_abc")
       *   .doc(locale as string)
       *   .collection("printed_sheets")
       *   .get();

       * docs.forEach(async (doc) => {
       *   const sheet = doc.data();
       *   console.log(sheet);
       *   setSheets((prev) =>
       *     R.assoc(
       *       sheet.id,
       *       {
       *         ...sheet,
       *         created: new Date(sheet.created * 1000)
       *       } as DbArchivedSheet,
       *       prev
       *     )
       *   );
       * }); */
    };

    execGetDocs();
  }, []);

  useEffect(() => {
    console.log("sheets:");
    console.log(sheets);
  }, [sheets]);

  return (
    <Grid templateColumns="repeat(3, 1fr)">
      {/* TODO: i18nify labels. */}
      {["Date", "Name", "Pages"].map((label, i) => (
        <GridItem colSpan={1} key={i}>
          <Text fontSize="1.5rem" fontWeight="bold" m="1rem">
            {label}
          </Text>
        </GridItem>
      ))}
      {Object.values(sheets).map((s, i) => (
        <GridItem colSpan={3} key={i} m=".5rem">
          <ArchivedSheetCard
            author={s.author}
            book={s.book}
            config={s.config}
            authorEmail={s.authorEmail}
            created={s.created}
          />
        </GridItem>
      ))}
    </Grid>
  );
};
