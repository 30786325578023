import { ETaskFontVariant, TaskFont, ETypeface, TTaskTypeface } from "../shared";

/* Could potentially generate font related files with a script? */
/* Idk... yagni? */

const germanSouthDotted: TaskFont = {
  fontFamily: "germanSouthDotted",
  typeface: ETypeface.GERMAN_SOUTH,
  variant: ETaskFontVariant.DOTTED
};

const germanSouthDottedStartpoints: TaskFont = {
  fontFamily: "germanSouthDottedStartpoints",
  typeface: ETypeface.GERMAN_SOUTH,
  variant: ETaskFontVariant.DOTTED_STARTPOINTS
};

const germanSouthOutline: TaskFont = {
  fontFamily: "germanSouthOutline",
  typeface: ETypeface.GERMAN_SOUTH,
  variant: ETaskFontVariant.OUTLINE
};

const germanSouthOutlineNumbers: TaskFont = {
  fontFamily: "germanSouthOutlineNumbers",
  typeface: ETypeface.GERMAN_SOUTH,
  variant: ETaskFontVariant.OUTLINE_NUMBERS
};

const germanSouthOutlineStartpoints: TaskFont = {
  fontFamily: "germanSouthOutlineStartpoints",
  typeface: ETypeface.GERMAN_SOUTH,
  variant: ETaskFontVariant.OUTLINE_STARTPOINTS
};

const germanSouthRegular: TaskFont = {
  fontFamily: "germanSouthRegular",
  typeface: ETypeface.GERMAN_SOUTH,
  variant: ETaskFontVariant.REGULAR
};

/* Should type as taskTypeface */
export const germanSouth: TTaskTypeface = {
  id: "6e27c35f-6fd1-4aa9-8db2-7a62a8ebdf67",
  name: ETypeface.GERMAN_SOUTH,
  nameForDisplay: "iMAL A",
  fonts: {
    dotted: germanSouthDotted,
    dottedStartpoints: germanSouthDottedStartpoints,
    outline: germanSouthOutline,
    outlineNumbers: germanSouthOutlineNumbers,
    outlineStartpoints: germanSouthOutlineStartpoints,
    regular: germanSouthRegular
  }
} as const;
