import { Box, Wrap } from "@chakra-ui/react";
import { TFilter, TWord } from "@imaldev/imal-factory/abc";
import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { takeRandoms } from "@imaldev/imal-factory/ts";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { concat, takeWhile } from "ramda";
import { useCallback } from "react";
import { getTypefaceWithId } from "../../../../../utils/utilsiMAL/fonts/typefaces";
import {
  useAppSheetsAbcUserData,
  useDefaultSequence
} from "../../context/AppSheetsAbcUserData/contextDefaultSequence";
import { useSheets } from "../contexts/ContextSheets";
import { useWords } from "../contexts/WordsContext";
import { mkOneLargeSound } from "../taskSheets/OneLargeSound";
import { ESheet, TSheetNamed } from "../taskSheets/shared/misc";
import { sheetConfigs, TaskSheet } from "../taskSheets/TaskSheet";

export const SheetMenu = () => {
  return (
    <Wrap mt="1.3rem" pos="relative" spacing="1.8vmin" w="70rem" maxWidth="95vw">
      {sheetOrder.map((sn, i) => (
        <MenuSheet key={i} sheetName={sn as ESheet} />
      ))}
    </Wrap>
  );
};

/* In addition to this, also have a per-locale array to determine for which locales a sheet is implemented. */
const sheetOrder: ESheet[] = [
  ESheet.SOUND_POSTER,
  ESheet.ONE_LARGE_SOUND,
  ESheet.LETTER_LARGE_AND_SMALL,
  ESheet.WRITE_SOUND_ROWS,
  ESheet.TRACK_AND_BOX,
  ESheet.ONE_LARGE_WWII,
  ESheet.WWII_8X,
  ESheet.WSR_2x_AND_READ_3X,
  ESheet.WSR_2X_AND_WWII_4X,
  ESheet.ENCIRCLE_WORDS_WITH_SOUND,
  ESheet.TRACK_AND_WWII_4X
  //ESheet.READ_AND_WRITE_MAPPING
];

/* For each sheet's menu dict: */
/* TODO?: rename to just MenuSheets? */
export type TMenuSheetsFor<SN extends ESheet> = LocaleRecord<TSheetNamed<SN>>;

/* Main problem is onClick/-touch on touch devices. */
/* Is there some other event which is better suited for this use case? */
export const MenuSheet = ({ sheetName }: { sheetName: ESheet }) => {
  const { addSheet, setIdActiveSheet, sheets } = useSheets();
  const { locale } = useLocale();
  const { defaultSequence: activeSequence } = useDefaultSequence();
  const { data } = useAppSheetsAbcUserData();

  const { getWords } = useWords();

  const handleCreateNewSheet = useCallback(() => {
    const focusedSound = activeSequence.sounds[Math.min(10, activeSequence.sounds.length - 1)];
    const newSheet = sheetConfigs[sheetName].fnMkSheet({
      locale,
      pSheet: {
        focusedSound,
        sounds: takeWhile((s) => s !== focusedSound, activeSequence.sounds)
      },
      getWords: (nWords: number, filters: TFilter[]) => {
        const words = filters.reduce(
          (acc, filter) =>
            acc.length >= nWords
              ? acc
              : concat(acc, takeRandoms(nWords - acc.length, getWords(filter))),
          [] as TWord[]
        );
        return words;
      },
      typefaceName: getTypefaceWithId(data.idDefaultTypeface)!.name
    } as any); // TODO?: fix cast to any.
    addSheet(newSheet);
    setIdActiveSheet(newSheet.id);
  }, [activeSequence, addSheet, setIdActiveSheet, sheets]);

  /* Why 2 containers? For legacy "coming soon" thingy? Can merge into one? */
  return (
    <Box
      _hover={{
        mt: "0.1rem"
      }}
      boxShadow="lg"
      h="fit-content"
      pos="relative"
      transition="all 0.14s ease-in-out"
    >
      <Box
        borderRadius=".3rem"
        cursor="pointer"
        h="20rem"
        maxH="25vh"
        onClick={handleCreateNewSheet}
        onTouchEnd={handleCreateNewSheet}
        overflow="hidden"
      >
        <TaskSheet sheetProps={sheetConfigs[sheetName].menuSheets[locale] ?? mkOneLargeSound()} />
        {/* Enforce `cursor: "pointer"` over whole menu sheet. */}
        <Box cursor="pointer" h="100%" left="0" opacity={0} pos="absolute" top="0" w="100%" />
      </Box>
    </Box>
  );
};

/* What should be the new max? 100? */
const translations = {
  [ELocale.de_DE]: {
    warning_max_pages: (x: number) => `Maximum ${x} Blätter!`
  },
  [ELocale.en_US]: {
    warning_max_pages: (x: number) => `Max ${x} pages!`
  }
};
