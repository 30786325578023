import * as R from "ramda";
import { useEffect, useRef, useState } from "react";

/* TODO: move to imal-factory. */
export const useIsDirectlyOver = () => {
  const ref = useRef<HTMLElement>();
  const [isDirectlyOver, setIsDirectlyOver] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    const cbCheckDirectlyOver = (e: MouseEvent) => {
      setIsDirectlyOver(ref.current === R.head(document.elementsFromPoint(e.clientX, e.clientY)));
    };

    ref.current?.addEventListener("mousemove", cbCheckDirectlyOver);
    ref.current?.addEventListener("mouseleave", cbCheckDirectlyOver);

    return () => {
      ref.current?.removeEventListener("mousemove", cbCheckDirectlyOver);
      ref.current?.removeEventListener("mouseleave", cbCheckDirectlyOver);
    };
  }, []);

  return [ref, isDirectlyOver] as [React.LegacyRef<any>, boolean];
};
