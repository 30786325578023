import { SimpleGrid, Text } from "@chakra-ui/react";
import { taskTypefaceByName } from "../../../../../../../../../utils/utilsiMAL/fonts/typefaces";
import { applyCap, ECap } from "@imaldev/imal-factory/abc";
import { Nullable } from "../../../../../../../../../utils/utilsTS/dictionary/dict";
import { SettingButton } from "../SettingButton/SettingButton";

/* TODO: potentially move this outside. Use this in other places too... */

type Props = {
  activeCap?: Nullable<ECap>;
  disableFirstUpper?: boolean;
  setCap: (v: ECap) => void;
  toggleable?: boolean;
};

export const SelectCap = ({
  activeCap = ECap.NORMAL,
  disableFirstUpper = false,
  setCap,
  toggleable = false
}: Props) => {
  const choices = [ECap.NORMAL, disableFirstUpper ? [] : [ECap.FIRST_UPPER], ECap.UPPER].flat();
  return (
    <SimpleGrid gap="1rem" templateColumns="repeat(3, 1fr)" p="1rem" userSelect="none">
      {choices.map((cap, i) => (
        <SettingButton
          active={cap === activeCap}
          key={i}
          onClick={() => setCap(cap)}
          toggle={toggleable}
        >
          <Text
            color="black"
            fontSize={"3.2vh"}
            fontFamily={taskTypefaceByName.germanBayern.fonts.regular!.fontFamily}
            m="0"
            p="0.4vh 0 0 0"
            textAlign="center"
            _hover={{
              cursor: cap === activeCap && !toggleable ? "default" : "pointer"
            }}
          >
            {applyCap("aa", cap)}
          </Text>
        </SettingButton>
      ))}
    </SimpleGrid>
  );
};
