import { applyCap, ECap } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { Percent } from "@imaldev/imal-factory/ts";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import { pipe } from "ramda";
import {
  ETaskFontVariant,
  getDefaultTypefaceName
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import {
  getTaskFont,
  taskTypefacesByLocale
} from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useSheets } from "../../contexts/ContextSheets";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import { EGuidelines } from "../shared/components/settings/SelectGuidelineSetting/SelectGuidelines";
import { SelectSound } from "../shared/components/settings/SelectSound/SelectSound";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { SectionHeader } from "../shared/components/sheets/SectionHeader/SectionHeader";
import { Sound } from "../shared/components/sheets/Sound/Sound";
import {
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  InstructionalVideos,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import { setCap, TWithCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried, TWithFocusedSound } from "../shared/sheetTypes/withFocusedSound";
import { setFont, TWithFont } from "../shared/sheetTypes/withFont";

const videos: InstructionalVideos = {
  [ELocale.de_DE]: {
    editor: "https://vimeo.com/652924450",
    method: "https://vimeo.com/656586313"
  }
};

const EditorTrackAndBox = () => {
  const { activeSheet: sheet } = useSheets() as {
    activeSheet: TTrackAndBox;
  };
  const { updateSheet } = useSheets();
  const { locale } = useLocale();

  // Probably want to present the sound vertically centered.
  //  This means sounds will be rendered at a different y-value
  // depending on its height (A and W large, and with low y value, opposite
  // for i,a,e etc.)

  return (
    <EditorContainer
      sheet={sheet}
      settings={
        <>
          <SelectSound
            setCap={pipe(setCap(sheet), updateSheet)}
            cap={sheet.cap}
            activeSound={sheet.focusedSound}
            setSound={pipe(setFocusedSoundCurried(sheet), updateSheet)}
          />
          <SelectFont
            activeFontVariant={sheet.font.variant}
            activeTypeface={sheet.font.typeface}
            disabledFontVariants={[ETaskFontVariant.STARTPOINTS]}
            typefaces={taskTypefacesByLocale[locale]}
            setFont={pipe(setFont(sheet), updateSheet)}
          />
        </>
      }
    />
  );
};

export type TTrackAndBox = TNewSheetType<ESheet.TRACK_AND_BOX> &
  TWithCap &
  TWithFocusedSound &
  TWithFont;

export const mkTrackAndBox: MkSheet<TTrackAndBox> = (arg) => {
  const { locale, pSheet } = getDefaultArgs(arg);
  return {
    ...mkAbstractSheet(),
    cap: ECap.UPPER,
    focusedSound: "a",
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    guidelinesSetting: EGuidelines.BASELINE,
    name: ESheet.TRACK_AND_BOX,
    ...pSheet
  };
};

export const menuSheetsTrackAndBox: TMenuSheetsFor<ESheet.TRACK_AND_BOX> = {
  [ELocale.de_DE]: mkTrackAndBox({ locale: ELocale.de_DE }),
  [ELocale.es_ES]: mkTrackAndBox({ locale: ELocale.es_ES })
};

export { menuSheetsTrackAndBox as menuPropsOneSoundAndWriteInBox };

export const SheetTrackAndBox: SheetPreview<ESheet.TRACK_AND_BOX> = ({
  interactive,
  sheetProps
}) => {
  const { txt } = useTxt(translations);

  const contentWidth: Percent = 96;
  const margin: Percent = (100 - contentWidth) / 2;

  return (
    <BlankSheet inEditor={interactive} logoSize="normal">
      <SectionHeader
        text={`${txt.write_and_say} ${sheetProps.focusedSound.toLocaleUpperCase()}`}
        y={-20}
      />
      <svg
        height="50%"
        width={`${contentWidth + 1}%`}
        x={margin + 2} // ??
        y={"5%"}
      >
        <Sound
          cap={sheetProps.cap}
          color={sheetProps.font.variant === "regular" ? "gray" : "black"}
          font={sheetProps.font}
          sound={sheetProps.focusedSound}
          width={`${contentWidth}%`}
        />
      </svg>
      <svg x={margin} y="51%" height="45%" width={`${contentWidth + 1}%`}>
        <text fontFamily="arial" fontSize={3} x="8%" y="6.5%">
          {txt.write_or_draw_words_with_sound_in_the_box(
            applyCap(sheetProps.focusedSound, ECap.UPPER)
          )}
        </text>
        <rect
          height="84%"
          rx="1.5%"
          style={{
            fill: "white",
            stroke: "#444",
            strokeWidth: "1%"
          }}
          width="94%"
          x="3%"
          y="10%"
        />
      </svg>
    </BlankSheet>
  );
};

const translations = {
  [ELocale.de_DE]: {
    write_and_say: "Schreibe und sprich",
    write_or_draw_words_with_sound_in_the_box: (sound: string) =>
      `Schreibe oder male Wörter mit ${sound}`
  },
  [ELocale.en_US]: {
    write_and_say: "Write and say",
    write_or_draw_words_with_sound_in_the_box: (sound: string) =>
      `Write or draw words with ${sound} in the box`
  },
  [ELocale.es_ES]: {
    write_and_say: "Escribe y diga",
    write_or_draw_words_with_sound_in_the_box: (sound: string) =>
      `Escriba o dibuje palabras con ${sound} en la caja.`
  }
};

export const configTrackAndBox: TSheetConfig<ESheet.TRACK_AND_BOX> = {
  Editor: EditorTrackAndBox,
  videos,
  fnMkSheet: mkTrackAndBox,
  menuSheets: menuSheetsTrackAndBox,
  Sheet: SheetTrackAndBox
};
