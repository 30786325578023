import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { BiText } from "react-icons/bi";
import { FaArrowLeft, FaUser } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { TiPencil } from "react-icons/ti";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { SelectPreferredTypeface } from "../AppSheetsAbc";
import { Profile } from "./Profile/Profile";

/* Sometime today, just start early version of mobile version of editor? */
/* Mobile version, period? */
/* Not only will mobile version quite a few things. */
/* Many things for desktop version will also change: */
/* - Implement workbook centered concept?  */
/*   - Sound and focused sound? */
/*   - typeface */

/* Can now start with initial plans for recruit-a-friend thingy? */
/* Disable in prod for now... */

/* TODO: sub menus should have a way to navigate back to "main" menu. */

export const SettingsMenu = () => {
  const { txt } = useTxt(translations);
  const navigate = useNavigate();

  return (
    <Flex flexDir="column" overflowY="scroll" width="100%">
      <Routes>
        <Route
          path="/"
          element={
            <Flex flex={1} flexDir="column" w="fit-content" mx="auto">
              <Text as="b" fontSize="2.5rem" color="#333" mt="1rem" mb="1.6rem">
                {txt.settings}
              </Text>
              <Grid gap="1.3rem" autoRows="1fr" justifyContent="center" mx="auto">
                <SettingCard
                  icon={<Icon fontSize="2.8rem" as={FaUser} />}
                  linkPath="profile"
                  text={txt.profile}
                />
                <SettingCard
                  icon={<Icon fontSize="8vmin" as={BiText} />}
                  linkPath="typeface"
                  text={txt.typeface}
                />
                {/* <SettingCard linkPath="subscription" text="Subscription" /> */}
                {/* Find a suitable icon for this card; can combine icons user&plus? */}
                {/* Might exisit precicely such an icon already? */}
                {/* <SettingCard linkPath="invite-a-friend" text="Invite a friend" /> */}
              </Grid>
            </Flex>
          }
        />
        <Route path="profile" element={<Profile />} />
        <Route
          path="typeface"
          element={
            <Flex h="100%" mx="auto">
              <Icon
                as={FaArrowLeft}
                cursor="pointer"
                fontSize="2rem"
                onClick={() => navigate(".")}
                mt="5rem"
                transition=".17s ease"
                _hover={{
                  color: "#666"
                }}
              />
              <SelectPreferredTypeface />
            </Flex>
          }
        />
        <Route path="subscription" element={<PageSubscription />} />
        <Route path="invite-a-friend" element={<InviteAFriend />} />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </Flex>
  );
};

const translations = {
  [ELocale.de_DE]: {
    book: "Lautreihenfolge",
    profile: "Nutzer",
    settings: "Einstellungen",
    typeface: "Schriftart"
  },
  [ELocale.en_US]: {
    book: "Book",
    profile: "Profile",
    settings: "Settings",
    typeface: "Typeface"
  }
};

type PropsSettingCard = {
  icon?: React.ReactNode;
  linkPath: string;
  text: string;
};

/* TODO: styles here could/should be put in a reusable Card component. */
/* Create test react app to test chakra layers, theme, components. */
/* Also, animations, bunch of stuffs. */
/* Should be able to prune 100s of lines of code. */

/* this can probably be written in a simpler way. */
const SettingCard = (props: PropsSettingCard) => {
  return (
    <Grid
      as={Link}
      to={props.linkPath}
      alignItems="center"
      bg="#fff"
      boxSizing="border-box"
      boxShadow="md"
      borderRadius=".4rem"
      cursor="pointer"
      h="100%"
      justifyContent="center"
      p="1vh"
      templateColumns="1fr 7fr"
      textColor="#222"
      textDecoration="none"
      transition="ease .1s"
      _hover={{
        bg: "#f8f8f8"
      }}
    >
      {props.icon ? <Center h="inherit">{props.icon}</Center> : <Box />}
      <Text fontSize="4vh" ml="2.2rem">
        {props.text}
      </Text>
    </Grid>
  );
};

/* Subscription price should depend on the locale... */
/* How to structure this data? */
/* Prices will be in stripe currency? */
/* Or main currency for this country?  */

/* This has some overlap with stripe products? */

/* Perhaps change this a little... */
/* Instead of showing all sub choices straight away... */
/* show current sub... and some button to change to current subscription. */
/* See component below this one. */

/* This should be "centered" on the page. */
/* Use SimpleGrid? */
/* Component containing this is too tall? */
const PageSubscription = () => {
  return (
    <Grid autoRows="1fr" mx="auto" minW="800px">
      <GridItem bg="green.100" rowSpan={1}>
        <Heading>My subscription</Heading>
        <Flex gap="1em">
          <CurrentSubscription
            currentSub={{ currency: "dollar", period: "monthly", price: 10, title: "Monthly" }}
          />
        </Flex>
      </GridItem>
    </Grid>
  );
};

/* Modal: are you sure you wish to cancel subscription. */
/* What does edit subscription even mean? */
/* Can only change period monthly<->yearly? */
const CurrentSubscription = (props: { currentSub: Subscription }) => {
  const disclosureCancel = useDisclosure();
  const disclosureEdit = useDisclosure();
  return (
    <>
      <Grid
        bg="gray.300"
        alignItems="end"
        borderRadius="5%"
        minW="400px"
        templateColumns="1fr 1fr 1fr 1fr"
      >
        <Heading m="0" size="h3">
          {props.currentSub.title}
        </Heading>
        <Text>{props.currentSub.currency}</Text>
        <Text>{props.currentSub.price}</Text>
        <Stack>
          <Text>Current period ends:</Text>
          <Text fontStyle="italic">10. August 2022</Text>
        </Stack>
        {/* Second row. */}
        <Button onClick={disclosureEdit.onOpen} leftIcon={<Icon as={TiPencil} />}>
          Edit subscription
        </Button>
        <Button leftIcons={ImCross} onClick={disclosureCancel.onOpen}>
          Cancel subscription
        </Button>
      </Grid>
      <Modal isOpen={disclosureCancel.isOpen} onClose={disclosureCancel.onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack>
              <Heading size="h5">Do you really wish to cancel your subscription?</Heading>
              <Flex mx="auto" w="fit-content">
                {/* Should trigger some stripe stuff. */}
                <Button>Yes</Button>
                <Button onClick={disclosureCancel.onClose}>No</Button>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* ?Changing subscription will require interfacting with stripe? */}
      <Modal isOpen={disclosureEdit.isOpen} onClose={disclosureEdit.onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack>
              <Heading size="h5">Edit subscription</Heading>
              <SimpleGrid columns={2} gap="10px">
                <SubscriptionChoice currency="euro" isActive label="Monthly" price={10} />
                <SubscriptionChoice currency="euro" label="Yearly" price={100} />
              </SimpleGrid>
              <Flex mx="auto" w="fit-content">
                {/* Should trigger some stripe stuff. */}
                <Button>Save changes</Button>
                <Button onClick={disclosureEdit.onClose}>No</Button>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const SubscriptionChoice = (props: {
  isActive?: boolean;
  label: string;
  price: number;
  currency: string;
}) => {
  /* Add "per [period]" to choices? */
  return (
    <Flex
      alignItems="center"
      border="2px solid"
      borderColor={props.isActive ? "green.200" : "white"}
      borderRadius="12px"
      bg="gray.200"
      px="50px"
      py="20px"
      flexDir="column"
    >
      <Heading fontStyle="bold" size="h4" m="0">
        {props.label}
      </Heading>
      <HStack>
        <Currency value={props.currency} />
        <Text>{props.price},-</Text>
      </HStack>
    </Flex>
  );
};

const Currency = (props: { value: string }) => {
  const currencySymbols = {
    dollar: "$",
    euro: "E"
  } as any;
  return <Text>{currencySymbols[props.value] ?? ":)"}</Text>;
};

/* Need data about current subscription. */
const subscription = {
  period: "monthly"
};

/* 1 modal for cancel. */
/* 1 modal for edit sub. */

type SubscriptionKind = "freeTrail" | "monthly" | "yearly";

type Subscription = {
  currency: string;
  period: "monthly" | "yearly" | "trail";
  price: number;
  title: string;
};

/* Locales will havev different "products". */
/* Need to define each eriod for each product too? */
const prices: LocaleRecord<Record<SubscriptionKind, Subscription>> = {
  [ELocale.de_DE]: {
    freeTrail: {
      currency: "euro",
      period: "trail",
      price: 0,
      title: "Probe periode"
    },
    monthly: {
      currency: "euro",
      period: "monthly",
      price: 10,
      title: "Monatlich"
    },
    yearly: {
      currency: "euro",
      period: "yearly",
      price: 100,
      title: "Monatlich"
    }
  },
  [ELocale.en_US]: {
    freeTrail: {
      currency: "dollar_us",
      period: "trail",
      price: 0,
      title: "Free trail"
    },
    monthly: {
      currency: "dollar_us",
      period: "monthly",
      price: 10,
      title: "Monthly"
    },
    yearly: {
      currency: "dollar_us",
      period: "yearly",
      price: 100,
      title: "Yearly"
    }
  }
};

/* What can you do here? */
/* Different kind of invites? */
/* - Default invite: invited person gets... 3 number of months free usage. */
/* - After buying subscription, can invite another user for... 5 months with full access? */
/*   Additional niceness: */
/*   - If person ends up buying subscription, inviter gets 1/2 price for half a year? */
/*       If subscription kind is monthly, this will require some extra logic. */
/*   - Invitee also gets 1/2 off for half a year. */

/* "You have 3 premium invites"? */
/* Questionmark or info -icon to explain what it is and how it works. */
/* "Invite a friend" <- if they price x number of sheets/books, get 40% off for half a year? */
/*    This will require quite a bit of logic... */
/* "Send premium invite" <- disabled if don't have any */
const InviteAFriend = () => {
  return (
    <Stack>
      <Text>foo</Text>
      <Text>bar</Text>
      <Text>baz</Text>
    </Stack>
  );
};
