import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  query,
  QueryConstraint,
  QuerySnapshot,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { imalFirebase } from "../firebase";

export const db = getFirestore(imalFirebase);

export const wGetCollection = (path: string) => collection(db, path);

export const wGetCollectionDocs = async <T>(
  path: string,
  ...queryConstraints: QueryConstraint[]
) => {
  return (await getDocs(query(wGetCollection(path), ...queryConstraints))) as QuerySnapshot<T>;
};

export const wGetDoc = (path: string) => doc(db, path);

/* Should always merge? */
/* Is this merging by default? */
export const wSetDoc = async (path: string, data: any) => {
  const ref = wGetDoc(path);
  await setDoc(ref, data);
};

export const wGetDocData = async (path: string) => (await getDoc(wGetDoc(path))).data();

/* TODO?: Could we merge this with setDoc? wUpdateOrCreate()? */
export const wUpdateDoc = <T>(path: string, data: Partial<T>) => {
  updateDoc(wGetDoc(path) as DocumentReference<T>, data as any);
};

/* React hook must start with "use"... */
/* TODO: read about how isLoading thingy works... */
/* useDocumentData returns a third value for errors too... include in result? */
export const useWDocumentData = <T>(path: string) => {
  const [data, isLoading] = useDocumentData(wGetDoc(path));
  return [data, isLoading] as [T | undefined, boolean];
};
