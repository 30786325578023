export const now = () => new Date();

export const nowEpoch = () => Math.floor(new Date().getTime() / 1000);

/* To toEpochSeconds? */
export const toEpochFormat = (date: Date) => date.getTime() / 1000;

const epoch = new Date("1970-01-01");

export const Time = {
  epoch,
  now,
  nowEpoch,
  toEpochFormat
};
