import { Icon, IconProps } from "@chakra-ui/react";

export const Paper = (props: IconProps) => (
  <Icon viewBox="0 0 512.001 512.001" {...props}>
    <g>
      <g>
        <path
          d="M495.328,113.718l-88.835-18.319l6.401-40.547c0.312-1.971-0.174-3.984-1.347-5.597c-1.173-1.613-2.94-2.695-4.909-3.006
			L114.266,0.092c-1.97-0.31-3.985,0.174-5.597,1.347c-1.614,1.173-2.695,2.94-3.006,4.91L95.977,67.7H18.189
			c-4.154,0-7.522,3.368-7.522,7.522v79.739c0,4.154,3.368,7.522,7.522,7.522c4.155,0,7.522-3.368,7.522-7.522V82.745h76.694H306.66
			v245.101c0,4.154,3.367,7.522,7.522,7.522c4.155,0,7.522-3.368,7.522-7.522V75.223c0-4.154-3.367-7.522-7.522-7.522H111.208
			l8.141-51.575l277.511,43.811l-61.32,388.421l-13.838-2.184v-88.239c0-4.154-3.367-7.522-7.522-7.522
			c-4.155,0-7.522,3.368-7.522,7.522V452.6v23.376H199.131l-25.711-90.355c-1.031-3.623-4.583-5.923-8.309-5.387l-139.4,20.119
			V185.05c0-4.154-3.367-7.522-7.522-7.522c-4.154,0-7.522,3.368-7.522,7.522v223.989c0,0.049,0.01,0.096,0.011,0.144
			c0.003,0.167,0.022,0.329,0.036,0.492c0.019,0.228,0.04,0.453,0.08,0.675c0.029,0.164,0.069,0.324,0.109,0.484
			c0.053,0.217,0.112,0.43,0.185,0.64c0.053,0.155,0.113,0.307,0.177,0.457c0.086,0.206,0.18,0.405,0.284,0.602
			c0.075,0.143,0.153,0.285,0.238,0.423c0.117,0.192,0.245,0.373,0.378,0.554c0.095,0.128,0.189,0.257,0.293,0.38
			c0.149,0.177,0.312,0.34,0.477,0.502c0.11,0.108,0.215,0.22,0.331,0.321c0.193,0.167,0.401,0.316,0.612,0.464
			c0.108,0.076,0.209,0.161,0.322,0.232c0.333,0.209,0.681,0.396,1.05,0.553l175.261,74.459c0.234,0.099,0.471,0.187,0.713,0.261
			c0.155,0.048,0.314,0.083,0.471,0.12c0.087,0.021,0.172,0.048,0.261,0.066c0.184,0.037,0.369,0.061,0.554,0.084
			c0.064,0.008,0.127,0.022,0.191,0.029c0.251,0.025,0.501,0.038,0.751,0.038c0,0,0,0,0.001,0h118.793l100.983,20.824
			c0.504,0.104,1.013,0.155,1.52,0.155c1.458,0,2.898-0.424,4.135-1.239c1.666-1.097,2.828-2.81,3.233-4.765l59.716-289.575
			c0.838-4.069-1.779-8.048-5.848-8.886c-4.068-0.835-8.047,1.779-8.886,5.848l-58.196,282.207l-87.194-17.981v-16.207l18.921,2.987
			c0.35,0.055,0.7,0.077,1.05,0.082c0.041,0.001,0.082,0.01,0.123,0.01c0.033,0,0.065-0.007,0.099-0.008
			c0.345-0.004,0.688-0.029,1.028-0.081c0.052-0.008,0.104-0.021,0.156-0.03c0.313-0.054,0.621-0.128,0.925-0.222
			c0.053-0.016,0.107-0.029,0.16-0.047c0.325-0.108,0.642-0.241,0.952-0.393c0.066-0.032,0.13-0.067,0.196-0.102
			c0.313-0.166,0.619-0.346,0.909-0.557c1.614-1.173,2.694-2.94,3.006-4.91l54.917-347.858l80.777,16.657l-11.671,56.598
			c-0.838,4.069,1.779,8.048,5.848,8.886c4.067,0.833,8.047-1.779,8.886-5.848l13.19-63.964
			C502.014,118.535,499.397,114.557,495.328,113.718z M108.489,439.23l-62.588-26.59l114.849-16.576l21.158,74.358L108.489,439.23z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </Icon>
);
