import { Button, SimpleGrid, Text } from "@chakra-ui/react";
import {
  applyCap,
  arrangeSyllable,
  ECap,
  isEdgeCaseSound,
  TSound,
  VowelPosition
} from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { Percent, takeRandoms, tupleOf } from "@imaldev/imal-factory/ts";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import { produce } from "immer";
import { assoc, mergeRight, pipe as p, range, take, toLower, __ } from "ramda";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { localesDisabledEverywhere, useGlobalTxt } from "../../../../../../i18n/useGlobalTxt";
import { cs } from "../../../../../../styles/colors";
import {
  ETaskFontVariant,
  getDefaultTypefaceName,
  TaskFont
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import {
  getTaskFont,
  taskTypefaceByName,
  taskTypefacesByLocale
} from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useDefaultSequence } from "../../../context/AppSheetsAbcUserData/contextDefaultSequence";
import { useSheets } from "../../contexts/ContextSheets";
import { useWords } from "../../contexts/WordsContext";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { Popover } from "../shared/components/settings/Popover/MaterialUI/PopoverMaterialUI";
import { CheckboxCountSelector } from "../shared/components/settings/SelectCheckboxCount/SelectCheckboxCount";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import {
  EGuidelines,
  SelectGuidelineSetting
} from "../shared/components/settings/SelectGuidelineSetting/SelectGuidelines";
import { SelectCap } from "../shared/components/settings/SelectSound/SelectCapitalization";
import { SelectSounds } from "../shared/components/settings/SelectSounds/SelectSounds";
import { SettingButton } from "../shared/components/settings/SettingButton/SettingButton";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { CheckboxSVG } from "../shared/components/sheets/Checkbox/CheckboxSVG";
import { SectionHeader } from "../shared/components/sheets/SectionHeader/SectionHeader";
import { Sound } from "../shared/components/sheets/Sound/Sound";
import {
  deriveRowSounds,
  mkWriteSoundRow,
  SettingsIcon,
  TWithWriteSoundRows,
  WriteSoundRow
} from "../shared/components/sheets/WriteSoundRow";
import {
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import {
  setGuidelineSetting,
  TWithGuidelinesSetting
} from "../shared/sheetTypes/guidelinesSetting";
import { regenSoundGrid, TWithSoundGrid } from "../shared/sheetTypes/soundGrid/soundGrid";
import {
  regenSyllables,
  regenSyllablesM,
  WithSyllables
} from "../shared/sheetTypes/syllables/withSyllables";
import { setCap, TWithCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried } from "../shared/sheetTypes/withFocusedSound";
import { EFontSize, setFont, TWithFont } from "../shared/sheetTypes/withFont";
import { enableSoundsToFocused, toggleSound } from "../shared/sheetTypes/withSounds";
import {
  mkWordObj,
  ProviderSelectableWords,
  setWords,
  useSelectableWords,
  WithReadingWords
} from "../shared/sheetTypes/withWords";
import { Line, PopoverSoundInRow } from "../WriteSoundRows";

/* TODO: Maybe nest some props by sheet section; */
/* syllables.cap, syllables.vowelFirst etc. */
/* Will improve readability. */

/* Need fn for getting words for sheet. */

export type TWrite2xAndRead3x = TNewSheetType<ESheet.WSR_2x_AND_READ_3X> &
  TWithCap &
  TWithFont &
  TWithGuidelinesSetting &
  TWithSoundGrid &
  WithSyllables &
  WithReadingWords &
  TWithWriteSoundRows<2> & {
    countBoxesSoundGrid: number;
    countBoxesSyllables: number;
    countBoxesWords: number;
    guidelinesSetting: EGuidelines;
  };

export const fnMkSheet: MkSheet<TWrite2xAndRead3x> = (arg) => {
  const { locale, pSheet: partSheet } = getDefaultArgs(arg);
  const defaults: TWrite2xAndRead3x = {
    ...mkAbstractSheet(),
    cap: ECap.NORMAL,
    countBoxesSoundGrid: 1,
    countBoxesSyllables: 1,
    countBoxesWords: 1,
    focusedSound: "e",
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    guidelinesSetting: EGuidelines.ALL_WITH_XAREA_BLUE,
    name: ESheet.WSR_2x_AND_READ_3X,
    rows: tupleOf(mkWriteSoundRow(), 2),
    sounds: [],
    soundGrid: [
      ["e", "sch", "i", "t", "e", "pf", "m", "e", "s", "e"],
      ["ch", "p", "t", "e", "ng", "i", "e", "t", "y", "st"]
    ],
    soundGridCap: null,
    syllables: [
      // TODO: create default syllable array (with map?)?
      ["e", "k"],
      ["e", "p"],
      ["e", "b"],
      ["e", "m"],
      ["e", "s"],
      ["e", "r"],
      ["e", "d"],
      ["e", "g"]
    ],
    syllablesCap: null,
    syllablesVowelPosition: "last",
    usesShortWords: false,
    words: tupleOf({ value: "" }, 6),
    wordsCap: null
  };

  let sheet = mergeRight(defaults, partSheet);

  /* Regen some props which are not provided in `partSheet`. */
  if (!partSheet.syllables) sheet = regenSyllables(arg!.locale!)(sheet);

  if (!partSheet.soundGrid) sheet = regenSoundGrid(sheet);
  /* if (!partSheet.words) { */
  /* sheet = setWords( */
  /* () => take(5,  getWords({
       *   locale,
       *   taskWords: getTaskWords(arg?.locale ?? ELocale.de_DE)
         })))(sheet); */
  /* } */

  return sheet;
};

/* These should really be constants... don't want to run these fns every time we go to menu... */
export const menuSheets: TMenuSheetsFor<ESheet.WSR_2x_AND_READ_3X> = {
  [ELocale.de_DE]: fnMkSheet({
    pSheet: {
      soundGrid: [
        ["e", "sch", "i", "t", "e", "pf", "m", "e", "s", "e"],
        ["ch", "p", "t", "e", "ng", "i", "e", "t", "y", "st"]
      ],
      words: ["Oma", "Ameise", "Lama", "singen", "Haus", "Lila"].map(
        mkWordObj
      ) as TWrite2xAndRead3x["words"],
      syllables: [
        ["e", "k"],
        ["e", "p"],
        ["e", "b"],
        ["e", "m"],
        ["e", "s"],
        ["e", "r"],
        ["e", "d"],
        ["e", "g"]
      ]
    }
  }),
  [ELocale.es_ES]: fnMkSheet({
    locale: ELocale.es_ES,
    pSheet: {
      soundGrid: [
        ["e", "sch", "i", "t", "e", "pf", "m", "e", "s", "e"],
        ["ch", "p", "t", "e", "ng", "i", "e", "t", "y", "st"]
      ]
    }
  })
};

type PropsCheckboxes = {
  count: number;
  title: string;
  x?: Percent;
  y?: Percent;
};

/* TODO: refactor to Checkbox? */
export const Checkboxes = ({ count, title, x = 34, y = 0 }: PropsCheckboxes) => {
  const X_OFFSET = 8;
  const xPositions = range(0, 3).map((val) => `${x + X_OFFSET * val}%`);

  /* TODO: better to wrap in an svg, with `x = 0`. */
  return (
    <>
      <text fontFamily="arial" fontWeight="bold" fontSize={2.45} x={4} y={`${y + 3}%`}>
        {title}
      </text>
      <svg y={`${y}%`}>
        {take(count, xPositions).map((xPos, i) => (
          <CheckboxSVG key={i} x={xPos} />
        ))}
      </svg>
    </>
  );
};

type Props = {
  cap: ECap;
  font?: TaskFont;
  /* TODO?: highlightVowels: boolean; */
  soundPair: [TSound, TSound];
  vowelPosition: VowelPosition;
  x: number;
};

export const Syllable = ({
  cap,
  font = taskTypefaceByName.germanBayern.fonts.regular!,
  soundPair,
  vowelPosition = "first",
  x
}: Props) => {
  const { locale } = useLocale();
  return (
    <text fontFamily={font.fontFamily} fontSize="6" x={`${x}%`} y="72%">
      {applyCap(arrangeSyllable(soundPair, vowelPosition, locale), cap)}
    </text>
  );
};

/* Maybe a nice pattern is to have all required fn available in the Editor, */
/* and then pass them down to Sheet setting components with hooks. */

/* Couple this with not needing to generate words, sound grids etc. etc. during fnMkSheet's. */

/* TODO?: potential try to include 2 words with focused sound. */

export const Editor = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TWrite2xAndRead3x };
  const { updateSheet } = useSheets();
  const { locale } = useLocale();
  const { defaultSequence } = useDefaultSequence();

  const { getWords } = useWords();

  return (
    <ProviderSelectableWords words={getWords({ sounds: sheet.sounds })}>
      <EditorContainer
        sheet={sheet}
        settings={
          <>
            <PopoverSoundInRow />
            <SelectSounds
              cap={sheet.cap}
              focusedSound={sheet.focusedSound}
              selectedSounds={sheet.sounds}
              onSelectSound={p(
                setFocusedSoundCurried(sheet),
                enableSoundsToFocused(defaultSequence),
                regenSoundGrid,
                regenSyllablesM(locale),
                setWords((s: TWrite2xAndRead3x) => takeRandoms(6, getWords({ sounds: s.sounds }))),
                updateSheet
              )}
              setCap={p(setCap(sheet), updateSheet)}
              toggleSound={p(
                toggleSound(sheet),
                regenSoundGrid,
                regenSyllablesM(locale),
                setWords((s: TWrite2xAndRead3x) =>
                  takeRandoms(6, getWords({ mainSound: s.focusedSound, sounds: s.sounds }))
                ),
                updateSheet
              )}
            />
            <SelectFont
              activeFontVariant={sheet.font.variant}
              activeTypeface={sheet.font.typeface}
              includeBlank
              setFont={p(setFont(sheet), updateSheet)}
              typefaces={taskTypefacesByLocale[locale]}
            />
            <SelectGuidelineSetting
              activeSetting={sheet.guidelinesSetting}
              setSetting={p(setGuidelineSetting(sheet), updateSheet)}
            />
          </>
        }
      />
    </ProviderSelectableWords>
  );
};

const translations = {
  [ELocale.de_DE]: {
    i_have_read_x_times: (x: number) =>
      `Ich habe ${{ 1: "ein", 2: "zwei", 3: "drei" }[x]}mal gelesen`,
    read_aloud_and_follow_with_finger: "Lies jeden Laut und folge mit dem Finger",
    write_and_say_every_sound_on_each_line: "Schreibe und sprich jeden Laut"
  },
  [ELocale.es_ES]: {
    i_have_read_x_times: (x: number) => `I have read ${x} time${x > 1 ? "s" : ""}`,
    read_aloud_and_follow_with_finger: "Read aloud and follow with finger",
    write_and_say_every_sound_on_each_line: "Escriba y diga cada sonido en cada linea"
  }
};

type PropsGridSound = {
  cap: ECap;
  font: TaskFont;
  sound: string;
  x: string;
};

/* Unneccessary wrapper? */
const GridSound = ({ cap, font, sound, x }: PropsGridSound) => (
  <svg x={x} y="25" height="8">
    <Sound cap={cap} font={font} sound={sound} />
  </svg>
);

export const Sheet: SheetPreview<ESheet.WSR_2x_AND_READ_3X> = ({
  sheetProps: sheet,
  interactive = false
}) => {
  const { txt } = useTxt(translations);
  const readingFont = taskTypefaceByName[sheet.font.typeface].fonts["regular"]!;
  const rowYMargin = 17;
  const WORD_SEP: Percent = 8;
  let syllableX: Percent = -4;

  /* Rename and use new getWordX() for both syllables and words?  */
  /* Could potentially use an inline `.reduce` */
  /* TODO: This ain't working right. Different rendering in sheet menu and sheet editor. */
  const getSyllableX = (syllableLength: number, cap: ECap) => {
    if (syllableX > 80) return 9001;
    const value = syllableX + WORD_SEP;
    const wordWidth = syllableLength * (cap === ECap.UPPER ? 2.75 : 2);
    syllableX = value + wordWidth;
    return value;
  };

  return (
    <BlankSheet logoSize={"small"}>
      <SectionHeader text={txt.write_and_say_every_sound_on_each_line} y="-13.5%" />
      <svg y="4%">
        {sheet.rows.map((row, i) => (
          <WriteSoundRow
            cap={row.cap ?? sheet.cap}
            editable={interactive}
            rowSounds={deriveRowSounds(sheet, i)}
            fontSize={EFontSize.MEDIUM}
            guidelinesSetting={row.guidelinesSetting ?? sheet.guidelinesSetting}
            key={i}
            iRow={i}
            sound={row.focusedSound || sheet.focusedSound}
            y={`${2 + rowYMargin * i}%`}
          />
        ))}
      </svg>

      <SectionHeader y="27%" text={txt.read_aloud_and_follow_with_finger} />
      {/* Sound grid. */}
      {/* TODO: increase spacing when cap is all upper? */}
      {["30%", "36%"]
        .map((yGridRow, i) => (
          <svg key={i} y={yGridRow}>
            {(sheet.soundGrid[i] ?? []).map((sound, j) => (
              <GridSound
                cap={sheet.soundGridCap || sheet.cap}
                font={readingFont}
                key={j}
                sound={sound}
                x={`${-41 + 9 * j}`}
              />
            ))}
          </svg>
        ))
        .flat()}
      {interactive && <SoundGridPopoverStuff />}
      <Checkboxes
        count={sheet.countBoxesSoundGrid}
        title={txt.i_have_read_x_times(sheet.countBoxesSoundGrid)}
        y={60.55}
      />
      {/* Syllables. */}
      {sheet.syllables.map((sp, i) => (
        <Syllable
          cap={sheet.syllablesCap ?? sheet.cap}
          font={readingFont}
          key={i}
          soundPair={sp}
          vowelPosition={sheet.syllablesVowelPosition}
          x={getSyllableX(sp.join("").length, sheet.syllablesCap ?? sheet.cap)}
        />
      ))}
      {interactive && <SyllablesPopoverStuff />}
      <Checkboxes
        count={sheet.countBoxesSyllables}
        title={txt.i_have_read_x_times(sheet.countBoxesSyllables)}
        y={75}
      />

      {/* Words. */}
      {sheet.words.length > 0 && (
        <Words
          cap={sheet.wordsCap ?? sheet.cap ?? null}
          font={readingFont}
          words={sheet.words.map((wObj) => wObj.value)}
        />
      )}
      {interactive && <ReadingWordsPopoverStuff />}
      <Checkboxes
        count={sheet.countBoxesWords}
        title={txt.i_have_read_x_times(sheet.countBoxesWords)}
        y={89.7}
      />
    </BlankSheet>
  );
};

/* Maybe can refactor it like this: */
/* Generic settings icon component for sheets. */
/* Settings icon has prop taking popover to show on click.  */

/* This is stupid. */
export const ReadingWordsPopoverStuff = () => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  return (
    <>
      <PopoverReadWords anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <SettingsIcon anchorEl={anchorEl} setAnchorEl={setAnchorEl} x="95%" y="35.4%" />
    </>
  );
};

/* Sheet state should probaly be an arg. */
const PopoverReadWords = ({
  anchorEl,
  setAnchorEl
}: {
  anchorEl: SVGSVGElement | null;
  setAnchorEl: (node: SVGSVGElement | null) => void;
}) => {
  const { txt } = useTxt({
    [ELocale.en_US]: {
      new_words: "New words"
    },
    [ELocale.de_DE]: {
      new_words: "Neue Wörter"
    }
  });
  const { activeSheet: sheet } = useSheets() as { activeSheet: TWrite2xAndRead3x };
  const { updateSheet } = useSheets();

  const open = Boolean(anchorEl);
  const id = open ? "wordSoundRowPopover" : undefined; // wtf is this for?

  const close = () => setAnchorEl(null);

  const words = useSelectableWords();

  return (
    <Popover anchorEl={anchorEl} close={close} id={id}>
      <Popover.Body>
        <Popover.Section display="flex" justifyContent="flex-start">
          <CloseButton close={close} />
        </Popover.Section>
        <Popover.Section>
          <Button
            bg={cs.TURQUOISE}
            onClick={() => updateSheet(setWords(() => takeRandoms(6, words))(sheet) as any)}
          >
            <Text as="b">{txt.new_words}</Text>
          </Button>
        </Popover.Section>
        <Line />
        <Popover.Section>
          <SelectCap
            activeCap={sheet.wordsCap}
            disableFirstUpper
            setCap={(cap) =>
              updateSheet(assoc("wordsCap", sheet.wordsCap === cap ? null : cap, sheet))
            }
            toggleable
          />
        </Popover.Section>
        <Line />
        <Popover.Section>
          <CheckboxCountSelector
            selectedCount={sheet.countBoxesWords ?? null}
            setCount={p(assoc("countBoxesWords", __, sheet), updateSheet)}
          />
        </Popover.Section>
      </Popover.Body>
    </Popover>
  );
};

/* Actually, could make icon take a render prop for the contents of the popover. */

/* Should be renamed... is not the popover, but provides all the popover stuff. */
export const SoundGridPopoverStuff = () => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  return (
    <>
      <SoundGridPopover iRow={0} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <SettingsIcon x="95%" y="3%" anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

type PropsPopoverSoundGrid = {
  anchorEl: SVGSVGElement | null;
  iRow: number;
  setAnchorEl: React.Dispatch<React.SetStateAction<SVGSVGElement | null>>;
};

const SoundGridPopover = ({ anchorEl, setAnchorEl }: PropsPopoverSoundGrid) => {
  const { activeSheet: sheet } = useSheets() as {
    activeSheet: TWrite2xAndRead3x;
  };
  const { updateSheet } = useSheets();

  const { txt } = useTxt({
    [ELocale.en_US]: {
      new_sounds: "New sounds"
    },
    [ELocale.de_DE]: {
      new_sounds: "Neue Laute"
    }
  });

  return (
    <Popover anchorEl={anchorEl} close={() => setAnchorEl(null)}>
      <Popover.Body>
        <Popover.Section display="flex" justifyContent="flex-start">
          <CloseButton close={() => setAnchorEl(null)} />
        </Popover.Section>
        <Popover.Section>
          <Button bg={cs.TURQUOISE} onClick={() => updateSheet(regenSoundGrid(sheet))}>
            {txt.new_sounds}
          </Button>
        </Popover.Section>
        <Line />
        <Popover.Section>
          <SelectCap
            activeCap={sheet?.soundGridCap}
            disableFirstUpper
            setCap={(cap) =>
              /* Don't need fn; only used here. */
              updateSheet(
                produce(sheet, (sheet) => {
                  sheet.soundGridCap = sheet.soundGridCap === cap ? null : cap;
                })
              )
            }
            toggleable
          />
        </Popover.Section>
        <Line />
        <Popover.Section>
          <CheckboxCountSelector
            selectedCount={sheet?.countBoxesSoundGrid ?? null}
            setCount={p(assoc("countBoxesSoundGrid", __, sheet), updateSheet)}
          />
        </Popover.Section>
      </Popover.Body>
    </Popover>
  );
};
export const CloseButton = ({ close }: { close: () => void }) => (
  <Button bg="black" color="white" m="0" onClick={close} p="0" w="1rem">
    <FaTimes />
  </Button>
);

export const SyllablesPopoverStuff = () => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  return (
    <>
      <SyllablesPopoverContent anchorEl={anchorEl} close={() => setAnchorEl(null)} />
      <SettingsIcon anchorEl={anchorEl} setAnchorEl={setAnchorEl} x="95%" y="21.2%" />
    </>
  );
};

export const SyllablesPopoverContent = ({
  anchorEl,
  close
}: {
  anchorEl: SVGSVGElement | null;
  close: () => void;
}) => {
  const { txt } = useTxt({
    [ELocale.de_DE]: {
      new_syllables: "Neue Silben",
      flip: "Flippen"
    },
    [ELocale.en_US]: {
      new_syllables: "New syllables",
      flip: "Flip"
    }
  });

  const { activeSheet: sheet } = useSheets() as { activeSheet: TWrite2xAndRead3x };

  const { updateSheet } = useSheets();
  const { locale } = useLocale();

  return (
    <Popover anchorEl={anchorEl} close={close}>
      <Popover.Body>
        <Popover.Section display="flex" justifyContent="flex-start">
          <CloseButton close={close} />
        </Popover.Section>
        <Popover.Section>
          <Button
            bg={cs.TURQUOISE}
            w="100%"
            onClick={() => updateSheet(regenSyllables(locale, sheet))}
          >
            <Text as="b">{txt.new_syllables}</Text>
          </Button>
        </Popover.Section>
        <Line />
        <Popover.Section>
          <SelectVowelPosition
            activePosition={sheet.syllablesVowelPosition}
            disabled={isEdgeCaseSound(locale, sheet.focusedSound)}
            setVowelPosition={p(assoc("syllablesVowelPosition", __, sheet) as any, updateSheet)}
          />
        </Popover.Section>
        <Line />
        <Popover.Section>
          <SelectCap
            activeCap={sheet.syllablesCap}
            setCap={(cap) =>
              updateSheet(
                assoc("syllablesCap", sheet.syllablesCap === cap ? null : cap, sheet) as any
              )
            }
            toggleable
          />
        </Popover.Section>
        <Line />
        <Popover.Section>
          <CheckboxCountSelector
            selectedCount={sheet.countBoxesSyllables}
            setCount={p(assoc("countBoxesSyllables", __, sheet) as any, updateSheet)}
          />
        </Popover.Section>
      </Popover.Body>
    </Popover>
  );
};
type PropsSelectVowelPosition = {
  activePosition: VowelPosition;
  disabled: boolean;
  setVowelPosition: (placement: VowelPosition) => void;
};

const SelectVowelPosition = ({
  activePosition,
  disabled,
  setVowelPosition
}: PropsSelectVowelPosition) => {
  const { txt_g } = useGlobalTxt();

  return (
    <SimpleGrid gap="1vmin" templateColumns="repeat(2, auto)" height="5vh">
      {(["first", "last"] as VowelPosition[]).map((placement, i) => (
        <SettingButton
          active={activePosition === placement}
          disabled={disabled}
          key={i}
          onClick={!disabled ? undefined : () => setVowelPosition(placement)}
        >
          {`${txt_g.vowel} ${toLower(txt_g[placement])}`}
        </SettingButton>
      ))}
    </SimpleGrid>
  );
};

export const Words = ({
  words,
  font,
  cap
}: {
  words: string[];
  font: TaskFont;
  cap: ECap | null;
}) => {
  let x = 4;
  const WORD_SEP = 8;
  const MAX_WORD_X = 80;
  return (
    /* How to solve this... */
    /* Try count letters in word, and add to a counter for each letter. */
    /* WTF is even going on in this component. */
    <>
      {words.map((word, i, a) => {
        if (i) {
          x += WORD_SEP + a[i - 1].length * (cap === ECap.UPPER ? 2.8 : 2.2);
        }

        return x > MAX_WORD_X ? null : (
          <text fontFamily={font.fontFamily} fontSize={6} key={i} x={x} y="86.5%">
            {cap === ECap.NORMAL || !cap ? word : applyCap(word, cap)}
          </text>
        );
      })}
    </>
  );
};

export const configWSR2xAndRead3x: TSheetConfig<ESheet.WSR_2x_AND_READ_3X> = {
  disabledLocales: localesDisabledEverywhere,
  Editor,
  videos: {},
  fnMkSheet,
  menuSheets,
  Sheet: Sheet
};
