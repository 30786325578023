export type TRole = "admin" | "courseHolder" | "schoolAdmin" | "userAdmin";

/* Eventually, should create thorough documentation for */
/* role/permissions/access system. */
/* This must be clear! */

export enum EPermission {
  VIEW_USERS = "viewUsers",
  CREATE_USER = "createUser",
  CREATE_ADMIN_USER = "createAdminUser",
  REVOKE_ACCESS = "revokeAccess"
}

export const roleToPermissions: Record<TRole, EPermission[]> = {
  admin: Object.values(EPermission),
  courseHolder: [],
  schoolAdmin: [],
  userAdmin: [EPermission.REVOKE_ACCESS, EPermission.VIEW_USERS]
};
