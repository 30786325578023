import { Box, ChakraProps, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";

type Props = ChakraProps & {
  numbered?: boolean;
  slides?: ReactNode[];
};

export const Carousel = ({ numbered = false, slides = [], ...rest }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesCount = slides.length;

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };
  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };
  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`
  };

  const SLIDES_INTERVAL_TIME = 3000;
  const ANIMATION_DIRECTION = "right";

  useEffect(() => {
    const automatedSlide = setInterval(() => {
      ANIMATION_DIRECTION.toLowerCase() === "left" ? prevSlide() : nextSlide();
    }, SLIDES_INTERVAL_TIME);
    return () => clearInterval(automatedSlide);
  }, []);

  /* How to pass slides, but keep numbers and stuff. */
  return (
    <Flex m="auto" w="full" alignItems="center" justifyContent="center" {...rest}>
      <Flex overflow="hidden">
        <Flex pos="relative" w="100%" {...carouselStyle}>
          {slides.map((slide, i) => (
            <Box key={i} flex="none" boxSize="full" shadow="md">
              {numbered && (
                <Text
                  color="#555"
                  fontSize="xs"
                  p="8px 12px"
                  pos="absolute"
                  top="0"
                  whiteSpace="nowrap"
                >
                  {i + 1} / {slidesCount}
                </Text>
              )}
              {slide}
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
