// Colors
/* TODO: Color scheme should perhaps be a double layered system? */
/* dict with colors, and then can create themes on top of this dict. */

/* TODO: read about color+number system, as used with chakra-ui theme docs */
/* and tailwind-css; eg. white.200 */
/* What is the name of this? */

/* TODO: use Chakra UI for colors (and theming?). */
/* Try it in exp project first? */

export enum colorScheme {
  BLACK = "#101010",
  GRAY = "#B1B1B1",
  GRAY_DARK = "#919191",
  GRAY_LIGHT = "#D1D1D1",
  NAVY = "#04485f",
  NAVY_LIGHT = "#14586f",
  PERSIAN_GREEN = "#00A896",
  PUMPKIN = "#92828D",
  RED_SALMON = "#C14548",
  RED = "#b82c2e",
  RED_WEAK = "#c78a8b",
  SAFRON = "#F4C430",
  ORANGE_LIGHT = "#ffae21",
  TURQUOISE = "#428095",
  TURQUOISE_DARK = "#03455C",
  TURQUOISE_LIGHT = "#72ADC1",
  GREEN_DARK = "#0D5920",
  GREEN = "#0b8128",
  GREEN_LIGHT = "#D2DEE2",
  WHITE = "#FAFAFA",

  BUTTON_DEFAULT = "#eee",
  BUTTON_HOVER = "lightgray",
  TEXT_DEFAULT = "black",
  TEXT_SELECTED = "white",
  SEQUENCE_LIMIT = "#d2d2d2",
  BORDER = "#bfbfbf",
  HOVER_BACKGROUND = "#c1c1c1"
}

/* Always wanna import as cs? colorScheme is bloated af. */

export { colorScheme as cs };
