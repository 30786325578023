import { applyCap, ECap } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import * as R from "ramda";
import { localesDisabledEverywhere } from "../../../../../../i18n/useGlobalTxt";
import {
  ETaskFontVariant,
  getDefaultTypefaceName
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import {
  getTaskFont,
  taskTypefacesByLocale
} from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useSheets } from "../../contexts/ContextSheets";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import {
  EGuidelines,
  getXAreaBg,
  SelectGuidelineSetting
} from "../shared/components/settings/SelectGuidelineSetting/SelectGuidelines";
import { SelectSound } from "../shared/components/settings/SelectSound/SelectSound";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { Guidelines } from "../shared/components/sheets/Guidelines/Guidelines";
import { Sound } from "../shared/components/sheets/Sound/Sound";
import { getGuidelineNames } from "../shared/components/sheets/WriteSoundRow";
import {
  AbstractSheet,
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TSheetConfig
} from "../shared/misc";
import { setGuidelineSetting } from "../shared/sheetTypes/guidelinesSetting";
import { setFocusedSoundCurried, TWithFocusedSound } from "../shared/sheetTypes/withFocusedSound";
import { setFont, TWithFont } from "../shared/sheetTypes/withFont";

export type TLetterLargeAndSmall = AbstractSheet &
  TWithFocusedSound &
  TWithFont & {
    guidelinesSetting: EGuidelines;
    name: ESheet.LETTER_LARGE_AND_SMALL;
  };

export const newSheetOneLetterLargeAndSmall: MkSheet<TLetterLargeAndSmall> = (arg) => {
  const { locale, pSheet } = getDefaultArgs(arg);
  return {
    ...mkAbstractSheet(),
    focusedSound: "e",
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    guidelinesSetting: EGuidelines.ALL_WITH_XAREA_BLUE,
    name: ESheet.LETTER_LARGE_AND_SMALL,
    ...pSheet
  };
};

/* TODO: try to do one of these with assoc and ELocale array, and config objects. */
export const menuSheetsOneLetterLargeAndSmall: TMenuSheetsFor<ESheet.LETTER_LARGE_AND_SMALL> = {
  [ELocale.de_DE]: newSheetOneLetterLargeAndSmall({
    locale: ELocale.de_DE
  }),
  [ELocale.en_US]: newSheetOneLetterLargeAndSmall({
    locale: ELocale.en_US,
    pSheet: {
      font: getTaskFont(
        /* Probably use regular as default for all locales... */
        getDefaultTypefaceName(ELocale.en_US),
        ETaskFontVariant.REGULAR
      )
    }
  }),
  [ELocale.es_ES]: newSheetOneLetterLargeAndSmall({
    locale: ELocale.es_ES,
    pSheet: {
      font: getTaskFont(getDefaultTypefaceName(ELocale.es_ES), ETaskFontVariant.REGULAR)
    }
  })
};

/* This is kinda dirty... */
/* Create a dict which has spacing by letter-width. spacing-by-collection. */
const conditionallyGetSpace = (sound: string) => {
  if (["i", "l", "j", "s", "z", "f", "r", "t", "e", "p", "b", "h", "c", "y"].includes(sound))
    return " ";
  return "";
};

/* TODO: fix bug with initial sound. Depending on sequence, a multiletter */
/* sound may be selected.  */
export const EditorOneLetterLargeAndSmall = () => {
  const { activeSheet: sheet } = useSheets() as {
    activeSheet: TLetterLargeAndSmall;
  };
  const { updateSheet } = useSheets();
  const { locale } = useLocale();

  // want to present sound "vertically centered" if not using
  // guidelines. This means sounds will be rendered at a different y-value
  // depending on its height (A and W large, and with low y value, opposite
  // for i,a,e etc.)
  return (
    <EditorContainer
      sheet={sheet}
      settings={
        <>
          <SelectSound
            activeSound={sheet.focusedSound}
            hideCapSetting
            hideMultiGraph
            setSound={R.pipe(setFocusedSoundCurried(sheet), updateSheet)}
          />
          <SelectFont
            activeFontVariant={sheet.font.variant}
            activeTypeface={sheet.font.typeface}
            disabledFontVariants={[ETaskFontVariant.STARTPOINTS]}
            typefaces={taskTypefacesByLocale[locale]}
            setFont={R.pipe(setFont(sheet), updateSheet)}
          />
          <SelectGuidelineSetting
            activeSetting={sheet?.guidelinesSetting}
            setSetting={R.pipe(setGuidelineSetting(sheet), updateSheet)}
          />
        </>
      }
    />
  );
};

export const Sheet: SheetPreview<ESheet.LETTER_LARGE_AND_SMALL> = ({ sheetProps: sheet }) => {
  const soundWidth = 96;
  const marginWidth = (100 - soundWidth) / 2;

  const lines = getGuidelineNames(sheet.guidelinesSetting);

  return (
    <BlankSheet logoSize="normal">
      <svg x={`${marginWidth}%`} width={`${soundWidth + 1}%`}>
        <svg height="66%" y="17%">
          <Guidelines xAreaColor={getXAreaBg(sheet.guidelinesSetting)} lines={lines} />
        </svg>
        <Sound
          color={sheet.font.variant === ETaskFontVariant.REGULAR ? "gray" : "black"}
          font={sheet.font}
          sound={`${applyCap(sheet.focusedSound, ECap.UPPER)}${conditionallyGetSpace(
            sheet.focusedSound
          )}${sheet.focusedSound}`}
        />
      </svg>
    </BlankSheet>
  );
};

export const configLetterLargeAndSmall: TSheetConfig<ESheet.LETTER_LARGE_AND_SMALL> = {
  disabledLocales: localesDisabledEverywhere,
  Editor: EditorOneLetterLargeAndSmall,
  videos: {},
  fnMkSheet: newSheetOneLetterLargeAndSmall,
  menuSheets: menuSheetsOneLetterLargeAndSmall,
  Sheet
};
