import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { UUID } from "../../utilsTS/uuid/uuid";

/* I guess aaaall of this goes into package. */

/* Can omit "Task" prefix here and in `ETaskTypeface`? */
export enum ETaskFontVariant {
  DOTTED = "dotted",
  DOTTED_STARTPOINTS = "dottedStartpoints",
  OUTLINE = "outline",
  OUTLINE_STARTPOINTS = "outlineStartpoints",
  OUTLINE_NUMBERS = "outlineNumbers",
  REGULAR = "regular",
  STARTPOINTS = "startpoints",
  BLANK = "blank"
}

/* Values also used for fontFamily value in css file's @font-face's */
/* font-family field. */
export enum ETypeface {
  GERMAN_BAYERN = "germanBayern",
  GERMAN_GRUNDSCHRIFT = "germanGrundschrift",
  GERMAN_NORTH = "germanNorth",
  GERMAN_SOUTH = "germanSouth",
  NORDIC_SANS_SERIF = "nordicSansSerif",
  NORDIC_SERIF = "nordicSerif"
}

export type FontFamily = string;

export type TaskFont = {
  fontFamily: string;
  typeface: ETypeface;
  variant: ETaskFontVariant;
};

/* Should they also have an id? */
/* pro: */
/* - easier to rename fonts */
/* cons: */
/* - cannot read which tf we are using when looking at data. */
export type TTaskTypeface = {
  id: UUID;
  name: ETypeface;
  nameForDisplay: string;
  fonts: Partial<Record<ETaskFontVariant, TaskFont>>;
};

/* Should return the actual typeface, and not the name? */
export const getDefaultTypefaceName = (locale: ELocale) => {
  const defaultTypefaceByLocale: LocaleRecord<ETypeface> = {
    [ELocale.de_DE]: ETypeface.GERMAN_NORTH
  };
  return defaultTypefaceByLocale[locale] ?? ETypeface.NORDIC_SANS_SERIF;
};

/* TODO: need a new font thingy: */
/* a gray regular font, with startpoints overlapping it. */
/* How will this work... */
/* Will be trying something new: */
/* Have a font without startpoints be overlapped by a startpointOnly */
/* font (Means we export enum ETaskFontVariant {
   DOTTED = "dotted",
don't have to create as many fonts). */

/* Needs to belong to all typefaces? */
/* Do TaskFonts need the typeface field? */
export const blankFont: TaskFont = {
  fontFamily: "",
  typeface: null!,
  variant: ETaskFontVariant.BLANK
};

/* TODO: Replace this with blankFont object on each taskTypeface object. */
/* Which can be further improved with fn which creates typeface object. */
export const newBlankFont: (typeface: ETypeface) => TaskFont = (typeface) => ({
  ...blankFont,
  typeface
});
