import { Popover, PopoverBody, PopoverContent, Portal, useDisclosure } from "@chakra-ui/react";
import { applyCap, ECap, getSoundAtIndex, TWord } from "@imaldev/imal-factory/abc";
import { Percent } from "@imaldev/imal-factory/ts";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { useEffect, useMemo, useState } from "react";
import {
  ETaskFontVariant,
  TaskFont
} from "../../../../../../../../../utils/utilsiMAL/fonts/shared";
import { useBoundingRect } from "../../../../../../../../../utils/utilsReact/useBoundingRect";
import { useHover } from "../../../../../../../../../utils/utilsReact/useHover";
import { useImageByWord } from "../../../../../../context/WordImages";
import { SelectWordWithImage } from "../../settings/SelectWordWithImage/SelectWordWithImage";
import { WriteWord } from "../WriteWord/WriteWord";

/* Can we extrapolate how to display word text, based on `selectedSounds[]`? */
/* Will this make things slow? */
/* Perhaps don't need to compute it; already know if the word comes from filler-words-array. */
/* In that case, type of `words` will be: */
/* words: { value: Sound[]; useFirstOnly: boolean }; /* isFiller || onlyUseFirst */

/* This might be... something to implement when optimizing word sourcing with trie data struture. */
/* TODO: fix editor sizing being wrong when have this popover... getting small gap below editor. */
/* But in editors without this component have no gap. */
/* Maybe need to calulate better/properly how to position the popover; ie. such that no part of it */
/* appears outside of the editor container -> need some things for calc.: */
/* - dimensions and position of popover */
/* - dimensions and position of container */
/* - origin of anchor */
/* When any values in popovers positioning fall outside of editor container, adjust the popovers */
/* positioning values such that all of it fits inside the container. */

/* TODO: should maybe take a prop selected sounds. */
/* Reason: if showing words where only first is guaranteed to be known, can then in popover color the text */
/* in a way which tells the user that we are only showing the first letter of these words; */
/* ie. color sounds after sound with index 0 in a lighter color or with strike-through. */
/* Otherwise it will be hard for user to gauge which words consists of only known letters, and which ones */
/* will only have the first sound displayed. */

/* TODO: should also have setting for selecting fontVariant for word. */
/* Why use portal? */
/* TODO?: move to `SelectWordWithImage`. */
/* TODO: add some box shadow to the popover. */
/* TODO: word cells should have a colored border when this popover is open. */
/* Move to WordImage. */

type Props = {
  cap: ECap;
  column?: number;
  interactive?: boolean;
  font: TaskFont;
  fontPerSound: Array<TaskFont | null>;
  onlyFirstLetter?: boolean;
  row: number;
  setSoundFontVariant: (iSound: number, variant: ETaskFontVariant) => void;
  setWord?: (w: TWord) => void;
  word: TWord;
  /* Rename words? popoverWords? */
  /* Is this a nice place to use renderProp? */
  popoverWords?: Array<TWord>; // For when sheet is editable, and can select word by clicking img.
};

export const WriteWordInImageSmall = ({
  cap,
  column = 1,
  interactive,
  font,
  fontPerSound,
  onlyFirstLetter = false,
  row = 1,
  setSoundFontVariant,
  setWord = () => {},
  word,
  popoverWords = []
}: Props) => {
  const { imageByWord } = useImageByWord();
  const imageBytes = imageByWord[word];
  const { hoverHandlers, ...hover } = useHover();
  const { locale } = useLocale();
  const popover = useDisclosure();

  /* Is this whats messing things up? */
  /* To get perfect, have to make sure positioning is adjusted to fit inside the viewport. */
  const [posRef, bRect] = useBoundingRect<SVGImageElement>();
  const [pos, setPos] = useState({ left: 0, top: 0 });

  /* Should only do this stuff if editable?? */
  useEffect(() => {
    setPos({
      left: bRect.x + bRect.width * 1.015,
      top: bRect.y - bRect.height * 0.4
    });
  }, [posRef.current]);

  /* TODO: button to get random word/image. */

  const text = useMemo(
    () =>
      applyCap(
        onlyFirstLetter ? getSoundAtIndex({ index: 0, locale, word, preserveCap: true }) : word,
        cap
      ),
    [word, locale, cap]
  );

  const wordXByLength: Record<number, Percent> = {
    1: 38,
    2: 33,
    3: 22,
    4: 13,
    5: 7,
    6: -2,
    7: -11
  };

  return (
    <svg x={`${50 * column}%`} y={`${25 * row}%`} width="50%" height="24.5%">
      <rect width="100%" height="100%" fill="black" />
      <rect x="1%" y="1%" width="98%" height="99%" fill="white" />
      {interactive && (
        <PopoverSelectWordWithImage
          activeWord={word}
          isOpen={popover.isOpen}
          onClose={popover.onClose}
          setWord={setWord}
          words={popoverWords}
          x={pos.left}
          y={pos.top}
        />
      )}
      {imageBytes && (
        <image
          {...hoverHandlers}
          cursor={interactive && hover.hasHover ? "pointer" : "default"}
          height="65%"
          href={`data:image/png;base64,${imageBytes}`}
          onClick={popover.onOpen}
          ref={posRef}
          style={{ transition: ".12s" }}
          transform={interactive && hover.hasHover ? "scale(1.07)" : undefined}
          transform-origin="50% 50%"
          width="100%"
          y="3%"
        />
      )}
      <svg x={`${wordXByLength[text.length] ?? 1}%`} y="1">
        <WriteWord
          editable={interactive}
          fallbackFont={font}
          fontPerSound={fontPerSound}
          setFontVariantForSound={setSoundFontVariant}
          word={text}
        />
      </svg>
    </svg>
  );
};

type PropsPopoverSelectWordWithImage = {
  activeWord: TWord;
  isOpen: boolean;
  onClose: () => void;
  setWord: (w: TWord) => void;
  words: TWord[];
  x: number;
  y: number;
};

export const PopoverSelectWordWithImage = ({
  onClose = () => {},
  isOpen,
  ...props
}: PropsPopoverSelectWordWithImage) => {
  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <Portal>
        <PopoverContent w="30rem" left={`${props.x}px`} top={`${props.y}px`}>
          <PopoverBody>
            <SelectWordWithImage {...props} isCollapsible={false} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
