import {
  Box,
  Center,
  Flex,
  Icon as ChakraIcon,
  IconProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import VimeoVideo from "@u-wave/react-vimeo";
import { AnimatePresence } from "framer-motion";
import { any, equals } from "ramda";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { CgMenuGridR } from "react-icons/cg";
import { FaPrint } from "react-icons/fa";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Chevron, Collapsible } from "../../../../../components/Collapsable/Collapsible";
import { MotionBox } from "../../../../../components/MotionBox";
import { cs } from "../../../../../styles/colors";
import { useIsDirectlyOver } from "../../../../../utils/utilsReact/hooks/useIsDirectlyOver";
import { useHover } from "../../../../../utils/utilsReact/useHover";
import { useToggle } from "../../../../../utils/utilsReact/useToggle";
import { useSheets } from "../contexts/ContextSheets";
import { TTaskSheet } from "../taskSheets/TaskSheet";
import { DrawerSheet } from "./DrawerSheet";

export const SheetDrawer = () => {
  const { activeSheet, setIdActiveSheet, sheets, moveSheet } = useSheets();
  const { txt } = useTxt(translations);
  const [iDrag, setDragIndex] = useState<number | null>(null);
  const [isOpen, toggleIsOpen, setIsOpen] = useToggle(false);
  const { hoverHandlers, hasHover } = useHover(); // `_hover` does not suffice here.
  const [showingVideoPopover, toggleShowingVideoPopover] = useToggle();
  const navigate = useNavigate();
  const { locale } = useLocale();

  const [refBg, isDirectlyOver1] = useIsDirectlyOver();

  const [refLeftContent, hoveringLeftContent] = useIsDirectlyOver(); // Will remove this ref?
  const [refChevronBox, hoveringChevronBox] = useIsDirectlyOver(); // Not sure if need nr 3...
  const [refChevron, hoveringChevron] = useIsDirectlyOver();

  const canOpen = sheets.length > 0;

  /* Confusing name? */
  const canTriggerCollapse =
    canOpen &&
    any(equals(true), [isDirectlyOver1, hoveringLeftContent, hoveringChevronBox, hoveringChevron]);

  useEffect(() => {
    if (sheets.length === 0) setIsOpen(false);
  }, [sheets.length]);

  /* TODO: make icons look like buttons. */
  /* TODO: disable print button until images have been loaded? */
  /* TODO?: now when logo onClick has been disabled, perhaps make right hand side */
  /* of drawer (the part with buttons) not toggle drawer opening; to prevent user */
  /* accidentally opening the drawer when indent to print or navigate to menu. */
  /* Did AnimatePresence get deprecated in framer-motion 5? */
  return (
    <>
      <VideoModal
        isOpen={showingVideoPopover}
        onClose={toggleShowingVideoPopover}
        url={videoUrlByLocale[locale] ?? ""}
      />
      <AnimatePresence>
        {isOpen && (
          <MotionBox
            animate={{ opacity: 0.7 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
            bg="#111d"
            h="100%"
            left={0}
            pos="absolute"
            top={0}
            transition={{ duration: 0.12 }}
            w="100%"
          />
        )}
      </AnimatePresence>
      <Box
        bottom="0"
        boxShadow="0px 2px 14px #222"
        left="0"
        pos="absolute"
        right="0"
        userSelect="none"
      >
        <Collapsible
          bg={cs.NAVY}
          isOpen={isOpen}
          toggleIsOpen={canOpen ? toggleIsOpen : () => {}}
          overrideHoverHandlers={hoverHandlers}
          header={
            <SimpleGrid
              _hover={{ filter: "brightness(100%)" }}
              {...hoverHandlers}
              alignItems="center"
              bg={canOpen ? "#106D8E" : "#045"}
              borderY="1px #222 solid"
              borderTopRadius=".3rem"
              cursor={canTriggerCollapse ? "pointer" : "default"}
              columns={3}
              h={canOpen ? DrawerH.ACTIVE : DrawerH.INACTIVE}
              ref={refBg}
              boxSizing="content-box"
              px="1rem"
              transition="ease .23s"
            >
              <Flex alignItems="center" color="white" ref={refLeftContent}>
                {/* TODO: constant width. */}
                <Text fontSize="1.5rem" fontWeight="bold" mx="1rem" w="8rem">
                  {txt.x_pages_ready_to_print(sheets.length)}
                </Text>
                <SimpleGrid
                  alignItems="center"
                  columns={2}
                  justifyContent="space-between"
                  w="10rem"
                >
                  <Tooltip fontSize="md" label="Blätter ausdrucken" placement="top">
                    <Center h="fit-content">
                      <DrawerIcon
                        as={FaPrint}
                        isDisabled={sheets.length === 0}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("print");
                        }}
                      />
                    </Center>
                  </Tooltip>
                  <Tooltip fontSize="md" label="Blattmenü" placement="top">
                    <Center>
                      <DrawerIcon
                        as={CgMenuGridR}
                        isDisabled={activeSheet === null}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIdActiveSheet(null);
                        }}
                        fontSize={activeSheet === null ? "3rem" : "4.2rem"}
                      />
                    </Center>
                  </Tooltip>
                </SimpleGrid>
              </Flex>
              <Center
                ref={refChevronBox}
                transform={`
translateY(${getChevronYTrans(isOpen, canTriggerCollapse && hasHover, canOpen)}px)`}
                transition="transform .2s ease"
                w="100%"
              >
                <Box
                  transition="ease .35s"
                  transitionDelay=".13s"
                  transform={`rotate(${isOpen ? 0 : 180}deg)`}
                  pos="relative"
                >
                  {/* forwardRef() is hard, so using a foreground div. */}
                  <Box w="100%" h="100%" ref={refChevron} pos="absolute" />
                  <Chevron
                    {...hoverHandlers}
                    color={canOpen ? "#eee" : "#666"}
                    cursor={canOpen ? "pointer" : "default"}
                    double
                    fontSize={canOpen ? "3rem" : "2rem"}
                    transition="fontSize 0.1s ease-in-out"
                  />
                </Box>
              </Center>
              {/* TODO: this can be a reusable component. */}
              {videoUrlByLocale[locale] && (
                <ChakraIcon
                  as={HiQuestionMarkCircle}
                  _hover={{
                    cursor: "pointer",
                    filter: "brightness(110%)",
                    transform: "scale(1.25)"
                  }}
                  borderRadius="50%"
                  color="yellow"
                  filter="brightness(100%)"
                  fontSize="2.5rem"
                  justifySelf="flex-end"
                  mr="3rem"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleShowingVideoPopover();
                  }}
                  transition="ease .08s"
                />
              )}
            </SimpleGrid>
          }
        >
          <Flex bg="#225D71">
            <DragDropContext
              onDragEnd={(result: DropResult) => {
                // todo: extract fn? (on/handle)Change?
                if (!result.destination) return;
                moveSheet(result.source.index, result.destination.index);
                setDragIndex(null);
              }}
              onDragStart={(drag) => {
                setDragIndex(parseInt(drag.draggableId));
              }}
            >
              <Droppable direction="horizontal" droppableId={"previewDrawer"}>
                {(provided) => (
                  <Flex
                    ref={provided.innerRef}
                    overflowX="auto"
                    p="1rem"
                    w="100%"
                    {...provided.droppableProps}
                  >
                    {sheets.map((sheet, index) => (
                      <Draggable draggableId={index.toString()} index={index} key={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {/* TODO: abstract away react-beautiful-dnd components. */}
                            <DrawerSheet
                              closeDrawer={() => setIsOpen(false)}
                              isBeingDragged={iDrag === index}
                              key={index}
                              sheet={sheet as TTaskSheet}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Flex>
                )}
              </Droppable>
            </DragDropContext>
          </Flex>
        </Collapsible>
      </Box>
    </>
  );
};

export enum DrawerH {
  ACTIVE = "6em",
  INACTIVE = "4.5em"
}

/* TODO: should have min and max dimensions. Video should be sized accordingly. */
/* Can run VimeoVideo through chakra-factory? */
/* This shouldn't be here lmao. */
export const VideoModal = ({
  isOpen,
  onClose,
  url = ""
}: {
  isOpen: boolean;
  onClose: () => void;
  url: string;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody m="1rem">
          <Box>
            <VimeoVideo
              responsive
              style={{ height: "fill", marginBottom: "1rem", zIndex: 5 }}
              video={url}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

/* Could use chakra-factory here? */
/* e.propagation in here? */
const DrawerIcon = ({ isDisabled, ...rest }: IconProps & { as: any; isDisabled: boolean }) => {
  return (
    <ChakraIcon
      _hover={{
        cursor: isDisabled ? "default" : "pointer",
        filter: `brightness(${isDisabled ? "40" : "90"}%)`
      }}
      cursor={isDisabled ? "default" : "pointer"}
      filter={`brightness(${isDisabled ? "40" : "65"}%)`}
      fontSize="2.5rem"
      transition="ease .15s"
      {...rest}
    />
  );
};

const translations = {
  [ELocale.en_US]: {
    close: "Close",
    open: "Open",
    x_pages_ready_to_print: (x: number) => `${x} page${x === 1 ? "" : "s"}`
  },
  [ELocale.de_DE]: {
    close: "Schließen",
    open: "Öffnen",
    x_pages_ready_to_print: (x: number) => `${x} ${x === 1 ? "Blatt" : "Blätter"}`
  }
};

const getChevronYTrans = (isOpen: boolean, hasHover: boolean, canOpen = true) => {
  if (!canOpen) return -2;
  if (isOpen && !hasHover) return -10;
  if (isOpen && hasHover) return 3;
  if (!isOpen && hasHover) return -10;
  return 2;
};

/* TODO: use grid layout for workbook sheets always. */
/* Adjust height based on number of sheets. */

/* TODO: make sure collapsible styles are as they should be before pushing */
/* new remove-bootstrap changes to prod; */
/* - drawer bg */
/* - drawer header border/shadow */
/* - potentially also vertial size */

const videoUrlByLocale: LocaleRecord<string> = {
  [ELocale.de_DE]: "https://vimeo.com/634460644"
};
