import { TSound } from "@imaldev/imal-factory/abc";
import { arrayOf, dropIndex, takeRandom } from "@imaldev/imal-factory/ts";
import { produce } from "immer";
import _ from "lodash";
import {
  createGrid,
  Grid,
  GridSize,
  TGridPos
} from "../../../../../../../../../utils/utilsTS/grid/grid";

/* Creates collection with SIZE, ensuring all elements appear x or x+1 times. */
/* 'createEqualFrequencyCollection()', */
/* Could be made to be generic... Ie. don't need to use the word grid inside */
/* this function -> not relevant to what function is doing. */
export const createEqFreqCollection = (items: string[], size: number) => {
  let occuranceToItems: Record<number, TSound[]> = {
    0: _.uniq(items)
  };
  const getNextItem = () => {
    const lowestFreq = Math.min(...Object.keys(occuranceToItems).map((key) => parseInt(key)));
    const sound = takeRandom(occuranceToItems[lowestFreq]);

    occuranceToItems = produce(occuranceToItems, (ots) => {
      ots[lowestFreq] = occuranceToItems[lowestFreq].filter((withLowest) => withLowest !== sound);
      if (ots[lowestFreq + 1]) ots[lowestFreq + 1].push(sound);
      else ots[lowestFreq + 1] = [sound];
      if (ots[lowestFreq].length === 0) delete ots[lowestFreq];
    });
    return sound;
  };

  const collection: TSound[] = [];
  while (collection.length < size) {
    collection.push(getNextItem());
  }
  return collection;
};

/* Creates a grid with `sounds`, ensuring valid sound positions. */
export const createGridWithSounds = (
  sounds: TSound[], // sounds are not distinct
  columns: number,
  rows: number
) => {
  let remainingSounds = sounds;
  // Create empty grid. To be filled with sounds.
  let grid: Grid<TSound> = createGrid({ rows, columns });

  let sound: TSound; // next sound to insert
  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < columns; col++) {
      // valid sound for position [row,col]
      [sound, remainingSounds] = getSoundForGridPosition(grid, { row, col }, remainingSounds);

      //Place the sound in position [row, col]
      // grid[row][col] = sound;
      grid[row] = produce(grid[row], (row) => {
        row[col] = sound;
      });

      // todo: do this after entire grid is filled.
      if (!isSoundPositionValid(grid, { row, col })) {
        const swapTo = findSwapPos(grid, sound);
        if (swapTo) {
          grid = swap(grid, [{ row, col }, swapTo]);
        }
        /* if no swapTo, will have to make due with invalid grid ¯\_(ツ)_/¯ */
        /* Could happen if inserting too many focused. */
      }
    }
  }
  return grid;
};

/* TODO: This probably doesn't work 100% right now. */
/* Have to check that current position is valid for sound we are swapping with. */
/* Therefore, not enough to know sound. */
/* We need both sound and position of where we are swapping from. */
const findSwapPos = (grid: Grid<TSound>, sound: TSound) => {
  // Random indexes using stacks would be better?
  for (let row = 0; row < grid.length; row++) {
    for (let col = 0; col < grid[0].length; col++) {
      if (
        isSoundPositionValid(
          produce(grid, (grid) => {
            grid[row][col] = sound;
          }),
          { row, col }
        )
      ) {
        return { row, col };
      }
    }
  }
  return null;
};

/* Returns randomized collection of sounds for grid where majority */
/* occurunces is focusedSound. Remaining sounds appear of equal frequence. */
export const generateSoundInstances = (
  sounds: TSound[],
  focused: TSound,
  countToGenerate: number
) => {
  const countToGenerateFocused = Math.floor(countToGenerate * 0.4);
  const gridSounds = [
    ...arrayOf(focused, countToGenerateFocused),
    ...createEqFreqCollection(
      sounds.filter((sound) => sound !== focused),
      countToGenerate - countToGenerateFocused
    )
  ];
  return _.shuffle(gridSounds);
};

type GetSoundForGridPosition = (
  grid: Grid<TSound>,
  pos: TGridPos,
  sounds: TSound[]
) => [TSound, TSound[], number];

/* Returns a sound from SOUNDS which is valid for POS in GRID. */
/* If no sounds are valid for POS, an invalid sound is chosen. */
/* Return copy of SOUNDS with the valid sound.  */
export const getSoundForGridPosition: GetSoundForGridPosition = (grid, { col, row }, sounds) => {
  let iSelectedSound = sounds.findIndex((sound) => {
    const result = isSoundPositionValid(
      produce(grid, (grid) => {
        grid[row][col] = sound;
      }),
      { col, row }
    );
    return result === true;
  });

  if (iSelectedSound < 0) iSelectedSound = 0;

  return [sounds[iSelectedSound], dropIndex(sounds, iSelectedSound), iSelectedSound];
};

export const isSoundPositionValid = (grid: Grid<TSound>, pos: TGridPos) => {
  const positionsMatches = getDirectionsOfMatchingAdjacent(grid, pos);
  return positionsMatches.length === 0;
};

type Direction = "top" | "right" | "bottom" | "left";
export const getDirectionsOfMatchingAdjacent = (grid: Grid<TSound>, { col, row }: TGridPos) => {
  const directions: Direction[] = [];

  const sound = grid[row][col];
  if (row && grid[row - 1][col] === sound) directions.push("top");
  if (row < grid[0].length - 1 && grid[row][col + 1] === sound) directions.push("right");
  if (row < grid.length - 1 && grid[row + 1][col] === sound) directions.push("bottom");
  if (col && grid[row][col - 1] === sound) directions.push("left");

  return directions;
};

/* Swap the two cells in the grid. */
/* This could potentially be moved to the util file for grid stuff, and be */
/* rewritten as a generic function. */
export const swap = (
  grid: Grid<TSound>,
  positions: [{ row: number; col: number }, { row: number; col: number }]
) => {
  const { row: row1, col: col1 } = positions[0];
  const { row: row2, col: col2 } = positions[1];
  grid = _.cloneDeep(grid);
  const tmp = grid[row1][col1];
  grid[row1][col1] = grid[row2][col2];
  grid[row2][col2] = tmp;
  return grid;
};

/* -------------------------------------------------------------------------- */
/* ----- Entry point. ----- */
/* Only interface with this f() to create sound grids. */
/* TODO: countFocused should be a little lower such that its not so close */
/* to being 50-50 focused-other_sounds. */
type GenerateSoundGrid = (
  sounds: TSound[], // sounds only appear once
  focused: TSound,
  size?: Partial<GridSize>
) => Grid<TSound>;
export const generateSoundGrid: GenerateSoundGrid = (
  sounds,
  focused,
  { columns = 10, rows = 3 } = { columns: 10, rows: 3 }
) => {
  /* Sounds should not have focused, but filter just in case. */
  sounds = sounds.filter((sound) => sound !== focused);

  if (sounds.length === 0) return [arrayOf(focused, columns)];

  let countGridSounds = rows * columns;
  if (sounds.length < 9) {
    countGridSounds = columns;
    rows = 1;
  }

  // Sounds to insert in grid. Aka SoundsForGrid
  const soundInstances = generateSoundInstances(sounds, focused, countGridSounds);
  const grid = createGridWithSounds(soundInstances, columns, rows);
  return grid;
};
