import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  useMediaQuery
} from "@chakra-ui/react";
import { TLetter } from "@imaldev/imal-factory/abc";
import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import { differenceInMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { ImPlus } from "react-icons/im";
import { Navigate, Route, Routes } from "react-router-dom";
import { callCloudFn } from "../../../api/cloudFunctions";
import { wUpdateDoc } from "../../../api/shared";
import { useUser } from "../../../api/user/context";
import { dbSaveSequence, SequenceEditor } from "../../../components/SequenceEditor";
import { IS_FAST_DEV } from "../../../constants";
import { getLocaleTypefaces } from "../../../utils/utilsiMAL/fonts/typefaces";
import { SoundSequence, useBookSequences } from "../../../utils/utilsiMAL/soundSequences/sequences";
import { useViewportDimensions } from "../../../utils/utilsReact/hooks/useViewportDimensions";
import { isPlaceholder } from "../../../utils/utilsTS/misc";
import { AdminDashboard } from "./AdminDashboard/AdminDashboard";
import {
  ProviderAppSheetsAbcUserData,
  ProviderDefaultSequence,
  useAppSheetsAbcUserData,
  useDefaultSequence
} from "./context/AppSheetsAbcUserData/contextDefaultSequence";
import { ImageByWordProvider } from "./context/WordImages";
import { MenuBar } from "./MenuBar/MenuBar";
import { AppSheetsAbcMobile } from "./MobileVersion";
import { Book } from "./SequenceMenu/Book";
import { SequenceMenu } from "./SequenceMenu/SequenceMenu";
import { SettingsMenu } from "./Settings/SettingsMenu";
import { TaskWordProvider } from "./SheetCreator/contexts/WordsContext";
import { SheetCreator } from "./SheetCreator/SheetCreator";

/* Eventually, change to checking user agent instead of checking width. */
const BIGGEST_PHONE_WIDTH = 926; // iPhone 13 Pro Max height

const WrappedAppSheetsAbc = () => {
  /* Get device display size/device kind: */
  /* TODO?: need device height as well to determine if is phone. */
  /* That, or check user agent if is phone? */
  const [isPhone] = useMediaQuery(`(max-width: ${BIGGEST_PHONE_WIDTH}px)`);
  const { locale } = useLocale();
  /* The library (already installed as dependency) react-device-detect has a function which does exactly this. */

  return (
    <TaskWordProvider>
      <ProviderAppSheetsAbcUserData>
        <ProviderDefaultSequence>
          <ImageByWordProvider locale={locale}>
            {isPhone ? <AppSheetsAbcMobile /> : <AppSheetsAbc />}
          </ImageByWordProvider>
        </ProviderDefaultSequence>
      </ProviderAppSheetsAbcUserData>
    </TaskWordProvider>
  );
};

export { WrappedAppSheetsAbc as AppSheetsAbc };

const AppSheetsAbc = () => {
  const { locale } = useLocale();
  const { hasDefaultSequence, isLoadingSequence } = useDefaultSequence();
  const { data } = useAppSheetsAbcUserData();

  useEffect(() => {
    const isNewSession =
      differenceInMinutes(data.metadata?.dateLastUsage ?? new Date(), new Date()) < 60;
    if (isNewSession) IS_FAST_DEV ? null : callCloudFn("procUsedSheetsAbc", { locale });
  }, []);

  /* Use this everywhere instead of vh & vw. */
  const viewport = useViewportDimensions();

  if (isLoadingSequence)
    return (
      <Center>
        <Spinner mt="40vh" />
      </Center>
    );

  /* TODO: add progress indicator for preferred select book and typeface? */
  if (!hasDefaultSequence)
    return (
      <>
        <MenuBar />
        <SelectPreferredSequence />
      </>
    );

  if (!data.idDefaultTypeface || isPlaceholder(data.idDefaultTypeface))
    return <SelectPreferredTypeface />;

  /* ProviderImages must recide inside ProviderWords? */
  return (
    <Grid h={viewport.height} w={viewport.width} overflow="hidden" templateRows="auto 1fr">
      <MenuBar />
      <Routes>
        <Route path="admin_dashboard" element={<AdminDashboard />} />
        <Route path="settings/*" element={<SettingsMenu />} />
        <Route path="sheetcreator/*" element={<SheetCreator />} />
        <Route path="sequences" element={<SequenceMenu />} />
        <Route path="*" element={<Navigate to="sheetcreator" />} />
      </Routes>
    </Grid>
  );
};

/* TODO: where to put this? DefaultSequence context? SequenceEditor(<-)?? */
/* Sound sequence module. */
/* Will user sequences eventually contain more fields? */
/* - created? */
/* - updated? */
export type TUserSequence = SoundSequence;

const SelectPreferredSequence = () => {
  const { sequences } = useBookSequences();
  const [isCreatingSequence, setIsCreatingSequence] = useState(false);
  const { setDefaultSequence } = useDefaultSequence();
  const { user } = useUser();
  const { locale } = useLocale();
  const { txt } = useTxt({
    [ELocale.de_DE]: {
      pick_your_preferred_sequence: "Wahlen Sie Ihre gewünschte Lautreihenfolge"
    },
    [ELocale.en_US]: {
      pick_your_preferred_sequence: "Pick or create your desired sound sequence"
    },
    [ELocale.es_ES]: {
      pick_your_preferred_sequence: "Elige o crea la secuencia de sonido que desees"
    }
  });

  return (
    <>
      <SequenceEditor
        isOpen={isCreatingSequence}
        onClose={() => setIsCreatingSequence(false)}
        onSave={(seq) => {
          dbSaveSequence(locale, user.id, seq);
          setDefaultSequence(seq.id);
        }}
      />
      <Flex
        boxSizing="border-box"
        flexDir="column"
        h="100%"
        justifyContent="center"
        mx="auto"
        p="1.5rem"
      >
        <Text as="b" fontSize="3rem" overflow="auto">
          {txt.pick_your_preferred_sequence}
        </Text>
        <Flex flexWrap="wrap" gap="1rem" columns={5}>
          <Center
            _hover={{
              cursor: "pointer",
              filter: "brightness(90%)"
            }}
            alignSelf="center"
            bg="#5EC94F"
            borderRadius="3rem"
            color="#fff"
            h="3rem"
            m="2rem"
            p="4rem"
            onClick={() => setIsCreatingSequence(true)}
            transition="ease .065s"
          >
            <ImPlus size="3rem" />
          </Center>
          {sequences.map((seq, i) => (
            <Box key={i} m="1rem">
              <Book active={false} onClick={() => setDefaultSequence(seq.id)} sequence={seq} />
            </Box>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

const getExampleLetters = (locale: ELocale) => {
  const lettersByLocale: LocaleRecord<TLetter[]> = {
    [ELocale.de_DE]: ["a", "t", "ß", "M"]
  }; // as const?
  return lettersByLocale[locale] ?? lettersByLocale.de_DE;
};

/* TODO?: Move to where it is used? */
export const SelectPreferredTypeface = () => {
  const { locale } = useLocale();
  const { data } = useAppSheetsAbcUserData();
  const { user } = useUser();
  const { txt } = useTxt({
    [ELocale.de_DE]: {
      pick_your_preferred_typeface: "Wählen Sie Ihren bevorzugten Schriftart"
    },
    [ELocale.en_US]: {
      pick_your_preferred_typeface: "Pick you preferred font"
    },
    [ELocale.es_ES]: {
      pick_your_preferred_typeface: "Elija su fuente preferida"
    }
  });

  return (
    <Center h="100%">
      <Center flexDir="column" maxW="100vw" m="auto" w="60rem">
        <Heading fontWeight="bold" mb="1rem">
          {txt.pick_your_preferred_typeface}
        </Heading>
        <SimpleGrid columns={2} gap="1.5rem" m="2rem">
          {getLocaleTypefaces(locale).map((tf, i) => {
            const isActive = data.idDefaultTypeface === tf.id;
            return (
              <Box
                bg={isActive ? "#5bba6b" : "default"}
                borderRadius=".4rem"
                key={i}
                onClick={() =>
                  wUpdateDoc(`/app_sheets_abc/${locale}/users/${user.id}`, {
                    idDefaultTypeface: tf.id
                  })
                }
                p=".2rem"
              >
                <Box
                  bg={isActive ? "#ffffff" : "#fff"}
                  border={`2px solid ${isActive ? "#5bba6b" : "black"}`}
                  borderRadius=".4rem"
                  boxShadow="md"
                  boxSizing="content-box"
                  cursor="pointer"
                  p="1rem"
                >
                  <Text as="b">{tf.nameForDisplay}</Text>
                  <Flex>
                    {getExampleLetters(locale).map((l, i) => (
                      <Text
                        fontFamily={tf.fonts.regular!.fontFamily}
                        fontSize="6xl"
                        key={i}
                        mx="1rem"
                        w="fit-content"
                      >
                        {l}
                      </Text>
                    ))}
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </SimpleGrid>
      </Center>
    </Center>
  );
};
