import { Icon, IconProps } from "@chakra-ui/react";

/* Copied from */
/* https://www.flaticon.com/free-icon/abc-block_794562?term=abc&page=1&position=2&page=1&position=2&related_id=794562&origin=style */

export const AbcBlock = (props: IconProps) => (
  <Icon viewBox="0 0 404.556 404.556" {...props}>
    <g>
      <g>
        <path
          d="M300.903,144.956l-53.28-136c-2.961-7.247-11.17-10.8-18.48-8l-136,53.28c-7.312,2.894-10.893,11.168-8,18.48l53.68,136
			  c2.894,7.312,11.168,10.893,18.48,8l135.6-53.28C300.215,160.542,303.796,152.268,300.903,144.956z M153.063,201.116l-52-132.32
			  l132.32-52.08l52,132.4L153.063,201.116z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M169.543,230.316H23.863c-7.865,0-14.24,6.375-14.24,14.24v145.76c0,7.865,6.375,14.24,14.24,14.24h145.68
			  c7.865,0,14.24-6.375,14.24-14.24v-145.76C183.783,236.691,177.407,230.316,169.543,230.316z M167.783,388.476H25.623v-142.16
			  h142.16V388.476z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M384.663,229.516l-140.8-37.44c-7.677-1.873-15.439,2.755-17.44,10.4l-37.44,140.8
			  c-2.034,7.597,2.476,15.404,10.073,17.438c0.002,0.001,0.005,0.001,0.007,0.002l140.8,37.44
			  c7.597,2.034,15.404-2.476,17.438-10.073c0.001-0.002,0.001-0.005,0.002-0.007l37.12-141.12
			  C396.48,239.453,392.134,231.687,384.663,229.516z M342.343,381.516l-137.44-36.08l36.56-137.36l137.36,36.56L342.343,381.516z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M240.823,124.636c-1.453-2.764-3.375-5.254-5.68-7.36l-40-42.16l-4.72-5.12c-1.186-1.23-2.5-2.329-3.92-3.28
			  c-1.436-0.977-3.079-1.607-4.8-1.84c-4.094-0.286-8.097,1.303-10.88,4.32c-1.1,1.35-1.867,2.939-2.24,4.64
			  c-0.406,2-0.594,4.039-0.56,6.08c0,2.32,0,4.32,0,6v58.24c0.16,2.4,0.293,4.24,0.4,5.52c0.061,1.203,0.304,2.39,0.72,3.52
			  c0.778,1.877,2.321,3.333,4.24,4c1.948,0.8,4.132,0.8,6.08,0c1.981-0.563,3.563-2.056,4.24-4c0.6-3.081,0.815-6.226,0.64-9.36
			  v-11.44l31.6-12.4l7.52,8l3.44,3.44c0.98,1.036,2.081,1.949,3.28,2.72c0.9,0.542,1.913,0.87,2.96,0.96
			  c1.175,0.085,2.353-0.107,3.44-0.56c1.88-0.772,3.396-2.231,4.24-4.08C241.619,128.611,241.619,126.501,240.823,124.636z
			  M183.623,118.796l-1.12-36.32l24.32,27.2L183.623,118.796z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M116.663,311.196c7.621-2.846,12.621-10.186,12.48-18.32c0.007-2.346-0.4-4.675-1.2-6.88c-0.785-2.187-1.95-4.218-3.44-6
			  c-1.469-1.775-3.227-3.29-5.2-4.48c-2.455-1.469-5.172-2.447-8-2.88c-3.415-0.522-6.866-0.762-10.32-0.72h-26.56
			  c-2.881-0.314-5.767,0.551-8,2.4c-1.825,2.245-2.687,5.121-2.4,8v62.72c-0.292,2.867,0.539,5.735,2.32,8
			  c2.231,1.916,5.159,2.814,8.08,2.48h25.52c3.725-0.115,7.438-0.463,11.12-1.04c3.019-0.355,5.979-1.108,8.8-2.24
			  c3.95-1.645,7.32-4.43,9.68-8c2.41-3.771,3.662-8.165,3.6-12.64C133.327,321.698,126.378,313.097,116.663,311.196z
			  M81.063,284.796h13.36c4.654-0.24,9.308,0.467,13.68,2.08c3.025,1.461,4.815,4.657,4.48,8c0.018,1.885-0.48,3.738-1.44,5.36
			  c-1.1,2.149-2.994,3.785-5.28,4.56c-3.169,0.916-6.464,1.321-9.76,1.2h-15.44L81.063,284.796z M115.943,330.476
			  c0,8.213-5.707,12.32-17.12,12.32h-18.16l0.4-24.32h17.36c4.608-0.291,9.206,0.706,13.28,2.88
			  C114.64,323.423,116.255,326.898,115.943,330.476z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M331.943,271.436c-2.04-5.048-5.313-9.504-9.52-12.96c-4.641-3.872-10.118-6.61-16-8c-5.204-1.439-10.639-1.846-16-1.2
			  c-5.156,0.629-10.119,2.347-14.56,5.04c-4.595,2.83-8.547,6.59-11.6,11.04c-3.44,5.065-5.959,10.699-7.44,16.64
			  c-1.04,3.79-1.684,7.677-1.92,11.6c-0.206,3.669,0.035,7.35,0.72,10.96c0.645,3.428,1.72,6.761,3.2,9.92
			  c1.539,3.242,3.592,6.214,6.08,8.8c2.478,2.538,5.34,4.671,8.48,6.32c3.584,1.864,7.368,3.313,11.28,4.32
			  c5.013,1.44,10.265,1.848,15.44,1.2c4.36-0.671,8.531-2.252,12.24-4.64c3.066-1.916,5.773-4.352,8-7.2
			  c1.894-2.359,3.282-5.082,4.08-8c0.562-1.885,0.3-3.919-0.72-5.6c-0.972-1.685-2.59-2.899-4.48-3.36
			  c-1.848-0.666-3.904-0.398-5.52,0.72c-1.545,1.266-2.87,2.78-3.92,4.48c-2.484,3.615-5.995,6.402-10.08,8
			  c-4.159,1.356-8.641,1.356-12.8,0c-3.779-0.734-7.289-2.475-10.16-5.04c-2.777-3.159-4.509-7.099-4.96-11.28
			  c-0.536-5.381-0.02-10.816,1.52-16c1.427-8.033,5.547-15.34,11.68-20.72c5.124-3.989,11.865-5.241,18.08-3.36
			  c3.791,0.87,7.225,2.88,9.84,5.76c2.56,3.19,4.316,6.949,5.12,10.96c0.408,2.124,1.136,4.174,2.16,6.08
			  c1.032,1.433,2.581,2.409,4.32,2.72c1.829,0.506,3.788,0.184,5.36-0.88c1.625-0.983,2.802-2.562,3.28-4.4
			  C333.986,279.354,333.567,275.189,331.943,271.436z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </Icon>
);
