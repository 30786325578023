import { Button, chakra } from "@chakra-ui/react";
import { Popover, PopoverOrigin } from "@material-ui/core";
import { ReactNode } from "react";
import { FaTimes } from "react-icons/fa";

type Props = {
  anchorOrigin?: PopoverOrigin;
  anchorEl: SVGElement | null;
  children: ReactNode;
  id?: string | undefined;
  close: () => void;
};

const PopoverMaterialUI = ({ anchorEl, anchorOrigin, children, id, close }: Props) => (
  <Popover
    anchorEl={anchorEl}
    onClose={close}
    anchorOrigin={
      anchorOrigin ?? {
        vertical: "top",
        horizontal: "right"
      }
    }
    id={id}
    open={!!anchorEl}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
  >
    {children}
  </Popover>
);

const PopoverBody = chakra("div", {
  baseStyle: {
    alignItems: "center",
    bg: "#e6e6e6",
    border: "0.2rem solid black",
    boxShadow: "0px 0px 15px black",
    transition: "background 1s ease"
  }
});

PopoverMaterialUI.Body = PopoverBody;

export const PopoverSection = chakra("div", {
  baseStyle: {
    bg: "#e6e6e6",
    borderRadius: "0.2vh",
    justifyContent: "center",
    p: "0.5rem",
    w: "inherit"
  }
});

PopoverMaterialUI.Section = PopoverSection;

export { PopoverMaterialUI as Popover };

/* Chakra already has one though.... */
/* TODO: style close button in theme (and use only this one?). */
const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      bg="black"
      color="white"
      float="left"
      ml="0.1rem"
      mt="0.1rem"
      w="0.3rem"
      onClick={onClick}
    >
      <FaTimes />
    </Button>
  );
};

PopoverMaterialUI.CloseButton = CloseButton;
