import { SimpleGrid, Text } from "@chakra-ui/react";
import { Property } from "csstype";
import { nordicSerif } from "../../../../../../../../../utils/utilsiMAL/fonts/nordicSerif/nordicSerif";
import {
  ETaskFontVariant,
  ETypeface,
  newBlankFont,
  TaskFont
} from "../../../../../../../../../utils/utilsiMAL/fonts/shared";
import { taskTypefaceByName } from "../../../../../../../../../utils/utilsiMAL/fonts/typefaces";
import { PropsSettingButton, SettingButton, Toggleable } from "../SettingButton/SettingButton";

type Props = Toggleable & {
  disabledVariants?: ETaskFontVariant[];
  includeBlank?: boolean; // this is ugly...
  setFontCallback?: () => void; // used for what??
  setFontVariant: (font: TaskFont) => void; // should be TaskFontVariant (prop or param?).
  selectedTypeface: ETypeface;
  selectedVariant: ETaskFontVariant | null;
};

/* This could be it's own setting module. */
export const SelectFontVariant = ({
  includeBlank,
  selectedVariant,
  setFontCallback = () => {},
  setFontVariant = () => null,
  toggle,
  selectedTypeface
}: Props) => {
  const fonts = Object.values(taskTypefaceByName[selectedTypeface].fonts);
  if (includeBlank) fonts.push(newBlankFont(selectedTypeface));

  return (
    <SimpleGrid gap="0.5vmin" p=".5rem" templateColumns={`repeat(${fonts.length}, 1fr)`}>
      {fonts.map((font, i) => (
        <FontButton
          font={font}
          active={font.variant === selectedVariant}
          onClick={() => {
            setFontVariant(font);
            setFontCallback();
          }}
          text={"t"}
          toggle={toggle}
          key={i}
        />
      ))}
    </SimpleGrid>
  );
};

type PropsFontButton = Toggleable & {
  font?: TaskFont;
  fontSize?: Property.FontSize;
  scale?: number;
  text?: string;
  color?: string;
} & Exclude<PropsSettingButton, "children">;

export const FontButton = ({
  active,
  font = nordicSerif.fonts.regular!,
  fontSize = "12vh",
  text,
  ...rest
}: PropsFontButton) => {
  return (
    <SettingButton active={active} {...rest}>
      {font.variant === ETaskFontVariant.BLANK ? null : (
        <Text
          color={font.variant === ETaskFontVariant.REGULAR ? "gray" : "default"}
          fontFamily={font.fontFamily}
          fontSize={fontSize}
          m="-2.1vh 0 -4.5vh 0"
          textAlign="center"
          _hover={{
            cursor: "pointer"
          }}
          font={font}
          {...rest}
        >
          {text}
        </Text>
      )}
    </SettingButton>
  );
};
