import firestore from "firebase/firestore";
import { roleToPermissions } from "../../permissions/permissions";
import { FirebaseTime } from "../../utils/utilsFirebase/time";
import { mkCommonUser, TCommonUser } from "./context";
import { mkUser, TUser as TUser } from "./user";

/* Handles operations on documents in `/users`. */

/* why is this in its own file? why not in user.ts */
/* Can we put this shit inside other user file? */

/* Have the user.ts, just exposing things we use elsewhere in there. */
/* Pertains to DbUser.create */

/* Whats the purpose of this type? */
/* Feels unneccessary... */
export type TUserDb = TCommonUser & {
  metadata: {
    dateCreated: firestore.Timestamp;
    dateLastSignIn: firestore.Timestamp;
  };
};

export const newDbUser: () => TUserDb = () => ({
  ...mkCommonUser(),
  metadata: {
    dateLastSignIn: FirebaseTime.epoch(),
    dateCreated: FirebaseTime.epoch()
  }
});

/* TODO: Is this neccessary?? */
/* Better to keep all data up-to-date all the time?? */
export const mapDbUserToUser = (dbUser: TUserDb) => {
  const user: TUser = {
    ...mkUser(),
    permissions: dbUser.roles?.map((role) => roleToPermissions[role]).flat() ?? [],
    ...dbUser,
    metadata: {
      dateLastSignIn: dbUser.metadata?.dateLastSignIn?.toDate() ?? new Date(),
      dateCreated: dbUser.metadata?.dateCreated?.toDate() ?? new Date()
    }
  };

  return user;
};
