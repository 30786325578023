import {
  ETaskFontVariant,
  TaskFont
} from "../../../../../../../../../utils/utilsiMAL/fonts/shared";
import { taskTypefaceByName } from "../../../../../../../../../utils/utilsiMAL/fonts/typefaces";
import { applyCap, ECap } from "@imaldev/imal-factory/abc";
import { Size } from "../../../../../../../../../utils/utilsTS/miscTypes/types";
import { PercentStr } from "@imaldev/imal-factory/ts";

/* RETHINK: unnecessary width prop? */

type Props = {
  cap?: ECap;
  color?: "black" | "gray" | "red";
  font?: TaskFont;
  fontSize?: number;
  size?: Size;
  sound?: string;
  width?: PercentStr; // what does this do exactly?
  y?: PercentStr;
};

/* Generic Sound for sheets? Wrap this component if want other types of Sounds. */
/* TODO: Optional prop for coloring vowels red. */
/* perhaps this prop can be an object? { colorVowel: boolean, locale: ELocale } */

/* TODO: sound should not be optional. */
export const Sound = ({
  cap = ECap.FIRST_UPPER,
  color = "black",
  /* TODO: use correct typeface. User can choose? */
  font = taskTypefaceByName.germanGrundschrift.fonts.regular!,
  fontSize = 70,
  size = "large",
  sound = "Å",
  width = "100%",
  y = "70%"
}: Props) => {
  if (size === "medium") fontSize *= 0.75;
  else if (size === "small") fontSize *= 0.6;

  return (
    <svg width={width} height="100%" viewBox="0 0 100 100">
      {font.variant === ETaskFontVariant.BLANK ? null : (
        <text
          fill={color}
          fontFamily={font.fontFamily}
          fontSize={fontSize}
          textAnchor="middle"
          width="100%"
          y={y}
          x="50%"
        >
          {applyCap(sound, cap)}
        </text>
      )}
    </svg>
  );
};
