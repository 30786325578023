import * as R from "ramda";
import { takeRandom } from "@imaldev/imal-factory/ts";

/* From including to including. */
type TGetDistinctRandomInts = (
  low: number,
  high: number,
  n: number,
  exclude?: number[]
) => number[];

/* Consider case when n > (high - low). Should return all number from low to high, */
/* or return all numbers from low to low + n - 1 ? */
/* Feel like all numbers */
export const getDistinctRandomInts: TGetDistinctRandomInts = (
  low: number,
  high: number,
  n: number,
  exclude = [] as number[]
) => {
  if (n > high - low) {
    high = low + n - 1;
  }
  const randomNum = getRandomInt(low, high, exclude);
  if (n === 1) return [randomNum];
  return [randomNum, ...getDistinctRandomInts(low, high, n - 1, R.append(randomNum, exclude))];
};

export const percentify = (v: number) => `${v}%`;

/* Own random-fn with simpler api compared to Math.random with Math.floor. */
const getRandomInt = (low: number, high: number, exclude = [] as number[]) => {
  const possibles = R.difference(R.range(low, high + 1), exclude);
  if (R.isEmpty(possibles)) throw Error("ERROR: possible numbers is empty array.");

  return takeRandom(possibles);
};
