import { SoundSequence } from "../../../sequences";
import img_einsterns_schwester from "./einsterns_schwester.jpg";

export const einsternsSchwester: SoundSequence = {
  bookPicture: img_einsterns_schwester,
  displayName: "Einsterns Schwester",
  id: "35310743-9db4-431e-9ae9-0052f7018fc4",
  name: "Einsterns Schwester",
  sounds: [
    "a",
    "e",
    "i",
    "o",
    "u",
    "m",
    "p",
    "l",
    "t",
    "s",
    "k",
    "r",
    "f",
    "n",
    "b",
    "h",
    "d",
    "z",
    "g",
    "sch",
    "w",
    "j",
    "st",
    "ch",
    "sp",
    "qu",
    "c",
    "v",
    "x",
    "y",
    "au",
    "eu",
    "ei",
    "ä",
    "ö",
    "ü"
  ]
};
