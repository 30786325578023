import { applyCap, ECap } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { Percent } from "@imaldev/imal-factory/ts";
import { EFontLevel, getFontLevels, isCapWithDiacritic } from "@imaldev/imal-react-ui/abc";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { add, flatten, map, mergeWith, pipe, split, uniq } from "ramda";
import { localesDisabledEverywhere } from "../../../../../../i18n/useGlobalTxt";
import {
  ETaskFontVariant,
  ETypeface,
  getDefaultTypefaceName,
  TaskFont
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import {
  getTaskFont,
  taskTypefacesByLocale
} from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { percentify } from "../../../../../../utils/utilsTS/number/number";
import { useSheets } from "../../contexts/ContextSheets";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import {
  EGuidelines,
  getXAreaBg,
  SelectGuidelineSetting
} from "../shared/components/settings/SelectGuidelineSetting/SelectGuidelines";
import { SelectSound } from "../shared/components/settings/SelectSound/SelectSound";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import { EGuidelineName, Guidelines } from "../shared/components/sheets/Guidelines/Guidelines";
import { Sound } from "../shared/components/sheets/Sound/Sound";
import { getGuidelineNames } from "../shared/components/sheets/WriteSoundRow";
import {
  ESheet,
  getDefaultArgs,
  InstructionalVideos,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import {
  setGuidelineSetting,
  TWithGuidelinesSetting
} from "../shared/sheetTypes/guidelinesSetting";
import { setCap, TWithCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried, TWithFocusedSound } from "../shared/sheetTypes/withFocusedSound";
import { setFont, TWithFont } from "../shared/sheetTypes/withFont";

export type TOneLargeSound = TNewSheetType<ESheet.ONE_LARGE_SOUND> &
  TWithCap &
  TWithFocusedSound &
  TWithFont &
  TWithGuidelinesSetting;

export const videos: InstructionalVideos = {
  [ELocale.de_DE]: {
    editor: "https://vimeo.com/652924389",
    method: "https://vimeo.com/656586197"
  }
};

export const EditorOneLargeSound = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TOneLargeSound };
  const { updateSheet } = useSheets();
  const { locale } = useLocale();

  return (
    <EditorContainer
      sheet={sheet}
      settings={
        <>
          <SelectSound
            setCap={pipe(setCap(sheet), updateSheet)}
            cap={sheet.cap}
            activeSound={sheet.focusedSound}
            setSound={pipe(setFocusedSoundCurried(sheet), updateSheet)}
          />
          <SelectFont
            activeFontVariant={sheet.font.variant}
            activeTypeface={sheet.font.typeface}
            disabledFontVariants={[ETaskFontVariant.STARTPOINTS]}
            setFont={pipe(setFont(sheet), updateSheet)}
            typefaces={taskTypefacesByLocale[locale]}
          />
          <SelectGuidelineSetting
            activeSetting={sheet.guidelinesSetting}
            setSetting={pipe(setGuidelineSetting(sheet), updateSheet)}
          />
        </>
      }
    />
  );
};

export const mkOneLargeSound: MkSheet<TOneLargeSound> = (arg) => {
  const { locale, pSheet } = getDefaultArgs(arg);
  return {
    ...mkAbstractSheet(),
    cap: ECap.UPPER,
    focusedSound: "e",
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(locale),
      ETaskFontVariant.REGULAR
    ),
    guidelinesSetting: EGuidelines.ALL_WITH_XAREA_BLUE,
    name: ESheet.ONE_LARGE_SOUND,
    ...pSheet
  };
};

export const menuSheetsOneLargeSound: TMenuSheetsFor<ESheet.ONE_LARGE_SOUND> = {
  [ELocale.de_DE]: mkOneLargeSound({
    locale: ELocale.de_DE
  }),
  [ELocale.es_ES]: mkOneLargeSound({
    locale: ELocale.es_ES
  })
};

export const SheetOneLargeSound: SheetPreview<ESheet.ONE_LARGE_SOUND> = ({
  interactive,
  sheetProps
}) => {
  const soundWidth: Percent = 96;
  const margin: Percent = (100 - soundWidth) / 2;

  const coversWholeSheet = [EGuidelines.BASELINE, EGuidelines.NONE].includes(
    sheetProps.guidelinesSetting
  );

  const props = {
    cap: sheetProps.cap,
    sound: sheetProps.focusedSound,
    font: sheetProps.font
  };

  return (
    <BlankSheet inEditor={interactive} logoSize="normal">
      <svg x={`${margin}%`} width={`${soundWidth + 1}%`}>
        {coversWholeSheet ? (
          <SoundWholeSheet
            {...props}
            hasBaseline={sheetProps.guidelinesSetting !== EGuidelines.NONE}
          />
        ) : (
          <SoundWithGuidelines {...props} guidelinesSetting={sheetProps.guidelinesSetting} />
        )}
      </svg>
    </BlankSheet>
  );
};

type PropsSoundWithGuidelines = {
  cap: ECap;
  font: TaskFont;
  guidelinesSetting: EGuidelines;
  sound: string;
};

type SoundStyle = {
  fontSize: number;
  guidelinesHeight: Percent;
  guidelinesYOffset: Percent;
  soundYOffset: Percent;
};

const SoundWithGuidelines = ({ cap, font, guidelinesSetting, sound }: PropsSoundWithGuidelines) => {
  const styleByLength: Record<string, SoundStyle> = {
    1: {
      soundYOffset: 79,
      guidelinesHeight: 117,
      guidelinesYOffset: -13,
      fontSize: 125
    },
    2: {
      soundYOffset: 63.5,
      guidelinesHeight: 72,
      guidelinesYOffset: 8.5,
      fontSize: 76.8
    },
    3: {
      soundYOffset: 65.2,
      guidelinesHeight: 55.5,
      guidelinesYOffset: 21.2,
      fontSize: 59
    }
  };

  const { guidelinesHeight, guidelinesYOffset, fontSize, soundYOffset } = isCapWithDiacritic(
    applyCap(sound, cap)
  )
    ? {
        soundYOffset: 79,
        guidelinesHeight: 107.7,
        guidelinesYOffset: -6.4,
        fontSize: 115
      }
    : styleByLength[sound.length]!;

  const lines = getGuidelineNames(guidelinesSetting);

  return (
    <>
      <svg height={percentify(guidelinesHeight)} y={percentify(guidelinesYOffset)}>
        <Guidelines xAreaColor={getXAreaBg(guidelinesSetting)} lines={lines} />
      </svg>
      <Sound
        cap={cap}
        color={font.variant === ETaskFontVariant.REGULAR ? "gray" : "black"}
        font={font}
        fontSize={fontSize}
        sound={sound}
        y={percentify(soundYOffset)}
      />
    </>
  );
};

/* TODO: which module should this belong to? Leaning towards font. */
export const getSoundFontLevels = (sound: string, typeface: ETypeface) => {
  return pipe(
    () => sound,
    split(""),
    map((letter) => getFontLevels(letter, typeface)),
    flatten,
    uniq
  )();
};

const getStylingSoundWholeSheet = (s: string, typeface: ETypeface) => {
  type SoundLengthSettings = {
    defaultStyle: SoundStyle;
    predicates: Record<
      string,
      {
        predicate: (sound: string) => boolean;
        tweaks: Partial<SoundStyle>;
      }
    >;
  };

  /* This fn makes it harder to read? */
  const getStyles = (sound: string, { defaultStyle, predicates }: SoundLengthSettings) => {
    /* Would be cleaner if predicates edited the value if true, and returns */
    /* value unchanged if false. */
    const predTTuples = Object.values(predicates).map((dict) => Object.values(dict)) as [
      (sound: string) => boolean,
      SoundStyle
    ][];
    return predTTuples.reduce(
      (acc, [pred, adjustments]) => (pred(sound) ? mergeWith(add, acc, adjustments) : acc),
      defaultStyle
    );
  };

  const styleAndPredicatesByLength: Record<number, SoundLengthSettings> = {
    1: {
      defaultStyle: {
        fontSize: 130,
        soundYOffset: 85,
        guidelinesHeight: 50,
        guidelinesYOffset: 10
      },
      predicates: {
        hasCap: {
          fn: (sound: string) => getSoundFontLevels(sound, typeface).includes(EFontLevel.CAP),
          tweaks: { soundYOffset: 10 }
        },
        hasDescend: {
          fn: (sound: string) => getSoundFontLevels(sound, typeface).includes(EFontLevel.DESCENDER),
          tweaks: { soundYOffset: -20 }
        },
        isLargeWithDiacritic: {
          fn: (sound: string) =>
            getSoundFontLevels(sound, typeface).includes(EFontLevel.CAP_AND_DIACRITIC),
          tweaks: {
            soundYOffset: 8
          }
        }
      }
    },
    2: {
      defaultStyle: {
        fontSize: 105,
        soundYOffset: 75,
        guidelinesHeight: 50,
        guidelinesYOffset: 10
      },
      predicates: {
        isWide: {
          fn: (sound: string) => ["Ch", "Ck", "EU", "Ng", "Ng", "Nk"].includes(sound),
          tweaks: {
            fontSize: -15
          }
        },
        isVeryWide: {
          fn: (sound: string) =>
            ["Au", "AU", "Äu", "ÄU", "CH", "CK", "NG", "NK", "QU", "Qu"].includes(sound),
          tweaks: {
            fontSize: -28,
            soundYOffset: -8
          }
        },
        isCap: {
          /* maybe use this way for all, instead of checking both */
          /* xarea and cap/desc? */
          fn: (s: string) => getSoundFontLevels(s, typeface).includes(EFontLevel.CAP),
          tweaks: { soundYOffset: 8 }
        },
        isDescending: {
          fn: (s: string) => getSoundFontLevels(s, typeface).includes(EFontLevel.DESCENDER),
          tweaks: { soundYOffset: -10 }
        }
      }
    },
    3: {
      defaultStyle: {
        fontSize: 80,
        soundYOffset: 75,
        guidelinesHeight: 50,
        guidelinesYOffset: 10
      },
      predicates: {
        isWide: {
          fn: (sound: string) => ["Chs", "Sch"].includes(sound),
          tweaks: {
            fontSize: -8,
            yOffset: 20
          }
        },
        isVeryWide: {
          fn: (sound: string) => ["CHS", "SCH"].includes(sound),
          tweaks: {
            fontSize: -20,
            yOffset: 20
          }
        }
      }
    }
  } as any;

  return getStyles(s, styleAndPredicatesByLength[s.length]);
};

type PropsSoundWholeSheet = {
  cap: ECap;
  font: TaskFont;
  hasBaseline: boolean;
  sound: string;
};

const SoundWholeSheet = ({ cap, font, hasBaseline, sound }: PropsSoundWholeSheet) => {
  const settings = getStylingSoundWholeSheet(applyCap(sound, cap), font.typeface);

  return (
    <>
      {hasBaseline && (
        <svg height={"117%"} y={percentify(settings.guidelinesYOffset)}>
          <Guidelines lines={[EGuidelineName.BASE]} />
        </svg>
      )}
      <Sound
        cap={cap}
        color={font.variant === ETaskFontVariant.REGULAR ? "gray" : "black"}
        font={font}
        fontSize={settings.fontSize}
        sound={sound}
        y={percentify(settings.soundYOffset)}
      />
    </>
  );
};

export const configOneLargeSound: TSheetConfig<ESheet.ONE_LARGE_SOUND> = {
  disabledLocales: localesDisabledEverywhere,
  Editor: EditorOneLargeSound,
  fnMkSheet: mkOneLargeSound,
  menuSheets: menuSheetsOneLargeSound,
  Sheet: SheetOneLargeSound,
  videos
};
