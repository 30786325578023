import { Box, ChakraProps, Flex, Grid, Icon as ChakraIcon, Image, Text } from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaLinkedin, FaRegCopyright } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FlexColumn } from "../../../components/layout/Flex/Flex";
import iMalLogoGerman from "../../../utils/utilsiMAL/images/logo/logo_german_white_text.png";

const Icon = ({ url, ...rest }: { as: any; url: string } & ChakraProps) => {
  return (
    <ChakraIcon
      _hover={{ color: "#ddd", cursor: "pointer" }}
      color="#999"
      fontSize="2rem"
      onClick={() => window.location.assign(`https://www.${url}`)}
      transition=".15s ease"
      {...rest}
    />
  );
};

/* Would grids be better? */
/* TODO: different layout for mobile view. */
export const Footer = () => {
  return (
    <Box bg="#332438">
      <Grid
        alignItems="center"
        templateColumns={["1fr", "repeat(2, 1fr)"]}
        templateAreas={["'social_media'  'logo'", "'logo social_media'"]}
        h="20rem"
        pt="2rem"
        px="2rem"
        m="auto"
        maxW="80rem"
      >
        <Image gridArea="logo" m="auto" maxH="5.5rem" src={iMalLogoGerman} />
        <FlexColumn gridArea="social_media" justifySelf="flex-end" m="auto" w="fit-content">
          <Flex alignItems="center" justifyContent="space-between">
            <Icon as={FaInstagram} url="instagram.com/imalabc_deutsch/" />
            <Icon
              as={FaFacebook}
              url="facebook.com/iMAL-zur-Buchstabeneinf%C3%BChrung-100820318767635"
            />
            <Icon as={FaLinkedin} url="linkedin.com/company/imal-knekker-lesekoden/" />
          </Flex>
          <Flex alignItems="center" mt="1.5rem" ml=".2rem" w="fit-content">
            <ChakraIcon as={MdEmail} color="#888" fontSize="1.4rem" />
            <Text color="#999" fontSize="1.3rem" ml=".3rem">
              mail@imalabc.de
            </Text>
          </Flex>
        </FlexColumn>
      </Grid>
      <Flex
        bg="#251D29"
        p="1rem"
        pl="4rem"
        justifyContent={{ base: "center", md: "flex-start" }}
        alignItems="center"
      >
        <FaRegCopyright
          color="#999"
          style={{
            marginRight: ".2rem",
            fontSize: ".75rem"
          }}
        />
        <Text color="#999" fontWeight="bold" fontSize=".97rem">
          2021 iMAL Norge AS
        </Text>
      </Flex>
    </Box>
  );
};
