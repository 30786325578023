/* Not using NODE_ENV, since `react-scripts build` will always use value "production" */
/* for this variable. */
/* Therefore we instead create our own environment variable. */

/* REACT_APP_IS_PRODUCTION=... is set in github action workflow file. */
/* The environment the app is running in. */
/* Used for determining which db to use, feature flags etc. */
/* Cannot rely on NODE_ENV, as this is always set to "production" when running `npm run build`; */
/* ie. dev environment would use values meant for prod. */

/* TODO?: don't need a separate file for this. */

export const ENVIRONMENT = process.env.REACT_APP_IS_PRODUCTION ? "production" : "development";

export const IS_FAST_DEV = process.env.REACT_APP_FAST_DEV === "true";
