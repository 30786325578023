import { Center, ChakraProps, Image, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import SignDisabled from "./sign_disabled.svg";

/* TODO: put these in suitable module. */
/* What would be a good place? (new) react/components/types.ts? yehh! */
export type Activatable = { active?: boolean };

export type Toggleable = { toggle?: boolean };

enum BgColor {
  ACTIVE = "#d2dee2",
  DEFAULT = "white",
  DISABLED = "#686868",
  HOVER = "#efefef"
}

/* TODO: use Colorscheme object. */
enum BorderColor {
  ACTIVE = "#04485F",
  DEFAULT = "lightgray",
  DISABLED = "darkgray"
}

type Props = ChakraProps &
  Partial<Activatable> &
  Toggleable & {
    children?: ReactNode;
    customStyle?: React.CSSProperties; // This is bloat? use chakra factory.
    disabled?: boolean;
    label?: string;
    onClick?: () => void;
  };

export type { Props as PropsSettingButton };

/* TODO: Make background a little bit lighter when hovering a toggleable version.  */
/* TODO: don't have different border width for active and inactive. */
export const SettingButton = ({
  active,
  children,
  disabled,
  toggle = false,
  label,
  customStyle,
  ...rest
}: Props) => {
  return (
    <Center
      alignItems="center"
      bg={disabled ? BgColor.DISABLED : active ? BgColor.ACTIVE : BgColor.DEFAULT}
      border={
        disabled
          ? BorderColor.DISABLED
          : active
          ? `2px solid ${BorderColor.ACTIVE}`
          : `1px solid ${BorderColor.DEFAULT}`
      }
      borderRadius="5px"
      color="black"
      overflow="hidden"
      p="0 1vh"
      pos="relative"
      style={customStyle}
      _hover={{
        cursor: disabled ? "default" : "pointer",
        bg: disabled ? BgColor.DISABLED : BgColor.HOVER
      }}
      {...rest}
    >
      {disabled ? <Image src={SignDisabled} mr=".3vh" /> : null}
      {label ? <Text>{label}</Text> : children}
    </Center>
  );
};
