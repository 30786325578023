export const CheckboxSVG = ({ x, y }: { x?: string; y?: string }) => {
  return (
    <svg x={x} y={y}>
      <polygon
        points="2.5,2.5 5,2.5 5,5 2.5,5 2.5,2.5"
        fill="transparent"
        stroke="black"
        strokeWidth=".4%"
        x={x}
        y={y}
      />
    </svg>
  );
};
