import { MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from "react";

export const useBoundingRect: <T extends Element = HTMLDivElement>() => [
  MutableRefObject<T>,
  DOMRect
] = <T extends Element = HTMLDivElement>() => {
  const ref = useRef<T>(null!); // Try HTMLElement first. Then .
  const [br, setBr] = useState(new DOMRect());

  useEffect(() => {
    const handleResize = () => ref.current && setBr(ref.current.getBoundingClientRect());
    handleResize(); // Also set initially on client side
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return [ref, br];
};
