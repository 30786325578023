import { ELocale } from "@imaldev/imal-factory/i18n";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { Property } from "csstype";

// Should also take fontSize? Need to make tiny adjustments for guidelines
// when used as <WriteSoundLines />.
export const lineToTopDistance = (variant: EGuidelineName, locale?: ELocale) => {
  switch (variant) {
    case EGuidelineName.CAP:
      return 20.6;
    case EGuidelineName.MEAN:
      if (locale === ELocale.de_DE) return 42.2;
      return 37.35;
    case EGuidelineName.BASE:
      return 70.4;
    case EGuidelineName.DESCENDER:
      if (locale === ELocale.de_DE) return 90.6;
      return 88;
  }
};

/* Aka. "typography lines": */
/* https://material.io/design/typography/understanding-typography.html#type-properties */
export enum EGuidelineName {
  CAP = "cap",
  MEAN = "mean",
  BASE = "base", // TODO: rename -> baseline?
  DESCENDER = "descender"
}

export const Guideline = ({ variant = EGuidelineName.BASE }: { variant?: EGuidelineName }) => {
  const { locale } = useLocale();
  let deltaTop = lineToTopDistance(variant, locale);
  if (variant === EGuidelineName.DESCENDER) deltaTop += 1.5;
  const strokeWidth =
    variant === EGuidelineName.BASE ? 0.8 : variant === EGuidelineName.MEAN ? 0.4 : 0.6;
  const color = variant === EGuidelineName.MEAN ? "#aaa" : "#888";

  return (
    <line
      stroke={color}
      strokeWidth={`${strokeWidth}%`}
      width="100%"
      x1="0"
      y1={`${deltaTop}%`}
      x2="100%"
      y2={`${deltaTop}%`}
    />
  );
};

export const XArea = ({ color }: { color?: Property.Color }) => {
  const { locale } = useLocale();

  const height =
    lineToTopDistance(EGuidelineName.BASE) - lineToTopDistance(EGuidelineName.MEAN, locale);

  return (
    <rect
      y={`${lineToTopDistance(EGuidelineName.MEAN, locale)}%`}
      width="100%"
      height={`${height}%`}
      fill={color}
    />
  );
};

type Props = {
  lines: EGuidelineName[];
  verticalBorders?: boolean;
  xAreaColor?: Property.Color | null;
};

export type { Props as PropsGuidelines };

export const Guidelines = ({ verticalBorders = false, xAreaColor, lines }: Props) => {
  return (
    <svg width="100%" height="100%">
      {xAreaColor && <XArea color={xAreaColor} />}
      {/* Can be done with Object.values().map(). */}
      {lines.includes(EGuidelineName.CAP) && <Guideline variant={EGuidelineName.CAP} />}
      {lines.includes(EGuidelineName.MEAN) && <Guideline variant={EGuidelineName.MEAN} />}
      {lines.includes(EGuidelineName.BASE) && <Guideline variant={EGuidelineName.BASE} />}
      {lines.includes(EGuidelineName.DESCENDER) && <Guideline variant={EGuidelineName.DESCENDER} />}
      {verticalBorders &&
        lines.includes(EGuidelineName.DESCENDER) &&
        lines.includes(EGuidelineName.CAP) && (
          <>
            <line stroke="#888" strokeWidth={0.4} x1="0.2%" y1="20.5%" x2="0.2%" y2="93%" />
            <line stroke="#888" strokeWidth={0.4} x1="99.8%" y1="20.5%" x2="99.8%" y2="93%" />
          </>
        )}
    </svg>
  );
};
