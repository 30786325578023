import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  UserCredential
} from "firebase/auth";
import * as R from "ramda";
import { useState } from "react";
import { wSetDoc } from "../../api/shared";
import { newDbUser, TUserDb } from "../../api/user/db";
import { auth } from "../../auth/auth";
import { FirebaseTime } from "../../utils/utilsFirebase/time";

type TCreateNewUser = (userData: {
  email: string;
  id: string;
  isEmailVerified?: boolean;
}) => Promise<void>;

const createNewUser: TCreateNewUser = async (userData) => {
  const newUser: TUserDb = {
    ...newDbUser(),
    metadata: {
      dateLastSignIn: FirebaseTime.now(),
      dateCreated: FirebaseTime.now()
    },
    ...userData
  };
  await wSetDoc(`/users/${userData.id}`, newUser);
};

export enum EStatusSignUp {
  ERROR_EMAIL_ALREADY_IN_USE = "error:auth/email-already-in-use",
  LOADING = "LOADING",
  SUCCESS = "success",
  NOTHING = "nothing"
}

export const useSignUp = () => {
  const [status, setStatus] = useState(EStatusSignUp.NOTHING);

  type TSignUp = (args: { email: string; password: string }) => Promise<void>;

  const signUp: TSignUp = async (args) => {
    setStatus(EStatusSignUp.LOADING);
    let userCredentials: UserCredential = null!;
    try {
      userCredentials = await createUserWithEmailAndPassword(
        auth,
        args.email.toLocaleLowerCase(),
        args.password
      );
    } catch (error) {
      console.log("Error signing up:");
      console.log(error);
      setStatus(EStatusSignUp.ERROR_EMAIL_ALREADY_IN_USE);
    }

    if (!userCredentials?.user) return;

    sendEmailVerification(userCredentials.user);
    await createNewUser({
      id: userCredentials.user.uid,
      ...R.dissoc("password", args)
    });
    setStatus(EStatusSignUp.SUCCESS);
  };

  return {
    signUp,
    status
  };
};
