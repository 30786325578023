import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Spinner,
  Stack,
  Text
} from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { PDFDocument } from "pdf-lib";
import { useEffect, useRef, useState } from "react";
import { FiDownload, FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ScrollList } from "../../../../../components/ScrollList";
import { useSheets } from "../contexts/ContextSheets";

export const PrintSheets = () => {
  const { sheets } = useSheets();
  const [pdf, setPdf] = useState<Base64 | null>(null);
  const { txt } = useTxt(translations);
  const [shouldCreatePdf, setCreatePdf] = useState(false);
  const navigate = useNavigate();

  /* TOOD: wait with creating pdf until user has clicked button to create pdf. */
  /* TODO?: add fake delay before creating pdf? Otherwise, it seems instant when the file size is very small. */
  useEffect(() => {
    const execCreatePdf = async () => {
      const pdfBytes: Base64 = await pngsToPdf(sheets.map((sheet) => sheet.image.forPdf));
      setPdf(pdfBytes);
    };
    /* Have to increase the timeout for certain devices? */
    /* setTimeout(execCreatePdf, 5); */
    if (shouldCreatePdf) execCreatePdf();
  }, [shouldCreatePdf]);

  if (!shouldCreatePdf) {
    return (
      <Grid templateColumns="30em 25em" gap="2em" flex={1} mx="auto" p="1.5em">
        <GridItem>
          <Box h="30vh" />
          <Stack alignItems="center" gridGap="1em">
            <Text fontSize="1.3em">Sind sie zufrieden mit dem Dokument?</Text>
            <Flex gridGap="1em">
              <Button colorScheme="green" onClick={() => setCreatePdf(true)}>
                Ja, PDF erstellen!
              </Button>
              <Button onClick={() => navigate("..")}>Nein, Dokument bearbeiten</Button>
            </Flex>
          </Stack>
        </GridItem>
        <GridItem as={Stack}>
          <ScrollList>
            {/* TODO: only render when on screen. */}
            <Stack>
              {/* TODO: show page number next to the pages somehow? */}
              {sheets.map(({ image }, i) => (
                <Image maxW="30em" maxH="40em" key={i} src={image.forPdf} />
              ))}
            </Stack>
          </ScrollList>
        </GridItem>
      </Grid>
    );
  }

  /* Container of sheet needs to match size of image. */

  if (!pdf)
    return (
      <Grid templateRows="3fr auto 5fr" flex={1}>
        <GridItem />
        <GridItem as={Flex} justifyContent="center">
          <Text as="b" mr="1.2em">
            {txt.creating_pdf}
          </Text>
          <Spinner />
        </GridItem>
        <GridItem />
      </Grid>
    );

  return (
    <Flex h="100%" w="100%">
      <PdfViewer pdfBytes={pdf} />
    </Flex>
  );
};

type Base64 = string;

const translations = {
  [ELocale.en_US]: { creating_pdf: "Creating pdf" },
  [ELocale.de_DE]: { creating_pdf: "Pdf wird generiert" }
};

// Convert PNGs of task sheets to a PDF document with one task sheet per PDF page. */
type PngsToPdf = (images: Base64[], setGenerationProgress?: (n: number) => void) => Promise<Base64>;
export const pngsToPdf: PngsToPdf = async (images, setGenerationProgress = (n) => {}) => {
  const pdfDoc = await PDFDocument.create();
  images.forEach(async (image, i) => {
    console.log(`page ${i}`);
    setGenerationProgress(i);
    const pdfPage = pdfDoc.addPage([A4Page.WIDTH, A4Page.HEIGHT]);
    const pdfImage = await pdfDoc.embedPng(image);
    pdfPage.drawImage(pdfImage, {
      width: A4Page.WIDTH,
      height: A4Page.HEIGHT
    });
  });

  /* Pdf base64 string size seems to be the issue with iFrame not working reliably. */
  /* So far, 2087kb is the largest size which has been shown successfully in the pdf preview component. */
  /* Number of pages doesn't seem to be the issue. 50 pages of OLS was handled just fine (1794kb) */

  const pdfStr = await pdfDoc.saveAsBase64();

  return pdfStr;
};

/* Apparently, true h/w ratio for paper is 1.4143. */
/* 841:601 ~= 1.3993. */
/* Had to increase width to fit images inside real pdf pages; solves issue */
/* with small gap at top and bottom of PDF pages. */
/* TODO: move to better place. */
export enum A4Page {
  HEIGHT = 841,
  WIDTH = 601
}

export const PdfViewer = ({ pdfBytes }: { pdfBytes: string }) => {
  const btnRef = useRef<HTMLButtonElement>(null!);

  /* Holy shit it werks */
  useEffect(() => {
    btnRef.current.onclick = () => {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${pdfBytes}`;
      link.download = "foobarbaz.pdf";
      link.click();
    };
  }, []);

  /* Possible alternaties for print icon: */
  /* https://www.flaticon.com/search?type=icon&word=finish&license=&color=&shape=&current_section=&author_id=&pack_id=&family_id=&style_id=&choice=&type= */
  /* https://www.flaticon.com/free-icon/pdf_179482?term=pdf&page=2&position=64&page=2&position=64&related_id=179482&origin=tag */

  return (
    <Center bg="green.100" flex={1} gridGap="2em">
      <Button leftIcon={<Icon as={FiDownload} />} ref={btnRef} onClick={() => {}}>
        Download pdf
      </Button>
      <Button disabled leftIcon={<Icon as={FiSend} />}>
        An meine E-Mail schicken
      </Button>
    </Center>
  );
};
