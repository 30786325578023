import { ETaskFontVariant, TaskFont, ETypeface, TTaskTypeface } from "../shared";

/* --- Common nordic ------ */
export const nordicStartpoints: TaskFont = {
  fontFamily: "nordicStartpointsOnly",
  typeface: null!,
  variant: ETaskFontVariant.STARTPOINTS
};
/* ------------------------ */

const nordicSerifDotted: TaskFont = {
  fontFamily: "nordicSerifDotted",
  typeface: ETypeface.NORDIC_SERIF,
  variant: ETaskFontVariant.DOTTED
};

const nordicSerifDottedStartpoints: TaskFont = {
  fontFamily: "nordicSerifDottedStartpoints",
  typeface: ETypeface.NORDIC_SERIF,
  variant: ETaskFontVariant.DOTTED_STARTPOINTS
};

const nordicSerifRegular: TaskFont = {
  fontFamily: "nordicSerifRegular",
  typeface: ETypeface.NORDIC_SERIF,
  variant: ETaskFontVariant.REGULAR
};

const nordicSerifOutline: TaskFont = {
  fontFamily: "nordicSerifOutline",
  typeface: ETypeface.NORDIC_SERIF,
  variant: ETaskFontVariant.OUTLINE
};

const nordicSerifOutlineStartpoints: TaskFont = {
  fontFamily: "nordicSerifOutlineStartpoints",
  typeface: ETypeface.NORDIC_SERIF,
  variant: ETaskFontVariant.OUTLINE_STARTPOINTS
};

export const nordicSerif: TTaskTypeface = {
  id: "6bd05f21-a530-43ca-9576-57b59e695931",
  name: ETypeface.NORDIC_SERIF,
  nameForDisplay: "iMal A",
  fonts: {
    dotted: nordicSerifDotted,
    dottedStartpoints: nordicSerifDottedStartpoints,
    outline: nordicSerifOutline,
    outlineStartpoints: nordicSerifOutlineStartpoints,
    regular: nordicSerifRegular,
    startpoints: {
      ...nordicStartpoints,
      typeface: ETypeface.NORDIC_SERIF
    } as TaskFont
  }
} as const;
