import React, { useEffect, useRef, useState } from "react";

export const useHover = () => {
  const [hasHover, setHasHover] = useState(false);

  return {
    cursor: hasHover ? "pointer" : "default",
    hasHover,
    hoverHandlers: {
      onMouseLeave: () => setHasHover(false),
      onMouseOver: () => setHasHover(true)
    }
  };
};

/* Couldn't make work, but could error be because this is using mouseOut */
/* and not mouseLeave? */
export const useHover2 = () => {
  const [value, setValue] = useState(false);
  const ref = useRef<any>(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);
      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [ref.current]);
  return [ref, value] as [React.MutableRefObject<any>, boolean];
};
